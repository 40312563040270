import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button from '../../../components/UI/Button/Button';
import ConfirmDialog from '../../../components/UI/ConfirmDialog/ConfirmDialog';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { defaultModal, EModalSize } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../../context/InputContext';
import { buildingTypes, languages } from '../../../data/select-data';
import { EBuildingType, EOfferRequestType } from '../../../enums';
import { useAuthUser } from '../../../hooks/useAuthUser';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { useServiceOptions } from '../../../hooks/useServiceOptions';
import { IAppState, IOfferRequest, IOfferRequestState, IService, IServiceState } from '../../../interfaces';
import { formatDateString, getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

export enum EOfferRequest {
  operator = "operator",
  firstName = "firstName",
  lastName = "lastName",
  address = "address",
  phone = "phone",
  email = "email",
  service = "service",
  archived = "archived",
  area = "area",
  buildingType = "buildingType",
  buildingTypeOther = "buildingTypeOther",
  constructionYear = "constructionYear",
  zip = "zip",
  city = "city",
  additionalInfo = "additionalInfo",
  province = "province",
  language = "language",
  length = "length",
  regular = "regular"
}

const OfferRequest: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { setModal } = useContext(ModalContext);
  const { organizationOptions, organizationsLoading } = useOrganizationOptions({
    withBusinessId: true,
    withPlaceholder: true,
  });
  const [service, setService] = useState<IService | null>(null);

  const authUser = useAuthUser();
  const { loading, error, offerRequest, saveOrUpdateOk } = useSelector<IAppState, IOfferRequestState>(state => state.offerRequest);
  const { services } = useSelector<IAppState, IServiceState>(state => state.services);

  const { t } = useTranslation();
  const { id } = match.params;

  const [inputs, setInputs] = useState<IInputField>({
    [EOfferRequest.operator]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_OPERATOR_NAME,
      placeholderTranslation: ETranslation.COMMON_OPERATOR_NAME,
      value: "",
      options: [],
      validation: {
        required: authUser?.isPrimary,
      },
    },
    [EOfferRequest.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFERREQUEST_FIRSTNAME,
      placeholderTranslation: ETranslation.OFFERREQUEST_FIRSTNAME,
      value: "",
    },
    [EOfferRequest.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFERREQUEST_LASTNAME,
      placeholderTranslation: ETranslation.OFFERREQUEST_LASTNAME,
      value: "",
    },
    [EOfferRequest.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
    },
    [EOfferRequest.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
    },
    [EOfferRequest.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
    },
    [EOfferRequest.phone]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_PHONE,
      placeholderTranslation: ETranslation.COMMON_PHONE,
      value: "",
      validation: {
        required: true,
      },
    },
    [EOfferRequest.email]: {
      type: EInputType.email,
      labelTranslation: ETranslation.COMMON_EMAIL,
      placeholderTranslation: ETranslation.COMMON_EMAIL,
      value: "",
      validation: {
        required: true,
      },
    },
    [EOfferRequest.service]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_SERVICE,
      placeholderTranslation: ETranslation.COMMON_SERVICE,
      value: "",
      options: [],
      validation: {
        required: true,
      },
    },
    [EOfferRequest.archived]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.COMMON_ARCHIVED,
      placeholderTranslation: ETranslation.COMMON_ARCHIVED,
      value: "",
    },
    [EOfferRequest.area]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA,
      placeholderTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA,
      value: "",
    },
    [EOfferRequest.buildingType]: {
      type: EInputType.select,
      labelTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE,
      placeholderTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE,
      options: buildingTypes,
      value: EBuildingType.TOWN_HOUSE,
    },
    [EOfferRequest.buildingTypeOther]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER,
      placeholderTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER,
      value: "",
    },
    [EOfferRequest.constructionYear]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR,
      placeholderTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR,
      value: "",
    },
    [EOfferRequest.additionalInfo]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.OFFERREQUEST_INFO,
      placeholderTranslation: ETranslation.OFFERREQUEST_INFO,
      value: "",
    },
    [EOfferRequest.province]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFER_REQUEST_PROVINCE,
      placeholderTranslation: ETranslation.OFFER_REQUEST_PROVINCE,
      value: "",
    },
    [EOfferRequest.language]: {
      type: EInputType.select,
      labelTranslation: ETranslation.OFFER_REQUEST_LANGUAGE,
      placeholderTranslation: ETranslation.OFFER_REQUEST_LANGUAGE,
      value: "",
      options: []
    },
    [EOfferRequest.length]: {
      type: EInputType.select,
      labelTranslation: ETranslation.OFFER_REQUEST_WELLNESS_LENGTH,
      placeholderTranslation: ETranslation.OFFER_REQUEST_WELLNESS_LENGTH,
      value: "",
      options: []
    },
    [EOfferRequest.regular]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.OFFER_REQUEST_WELLNESS_REGULAR,
      placeholderTranslation: ETranslation.OFFER_REQUEST_WELLNESS_REGULAR,
      value: "",
    },
  });

  const [isValid, setIsValid] = useState<boolean>(false);

  let operatorId;
  if (authUser?.isOperator) {
    operatorId = authUser.organization.id;
  } else {
    operatorId = inputs[EOfferRequest.operator].value as string;
  }

  const { serviceOptions, servicesLoading } = useServiceOptions({
    withPlaceholder: true,
    filterByOperatorId: true,
    operatorId
  });

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(Routes.OFFER_REQUESTS);
    }
    return () => {
      dispatch(actions.clearOfferRequest());
    };
  }, [dispatch, history, saveOrUpdateOk]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getOfferRequest(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (offerRequest) {
      initForm(setInputs, offerRequest);
    }
  }, [offerRequest]);

  const serviceId = inputs[EOfferRequest.service].value as string;

  useEffect(() => {
    if (serviceId && services) {
      setService(services.find((service) => service.id === serviceId) || null);
    } else {
      setService(null);
    }
  }, [serviceId, services]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (inputName: EOfferRequest, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={authUser?.isBasic}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.OFFERREQUEST}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const offerRequest = getInputData<IOfferRequest>(inputs);

    if (Routes.isNew(id)) {
      dispatch(actions.saveOfferRequest(offerRequest));
    } else {
      offerRequest.id = id;
      dispatch(actions.updateOfferRequest(offerRequest));
    }
  };

  const deleteOfferRequest = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_REMOVE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          body={<p>{t(ETranslation.CONFIRM_DELETE_OFFER_REQUEST)}</p>}
          onConfirm={() => {
            dispatch(actions.deleteOfferRequest(id));
            history.goBack();
            setModal(defaultModal);
          }}
          onCancel={() => setModal(defaultModal)}
        />
      ),
    });

  const writeContactToClipboardHandler = () => {
   const info = [
        offerRequest?.firstName,
        offerRequest?.lastName,'\n',
        offerRequest?.address,'\n',
        offerRequest?.zip,
        offerRequest?.city,'\n',
        offerRequest?.phone,'\n',
        offerRequest?.email,
        ...offerRequest?.additionalInfo ? ['\n','\n',t(ETranslation.OFFERREQUEST_INFO),'\n'] : [],
        ...offerRequest?.additionalInfo ? [offerRequest?.additionalInfo] : []
   ]
   const notNullInfo = info.filter(function (e) {return e != null;});
   console.log(notNullInfo.join(' ') + " Copied to clipboard");
   return(notNullInfo.join(' '))
  };

  return (
    <>
      {error && <Alert children={error} />}
      <Container>
        <h2>
          {Routes.isNew(id)
            ? t(ETranslation.TITLE_OFFERREQUEST_NEW)
            : t(ETranslation.TITLE_OFFERREQUEST)}
        </h2>
        <p>{offerRequest?.created && formatDateString(offerRequest.created)}</p>
        {loading ? (
          <Spinner />
        ) : offerRequest?.deleted ? (
          <p>{t(ETranslation.OFFER_REQUEST_DELETED)}</p>
        ) : offerRequest?.archived && authUser?.isServiceProvider ? (
          <p>{t(ETranslation.OFFER_REQUEST_ARCHIVED)}</p>
        ) : (
          <>
            {authUser?.isPrimary &&
              createInput(EOfferRequest.operator, {
                options: organizationOptions,
                loading: organizationsLoading,
              })}
            <InputGroup>
              {createInput(EOfferRequest.firstName)}
              {createInput(EOfferRequest.lastName)}
            </InputGroup>
            {service?.type !== EOfferRequestType.WELLNESS ? (
              <InputGroup>
                {createInput(EOfferRequest.address)}
                {createInput(EOfferRequest.zip)}
                {createInput(EOfferRequest.city)}
              </InputGroup>
            ) : (
              <InputGroup>
                {createInput(EOfferRequest.city)}
                {createInput(EOfferRequest.province)}
              </InputGroup>
            )}
            <InputGroup>
              {createInput(EOfferRequest.phone)}
              {createInput(EOfferRequest.email)}
            </InputGroup>
            <InputGroup>
              {createInput(EOfferRequest.service, {
                options: serviceOptions,
                loading: servicesLoading,
              })}
              {createInput(EOfferRequest.language, {
                options: languages
              })}
            </InputGroup>
            {service?.type === EOfferRequestType.CONSTRUCTION ? (
              <>
                <InputGroup>
                  {createInput(EOfferRequest.area)}
                  {createInput(EOfferRequest.constructionYear)}
                </InputGroup>
                <InputGroup>
                  {createInput(EOfferRequest.buildingType)}
                  {inputs[EOfferRequest.buildingType].value ===
                  EBuildingType.OTHER
                    ? createInput(EOfferRequest.buildingTypeOther)
                    : null}
                </InputGroup>
              </>
            ) : null}
            {/*service?.type === EOfferRequestType.WELLNESS ? (
              <>
                {createInput(EOfferRequest.length, {
                  options: wellnessLength
                })}
                {createInput(EOfferRequest.regular)}
              </>
              ) : null*/}
            {createInput(EOfferRequest.additionalInfo)}
            {createInput(EOfferRequest.archived)}
          </>
        )}
        <InputGroup>
          {!authUser?.isBasic && !offerRequest?.deleted && (
            <Button disabled={loading || !isValid} onClick={submitHandler}>
              {t(ETranslation.COMMON_SAVE)}
            </Button>
          )}
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
          {(authUser?.isPrimary || authUser?.isOperator) &&
            Routes.isNotNew(id) &&
            !offerRequest?.deleted && (
              <Button disabled={loading} onClick={deleteOfferRequest}>
                {t(ETranslation.COMMON_REMOVE)}
              </Button>
            )}
          <Button onClick={() => {navigator.clipboard.writeText(writeContactToClipboardHandler()); alert(t(ETranslation.COMMON_COPIED));}}>
            {t(ETranslation.COMMON_COPY_CONTACT_INFORMATION)}
          </Button>
        </InputGroup>

        {(authUser?.isPrimary || authUser?.isOperator) &&
          offerRequest?.activeCampaignId && (
            <div style={{ marginTop: "1rem" }}>
              <Button
                onClick={() =>
                  window.open(
                    "https://positiveproductions.activehosted.com/app/contacts/" +
                      offerRequest?.activeCampaignId,
                    "_blank"
                  )
                }
              >
                ActiveCampaign contact: {offerRequest?.activeCampaignId}
              </Button>
            </div>
          )}
      </Container>
    </>
  );
};

export default OfferRequest;
