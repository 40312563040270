import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IUser, IUserAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const apiPath = '/json/users';

const getUserStart = (): IUserAction => {
  return {
    type: ActionTypes.USER_GET_START,
  };
};

const getUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.USER_GET_SUCCESS,
    user,
  };
};

const getUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.USER_GET_FAIL,
    error,
  };
};

export const getUser = (id: string, organizationId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserStart());
    try {
      const params = [`id=${id}`];
      if(organizationId) params.push(`organizationId=${organizationId}`);
      
      const res = await axios.get(`${apiPath}/get?${params.join('&')}`);
      dispatch(getUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(getUserFail((response as AxiosResponse).data));
    }
  };
};

const listUsersStart = (): IUserAction => {
  return {
    type: ActionTypes.USER_LIST_START,
  };
};

const listUsersSuccess = (users: Array<IUser>): IUserAction => {
  return {
    type: ActionTypes.USER_LIST_SUCCESS,
    users,
  };
};

const listUsersFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.USER_LIST_FAIL,
    error,
  };
};

export const listUsers = (organizationId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(listUsersStart());
    try {
      let param = ``;
      if(organizationId) param = `?organizationId=${organizationId}`;
      const res = await axios.get(`${apiPath}/list${param}`);
      dispatch(listUsersSuccess(res.data));
    } catch ({ response }) {
      dispatch(listUsersFail((response as AxiosResponse).data));
    }
  };
};

const saveUserStart = (): IUserAction => {
  return {
    type: ActionTypes.USER_SAVE_START,
  };
};

const saveUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.USER_SAVE_SUCCESS,
    user,
  };
};

const saveUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.USER_SAVE_FAIL,
    error,
  };
};

export const saveUser = (user: IUser, organizationId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveUserStart());
    const param = organizationId ? `?organizationId=${organizationId}` : '';
    try {
      const res = await axios.post(
        `${apiPath}/add${param}`,
        user
      );
      dispatch(saveUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveUserFail((response as AxiosResponse).data));
    }
  };
};

const updateUserStart = (): IUserAction => {
  return {
    type: ActionTypes.USER_UPDATE_START,
  };
};

const updateUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.USER_UPDATE_SUCCESS,
    user,
  };
};

const updateUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.USER_UPDATE_FAIL,
    error,
  };
};

export const updateUser = (user: IUser, organizationId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    const param = organizationId ? `?organizationId=${organizationId}` : '';
    try {
      const res = await axios.put(
        `${apiPath}/update${param}`,
        user
      );
      dispatch(updateUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateUserFail((response as AxiosResponse).data));
    }
  };
};

const acceptTermsOfUseStart = (): IUserAction => {
  return {
    type: ActionTypes.USER_ACCEPT_TERMS_OF_USE_START,
  };
};

const acceptTermsOfUseSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.USER_ACCEPT_TERMS_OF_USE_SUCCESS,
    user,
  };
};

const acceptTermsOfUseFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.USER_ACCEPT_TERMS_OF_USE_FAIL,
    error,
  };
};

export const acceptTermsOfUse = () => {
  return async (dispatch: Dispatch) => {
    dispatch(acceptTermsOfUseStart());
    try {
      const res = await axios.put(`${apiPath}/acceptTerms`);
      dispatch(acceptTermsOfUseSuccess(res.data));
    } catch ({ response }) {
      dispatch(acceptTermsOfUseFail((response as AxiosResponse).data));
    }
  };
};

const changeUserLanguageStart = (): IUserAction => {
  return {
    type: ActionTypes.USER_CHANGE_LANGUAGE_START,
  };
};

const changeUserLanguageSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.USER_CHANGE_LANGUAGE_SUCCESS,
    user,
  };
};

const changeUserLanguageFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.USER_CHANGE_LANGUAGE_FAIL,
    error,
  };
};

export const changeUserLanguage = (preferredLanguage: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(changeUserLanguageStart());
    try {
      const res = await axios.put(`${apiPath}/changeLanguage`, { preferredLanguage });
      dispatch(changeUserLanguageSuccess(res.data));
    } catch ({ response }) {
      dispatch(changeUserLanguageFail((response as AxiosResponse).data));
    }
  };
};

export const clearUser = (): IUserAction => {
  return {
    type: ActionTypes.USER_CLEAR,
  };
};
