import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { ERoute } from './classes/Routes';

import { AuthUser } from './classes/AuthUser';

interface IProps extends RouteProps {
  user: AuthUser;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const GuardedRoute: React.FC<IProps> = ({ component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user.routes.includes(rest.path as ERoute) ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={user.redirect} />
      )
    }
  />
);

export default GuardedRoute;
