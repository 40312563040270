import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button from '../../../components/UI/Button/Button';
import ConfirmDialog from '../../../components/UI/ConfirmDialog/ConfirmDialog';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { defaultModal, EModalSize } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../../context/InputContext';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { IAppState, IAuthState, ILocation, ILocationState } from '../../../interfaces';
import { getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum ELocation {
  operator = "operator",
  locationToken = "locationToken",
  name = "name",
  nameGenetive = "nameGenitive",
  nameInessive = "nameInessive",
  content = "content",
  latitude = "latitude",
  longitude = "longitude",
  zoomLevel = "zoomLevel",
}

const Location: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const { setModal } = useContext(ModalContext);
  
  const [isValid, setIsValid] = useState<boolean>(false);
  
  const { loading, error, location, saveOrUpdateOk } = useSelector<IAppState, ILocationState>((state) => state.locations);
  const { authUser } = useSelector<IAppState, IAuthState>((state) => state.auth);

  const { organizationOptions, organizationsLoading } = useOrganizationOptions({ withBusinessId: true, withPlaceholder: true });

  const { id } = match.params;

  const [inputs, setInputs] = useState<IInputField>({
    [ELocation.operator]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_OPERATOR_NAME,
      placeholderTranslation: ETranslation.COMMON_OPERATOR_NAME,
      value: "",
      options: [],
      validation: {
        required: authUser?.isPrimary,
      },
    },
    [ELocation.locationToken]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_TOKEN,
      value: "",
    },
    [ELocation.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LOCATION_NAME,
      placeholderTranslation: ETranslation.LOCATION_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [ELocation.nameGenetive]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LOCATION_NAME_GENITIVE,
      placeholderTranslation: ETranslation.LOCATION_NAME_GENITIVE,
      value: "",
    },
    [ELocation.nameInessive]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LOCATION_NAME_INESSIVE,
      placeholderTranslation: ETranslation.LOCATION_NAME_INESSIVE,
      value: "",
    },
    [ELocation.content]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.LOCATION_CONTENT,
      placeholderTranslation: ETranslation.LOCATION_CONTENT,
      value: "",
    },
    [ELocation.latitude]: {
      type: EInputType.number,
      labelTranslation: ETranslation.LOCATION_LATITUDE,
      placeholderTranslation: ETranslation.LOCATION_LATITUDE,
      value: "",
      validation: {
        required: true
      }
    },
    [ELocation.longitude]: {
      type: EInputType.number,
      labelTranslation: ETranslation.LOCATION_LONGITUDE,
      placeholderTranslation: ETranslation.LOCATION_LONGITUDE,
      value: "",
      validation: {
        required: true
      }
    },
    [ELocation.zoomLevel]: {
      type: EInputType.number,
      labelTranslation: ETranslation.LOCATION_ZOOM_LEVEL,
      placeholderTranslation: ETranslation.LOCATION_ZOOM_LEVEL,
      value: 8,
    },
  });


  useEffect(() => {
    if(saveOrUpdateOk) {
      history.push(Routes.LOCATIONS);
    }
    return () => {
      dispatch(actions.clearLocation());
    }
  }, [dispatch, saveOrUpdateOk, history]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getLocation(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (location) {
      initForm(setInputs, location);
    }
  }, [location]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (inputName: ELocation, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.LOCATION}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const location = getInputData<ILocation>(inputs);

    if (Routes.isNew(id)) {
      dispatch(actions.saveLocation(location));
    } else {
      location.id = id;
      dispatch(actions.updateLocation(location));
    }
  };

  const deleteLocation = () => setModal({
    isOpen: true,
    title: t(ETranslation.COMMON_REMOVE),
    size: EModalSize.SMALL,
    content: (
      <ConfirmDialog
        body={<p>{t(ETranslation.CONFIRM_DELETE_LOCATION)}</p>}
        onConfirm={() => {
          dispatch(actions.deleteLocation(id))
          setModal(defaultModal);
          history.goBack();
        }}
        onCancel={() => setModal(defaultModal)}
      />
    ),
  });

  return (
    <Container>
      {error && <Alert children={error} />}
      <h2>{Routes.isNew(id) ? t(ETranslation.LOCATIONS_NEW) : t(ETranslation.LOCATION)}</h2>
      {loading
        ? <Spinner />
        : (
          <>  
            { authUser?.isPrimary && createInput(ELocation.operator, { options: organizationOptions, loading: organizationsLoading }) }
            <InputGroup>
              {createInput(ELocation.name)}
              {createInput(ELocation.nameGenetive)}
              {createInput(ELocation.nameInessive)}
            </InputGroup>
            {createInput(ELocation.content)}
            <InputGroup>
              {createInput(ELocation.latitude)}
              {createInput(ELocation.longitude)}
            </InputGroup>
            {createInput(ELocation.zoomLevel)}
            {Routes.isNotNew(id) && (
                createInput(ELocation.locationToken)
            )}
          </>
        )
      }
      <InputGroup>
        <Button disabled={loading || !isValid} onClick={submitHandler}>
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        <Button onClick={() => history.goBack()}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {Routes.isNotNew(id) && (
          <Button disabled={loading} onClick={deleteLocation}>
            {t(ETranslation.COMMON_REMOVE)}
          </Button>
        )}
      </InputGroup>
    </Container>
  );
};

export default Location;
