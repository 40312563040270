import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Reducer,
} from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import App from "./App";
import "./i18n";
import "./index.scss";
import { IAppState } from "./interfaces";
import { ActionTypes } from "./store/actions/actionTypes";
import {
  authReducer,
  offerRequestReducer,
  organizationReducer,
  serviceProviderReducer,
  serviceReducer,
  usersReducer,
  locationsReducer,
  feedbackReducer,
  contentReducer,
  geoLocationReducer,
  logItemReducer
} from "./store/reducers";
import ReactGA from 'react-ga4';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const appReducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  users: usersReducer,
  offerRequest: offerRequestReducer,
  serviceProvider: serviceProviderReducer,
  services: serviceReducer,
  organization: organizationReducer,
  locations: locationsReducer,
  feedbacks: feedbackReducer,
  content: contentReducer,
  geoLocation: geoLocationReducer,
  logItem: logItemReducer
});

const rootReducer = (state: IAppState | undefined, action: { type: ActionTypes }) => {
  if (action.type === ActionTypes.AUTH_LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  } 
  return appReducer(state, action);
}

export type TDispatch = ThunkDispatch<IAppState, void, AnyAction>;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const root = (
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

ReactGA.initialize("G-1K886T9PEB")

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
