import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, ReactNode } from "react";
import Spinner, { ESpinnerColor, ESpinnerSize } from "../Spinner/Spinner";
import classes from "./Button.module.scss";

export enum EButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum EButtonColor {
  PRIMARY,
  SECONDARY,
  SUCCESS,
  DANGER,
  NONE,
}

interface IProps {
  icon?: IconDefinition;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  size?: EButtonSize;
  color?: EButtonColor;
  type?: "submit" | "reset" | "button";
  link?: boolean;
  loading?: boolean;
}

const Button: React.FC<IProps> = ({
  icon,
  onClick,
  disabled,
  children,
  className,
  style,
  size,
  color,
  type = "button",
  link,
  loading,
}) => {
  const classNames = [];

  if (link) {
    classNames.push(classes.Link);
  } else {
    classNames.push(classes.Button);
  }

  if (disabled) classNames.push(classes.Disabled);
  if (className) classNames.push(className);

  switch (size) {
    case EButtonSize.SMALL:
      classNames.push(classes.Small);
      break;
    case EButtonSize.LARGE:
      classNames.push(classes.Large);
      break;
    default:
  }

  switch (color) {
    case EButtonColor.SECONDARY:
      classNames.push(classes.Secondary);
      break;
    case EButtonColor.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EButtonColor.DANGER:
      classNames.push(classes.Danger);
      break;
    case EButtonColor.NONE:
      classNames.push(classes.None);
      break;
    default:
  }

  return (
    <button
      className={classNames.join(" ")}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      style={style}
    >
      {loading ? (
        <Spinner color={ESpinnerColor.CONTENT} size={ESpinnerSize.SMALL} />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={icon} />} {children}
        </>
      )}
    </button>
  );
};

export default Button;
