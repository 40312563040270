import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { AuthUser } from "../../classes/AuthUser";
import firebase from "../../firebase";
import { IAuthAction, IOrganization } from "../../interfaces";
import { ActionTypes } from "./actionTypes";
import ReactGA from 'react-ga4';

const apiPath = "/json/auth";

const authStart = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_START,
  };
};

const authSuccess = (
  user: AuthUser | null,
  firebaseToken: string
): IAuthAction => {
  return {
    type: ActionTypes.AUTH_SUCCESS,
    user,
  };
};

const authFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_FAIL,
    error,
  };
};

export const auth = (email: string, password: string, preferredLanguage: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(authStart());
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (response) => {
          if (response.user) {
            const tokenResult = await response.user.getIdTokenResult();
            if (tokenResult) {
              const res = await axios.post(`${apiPath}/login`, { preferredLanguage });

              ReactGA.event({category: "auth", action: "login",});

              dispatch(authSuccess(new AuthUser(res.data), tokenResult.token));
            } else {
              dispatch(authFail("error"));
            }
          } else {
            dispatch(authFail("error"));
          }
        })
        .catch((error) => {
          dispatch(authFail(error.message));
        });
    } catch ({ response }) {
      dispatch(authFail((response as AxiosResponse).data));
    }
  };
};

const logoutStart = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_LOGOUT_START,
  };
};

const logoutSuccess = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_LOGOUT_SUCCESS,
  };
};

const logoutFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_LOGOUT_FAIL,
    error,
  };
};

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch(logoutStart());
    try {
      await axios.post(`${apiPath}/logout`);
      firebase.auth().signOut();
      dispatch(logoutSuccess());
    } catch (error) {
      dispatch(logoutFail(error as string));
    }
  };
};

const getCurrentUserStart = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_START,
  };
};

const getCurrentUserSuccess = (user: AuthUser | null): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_SUCCESS,
    user,
  };
};

const getCurrentUserFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_FAIL,
    error,
  };
};

export const getCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getCurrentUserStart());
    try {
      const res = await axios.post(`${apiPath}/login`);
      dispatch(getCurrentUserSuccess(new AuthUser(res.data)));
    } catch ({ response }) {
      dispatch(getCurrentUserFail((response as AxiosResponse).data));
    }
  };
};

const getCustomTokenStart = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_CUSTOM_TOKEN_START,
  };
};

const getCustomTokenSuccess = (customToken: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_CUSTOM_TOKEN_SUCCESS,
    customToken
  };
};

const getCustomTokenFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_GET_CUSTOM_TOKEN_FAIL,
    error,
  };
};

export const getCustomToken = (token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomTokenStart());
    try {
      const resp = await axios.get<string>(`${apiPath}/getCustomToken?token=${token}`);
      dispatch(getCustomTokenSuccess(resp.data));
    } catch ({ response }) {
      dispatch(getCustomTokenFail((response as AxiosResponse).data));
    }
  };
};

export const addAuthUserOfferRequestAccess = (offerRequestId: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_ADD_OFFER_REQUEST_ACCESS,
    offerRequestId
  };
};

export const setAuthUserBillingConfirmed = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_SET_BILLING_CONFIRMED,
  };
};

export const setAuthUserOrganization = (organization: IOrganization): IAuthAction => {
  return {
    type: ActionTypes.AUTH_SET_ORGANIZATION,
    organization
  };
};