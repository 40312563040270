import { IGeoLocationAction } from './../../interfaces/state';
import { Dispatch } from "redux";
import axios from "../../axios";
import { IGeoLocation } from "../../interfaces/domain";
import { ActionTypes } from "./actionTypes";
import { AxiosResponse } from 'axios';

type TAction = IGeoLocationAction;

const getGeoLocationStart = (): TAction => {
  return {
    type: ActionTypes.GEOLOCATION_GET_START,
  };
};

const getGeoLocationSuccess = (geoLocation: IGeoLocation): TAction => {
  return {
    type: ActionTypes.GEOLOCATION_GET_SUCCESS,
    geoLocation,
  };
};

const getGeoLocationFail = (error: string): TAction => {
  return {
    type: ActionTypes.GEOLOCATION_GET_FAIL,
    error,
  };
};

export const getGeoLocation = (address: string, zip: string, city: string, country: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getGeoLocationStart());
    try {
      const params = [
        "address=" + encodeURIComponent(address),
        "zip=" + encodeURIComponent(zip),
        "city=" + encodeURIComponent(city),
        "country=" + encodeURIComponent(country),
      ];
      const res = await axios.get(`/json/geolocation/get?${params.join('&')}`);
      dispatch(getGeoLocationSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getGeoLocationFail((response as AxiosResponse).data));
      return null;
    }
  };
};