import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Alert, { EAlertColor } from '../../../components/UI/Alert/Alert';
import Button  from '../../../components/UI/Button/Button';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions, IOption } from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { IAppState, IFeedback, IFeedbackState } from '../../../interfaces';
import { createOptions } from '../../../shared/option-utils';
import { getInputData, updateInputHandler } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

export enum EFeedback {
  serviceProvider = "serviceProvider",
  serviceProviderOther = "serviceProviderOther",
  serviceSatisfaction = "serviceSatisfaction",
  operatorToken = "operatorToken",
  serviceToken = "serviceToken",
  message = "message",
}

const Feedback: React.FC<IProps> = ({ history, location }) => {
  const [id, setId] = useState("");
  const [pin, setPin] = useState("");

  const [serviceProviderOptions, setServiceProviderOptions] = useState<
    IOption[]
  >([]);

  useEffect(() => {
    const q = qs.parse(location.search);
    setId(q.id as string);
    setPin(q.pin as string);
  }, [location]);


  const { loading, serviceProviders, saveOk, error } = useSelector<IAppState, IFeedbackState>(state => state.feedbacks)

  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [inputs, setInputs] = useState<IInputField>({
    [EFeedback.serviceSatisfaction]: {
      type: EInputType.star,
      labelTranslation: ETranslation.FEEDBACK_SATISFACTION,
      placeholderTranslation: ETranslation.FEEDBACK_SATISFACTION,
      value: "",
      options: [
        { value: "1" },
        { value: "2" },
        { value: "3" },
        { value: "4" },
        { value: "5" },
      ],
    },

    [EFeedback.serviceProvider]: {
      type: EInputType.select,
      labelTranslation: ETranslation.FEEDBACK_SERVICEPROVIDER,
      placeholderTranslation: ETranslation.FEEDBACK_SERVICEPROVIDER,
      value: ETranslation.FEEDBACK_FORM_NOT_FOUND,
      options: [],
    },
    [EFeedback.serviceProviderOther]: {
      type: EInputType.text,
      labelTranslation: ETranslation.FEEDBACK_SERVICE_PROVIDER_OTHER,
      placeholderTranslation: ETranslation.FEEDBACK_SERVICE_PROVIDER_OTHER,
      value: "",
    },
    [EFeedback.serviceToken]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_TOKEN,
      value: "",
    },
    [EFeedback.message]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.FEEDBACK_MESSAGE,
      placeholderTranslation: ETranslation.FEEDBACK_MESSAGE,
      value: "",
    },
  });

  // get offer request
  useEffect(() => {
    if (id && pin) {
      dispatch(actions.getFeedbackFormData(id, pin));
    }
  }, [dispatch, id, pin]);

  // put service providers into input
  useEffect(() => {
    if (serviceProviders) {
      const options = createOptions(serviceProviders, (s) => s.name || "");
      options.unshift({
        value: ETranslation.FEEDBACK_FORM_NOT_FOUND,
        label: t(ETranslation.FEEDBACK_FORM_NOT_FOUND),
      });
      options.push({
        value: ETranslation.FEEDBACK_FORM_SOMETHING_ELSE,
        label: t(ETranslation.FEEDBACK_FORM_SOMETHING_ELSE),
      });
      setServiceProviderOptions(options);
    }
  }, [serviceProviders, t]);

  const createInput = (inputName: EFeedback, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={false}
        {...item}
        {...options}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  };

  const submitHandler = async (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const feedback = getInputData<IFeedback>(inputs);

    const serviceProvider = inputs[EFeedback.serviceProvider].value as string;
    if (serviceProvider) {
      switch (serviceProvider) {
        case ETranslation.FEEDBACK_FORM_NOT_FOUND:
          feedback.isServiceProviderNotFound = true;
          feedback.serviceProvider = undefined;
          break;
        case ETranslation.FEEDBACK_FORM_SOMETHING_ELSE:
          feedback.isServiceProviderOther = true;
          feedback.serviceProvider = undefined;
          break;
      }
    }
    
    dispatch(actions.saveFeedback(feedback, id, pin));
  };

  const serviceSatification = inputs[EFeedback.serviceSatisfaction]
    .value as string;
  const serviceProvider = inputs[EFeedback.serviceProvider].value as string;

  if (saveOk) {
    return (
      <Alert color={EAlertColor.SUCCESS}>
        {t(ETranslation.FEEDBACK_THANK_YOU_MESSAGE)}
      </Alert>
    )
  }

  if (error) {
    return <Alert>{t(error)}</Alert>
  }

  return (
    <Container>
      <h2>{t(ETranslation.TITLE_FEEDBACKS)}</h2>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={submitHandler}>
          {createInput(EFeedback.serviceSatisfaction)}
          {inputs[EFeedback.serviceSatisfaction].value && (
            <>
              {createInput(EFeedback.message)}
              {createInput(EFeedback.serviceProvider, {
                options: serviceProviderOptions,
                loading,
              })}
              {serviceProvider ===
                ETranslation.FEEDBACK_FORM_SOMETHING_ELSE &&
                createInput(EFeedback.serviceProviderOther)}
            </>
          )}
        </form>
      )}
      <div>
        <Button
          type="submit"
          disabled={loading || !serviceSatification}
          onClick={submitHandler}
        >
          {t(ETranslation.COMMON_SEND)}
        </Button>
      </div>
    </Container>
  );
};

export default Feedback;
