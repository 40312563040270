export enum ETranslation {
  // Common
  COMMON_SAVE = "COMMON_SAVE",
  COMMON_RETURN = "COMMMON_RETURN",
  COMMON_ADD = "COMMON_ADD",
  COMMON_REMOVE = "COMMON_REMOVE",
  COMMON_COPY = "COMMON_COPY",
  COMMON_STREET_ADDRESS = "COMMON_STREET_ADDRESS",
  COMMON_ZIP = "COMMON_ZIP",
  COMMON_CITY = "COMMON_CITY",
  COMMON_NO_CHOICE = "COMMON_NO_CHOICE",
  COMMON_CREATED = "COMMON_CREATED",
  COMMON_YES = "COMMON_YES",
  COMMON_NO = "COMMON_NO",
  COMMON_NAME = "COMMON_NAME",
  COMMON_COUNTRY = "COMMON_COUNTRY",
  COMMON_PHONE = "COMMON_PHONE",
  COMMON_EMAIL = "COMMON_EMAIL",
  COMMON_WWW = "COMMON_WWW",
  COMMON_FACEBOOK = "COMMON_FACEBOOK",
  COMMON_TWITTER = "COMMON_TWITTER",
  COMMON_INSTAGRAM = "COMMON_INSTAGRAM",
  COMMON_LINKEDIN = "COMMON_LINKEDIN",
  COMMON_SERVICE = "COMMON_SERVICE",
  COMMON_LATITUDE = "COMMON_LATITUDE",
  COMMON_LONGITUDE = "COMMON_LONGITUDE",
  COMMON_FETCH = 'COMMON_FETCH',
  COMMON_BUY = "COMMON_BUY",

  COMMON_ARCHIVED = "COMMON_ARCHIVED",
  COMMON_TOKEN = "COMMON_TOKEN",
  COMMON_SEND = "COMMON_SEND",
  COMMON_CANCEL = "COMMON_CANCEL",
  COMMON_ADD_ALL = "COMMON_ADD_ALL",
  COMMON_CLOSE = "COMMMON_CLOSE",

  COMMON_OPERATOR_NAME = "COMMON_OPERATOR_NAME",
  COMMON_SERVICE_PROVIDER_NAME = "COMMON_SERVICE_PROVIDER_NAME",

  COMMON_COPY_CONTACT_INFORMATION = "COMMON_COPY_CONTACT_INFORMATION",
  COMMON_COPIED = "COMMON_COPIED",
  COMMON_CONTINUE = "COMMON_CONTINUE",
  COMMON_CONFIRM = "COMMON_CONFIRM",
  COMMON_LOAD_ALL = "COMMON_LOAD_ALL",

  // GeoLocation
  GEO_LOCATION_ERROR = 'GEO_LOCATION_ERROR',

  // Auth
  TITLE_HANKIX = "TITLE_HANKIX",
  LOGIN = "LOGIN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",

  // Auth Inputs
  USERNAME = "USERNAME",
  PASSWORD = "PASSWORD",
  PASSWORD_MIN_LENGTH_MESSAGE = "PASSWORD_MIN_LENGTH_MESSAGE",

  // Header
  ADMIN = "ADMIN",
  OPERATORS = "OPERATORS",
  ORGANIZATIONS = "ORGANIZATIONS",
  OFFERREQUESTS = "OFFERREQUESTS",
  SERVICEPROVIDERS = "SERVICEPROVIDERS",
  SERVICES = "SERVICES",
  FEEDBACKS = "FEEDBACKS",
  USERS = "USERS",
  LOGOUT = "LOGOUT",
  SETTINGS = "SETTINGS",
  CONTENTS = "CONTENTS",
  MANAGEMENT = "MANAGEMENT",
  OFFER_REQUEST_ACCESS = "OFFER_REQUEST_ACCESS",
  LOG_ITEMS = "LOG_ITEMS",
  ORDER_SERVICE = "ORDER_SERVICE",

  // Operators
  TITLE_OPERATORS = "TITLE_OPERATORS",
  OPERATORS_ADD_NEW = "OPERATORS_ADD_NEW",
  NO_OPERATORS = "NO_OPERATORS",

  // Operator
  TITLE_OPERATOR = "TITLE_OPERATOR",
  TITLE_OPERATOR_NEW = "TITLE_OPERATOR_NEW",
  OPERATOR_NAME = "OPERATOR_NAME",
  OPERATOR_LEVEL = "OPERATOR_LEVEL",
  OPERATOR_BUSINESS_ID = "OPERATOR_BUSINESS_ID",
  OPERATOR_PIPEDRIVE_ORGANIZATION_ID = "OPERATOR_PIPEDRIVE_ORGANIZATION_ID",

  // OFFERREQUESTS
  TITLE_OFFERREQUESTS = "TITLE_OFFERREQUESTS",
  OFFERREQUESTS_ADD_NEW = "OFFERREQUESTS_ADD_NEW",
  NO_OFFERREQUESTS = "NO_OFFERREQUESTS",

  // OfferRequest Edit
  TITLE_OFFERREQUEST = "TITLE_OFFERREQUEST",
  TITLE_SORT = "TITLE_SORT",
  TITLE_OFFERREQUEST_NEW = "TITLE_OFFERREQUEST_NEW",
  OFFERREQUEST_FIRSTNAME = "OFFERREQUEST_FIRSTNAME",
  OFFERREQUEST_LASTNAME = "OFFERREQUEST_LASTNAME",
  OFFERREQUEST_EMAIL = "OFFERREQUEST_EMAIL",
  OFFERREQUEST_CREATED = "OFFERREQUEST_CREATED",
  OFFERREQUEST_ADDRESS = "OFFERREQUESTS_ADDRESS",
  OFFERREQUEST_PHONE = "OFFERREQUESTS_PHONE",
  OFFERREQUEST_CITY = "OFFERREQUEST_CITY",
  OFFERREQUEST_STATUS = "OFFERREQUEST_STATUS",
  OFFERREQUEST_TYPE = "OFFERREQUEST_TYPE",
  OFFERREQUEST_SEND_DATE = "OFFERREQUEST_SEND_DATE",
  OFFERREQUEST_OPERATOR = "OFFERREQUEST_OPERATOR",
  OFFERREQUEST_INFO = "OFFERREQUEST_INFO",
  OFFER_REQUEST_DELETED = "OFFER_REQUEST_DELETED",
  OFFER_REQUEST_ARCHIVED = "OFFER_REQUEST_ARCHIVED",
  OFFER_REQUEST_PROVINCE = "OFFER_REQUEST_PROVINCE",
  OFFER_REQUEST_LANGUAGE = "OFFER_REQUEST_LANGUAGE",
  OFFER_REQUEST_ADD_ACCESS_ERROR = "OFFER_REQUEST_ADD_ACCESS_ERROR",

  // OfferRequest construction
  OFFER_REQUEST_CONSTRUCTION_AREA = "OFFER_REQUEST_CONSTRUCTION_AREA",
  OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE = "OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE",
  OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER = "OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER",
  OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR = "OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR",

  // OfferRequest construction
  OFFER_REQUEST_WELLNESS_LENGTH = "OFFER_REQUEST_WELLNESS_LENGTH",
  OFFER_REQUEST_WELLNESS_REGULAR = "OFFER_REQUEST_WELLNESS_REGULAR",

  // Offer Request types
  OFFER_REQUEST_TYPE_SIMPLE = "OFFER_REQUEST_TYPE_SIMPLE",
  OFFER_REQUEST_TYPE_CONSTRUCTION = "OFFER_REQUEST_TYPE_CONSTRUCTION",
  OFFER_REQUEST_TYPE_WELLNESS = "OFFER_REQUEST_TYPE_WELLNESS",

  //Offer Request Access
  OFFER_REQUEST_ACCESS_OFFER_REQUEST_CREATED = "OFFER_REQUEST_ACCESS_OFFER_REQUEST_CREATED",
  OFFER_REQUEST_ACCESS_USERNAME = "OFFER_REQUEST_ACCESS_USERNAME",
  OFFER_REQUEST_ACCESS_CREATED = "OFFER_REQUEST_ACCESS_CREATED",
  OFFER_REQUEST_ACCESS_CREATED_START = "OFFER_REQUEST_ACCESS_CREATED_START",
  OFFER_REQUEST_ACCESS_CREATED_END = "OFFER_REQUEST_ACCESS_CREATED_END",
  OFFER_REQUEST_ACCESS_NO_OFFER_REQUESTS = "OFFER_REQUEST_ACCESS_NO_OFFER_REQUESTS",

  // Log Items
  LOG_ITEM_EVENT = "LOG_ITEM_EVENT",
  LOG_ITEM_EVENT_CREATED_START = "LOG_ITEM_EVENT_CREATED_START",
  LOG_ITEM_EVENT_CREATED_END = "LOG_ITEM_EVENT_CREATED_END",
  LOG_ITEM_USER_AGENT = "LOG_ITEM_USER_AGENT",
  LOG_ITEM_NO_LOG_ITEMS = "LOG_ITEM_NO_LOG_ITEMS",

  // log Items events
  LOG_ITEM_EVENTS_SELECT = "LOG_ITEM_EVENTS_SELECT",
  LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW = "LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW",
  LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW_PIN = "LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW_PIN",
  LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE = "LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE",
  LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE_LOCAL = "LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE_LOCAL",
  LOG_ITEM_EVENTS_OFFER_REQUEST_DELETE = "LOG_ITEM_EVENTS_OFFER_REQUEST_DELETE",
  LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE = "LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE",
  LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE_PIN = "LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE_PIN",
  LOG_ITEM_EVENTS_OFFER_REQUEST_ARCHIVE_PIN = "LOG_ITEM_EVENTS_OFFER_REQUEST_ARCHIVE_PIN",
  LOG_ITEM_EVENTS_OFFER_REQUEST_LIST = "LOG_ITEM_EVENTS_OFFER_REQUEST_LIST",
  LOG_ITEM_EVENTS_OFFER_REQUEST_ADD_ACCESS = "LOG_ITEM_EVENTS_OFFER_REQUEST_ADD_ACCESS",
  LOG_ITEM_EVENTS_USER_ACCEPT_TERMS_OF_USE = "LOG_ITEM_EVENTS_USER_ACCEPT_TERMS_OF_USE",
  LOG_ITEM_EVENTS_ORGANIZATION_CONFIRM_BILLING = "LOG_ITEM_EVENTS_ORGANIZATION_CONFIRM_BILLING",
  LOG_ITEM_EVENTS_AUTH_LOGIN = "LOG_ITEM_EVENTS_AUTH_LOGIN",
  LOG_ITEM_EVENTS_AUTH_LOGOUT = "LOG_ITEM_EVENTS_AUTH_LOGOUT",

  // Building Types
  BUIDLING_TYPE_SELECT = "BUIDLING_TYPE_SELECT",
  BUILDING_TYPE_TOWN_HOUSE = "BUILDING_TYPE_TOWN_HOUSE",
  BUILDING_TYPE_SEMI_DETACHED_HOUSE = "BUILDING_TYPE_SEMI_DETACHED_HOUSE",
  BUILDING_TYPE_ROW_HOUSE = "BUILDING_TYPE_ROW_HOUSE",
  BUILDING_TYPE_DETACHED_HOUSE = "BUILDING_TYPE_DETACHED_HOUSE",
  BUILDING_TYPE_APARTMENT_HOUSE = "BUILDING_TYPE_APARTMENT_HOUSE",
  BUILDING_TYPE_OTHER = "BUILDING_TYPE_OTHER",

  //  OFFERREQUEST statuses
  OFFERREQUEST_STATUS_OFFER = "OFFERREQUEST_STATUS_OFFER",
  OFFERREQUEST_STATUS_OFFERED = "OFFERREQUEST_STATUS_OFFERED",
  OFFERREQUEST_STATUS_COMPLETE = "OFFERREQUEST_STATUS_COMPLETE",

  // Languages
  OFFERREQUEST_LANGUAGE_FI = "OFFERREQUEST_LANGUAGE_FI",
  OFFERREQUEST_LANGUAGE_EN = "OFFERREQUEST_LANGUAGE_EN",
  OFFERREQUEST_LANGUAGE_SE = "OFFERREQUEST_LANGUAGE_SE",

  // ServiceProvider Edit
  TITLE_SERVICEPROVIDER = "TITLE_SERVICEPROVIDER",
  TITLE_SERVICEPROVIDER_NEW = "TITLE_SERVICEPROVIDER_NEW",
  SERVICEPROVIDER_FIRSTNAME = "SERVICEPROVIDER_FIRSTNAME",
  SERVICEPROVIDER_LASTNAME = "SERVICEPROVIDER_LASTNAME",
  SERVICEPROVIDER_EMAIL = "SERVICEPROVIDER_EMAIL",
  SERVICEPROVIDER_CREATED = "SERVICEPROVIDER_CREATED",
  SERVICEPROVIDER_ADDRESS = "SERVICEPROVIDER_ADDRESS",
  SERVICEPROVIDER_PHONE = "SERVICEPROVIDER_PHONE",
  SERVICEPROVIDER_CITY = "SERVICEPROVIDER_CITY",
  SERVICEPROVIDER_STATUS = "SERVICEPROVIDER_STATUS",
  SERVICEPROVIDER_TYPE = "SERVICEPROVIDER_TYPE",
  SERVICEPROVIDER_SEND_DATE = "SERVICEPROVIDER_SEND_DATE",
  SERVICEPROVIDER_OPERATOR = "SERVICEPROVIDER_OPERATOR",
  SERVICEPROVIDER_COMPANY = "SERVICEPROVIDER_COMPANY",
  SERVICEPROVIDER_INVOICING_TYPE = "INVOICING_TYPE",
  SERVICEPROVIDER_EINVOICE_ADDRESS = "EINVOICE_ADDRESS",
  SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS = "EINVOICEOPERATOR_ADDRESS",
  SERVICEPROVIDER_INVOICEINFO = "INVOICEINFO",
  SERVICEPROVIDER_INVOICE_CONFIRM = "SERVICEPROVIDER_INVOICE_CONFIRM",
  SERVICEPROVIDER_INVOICE_MODAL_DESCRIPTION = "SERVICEPROVIDER_INVOICE_MODAL_DESCRIPTION",
  SERVICEPROVIDER_INVOICE_CONFIRM_LABEL = "SERVICEPROVIDER_INVOICE_CONFIRM_LABEL",
  SERVICEPROVIDER_INVOICE_CHANGE_BILLING_INFORMATION = "SERVICEPROVIDER_INVOICE_CHANGE_BILLING_INFORMATION",
  SERVICEPROVIDER_SUBSCRIPTION_LEVEL = "SUBSCRIPTION_LEVEL",
  SERVICEPROVIDER_USER_PHONE = "SERVICEPROVIDER_USER_PHONE",
  SERVICEPROVIDER_ORGANIZATION_PHONE = "SERVICEPROVIDER_ORGANIZATION_PHONE",
  SERVICEPROVIDER_EXPORT = "SERVICEPROVIDER_EXPORT",
  SERVICEPROVIDER_EXPORT_ALL = "SERVICEPROVIDER_EXPORT_ALL",
  SERVICEPROVIDER_EXPIRED_SUBSCRIBE = "SERVICEPROVIDER_EXPIRED_SUBSCRIBE",
  SERVICEPROVIDER_PAYMENT_METHOD = "SERVICEPROVIDER_PAYMENT_METHOD",

  INVOICING_TYPE_EINVOICE = "INVOICING_TYPE_EINVOICE",
  INVOICING_TYPE_OTHER = "INVOICING_TYPE_OTHER",

  SUBSCRIPTION_LEVEL_FREE = "SUBSCRIPTION_LEVEL_FREE",
  SUBSCRIPTION_LEVEL_BASIC = "SUBSCRIPTION_LEVEL_BASIC",

  PAYMENT_METHOD_MONTH = "PAYMENT_METHOD_MONTH",
  PAYMENT_METHOD_MICRO = "PAYMENT_METHOD_MICRO",

  // Serviceproviders
  TITLE_SERVICEPROVIDERS = "TITLE_SERVICEPROVIDERS",
  SERVICEPROVIDERS_ADD_NEW = "SERVICEPROVIDERS_ADD_NEW",
  NO_SERVICEPROVIDERS = "NO_SERVICEPROVIDERS",
  SERVICEPROVIDER_FORMS = "SERVICEPROVIDER_FORMS",
  NO_SERVICEPROVIDER_FORMS = "NO_SERVICEPROVIDER_FORMS",

  // SERVICES
  TITLE_SERVICES = "TITLE_SERVICES",
  SERVICES_ADD_NEW = "SERVICES_ADD_NEW",
  NO_SERVICES = "NO_SERVICES",
  TITLE_SERVICE = "TITLE_SERVICE",
  TITLE_SERVICE_NEW = "TITLE_SERVICE_NEW",
  SERVICE_NAME = "SERVICE_NAME",
  SERVICE_URL = "SERVICE_URL",

  // OfferRequest Edit
  FEEDBACK_SATISFACTION = "FEEDBACK_SATISFACTION",
  FEEDBACK_SERVICEPROVIDER = "FEEDBACK_SERVICEPROVIDER",
  FEEDBACK_SERVICE_PROVIDER_OTHER = "FEEDBACK_SERVICE_PROVIDER_OTHER",
  FEEDBACK_MESSAGE = "FEEDBACK_MESSAGE",

  // Feedback form
  FEEDBACK_FORM_NOT_FOUND = "FEEDBACK_FORM_NOT_FOUND",
  FEEDBACK_FORM_SOMETHING_ELSE = "FEEDBACK_FORM_SOMETHING_ELSE",
  FEEDBACK_THANK_YOU_TITLE = "FEEDBACK_THANK_YOU_TITLE",
  FEEDBACK_THANK_YOU_MESSAGE = "FEEDBACK_THANK_YOU_MESSAGE",
  FEEDBACK_EXISTS_MESSAGE = "FEEDBACK_EXISTS_MESSAGE",

  // OFFERREQUESTS
  TITLE_FEEDBACKS = "TITLE_FEEDBACKS",
  TITLE_FEEDBACK_NEW = "TITLE_FEEDBACK_NEW",
  FEEDBACKS_ADD_NEW = "FEEDBACKS_ADD_NEW",
  NO_FEEDBACK = "NO_FEEDBACK",

  // React Select
  REACT_SELECT_PLACEHOLDER = "REACT_SELECT_PLACEHOLDER",
  REACT_SELECT_ADD_NEW = "REACT_SELECT_ADD_NEW",

  // Users
  TITLE_USERS = "TITLE_USERS",
  USERS_ADD_NEW = "USERS_ADD_NEW",
  NO_USERS = "NO_USERS",
  USER_NAME = "USER_NAME",

  // User
  TITLE_USER = "TITLE_USER",
  TITLE_USER_NEW = "TITLE_USER_NEW",
  USER_FIRST_NAME = "USER_FIRST_NAME",
  USER_LAST_NAME = "USER_LAST_NAME",
  USER_EMAIL = "USER_EMAIL",
  USER_PHONE_NUMBER = "USER_PHONE_NUMBER",
  USER_TITLE = "USER_TITLE",
  USER_ALLOW_LOGIN = "USER_ALLOW_LOGIN",
  USER_PROFILE_IMAGE = "USER_PROFILE_IMAGE",
  USER_PASSWORD = "USER_PASSWORD",
  USER_ADDRESS = "USER_ADDRESS",
  USER_SEND_NEW_OFFER_REQUEST_SUMMARY = "USER_SEND_NEW_OFFER_REQUEST_SUMMARY",

  // Validation
  VALIDATION_EMAIL = "VALIDATION_EMAIL",
  VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH",
  VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH",
  VALIDATION_MIN_AMOUNT = "VALIDATION_MIN_AMOUNT",
  VALIDATION_MAX_AMOUNT = "VALIDATION_MAX_AMOUNT",

  // Errors
  TOKEN_ERROR = "TOKEN_ERROR",
  REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",
  FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR",
  IDENTIFIER_ERROR = "IDENTIFIER_ERROR",
  DATABASE_ERROR = "DATABASE_ERROR",
  DATA_EMPTY_ERROR = "DATA_EMPTY_ERROR",
  ORGANIZATION_ERROR = "ORGANIZATION_ERROR",
  NO_ACCESS_ERROR = "NO_ACCESS_ERROR",
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  PIPEDRIVE_NOT_CONNECTED_ERROR = "PIPEDRIVE_NOT_CONNECTED_ERROR",
  NO_CONTRACT_LENGTH_ERROR = "NO_CONTRACT_LENGTH_ERROR",
  SEARCH_QUERY_STRING_ERROR = "SEARCH_QUERY_STRING_ERROR",
  FEEDBACK_ALREADY_EXISTS = "FEEDBACK_ALREADY_EXISTS",

  // Organization
  ORGANIZATION_ME = "ORGANIZATION_ME",
  ORGANIZATION_ORDER_DATE = "ORGANIZATION_ORDER_DATE",
  ORGANIZATION_IMAGE = "ORGANIZATION_IMAGE",
  ORGANIZATION_IMAGE_DROPZONE = "ORGANIZATION_IMAGE_DROPZONE",
  ORGANIZATION_IMAGE_DROPZONE_DROP = "ORGANIZATION_IMAGE_DROPZONE_DROP",

  // Customer Offer Request
  CUSTOMER_OFFER_REQUEST = "CUSTOMER_OFFER_REQUEST",
  CUSTOMER_OFFER_REQUEST_SAVE_CHANGES = "CUSTOMER_OFFER_REQUEST_SAVE_CHANGES",
  CUSTOMER_OFFER_REQUEST_ARCHIVE = "CUSTOMER_OFFER_REQUEST_ARCHIVE",
  CUSTOMER_OFFER_REQUEST_UNARCHIVE = "CUSTOMER_OFFER_REQUEST_UNARCHIVE",
  CUSTOMER_OFFER_REQUEST_HELP_TEXT_1 = "CUSTOMER_OFFER_REQUEST_HELP_TEXT_1",
  CUSTOMER_OFFER_REQUEST_HELP_TEXT_2 = "CUSTOMER_OFFER_REQUEST_HELP_TEXT_2",
  CUSTOMER_OFFER_REQUEST_THANK_YOU_MESSAGE = "CUSTOMER_OFFER_REQUEST_THANK_YOU_MESSAGE",
  CUSTOMER_OFFER_REQUEST_ARCHIVED_CONFIRM = "CUSTOMER_OFFER_REQUEST_ARCHIVED_CONFIRM",
  CUSTOMER_OFFER_REQUEST_ERROR_TOP = "CUSTOMER_OFFER_REQUEST_ERROR_TOP",
  CUSTOMER_OFFER_REQUEST_ERROR_BOTTOM = "CUSTOMER_OFFER_REQUEST_ERROR_BOTTOM",

  // delete
  CONFIRM_DELETE_SERVICEPROVIDER = "CONFIRM_DELETE_SERVICEPROVIDER",
  CONFIRM_DELETE_SERVICE = "CONFIRM_DELETE_SERVICE",
  CONFIRM_DELETE_OPERATOR = "CONFIRM_DELETE_OPERATOR",
  CONFIRM_DELETE_LOCATION = "CONFIRM_DELETE_LOCATION",
  CONFIRM_DELETE_SERVICEPROVIDER_FORM = "CONFIRM_DELETE_SERVICEPROVIDER_FORM",
  CONFIRM_DELETE_OFFER_REQUEST = "CONFIRM_DELETE_OFFER_REQUEST",

  CONFIRM_BUY_TITLE = "CONFIRM_BUY_TITLE",
  CONFIRM_BUY_DESCRIPTION = "CONFIRM_BUY_DESCRIPTION",
  CONFIRM_BUY_QUESTION = "CONFIRM_BUY_QUESTION",

  // locations
  NO_LOCATIONS = "NO_LOCATIONS",
  LOCATIONS = "LOCATIONS",
  LOCATIONS_NEW = "LOCATIONS_NEW",
  LOCATION = "LOCATION",
  LOCATION_NAME = "LOCATION_NAME",
  LOCATION_NAME_GENITIVE = "LOCATION_NAME_GENITIVE",
  LOCATION_NAME_INESSIVE = "LOCATION_NAME_INESSIVE",
  LOCATION_CONTENT = "LOCATION_CONTENT",
  LOCATION_LATITUDE = "LOCATION_LATITUDE",
  LOCATION_LONGITUDE = "LOCATION_LONGITUDE",
  LOCATION_ZOOM_LEVEL = "LOCATION_ZOOM_LEVEL",

  // SEARCH
  COMMON_SEARCH = "COMMON_SEARCH",

  // Settings
  TITLE_SETTINGS = "TITLE_SETTINGS",
  SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK",
  PASSWORD_RESET_TEXT = "PASSWORD_RESET_TEXT",

  //Billing
  BILLING_EXPORT_ALL = "BILLING_EXPORT_ALL",
  BILLING_EXPORT_ORGANIZATION = "BILLING_EXPORT_ORGANIZATION",

  SELECT_SERVICE_PLACEHOLDER = 'SELECT_SERVICE_PLACEHOLDER',
  SELECT_OPERATOR_PLACEHOLDER = "SELECT_OPERATOR_PLACEHOLDER",
  SELECT_LOCATIONS_PLACEHOLDER = 'SELECT_LOCATIONS_PLACEHOLDER',
  SELECT_SERVICE_PROVIDER_PLACEHOLDER = "SELECT_SERVICE_PROVIDER_PLACEHOLDER",

  SUBSCRIPTION_EXPIRED_INFO = "SUBSCRIPTION_EXPIRED_INFO",
  SUBSCRIPTION_EXPIRED = "SUBSCRIPTION_EXPIRED",
  SUBSCRIPTION_EXPIRED_INFO_LINK = "SUBSCRIPTION_EXPIRED_INFO_LINK",

  // CONTENT
  TITLE_CONTENTS = "TITLE_CONTENTS",
  CONTENTS_ADD_NEW = "CONTENTS_ADD_NEW",
  NO_CONTENTS = "NO_CONTENTS",
  TITLE_CONTENT = "TITLE_CONTENT",
  TITLE_CONTENT_NEW = "TITLE_CONTENT_NEW",
  CONTENT_NAME = "CONTENT_NAME",
  CONTENT_SHORTCONTENT = "CONTENT_SHORTCONTENT",
  CONTENT_CONTENTTEXT = "CONTENT_CONTENTTEXT",
  CONTENT_DATE = "CONTENT_DATE",
  CONTENT_SHOWDATE = "CONTENT_SHOWDATE",
  CONTENT_TAGS = "CONTENT_TAGS",
  CONTENT_TAG_NORMAL = "CONTENT_TAG_NORMAL",
  CONTENT_TAG_NEWS = "CONTENT_TAG_NEWS",
  CONTENT_TAG_FRONTPAGE = "CONTENT_TAG_FRONTPAGE",

  //Language
  LANGUAGE_FINNISH = "LANGUAGE_FINNISH",
  LANGUAGE_ENGLISH = "LANGUAGE_ENGLISH",
  LANGUAGE_TITLE = "LANGUAGE_TITLE",
  LANGUAGE_IN_FINNISH = "LANGUAGE_IN_FINNISH",
  LANGUAGE_IN_ENGLISH = "LANGUAGE_IN_ENGLISH",

  // Terms of use
  TERMS_BINDING = "TERMS_BINDING",
  TERMS_LIST_1 = "TERMS_LIST_1",
  TERMS_LIST_2 = "TERMS_LIST_2",
  TERMS_LIST_3 = "TERMS_LIST_3",
  TERMS_READ_MORE = "TERMS_READ_MORE",
  TERMS_TERMS = "TERMS_TERMS",
  TERMS_ACCEPT = "TERMS_ACCEPT",
  TERMS_TITLE = "TERMS_TITLE",

  // Offer request Info
  OFFERREQUEST_INFO_OFFER_YOUR_SERVICE = "OFFERREQUEST_INFO_OFFER_YOUR_SERVICE",
  OFFERREQUEST_INFO_LIST_ITEM_1 = "OFFERREQUEST_INFO_LIST_ITEM_1",
  OFFERREQUEST_INFO_LIST_ITEM_2 = "OFFERREQUEST_INFO_LIST_ITEM_2",
  OFFERREQUEST_INFO_LIST_ITEM_3 = "OFFERREQUEST_INFO_LIST_ITEM_3",
  OFFERREQUEST_INFO_QUESTIONS = "OFFERREQUEST_INFO_QUESTIONS",
  OFFERREQUEST_INFO_CONFIRM_INFO = "OFFERREQUEST_INFO_CONFIRM_INFO",
  OFFERREQUEST_INFO_EDIT_INFO = "OFFERREQUEST_INFO_EDIT_INFO",
  OFFERREQUEST_INFO_READ_MORE = "OFFERREQUEST_INFO_READ_MORE",
  OFFER_REQUEST_ACCESS_OFFER_REQUEST = "OFFER_REQUEST_ACCESS_OFFER_REQUEST"
}
