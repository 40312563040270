export enum ActionTypes {
  // Auth
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,

  AUTH_LOGOUT_START,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAIL,

  AUTH_GET_CUSTOM_TOKEN_START,
  AUTH_GET_CUSTOM_TOKEN_SUCCESS,
  AUTH_GET_CUSTOM_TOKEN_FAIL,

  AUTH_GET_START,
  AUTH_GET_SUCCESS,
  AUTH_GET_FAIL,

  AUTH_ADD_OFFER_REQUEST_ACCESS,

  AUTH_SET_BILLING_CONFIRMED,

  AUTH_SET_ORGANIZATION,

  // Offerrequests
  OFFER_REQUEST_GET_START,
  OFFER_REQUEST_GET_SUCCESS,
  OFFER_REQUEST_GET_FAIL,

  OFFER_REQUEST_LIST_START,
  OFFER_REQUEST_LIST_SUCCESS,
  OFFER_REQUEST_LIST_FAIL,

  OFFER_REQUEST_DELETE_START,
  OFFER_REQUEST_DELETE_SUCCESS,
  OFFER_REQUEST_DELETE_FAIL,

  OFFER_REQUEST_SAVE_START,
  OFFER_REQUEST_SAVE_SUCCESS,
  OFFER_REQUEST_SAVE_FAIL,

  OFFER_REQUEST_UPDATE_START,
  OFFER_REQUEST_UPDATE_SUCCESS,
  OFFER_REQUEST_UPDATE_FAIL,

  OFFER_REQUEST_CLEAR,

  OFFER_REQUEST_CUSTOMER_GET_START,
  OFFER_REQUEST_CUSTOMER_GET_SUCCESS,
  OFFER_REQUEST_CUSTOMER_GET_FAIL,

  OFFER_REQUEST_CUSTOMER_UPDATE_START,
  OFFER_REQUEST_CUSTOMER_UPDATE_SUCCESS,
  OFFER_REQUEST_CUSTOMER_UPDATE_FAIL,

  OFFER_REQUEST_CUSTOMER_ARCHIVE_START,
  OFFER_REQUEST_CUSTOMER_ARCHIVE_SUCCESS,
  OFFER_REQUEST_CUSTOMER_ARCHIVE_FAIL,

  OFFER_REQUEST_ADD_ACCESS_START,
  OFFER_REQUEST_ADD_ACCESS_SUCCESS,
  OFFER_REQUEST_ADD_ACCESS_FAIL,
  OFFER_REQUEST_ADD_ACCESS_CLEAR,

  OFFER_REQUEST_ACCESS_LIST_START,
  OFFER_REQUEST_ACCESS_LIST_SUCCESS,
  OFFER_REQUEST_ACCESS_LIST_FAIL,


  // Serviceproviders
  SERVICE_PROVIDER_GET_START,
  SERVICE_PROVIDER_GET_SUCCESS,
  SERVICE_PROVIDER_GET_FAIL,

  SERVICE_PROVIDER_LIST_START,
  SERVICE_PROVIDER_LIST_SUCCESS,
  SERVICE_PROVIDER_LIST_FAIL,

  SERVICE_PROVIDER_DELETE_START,
  SERVICE_PROVIDER_DELETE_SUCCESS,
  SERVICE_PROVIDER_DELETE_FAIL,

  SERVICE_PROVIDER_SAVE_START,
  SERVICE_PROVIDER_SAVE_SUCCESS,
  SERVICE_PROVIDER_SAVE_FAIL,

  SERVICE_PROVIDER_UPDATE_START,
  SERVICE_PROVIDER_UPDATE_SUCCESS,
  SERVICE_PROVIDER_UPDATE_FAIL,

  CLEAR_SERVICEPROVIDER,

  // ServiceProviderForm
  SERVICE_PROVIDER_FORM_GET_START,
  SERVICE_PROVIDER_FORM_GET_SUCCESS,
  SERIVCE_PROVIDER_FORM_GET_FAIL,

  SERVICE_PROVIDER_FORM_LIST_START,
  SERVICE_PROVIDER_FORM_LIST_SUCCESS,
  SERVICE_PROVIDER_FORM_LIST_FAIL,

  SERVICE_PROVIDER_FORM_DELETE_START,
  SERVICE_PROVIDER_FORM_DELETE_SUCCESS,
  SERVICE_PROVIDER_FORM_DELETE_FAIL,

  // Service
  SERVICE_GET_START,
  SERVICE_GET_SUCCESS,
  SERVICE_GET_FAIL,

  SERVICE_LIST_START,
  SERVICE_LIST_SUCCESS,
  SERVICE_LIST_FAIL,

  SERVICE_DELETE_START,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,

  SERVICE_SAVE_START,
  SERVICE_SAVE_SUCCESS,
  SERVICE_SAVE_FAIL,

  SERVICE_UPDATE_START,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,

  SERVICE_CLEAR,

  SERVICE_TOKEN_GET_START,
  SERVICE_TOKEN_GET_SUCCESS,
  SERVICE_TOKEN_GET_FAIL,

  SERVICE_SEND_START,
  SERVICE_SEND_SUCCESS,
  SERVICE_SEND_FAIL,

  UPDATE_SERVICE_STATUS_START,
  UPDATE_SERVICE_STATUS_SUCCESS,
  UPDATE_SERVICE_STATUS_FAIL,

  // Feedback
  FEEDBACK_GET_START,
  FEEDBACK_GET_SUCCESS,
  FEEDBACK_GET_FAIL,

  FEEDBACK_LIST_START,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_LIST_FAIL,

  FEEDBACK_DELETE_START,
  FEEDBACK_DELETE_SUCCESS,
  FEEDBACK_DELETE_FAIL,

  FEEDBACK_SAVE_START,
  FEEDBACK_SAVE_SUCCESS,
  FEEDBACK_SAVE_FAIL,

  FEEDBACK_UPDATE_START,
  FEEDBACK_UPDATE_SUCCESS,
  FEEDBACK_UPDATE_FAIL,

  FEEDBACK_CLEAR,

  FEEDBACK_TOKEN_GET_START,
  FEEDBACK_TOKEN_GET_SUCCESS,
  FEEDBACK_TOKEN_GET_FAIL,

  FEEDBACK_SEND_START,
  FEEDBACK_SEND_SUCCESS,
  FEEDBACK_SEND_FAIL,

  FEEDBACK_UPDATE_STATUS_START,
  FEEDBACK_UPDATE_STATUS_SUCCESS,
  FEEDBACK_UPDATE_STATUS_FAIL,

  FEEDBACK_GET_FORM_DATA_START,
  FEEDBACK_GET_FORM_DATA_SUCCESS,
  FEEDBACK_GET_FORM_DATA_FAIL,

  // Users
  USER_GET_START,
  USER_GET_SUCCESS,
  USER_GET_FAIL,

  USER_LIST_START,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,

  USER_SAVE_START,
  USER_SAVE_SUCCESS,
  USER_SAVE_FAIL,

  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,

  USER_ACCEPT_TERMS_OF_USE_START,
  USER_ACCEPT_TERMS_OF_USE_SUCCESS,
  USER_ACCEPT_TERMS_OF_USE_FAIL,

  USER_CHANGE_LANGUAGE_START,
  USER_CHANGE_LANGUAGE_SUCCESS,
  USER_CHANGE_LANGUAGE_FAIL,


  USER_CLEAR,

  // Attachments
  ATTACHMENT_CLEAR,

  ATTACHMENT_UPLOAD_START,
  ATTACHMENT_UPLOAD_SUCCESS,
  ATTACHMENT_UPLOAD_FAIL,

  // ORGANIZATIONS
  ORGANIZATION_CLEAR,

  ORGANIZATION_GET_MY_START,
  ORGANIZATION_GET_MY_SUCCESS,
  ORGANIZATION_GET_MY_FAIL,

  ORGANIZATION_EXPORT_START,
  ORGANIZATION_EXPORT_SUCCESS,
  ORGANIZATION_EXPORT_FAIL,

  ORGANIZATION_UPDATE_MY_START,
  ORGANIZATION_UPDATE_MY_SUCCESS,
  ORGANIZATION_UPDATE_MY_FAIL,

  ORGANIZATION_UPDATE_IMAGE_START,
  ORGANIZATION_UPDATE_IMAGE_FAIL,
  ORGANIZATION_UPDATE_IMAGE_SUCCESS,

  ORGANIZATION_DELETE_IMAGE_START,
  ORGANIZATION_DELETE_IMAGE_FAIL,
  ORGANIZATION_DELETE_IMAGE_SUCCESS,

  ORGANIZATION_GET_START,
  ORGANIZATION_GET_SUCCESS,
  ORGANIZATION_GET_FAIL,

  ORGANIZATION_LIST_START,
  ORGANIZATION_LIST_SUCCESS,
  ORGANIZATION_LIST_FAIL,

  ORGANIZATION_SAVE_START,
  ORGANIZATION_SAVE_SUCCESS,
  ORGANIZATION_SAVE_FAIL,

  ORGANIZATION_UPDATE_START,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_UPDATE_FAIL,

  ORGANIZATION_CONFIRM_BILLING_START,
  ORGANIZATION_CONFIRM_BILLING_SUCCESS,
  ORGANIZATION_CONFIRM_BILLING_FAIL,

  // Locations
  LOCATION_GET_START,
  LOCATION_GET_SUCCESS,
  LOCATION_GET_FAIL,

  LOCATION_LIST_START,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_FAIL,

  LOCATION_DELETE_START,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DELETE_FAIL,

  LOCATION_SAVE_START,
  LOCATION_SAVE_SUCCESS,
  LOCATION_SAVE_FAIL,

  LOCATION_UPDATE_START,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_FAIL,

  LOCATION_CLEAR,

  //Content
  CONTENT_GET_START,
  CONTENT_GET_SUCCESS,
  CONTENT_GET_FAIL,
  CONTENT_LIST_START,
  CONTENT_LIST_SUCCESS,
  CONTENT_LIST_FAIL,
  CONTENT_DELETE_START,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_FAIL,
  CONTENT_SAVE_START,
  CONTENT_SAVE_SUCCESS,
  CONTENT_SAVE_FAIL,
  CONTENT_UPDATE_START,
  CONTENT_UPDATE_SUCCESS,
  CONTENT_UPDATE_FAIL,
  CONTENT_CLEAR,

  //Geolocation
  GEOLOCATION_GET_START,
  GEOLOCATION_GET_SUCCESS,
  GEOLOCATION_GET_FAIL,

  //LogItem
  LOG_ITEM_LIST_START,
  LOG_ITEM_LIST_SUCCESS,
  LOG_ITEM_LIST_FAIL,
  LOG_ITEM_LIST_CLEAR,
}
