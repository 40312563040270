import React from "react";
import Button from "../../Button/Button";

import classes from "./RadioButtonOption.module.scss";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps {
  value: string;
  label?: string;
  labelTranslation?: ETranslation;
  isActive: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onClick: (value: string) => void;
}

const RadioButtonOption: React.FC<IProps> = ({
  value,
  label,
  labelTranslation,
  isActive,
  disabled,
  onClick,
  invalid
}) => {
  const { t } = useTranslation();
  const classNames = [];

  if(!isActive) {
    classNames.push(classes.InActive);
  } 
  if(invalid) {
    classNames.push(classes.Invalid);
  }

  return <Button disabled={disabled} onClick={event => onClick(value)} className={classNames.join(' ')}>{labelTranslation ? t(labelTranslation) : label}</Button>;
};

export default RadioButtonOption;
