import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLocationOptions } from '../../../hooks/useLocationOptions';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';


interface IProps {
  setLocationId: React.Dispatch<React.SetStateAction<string>>;
  locationId: string;
}

const LocationSearch: React.FC<IProps> = ({ setLocationId, locationId}) => {

  const { locationOptions, locationsLoading } = useLocationOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.LOCATION)}</label>
      <Select
        inputName={"operatorSearch"}
        value={locationId}
        onChange={setLocationId}
        options={locationOptions}
        isSearchable
        isClearable
        isLoading={locationsLoading}
      />
    </div>
  )

}

export default LocationSearch;