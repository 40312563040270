import { useDispatch } from 'react-redux';
import { IOrganization } from './../interfaces/domain';
import { useCallback } from 'react';
import { ETranslation } from './../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import { IAppState, IOrganizationState, IAuthState } from './../interfaces/state';
import { useSelector } from 'react-redux';
import { IOption } from '../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { createOptions } from '../shared/option-utils';

import * as actions from '../store/actions';

const defaultParams = {
  withPlaceholder: false,
  withBusinessId: false
}

export const useOrganizationOptions = ({ withBusinessId, withPlaceholder } = defaultParams) => {
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { t } = useTranslation()
  const [organizationOptions, setOrganizationOptions] = useState<IOption[]>([])
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { organizations, loading } = useSelector<IAppState, IOrganizationState>((state) => state.organization);

  useEffect(() => {
    if(authUser) {
      setTryFetch(authUser.isPrimary);
    }
  }, [authUser]);

  useEffect(() => {
    if(tryFetch && !organizations && !loading) {
      dispatch(actions.listOrganizations());
      setTryFetch(false);
    }
  }, [dispatch, organizations, tryFetch, loading]);

  const createLabel = useCallback((organization: IOrganization) => {
    let labelArray = [organization.name || organization.id];

    if(withBusinessId && organization.businessId) {
      labelArray.push(`(${organization.businessId})`)
    }
    return labelArray.join(' ');
  }, [withBusinessId]);

  useEffect(() => {
    let placeholder;
    if (withPlaceholder) {
      placeholder = t(ETranslation.SELECT_OPERATOR_PLACEHOLDER);
    }
    setOrganizationOptions(organizations ? createOptions(organizations, createLabel, placeholder) : []);
  }, [organizations, t, withPlaceholder, createLabel]);

  return { organizationOptions, organizationsLoading: loading };

}