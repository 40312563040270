import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button, { EButtonColor, EButtonSize } from '../../../components/UI/Button/Button';
import ConfirmDialog from '../../../components/UI/ConfirmDialog/ConfirmDialog';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { defaultModal, EModalSize } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../../context/InputContext';
import { tagTypes } from '../../../data/select-data';
import { ETags } from '../../../enums';
import { useAuthUser } from '../../../hooks/useAuthUser';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { useServiceOptions } from '../../../hooks/useServiceOptions';
import { IAppState, IContent, IContentState } from '../../../interfaces';
import { getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';



interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum EContent {
  title = "title",
  shortContent = "shortContent",
  contentText = "contentText",
  publishDate = "publishDate",
  showPublishDate = "showPublishDate",
  tags = "tags",
  url = "url",
  operator = "operator",
  services = "services",
}

const Content: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { setModal } = useContext(ModalContext);
  
  const authUser = useAuthUser();
  const { loading, error, content, saveOrUpdateOk } = useSelector<IAppState, IContentState>(state => state.content);
  const { t } = useTranslation();
  const { id } = match.params;

  const [inputs, setContentInputs] = useState<IInputField>({
    [EContent.operator]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_OPERATOR_NAME,
      placeholderTranslation: ETranslation.COMMON_OPERATOR_NAME,
      value: "",
      options: [],
      validation: {
        required: authUser?.isPrimary,
      },
    },
    [EContent.services]: {
      type: EInputType.searchSelect,
      labelTranslation: ETranslation.COMMON_SERVICE,
      placeholderTranslation: ETranslation.COMMON_SERVICE,
      value: [],
      multiple: true,
    },
    [EContent.title]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTENT_NAME,
      placeholderTranslation: ETranslation.CONTENT_NAME,
      value: "",
      validation: {
        required: true
      }
    },
    [EContent.shortContent]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CONTENT_SHORTCONTENT,
      placeholderTranslation: ETranslation.CONTENT_SHORTCONTENT,
      value: "",
      validation: {
        required: true
      }
    },
    [EContent.contentText]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.CONTENT_CONTENTTEXT,
      placeholderTranslation: ETranslation.CONTENT_CONTENTTEXT,
      value: "",
      
    },
    [EContent.url]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_WWW,
      placeholderTranslation: ETranslation.COMMON_WWW,
      value: "",
      validation: {
        required: true
      }
    },
    [EContent.tags]: {
      type: EInputType.searchSelect,
      labelTranslation: ETranslation.CONTENT_TAGS,
      placeholderTranslation: ETranslation.CONTENT_TAGS,
      value: [],
      options: tagTypes,
      multiple: true,
    },
    [EContent.publishDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.CONTENT_DATE,
      value: format(new Date(), "yyyy-MM-dd"),
    },
    [EContent.showPublishDate]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.CONTENT_SHOWDATE,
      value: "",
    },
    
  });

  const { organizationOptions, organizationsLoading } = useOrganizationOptions({
    withBusinessId: true,
    withPlaceholder: true,
  });

  const [isValid, setIsValid] = useState<boolean>(false);

  let operatorId;
  if (authUser?.isOperator) {
    operatorId = authUser.organization.id;
  } else {
    operatorId = inputs[EContent.operator].value as string;
  }

  const { serviceOptions, servicesLoading } = useServiceOptions({
    filterByOperatorId: true,
    operatorId
  });

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(Routes.CONTENTS);
    }
    return () => {
      dispatch(actions.clearContent());
    };
  }, [dispatch, history, saveOrUpdateOk]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getContent(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (content) {
      let contentTags = content.tags;
      if ( contentTags ) {
        content.tags = contentTags.map(tag => {
          const tagType = tagTypes.find(t => t.value === tag);
          return { id: tagType?.value, name: tagType?.label };
        }) as unknown as ETags[];
      }
      initForm(setContentInputs, content);
    }
  }, [content]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (inputName: EContent, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={authUser?.isBasic}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.CONTENT}
        onChange={(value) => updateInputHandler(inputName, value, setContentInputs)}
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const content = getInputData<IContent>(inputs);

    if (Routes.isNew(id)) {
      dispatch(actions.saveContent(content));
    } else {
      content.id = id;
      dispatch(actions.updateContent(content));
    }
  };

  const addAllServices = () => {
    setContentInputs((inputs) => {
      const newInputs = { ...inputs };
      newInputs[EContent.services].value = [...serviceOptions];
      return newInputs;
    });
  };

  const deleteContent = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_REMOVE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          body={<p>{t(ETranslation.CONFIRM_DELETE_OFFER_REQUEST)}</p>}
          onConfirm={() => {
            dispatch(actions.deleteContent(id));
            history.goBack();
            setModal(defaultModal);
          }}
          onCancel={() => setModal(defaultModal)}
        />
      ),
    });


    return (
      <>
        {error && <Alert children={error} />}
        <Container>
          <h2>
            {Routes.isNew(id)
              ? t(ETranslation.TITLE_CONTENT_NEW)
              : t(ETranslation.TITLE_CONTENT)}
          </h2>
          {loading ? (
            <Spinner />
          ) : content?.deleted ? (
            <p>{t(ETranslation.OFFER_REQUEST_DELETED)}</p>
          ) : (
            <>
              {authUser?.isPrimary &&
                createInput(EContent.operator, {
                  options: organizationOptions,
                  loading: organizationsLoading,
                })
              }
              <InputGroup>
                {createInput(EContent.services, {
                  options: serviceOptions,
                  loading: servicesLoading,
                })}
                  {serviceOptions && (
                    <Button
                      onClick={addAllServices}
                      color={EButtonColor.PRIMARY}
                      size={EButtonSize.SMALL}
                      style={{ marginTop: '.5rem'}}
                    >
                      {t(ETranslation.COMMON_ADD_ALL)}
                    </Button>
                  )}
              </InputGroup>
              {createInput(EContent.title)}
              {createInput(EContent.shortContent)}
              {createInput(EContent.contentText)}
              {createInput(EContent.url)}
              {createInput(EContent.tags)}
              {createInput(EContent.publishDate)}
              {createInput(EContent.showPublishDate)}
            </>
          )}
          <InputGroup>
            {!authUser?.isBasic && !content?.deleted && (
              <Button disabled={loading || !isValid} onClick={submitHandler}>
                {t(ETranslation.COMMON_SAVE)}
              </Button>
            )}
            <Button onClick={() => history.goBack()}>
              {t(ETranslation.COMMON_RETURN)}
            </Button>
            {(authUser?.isPrimary || authUser?.isOperator) &&
              Routes.isNotNew(id) &&
              !content?.deleted && (
                <Button disabled={loading} onClick={deleteContent}>
                  {t(ETranslation.COMMON_REMOVE)}
                </Button>
              )}
          </InputGroup>
        </Container>
      </>
    );
};

export default Content;