import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useServiceProviderOptions } from '../../../hooks/useServiceProviderOptions';

import { IAppState, IAuthState } from '../../../interfaces';
import { getInputData, initForm, updateInputHandler } from '../../../shared/utility';
import { IOfferRequestAccessSearchOptions } from '../../../store/actions/offerRequestActions';
import { ETranslation } from '../../../translations/translation-keys';
import Button from '../../UI/Button/Button';
import Input, { EInputType, IInputField, IInputOptions } from '../../UI/Input/Input';
import InputGroup from '../../UI/InputGroup/InputGroup';
import * as dateFns from 'date-fns';
import { ESearchStorageKey } from '../../../enums';

interface IProps {
  onSearch: (search: IOfferRequestAccessSearchOptions) => void;
};

enum EInputs {
  organizationId = 'organizationId',
  createdStart = 'createdStart',
  createdEnd = 'createdEnd'
}

const defaultCreatedStartDate = dateFns.format(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), 'yyyy-MM-dd');

const OfferRequestAccessSearch: React.FC<IProps> = ({ onSearch }) => {
  
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.organizationId]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_SERVICE_PROVIDER_NAME,
      placeholderTranslation: ETranslation.COMMON_SERVICE_PROVIDER_NAME,
      value: "",
      options: []
    },
    [EInputs.createdStart]: {
      type: EInputType.date,
      labelTranslation: ETranslation.OFFER_REQUEST_ACCESS_CREATED_START,
      value: "",
    },
    [EInputs.createdEnd]: {
      type: EInputType.date,
      labelTranslation: ETranslation.OFFER_REQUEST_ACCESS_CREATED_END,
      value: "",
    },
  });

  const { serviceProviderOptions } = useServiceProviderOptions({ withPlaceholder: true });

  const { authUser } = useSelector<IAppState, IAuthState>((state) => state.auth);

  useEffect(() => {
    const storageData = localStorage.getItem(ESearchStorageKey.OFFER_REQUEST_ACCESS_SEARCH);
    let inputsData: IOfferRequestAccessSearchOptions = { 
      organizationId: '', 
      createdStart: defaultCreatedStartDate,
      createdEnd: ''
    };
    if (storageData) {
      inputsData = JSON.parse(storageData);
    }
    initForm(setInputs, inputsData);
    onSearch(inputsData);
  }, [onSearch]);

  const createInput = (inputName: EInputs, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={authUser?.isBasic}
        {...item}
        {...options}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  };

  const searchHandler = () => {
    const data = getInputData<IOfferRequestAccessSearchOptions>(inputs);
    localStorage.setItem(ESearchStorageKey.OFFER_REQUEST_ACCESS_SEARCH, JSON.stringify(data));
    onSearch(data);
  }

  return (
    <InputGroup>
      {createInput(EInputs.organizationId, {
        options: serviceProviderOptions
      })}
      {createInput(EInputs.createdStart)}
      {createInput(EInputs.createdEnd)}
      <Button onClick={searchHandler}>{t(ETranslation.COMMON_FETCH)}</Button>
    </InputGroup>
  );
};

export default OfferRequestAccessSearch;