import { AxiosResponse } from 'axios';
import { Dispatch } from "redux";
import axios from "../../axios";
import { IOrganizationAction } from "../../interfaces";
import { IOrganization } from "../../interfaces/domain";
import { ActionTypes } from "./actionTypes";
import * as actions from './'


type TAction = IOrganizationAction;

const apiPath = "/json/organizations";

export const clearOrganization = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_CLEAR,
  };
};

const getMyOrganizationStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_GET_MY_START,
  };
};

const getMyOrganizationSuccess = (organization: IOrganization): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_GET_MY_SUCCESS,
    organization,
  };
};

const getMyOrganizationFail = (error: string): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_GET_MY_FAIL,
    error,
  };
};

export const getMyOrganization = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getMyOrganizationStart());
    try {
      const res = await axios.get(`${apiPath}/me`);
      dispatch(getMyOrganizationSuccess(res.data));
    } catch ({ response }) {
      dispatch(getMyOrganizationFail((response as AxiosResponse).data));
    }
  };
};

const updateMyOrganizationStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_MY_START,
  };
};

const updateMyOrganizationSuccess = (
  organization: IOrganization
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_MY_SUCCESS,
    organization,
  };
};

const updateMyOrganizationFail = (
  error: string
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_MY_FAIL,
    error,
  };
};

export const updateMyOrganization = (organization: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateMyOrganizationStart());
    try {
      const res = await axios.put(`${apiPath}/me/update`, organization);
      const updatedOrganization = res.data;
      dispatch(updateMyOrganizationSuccess(updatedOrganization));
      dispatch(actions.setAuthUserOrganization(updatedOrganization));
    } catch ({ response }) {
      dispatch(updateMyOrganizationFail((response as AxiosResponse).data));
    }
  };
};

const updateOrganizationImageStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_IMAGE_START,
  };
};

const updateOrganizationImageSuccess = (
  image: string
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_IMAGE_SUCCESS,
    image,
  };
};

const updateOrganizationImageFail = (
  error: string
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_IMAGE_FAIL,
    error,
  };
};

export const updateOrganizationImage = (data: FormData) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateOrganizationImageStart());
    try {
      const res = await axios.post(`${apiPath}/image/update`, data); // returns image
      dispatch(updateOrganizationImageSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateOrganizationImageFail((response as AxiosResponse).data));
    }
  };
};

const deleteOrganizationImageStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_DELETE_IMAGE_START,
  };
};

const deleteOrganizationImageSuccess = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_DELETE_IMAGE_SUCCESS,
  };
};

const deleteOrganizationImageFail = (
  error: string
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_DELETE_IMAGE_FAIL,
    error,
  };
};

export const deleteOrganizationImage = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteOrganizationImageStart());
    try {
      await axios.delete(`${apiPath}/image/delete?id=${id}`);
      dispatch(deleteOrganizationImageSuccess());
    } catch ({ response }) {
      dispatch(deleteOrganizationImageFail((response as AxiosResponse).data));
    }
  };
};


const getOrganizationStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_GET_START,
  };
};

const getOrganizationSuccess = (
  organization: IOrganization
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_GET_SUCCESS,
    organization,
  };
};

const getOrganizationFail = (error: string): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_GET_FAIL,
    error,
  };
};

export const getOrganization = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getOrganizationStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getOrganizationSuccess(res.data));
    } catch ({ response }) {
      dispatch(getOrganizationFail((response as AxiosResponse).data));
    }
  };
};

const listOrganizationsStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_LIST_START,
  };
};

const listOrganizationsSuccess = (
  organizations: Array<IOrganization>
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_LIST_SUCCESS,
    organizations,
  };
};

const listOrganizationsFail = (error: string): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_LIST_FAIL,
    error,
  };
};

export const listOrganizations = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listOrganizationsStart());
    try {
      const res = await axios.get(`${apiPath}/list`);
      dispatch(listOrganizationsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listOrganizationsFail((response as AxiosResponse).data));
    }
  };
};

const saveOrganizationStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_SAVE_START,
  };
};

const saveOrganizationSuccess = (
  organization: IOrganization
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_SAVE_SUCCESS,
    organization,
  };
};

const saveOrganizationFail = (error: string): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_SAVE_FAIL,
    error,
  };
};

export const saveOrganization = (operator: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveOrganizationStart());
    try {
      const res = await axios.post(`${apiPath}/add`, operator);
      dispatch(saveOrganizationSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveOrganizationFail((response as AxiosResponse).data));
    }
  };
};

const updateOrganizationStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_START,
  };
};

const updateOrganizationSuccess = (
  organization: IOrganization
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_SUCCESS,
    organization,
  };
};

const updateOrganizationFail = (error: string): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_UPDATE_FAIL,
    error,
  };
};

export const updateOrganization = (orgnaization: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateOrganizationStart());
    try {
      const res = await axios.put(`${apiPath}/update`, orgnaization);
      dispatch(updateOrganizationSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateOrganizationFail((response as AxiosResponse).data));
    }
  };
};


const confirmBillingStart = (): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_CONFIRM_BILLING_START,
  };
};

const confirmBillingSuccess = (
): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_CONFIRM_BILLING_SUCCESS,
  };
};

const confirmBillingFail = (error: string): TAction => {
  return {
    type: ActionTypes.ORGANIZATION_CONFIRM_BILLING_FAIL,
    error,
  };
};

export const confirmBilling = () => {
  return async (dispatch: Dispatch) => {
    dispatch(confirmBillingStart());
    try {
      await axios.put(`${apiPath}/confirmBilling`);
      dispatch(confirmBillingSuccess());
    } catch ({ response }) {
      dispatch(confirmBillingFail((response as AxiosResponse).data));
    }
  };
};



