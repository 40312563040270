import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IOption } from '../components/UI/Input/Input';
import { IAppState, IAuthState, IServiceState } from '../interfaces/state';
import { createOptions } from '../shared/option-utils';
import { ETranslation } from '../translations/translation-keys';

import * as actions from '../store/actions';

interface IParams {
  withPlaceholder?: boolean;
  operatorId?: string;
  filterByOperatorId?: boolean;
}

const defaultParams: IParams = {
  withPlaceholder: false,
  operatorId: '',
  filterByOperatorId: false,
}

export const useServiceOptions = ({ withPlaceholder, operatorId, filterByOperatorId } = defaultParams) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [serviceOptions, setServiceOptions] = useState<IOption[]>([])
  const [tryFetch, setTryFetch] = useState(false);
  
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { services, loading } = useSelector<IAppState, IServiceState>(state => state.services);

  useEffect(() => {
    if(authUser) {
      setTryFetch(authUser.isPrimary || authUser.isOperator || authUser.isBasic);
    }
  }, [authUser]);

  useEffect(() => {
    if(tryFetch && !services && !loading) {
      dispatch(actions.listServices());
      setTryFetch(false);
    }
  }, [dispatch, services, tryFetch, loading]);

  useEffect(() => {
    let placeholder;
    if(withPlaceholder) {
      placeholder = t(ETranslation.SELECT_SERVICE_PLACEHOLDER);
    }
    if(services) {
      let optionServices = [...services];
      if (filterByOperatorId) {
        optionServices = optionServices.filter(s => s.operator?.id === operatorId);
      }
      optionServices = optionServices.filter(s => !s.archived);
      setServiceOptions(createOptions(optionServices, (s) => s.name || s.id, placeholder));

    } else {
      setServiceOptions([]);
    }
  }, [services, withPlaceholder, operatorId, t, filterByOperatorId]);

  return { serviceOptions, servicesLoading: loading };
}