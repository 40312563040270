import { IOfferRequest, IOfferRequestAccess } from "../../interfaces/domain";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IAppState, IOfferRequestAction } from "../../interfaces";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { TDispatch } from "../..";
import { AxiosResponse } from "axios";


const apiPath = '/json/offerrequests'

const getOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_GET_START,
  };
};

const getOfferRequestSuccess = (offerrequest: IOfferRequest): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_GET_SUCCESS,
    offerRequest: offerrequest,
  };
};

const getOfferRequestFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_GET_FAIL,
    error,
  };
};

export const getOfferRequest = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getOfferRequestStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getOfferRequestSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getOfferRequestFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_DELETE_START,
  };
};

const deleteOfferRequestSuccess = (offerrequest: IOfferRequest): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_DELETE_SUCCESS,
    offerRequest: offerrequest,
  };
};

const deleteOfferRequestFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_DELETE_FAIL,
    error,
  };
};

export const deleteOfferRequest = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteOfferRequestStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteOfferRequestSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteOfferRequestFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listOfferRequestsStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_LIST_START,
  };
};

const listOfferRequestsSuccess = (offerrequests: Array<IOfferRequest>): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_LIST_SUCCESS,
    offerrequests,
  };
};

const listOfferRequestsFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_LIST_FAIL,
    error,
  };
};

type TListOfferRequestPromise = Promise<IOfferRequest[] | null>;
type TListOfferRequestAction = ThunkAction<
  TListOfferRequestPromise,
  IAppState,
  any,
  IOfferRequestAction
>;

export interface IOfferRequestsSearchOptions {
  operatorId?: string;
  serviceId?: string;
  limit?: boolean;
}


export const listOfferRequests = (options?: IOfferRequestsSearchOptions): TListOfferRequestAction => (
  dispatch: TDispatch
): TListOfferRequestPromise => {
  dispatch(listOfferRequestsStart());
  return axios
    .post(`${apiPath}/list`, options)
    .then((res) => {
      dispatch(listOfferRequestsSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch(({ response }) => {
      dispatch(listOfferRequestsFail((response as AxiosResponse).data));
      return Promise.resolve((response as AxiosResponse).data);
    });
};

const saveOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_SAVE_START,
  };
};

const saveOfferRequestSuccess = (offerrequest: IOfferRequest): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_SAVE_SUCCESS,
    offerRequest: offerrequest,
  };
};

const saveOfferRequestFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_SAVE_FAIL,
    error,
  };
};

export const saveOfferRequest = (offerrequest: IOfferRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveOfferRequestStart());
    try {
      const res = await axios.post(`${apiPath}/addlocal`, offerrequest);
      dispatch(saveOfferRequestSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveOfferRequestFail((response as AxiosResponse).data));
    }
  };
};

const updateOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_UPDATE_START,
  };
};

const updateOfferRequestSuccess = (offerrequest: IOfferRequest): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_UPDATE_SUCCESS,
    offerRequest: offerrequest,
  };
};

const updateOfferRequestFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_UPDATE_FAIL,
    error,
  };
};

export const updateOfferRequest = (offerrequest: IOfferRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateOfferRequestStart());
    try {
      const res = await axios.put(`${apiPath}/update`, offerrequest);
      dispatch(updateOfferRequestSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateOfferRequestFail((response as AxiosResponse).data));
    }
  };
};

const getCustomerOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_GET_START,
  };
};

const getCustomerOfferRequestSuccess = (
  offerrequest: IOfferRequest
): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_GET_SUCCESS,
    offerRequest: offerrequest,
  };
};

const getCustomerOfferRequestFail = (
  error: string
): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_GET_FAIL,
    error,
  };
};

export const getCustomerOfferRequest = (email: string, pinCode: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerOfferRequestStart());
    try {
      const res = await axios.get(`${apiPath}/pin/get?email=${email}&pin=${pinCode}`);
      dispatch(getCustomerOfferRequestSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getCustomerOfferRequestFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const updateCustomerOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_UPDATE_START,
  };
};

const updateCustomerOfferRequestSuccess = (
  offerrequest: IOfferRequest
): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_UPDATE_SUCCESS,
    offerRequest: offerrequest,
  };
};

const updateCustomerOfferRequestFail = (
  error: string
): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_UPDATE_FAIL,
    error,
  };
};

export const updateCustomerOfferRequest = (
  email: string,
  pinCode: string,
  offerrequest: IOfferRequest
) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateCustomerOfferRequestStart());
    try {
      const res = await axios.put(`${apiPath}/pin/update?email=${email}&pin=${pinCode}`, offerrequest);
      dispatch(updateCustomerOfferRequestSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateCustomerOfferRequestFail((response as AxiosResponse).data));
    }
  };
};

const archiveCustomerOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_ARCHIVE_START,
  };
};

const archiveCustomerOfferRequestSuccess = (
  offerrequest: IOfferRequest
): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_ARCHIVE_SUCCESS,
    offerRequest: offerrequest,
  };
};

const archiveCustomerOfferRequestFail = (
  error: string
): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_CUSTOMER_ARCHIVE_FAIL,
    error,
  };
};

export const archiveCustomerOfferRequest = (email: string, pinCode: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(archiveCustomerOfferRequestStart());
    try {
      const res = await axios.put(`${apiPath}/pin/archive?email=${email}&pin=${pinCode}`);
      dispatch(archiveCustomerOfferRequestSuccess(res.data));
    } catch ({ response }) {
      dispatch(archiveCustomerOfferRequestFail((response as AxiosResponse).data));
    }
  };
};

const addAccessOfferRequestStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ADD_ACCESS_START,
  };
};

const addAccessOfferRequestSuccess = (offerRequest: IOfferRequest): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ADD_ACCESS_SUCCESS,
    offerRequest
  };
};

const addAccessOfferRequestFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ADD_ACCESS_FAIL,
    error,
  };
};

export const addAccessOfferRequestClear = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ADD_ACCESS_CLEAR,
  };
};

export const addAccessOfferRequest = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(addAccessOfferRequestStart());
    try {
      const resp = await axios.post<string>(`${apiPath}/access/add?id=${id}`);
      dispatch(addAccessOfferRequestSuccess({ id: resp.data }));
    } catch ({ response }) {
      dispatch(addAccessOfferRequestFail((response as AxiosResponse).data));
    }
  };
};

const listOfferRequestAccessStart = (): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ACCESS_LIST_START,
  };
};

const listOfferRequestAccessSuccess = (offerRequestAccess: Array<IOfferRequestAccess>): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ACCESS_LIST_SUCCESS,
    offerRequestAccess,
  };
};

const listOfferRequestAccessFail = (error: string): IOfferRequestAction => {
  return {
    type: ActionTypes.OFFER_REQUEST_ACCESS_LIST_FAIL,
    error,
  };
};

type TListOfferRequestAccessPromise = Promise<IOfferRequestAccess[] | null>;
type TListOfferRequestAccessAction = ThunkAction<
  TListOfferRequestAccessPromise,
  IAppState,
  any,
  IOfferRequestAction
>;

export interface IOfferRequestAccessSearchOptions {
  organizationId?: string;
  createdStart?: string;
  createdEnd?: string;
}


export const listOfferRequestAccess = (options?: IOfferRequestAccessSearchOptions): TListOfferRequestAccessAction => (
  dispatch: TDispatch
): TListOfferRequestAccessPromise => {
  dispatch(listOfferRequestAccessStart());
  return axios
    .post(`${apiPath}/access/list`, options)
    .then((res) => {
      dispatch(listOfferRequestAccessSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch(({ response }) => {
      dispatch(listOfferRequestAccessFail((response as AxiosResponse).data));
      return Promise.resolve((response as AxiosResponse).data);
    });
};

export const clearOfferRequest = () => {
  return {
    type: ActionTypes.OFFER_REQUEST_CLEAR,
  };
};
