import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../../Button/Button";
import classes from "./StarOption.module.scss";


interface IProps {
  value: string;
  isActive: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onClick: (value: string) => void;
}

const StarOption: React.FC<IProps> = ({
  value,
  isActive,
  disabled,
  onClick,
  invalid
}) => {
  const classNames = [classes.Button];

  if (!isActive) {
    classNames.push(classes.InActive);
  }
  if (invalid) {
    classNames.push(classes.Invalid);
  }

  return (
    <Button disabled={disabled} onClick={event => onClick(value)} className={classNames.join(' ')}>
      <FontAwesomeIcon icon={faStar} />
    </Button>
  );
};

export default StarOption;
