import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ETranslation } from './../translations/translation-keys';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IOption } from '../components/UI/Input/Input';
import { IAppState, IAuthState } from '../interfaces/state';
import { createOptions } from '../shared/option-utils';
import { ILocationState } from './../interfaces/state';

import * as actions from '../store/actions';

interface IParams {
  withPlaceholder?: boolean;
  operatorId?: string;
  filterByOperatorId?: boolean;
}

const defaultParams: IParams = {
  withPlaceholder: false,
  operatorId: '',
  filterByOperatorId: false
}

export const useLocationOptions = ({ withPlaceholder, operatorId, filterByOperatorId } = defaultParams) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationOptions, setLocationOptions] = useState<IOption[]>([])
  const [tryFetch, setTryFetch] = useState(false);
  
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { locations, loading } = useSelector<IAppState, ILocationState>((state) => state.locations);

  useEffect(() => {
    if (authUser) {
      setTryFetch(authUser.isPrimary || authUser.isOperator);
    }
  }, [authUser]);

  useEffect(() => {
    if(tryFetch && !locations && !loading) {
      dispatch(actions.listLocations());
      setTryFetch(false);
    }
  }, [dispatch, locations, tryFetch, loading]);

  useEffect(() => {
    let placeholder;
    if(withPlaceholder) {
      placeholder = t(ETranslation.SELECT_LOCATIONS_PLACEHOLDER);
    }
    if(locations) {
      let optionLocations = [...locations];
      if (filterByOperatorId) {
        optionLocations = optionLocations.filter(s => s.operator?.id === operatorId);
      }
      setLocationOptions(createOptions(optionLocations, (l) => l.name || l.id, placeholder));

    } else {
      setLocationOptions([]);
    }
  }, [locations, withPlaceholder, operatorId, t, filterByOperatorId]);

  return { locationOptions, locationsLoading: loading };
}
