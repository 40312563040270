import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ERouteStaticParams, Routes } from "../../classes/Routes";
import ConfirmBillingModal from "../../components/ConfirmBillingModal/ConfirmBillingModal";
import OfferRequestsInfo from "../../components/OfferRequests/OfferRequestsInfo/OfferRequestsInfo";
import OfferRequestsSearch from "../../components/OfferRequests/OfferRequestsSearch/OfferRequestsSearch";
import OfferRequestTable from "../../components/OfferRequests/OfferRequestsTable/OfferRequestsTable";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import ConfirmDialog from "../../components/UI/ConfirmDialog/ConfirmDialog";
import Container from "../../components/UI/Container/Container";
import ModalContext, {
  EModalSize,
} from "../../components/UI/Modal/ModalContext";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAuthUser } from "../../hooks/useAuthUser";
import { IAppState, IOfferRequestState } from "../../interfaces";
import { linkHandler } from "../../shared/utility";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./OfferRequests.module.scss";

interface IProps extends RouteComponentProps {
  offerRequestId?: number;
}

const OfferRequests: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { setModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const authUser = useAuthUser();
  const {
    loading,
    error,
    offerRequests,
    addAccessError,
    addAccessLoading,
    addAccessSuccessId,
  } = useSelector<IAppState, IOfferRequestState>((state) => state.offerRequest);

  const setAddAccessModal = useCallback(
    (content: React.ReactNode | null, loading: boolean = false) => {
      setModal({
        isOpen: true,
        title: t(ETranslation.CONFIRM_BUY_TITLE),
        size: EModalSize.SMALL,
        loading,
        content,
      });
    },
    [t, setModal]
  );

  const createAccessModal = (id: string) => {
    setAddAccessModal(
      <ConfirmDialog
        body={<div><p>{t(ETranslation.CONFIRM_BUY_DESCRIPTION)}</p><p>{t(ETranslation.CONFIRM_BUY_QUESTION)}</p></div>}
        onConfirm={() => {
          dispatch(actions.addAccessOfferRequest(id));
        }}
        onCancel={closeModal}
      />
    );
  };

  const showOffer = (
    event: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement>,
    id: string
  ) => {
    if (authUser?.isMicro) {
      if (authUser?.hasOfferRequestAccess(id)) {
        linkHandler(event, Routes.OFFER_REQUEST(id), history);
      } else if (authUser.organization.isBillingConfirmed) {
        createAccessModal(id);
      } else {
        setModal({
          isOpen: true,
          content: (
            <ConfirmBillingModal
              onSuccess={() => {
                createAccessModal(id);
              }}
            />
          ),
          size: EModalSize.MEDIUM,
          title: t(ETranslation.SERVICEPROVIDER_INVOICE_CONFIRM),
        });
      }
    } else {
      linkHandler(event, Routes.OFFER_REQUEST(id), history);
    }
  };

  useEffect(() => {
    if (addAccessLoading) setAddAccessModal(null, true);
  }, [addAccessLoading, setAddAccessModal]);

  useEffect(() => {
    if (addAccessError)
      setAddAccessModal(
        <Alert noHorizontalMargin>
          {t(ETranslation.OFFER_REQUEST_ADD_ACCESS_ERROR)}
        </Alert>
      );
  }, [addAccessError, setAddAccessModal, t]);

  useEffect(() => {
    if (addAccessSuccessId) {
      closeModal();
      dispatch(actions.addAuthUserOfferRequestAccess(addAccessSuccessId));
      history.push(Routes.OFFER_REQUEST(addAccessSuccessId));
    }

    return () => {
      dispatch(actions.addAccessOfferRequestClear());
    };
  }, [addAccessSuccessId, history, dispatch, closeModal]);

  const searchHandler = useCallback(
    (search) => {
      dispatch(actions.listOfferRequests(search));
    },
    [dispatch]
  );

  return (
    <Container>
      {error && <Alert>{t(error)}</Alert>}
      <h2>{t(ETranslation.TITLE_OFFERREQUESTS)}</h2>
      {(authUser?.isPrimary || authUser?.isOperator) && (
        <Button
          onClick={() =>
            history.push(Routes.OFFER_REQUEST(ERouteStaticParams.new))
          }
          icon={faPlus}
          style={{ marginBottom: "1rem" }}
        >
          {t(ETranslation.OFFERREQUESTS_ADD_NEW)}
        </Button>
      )}
      <section className={classes.Section}>
        <main style={{ flexGrow: 1 }}>
          <OfferRequestsSearch onSearch={searchHandler} />
          {loading ? (
            <Spinner />
          ) : offerRequests ? (
            <OfferRequestTable
              offerrequests={offerRequests}
              onClick={showOffer}
              sortable
              authUser={authUser!}
            />
          ) : (
            <p>
              <br />
              {t(ETranslation.NO_OFFERREQUESTS)}
            </p>
          )}
        </main>
        {authUser?.isServiceProvider && (
          <OfferRequestsInfo authUser={authUser} />
        )}
      </section>
    </Container>
  );
};

export default OfferRequests;
