import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_SAVE]: "Tallenna",
  [ETranslation.COMMON_RETURN]: "Palaa",
  [ETranslation.COMMON_ADD]: "Lisää",
  [ETranslation.COMMON_REMOVE]: "Poista",
  [ETranslation.COMMON_COPY]: "Kopioi",
  [ETranslation.COMMON_STREET_ADDRESS]: "Katuosoite",
  [ETranslation.COMMON_ZIP]: "Postinumero",
  [ETranslation.COMMON_CITY]: "Postitoimipaikka",
  [ETranslation.COMMON_NO_CHOICE]: "Ei valintaa",
  [ETranslation.COMMON_CREATED]: "Luotu",
  [ETranslation.COMMON_YES]: "Kyllä",
  [ETranslation.COMMON_NO]: "Ei",
  [ETranslation.COMMON_COUNTRY]: "Maa",
  [ETranslation.COMMON_PHONE]: "Puhelinnumero",
  [ETranslation.COMMON_EMAIL]: "Sähköposti",
  [ETranslation.COMMON_SERVICE]: "Palvelu",
  [ETranslation.COMMON_ARCHIVED]: "Arkistoitu",
  [ETranslation.COMMON_WWW]: "WWW",
  [ETranslation.COMMON_FACEBOOK]: "Facebook",
  [ETranslation.COMMON_TWITTER]: "Twitter",
  [ETranslation.COMMON_INSTAGRAM]: "Instagram",
  [ETranslation.COMMON_LINKEDIN]: "Linkedin",
  [ETranslation.COMMON_LATITUDE]: "Latitude",
  [ETranslation.COMMON_LONGITUDE]: "Longitude",
  [ETranslation.COMMON_TOKEN]: "Token",
  [ETranslation.COMMON_SEARCH]: "Haku",
  [ETranslation.COMMON_SEND]: "Lähetä",
  [ETranslation.COMMON_CANCEL]: "Peruuta",
  [ETranslation.COMMON_ADD_ALL]: "Lisää kaikki",
  [ETranslation.COMMON_CLOSE]: "Sulje",
  [ETranslation.COMMON_OPERATOR_NAME]: "Operaattori",
  [ETranslation.COMMON_SERVICE_PROVIDER_NAME]: "Palveluntarjoaja",
  [ETranslation.COMMON_FETCH]: "Hae",
  [ETranslation.COMMON_BUY]: "Osta",
  [ETranslation.COMMON_COPY_CONTACT_INFORMATION]: "Kopioi leikepyödälle",
  [ETranslation.COMMON_COPIED]: "Tarjouspyynnön yhteystiedot kopioitu leikepöydälle",
  [ETranslation.COMMON_CONTINUE]: "Jatka",
  [ETranslation.COMMON_CONFIRM]: "Vahvista",
  [ETranslation.COMMON_LOAD_ALL]: "Lataa kaikki",
  
};

const geoLocationTranslation = {
  [ETranslation.GEO_LOCATION_ERROR]: 'Haku epäonnistui.'
}

const authTranslations = {
  [ETranslation.TITLE_HANKIX]: "HankiX tarjouspyyntöpalvelu",
  [ETranslation.LOGIN]: "Kirjaudu",
  [ETranslation.USERNAME]: "Käyttäjänimi",
  [ETranslation.PASSWORD]: "Salasana",
  [ETranslation.PASSWORD_MIN_LENGTH_MESSAGE]:
    "Salasanan pitää sisältää vähintään 6 merkkiä.",
  [ETranslation.FORGOT_PASSWORD]: "Unohditko salasanasi?",
  [ETranslation.RESET_PASSWORD]: "Palauta salasana",
};

const headerTranslations = {
  [ETranslation.ADMIN]: "Admin",
  [ETranslation.OPERATORS]: "Operaattorit",
  [ETranslation.ORGANIZATIONS]: "Yritykset",
  [ETranslation.OFFERREQUESTS]: "Tarjouspyynnöt",
  [ETranslation.SERVICEPROVIDERS]: "Palveluntarjoajat",
  [ETranslation.SERVICES]: "Palvelut",
  [ETranslation.FEEDBACKS]: "Palautteet",
  [ETranslation.USERS]: "Käyttäjät",
  [ETranslation.LOGOUT]: "Kirjaudu ulos",
  [ETranslation.SETTINGS]: "Asetukset",
  [ETranslation.CONTENTS]: "Uutisten hallinta",
  [ETranslation.MANAGEMENT]: "Hallinta",
  [ETranslation.OFFER_REQUEST_ACCESS]: "Ostetut tarjouspyynnöt",
  [ETranslation.LOG_ITEMS]: "Käyttäjäloki",
  [ETranslation.ORDER_SERVICE]: 'Tilaa palvelu',
};

const offerRequestsTranslations = {
  [ETranslation.TITLE_OFFERREQUESTS]: "Tarjouspyynnöt",
  [ETranslation.OFFERREQUESTS_ADD_NEW]: "Lisää tarjouspyyntö",
  [ETranslation.NO_OFFERREQUESTS]: "Ei tarjouspyyntöjä vielä. Malttia! Markkinointi on käynnistymässä.",
};

const offerRequestTranslations = {
  [ETranslation.TITLE_OFFERREQUEST]: "Tarjouspyyntö",
  [ETranslation.TITLE_SORT]: "Lajittele",
  [ETranslation.TITLE_OFFERREQUEST_NEW]: "Uusi tarjouspyyntö",
  [ETranslation.OFFERREQUEST_FIRSTNAME]: "Etunimi",
  [ETranslation.OFFERREQUEST_LASTNAME]: "Sukunimi",
  [ETranslation.OFFERREQUEST_EMAIL]: "Sähköposti",
  [ETranslation.OFFERREQUEST_CREATED]: "Saapunut",
  [ETranslation.OFFERREQUEST_ADDRESS]: "Osoite",
  [ETranslation.OFFERREQUEST_PHONE]: "Puhelin-numero",
  [ETranslation.OFFERREQUEST_CITY]: "Kaupunki",
  [ETranslation.OFFERREQUEST_STATUS]: "Tila",
  [ETranslation.OFFERREQUEST_TYPE]: "Tyyppi",
  [ETranslation.OFFERREQUEST_OPERATOR]: "Asiakas",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA]: "Pinta-ala m\u00b2",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE]: "Rakennustyyppi",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER]:
    "Muu rakennustyyppi",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR]: "Rakennusvuosi",
  [ETranslation.OFFER_REQUEST_WELLNESS_LENGTH]: "Palvelun pituus",
  [ETranslation.OFFER_REQUEST_WELLNESS_REGULAR]: "Säännöllinen",
  [ETranslation.OFFERREQUEST_INFO]: "Lisätiedot ja tarkennukset",
  [ETranslation.OFFER_REQUEST_DELETED]: "Tarjouspyyntö poistettu",
  [ETranslation.OFFER_REQUEST_ARCHIVED]: "Tarjouspyyntö arkistoitu",
  [ETranslation.OFFER_REQUEST_PROVINCE]: "Maakunta",
  [ETranslation.OFFER_REQUEST_LANGUAGE]: "Kieli",
  
  [ETranslation.OFFER_REQUEST_ADD_ACCESS_ERROR]: "Jotain meni väärin, Ei voitu lisätä oikeutta tarjouspyyntöön",
  [ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST_CREATED]: "Tarjouspyyntö luontipvm",
  [ETranslation.OFFER_REQUEST_ACCESS_USERNAME]: "Käyttäjän nimi",
  [ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST]: "Tarjouspyyntö",
  [ETranslation.OFFER_REQUEST_ACCESS_CREATED]: "Ostopvm",
  [ETranslation.OFFER_REQUEST_ACCESS_CREATED_START]: "Ostopvm alku",
  [ETranslation.OFFER_REQUEST_ACCESS_CREATED_END]: "Ostopvm loppu",
  [ETranslation.OFFER_REQUEST_ACCESS_NO_OFFER_REQUESTS]: "Ei löytynyt yhtään ostettua tarjouspyyntöä",

};

const logItemTranslations = {
  [ETranslation.LOG_ITEM_EVENT]: "Tapahtuma",
  [ETranslation.LOG_ITEM_USER_AGENT]: "User Agent",
  [ETranslation.LOG_ITEM_EVENT_CREATED_START]: "Tapahtuma pvm alku",
  [ETranslation.LOG_ITEM_EVENT_CREATED_END]: "Tapahtuma pvm loppu",
  [ETranslation.LOG_ITEM_EVENTS_SELECT]: "Valitse tapahtuma",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW]: "OFFER_REQUEST_VIEW",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW_PIN]: "OFFER_REQUEST_VIEW_PIN",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE]: "OFFER_REQUEST_SAVE",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE_LOCAL]: "OFFER_REQUEST_SAVE_LOCAL",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_DELETE]: "OFFER_REQUEST_DELETE",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE]: "OFFER_REQUEST_UPDATE",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE_PIN]: "OFFER_REQUEST_UPDATE_PIN",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_ARCHIVE_PIN]: "OFFER_REQUEST_ARCHIVE_PIN",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_LIST]: "OFFER_REQUEST_LIST",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_ADD_ACCESS]: "OFFER_REQUEST_ADD_ACCESS",
  [ETranslation.LOG_ITEM_EVENTS_USER_ACCEPT_TERMS_OF_USE]: "USER_ACCEPT_TERMS_OF_USE",
  [ETranslation.LOG_ITEM_EVENTS_ORGANIZATION_CONFIRM_BILLING]: "ORGANIZATION_CONFIRM_BILLING",
  [ETranslation.LOG_ITEM_EVENTS_AUTH_LOGIN]: "AUTH_LOGIN",
  [ETranslation.LOG_ITEM_EVENTS_AUTH_LOGOUT]: "AUTH_LOGOUT",
  [ETranslation.LOG_ITEM_NO_LOG_ITEMS]: "Ei löytynyt käyttäjäloki merkintöjä",

}

const offerRequestTypeTranslations = {
  [ETranslation.OFFER_REQUEST_TYPE_SIMPLE]: "Perus",
  [ETranslation.OFFER_REQUEST_TYPE_CONSTRUCTION]: "Rakennus",
  [ETranslation.OFFER_REQUEST_TYPE_WELLNESS]: "Hyvinvointi",
};

const buildingTypeTranslations = {
  [ETranslation.BUIDLING_TYPE_SELECT]: "Valitse rakennustyyppi",
  [ETranslation.BUILDING_TYPE_TOWN_HOUSE]: "Omakotitalo",
  [ETranslation.BUILDING_TYPE_SEMI_DETACHED_HOUSE]: "Paritalo",
  [ETranslation.BUILDING_TYPE_ROW_HOUSE]: "Rivitalo",
  [ETranslation.BUILDING_TYPE_DETACHED_HOUSE]: "Erillistalo",
  [ETranslation.BUILDING_TYPE_APARTMENT_HOUSE]: "Kerrostalo",
  [ETranslation.BUILDING_TYPE_OTHER]: "Muu",
};

const languageSelectTranslations = {
  [ETranslation.OFFERREQUEST_LANGUAGE_FI]: "Suomi",
  [ETranslation.OFFERREQUEST_LANGUAGE_EN]: "Englanti",
  [ETranslation.OFFERREQUEST_LANGUAGE_SE]: "Ruotsi",
};

const reactSelectTranslations = {
  [ETranslation.REACT_SELECT_PLACEHOLDER]: "Valitse",
  [ETranslation.REACT_SELECT_ADD_NEW]: "Lisää",
};

const operatorsTranslations = {
  [ETranslation.TITLE_OPERATORS]: "Operaattorit",
  [ETranslation.OPERATORS_ADD_NEW]: "Lisää uusi operaattori",
  [ETranslation.NO_OPERATORS]: "Ei operaattoreita.",
};

const offerRequestStatusTranslations = {
  [ETranslation.OFFERREQUEST_STATUS_OFFER]: "Tarjous avoin",
  [ETranslation.OFFERREQUEST_STATUS_OFFERED]: "Tarjous tehty",
  [ETranslation.OFFERREQUEST_STATUS_COMPLETE]: "Tarjous otettu",
  [ETranslation.OFFERREQUEST_SEND_DATE]: "lähetetty",
};

const serviceProvidersTranslations = {
  [ETranslation.TITLE_SERVICEPROVIDERS]: "Palveluntarjoajat",
  [ETranslation.SERVICEPROVIDERS_ADD_NEW]: "Lisää palveluntarjoaja",
  [ETranslation.NO_SERVICEPROVIDERS]: "Ei palveluntarjoajia",
  [ETranslation.SERVICEPROVIDER_FORMS]: "Uudet tilaukset",
  [ETranslation.NO_SERVICEPROVIDER_FORMS]: "Ei uusia tilauksia",
};

const serviceProviderTranslations = {
  [ETranslation.TITLE_SERVICEPROVIDER]: "Palveluntarjoajat",
  [ETranslation.TITLE_SORT]: "Lajittele",
  [ETranslation.TITLE_SERVICEPROVIDER_NEW]: "Uusi palveluntarjoaja",
  [ETranslation.SERVICEPROVIDER_FIRSTNAME]: "Etunimi",
  [ETranslation.SERVICEPROVIDER_LASTNAME]: "Sukunimi",
  [ETranslation.SERVICEPROVIDER_EMAIL]: "Sähköposti",
  [ETranslation.SERVICEPROVIDER_CREATED]: "Liittynyt",
  [ETranslation.SERVICEPROVIDER_ADDRESS]: "Osoite",
  [ETranslation.SERVICEPROVIDER_PHONE]: "Puhelin-numero",
  [ETranslation.SERVICEPROVIDER_CITY]: "Kaupunki",
  [ETranslation.SERVICEPROVIDER_STATUS]: "Tila",
  [ETranslation.SERVICEPROVIDER_TYPE]: "Tyyppi",
  [ETranslation.SERVICEPROVIDER_OPERATOR]: "Asiakas",
  [ETranslation.SERVICEPROVIDER_COMPANY]: "Yritys",

  [ETranslation.SERVICEPROVIDER_INVOICING_TYPE]: "Laskutustapa",
  [ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS]: "Verkkolaskuosoite",
  [ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS]: "Operaattorin tunnus",
  [ETranslation.SERVICEPROVIDER_INVOICEINFO]: "Laskutuksen lisätiedot",
  [ETranslation.SERVICEPROVIDER_INVOICE_CONFIRM]: "Tarjouspyynnön tietojen avaaminen on maksullinen toiminto. Vahvista laskutustietosi.",
  [ETranslation.SERVICEPROVIDER_INVOICE_MODAL_DESCRIPTION]: "Laskutustietojen vahvistaminen mahdollistaa tarjouspyyntöjen tietojen ostamisen kappalehintaan. Laskutamme ostot kuukausittain koontilaskuna. Saat ostamalla tarjouspyynnön tiedot heti käyttöösi ja voit tehdä asiakkaalle tarjouksen. Tarjouspyynnön ostaminen ei varmista tarjouksen hyväksymistä, eikä velvoita tarjouksen tekemiseen.",
  [ETranslation.SERVICEPROVIDER_INVOICE_CONFIRM_LABEL]: "Vahvistan laskutustiedot oikeiksi",
  [ETranslation.SERVICEPROVIDER_INVOICE_CHANGE_BILLING_INFORMATION]: "Muuta laskutustiedot",
  [ETranslation.SERVICEPROVIDER_SUBSCRIPTION_LEVEL]: "Tilaus",
  [ETranslation.INVOICING_TYPE_EINVOICE]: "Verkkolasku",
  [ETranslation.INVOICING_TYPE_OTHER]: "Muu laskutus",
  [ETranslation.SERVICEPROVIDER_USER_PHONE]: "Käyttäjän puhelinnumero",
  [ETranslation.SERVICEPROVIDER_ORGANIZATION_PHONE]: "Yrityksen puhelinnumero",
  [ETranslation.SUBSCRIPTION_LEVEL_FREE]: "Maksuton",
  [ETranslation.SUBSCRIPTION_LEVEL_BASIC]: "Perus",
  [ETranslation.SERVICEPROVIDER_EXPORT_ALL]: "Vie palveluntarjoajat",
  [ETranslation.SERVICEPROVIDER_EXPORT]: "Vie palveluntarjoaja",
  [ETranslation.SUBSCRIPTION_EXPIRED]: "Palvelun tilauskausi ei ole voimassa!",
  [ETranslation.SUBSCRIPTION_EXPIRED_INFO]: 'Voit muokata ',
  [ETranslation.SUBSCRIPTION_EXPIRED_INFO_LINK]: 'yrityksesi tietoja',
  [ETranslation.SERVICEPROVIDER_EXPIRED_SUBSCRIBE]: "Tilaa palvelu osoitteesta www.hankix.fi tai ota yhteyttä osoitteeseen info@hankix.fi",
  [ETranslation.SERVICEPROVIDER_PAYMENT_METHOD]: "Maksutapa",
  [ETranslation.PAYMENT_METHOD_MONTH]: "Kuukausi",
  [ETranslation.PAYMENT_METHOD_MICRO]: "Micro",
};

const servicesTranslations = {
  [ETranslation.TITLE_SERVICES]: "Palvelut",
  [ETranslation.SERVICES_ADD_NEW]: "Lisää uusi palvelu",
  [ETranslation.NO_SERVICES]: "Ei palveluita",
};

const serviceTranslations = {
  [ETranslation.TITLE_SERVICE]: "Palvelu",
  [ETranslation.TITLE_SERVICE_NEW]: "Uusi palvelu",
  [ETranslation.SERVICE_NAME]: "Palvelun nimi",
  [ETranslation.SERVICE_URL]: "URL",
};

const operatorTranslations = {
  [ETranslation.TITLE_OPERATOR]: "Operaattori",
  [ETranslation.TITLE_OPERATOR_NEW]: "Uusi operaattori",
  [ETranslation.OPERATOR_NAME]: "Nimi",
  [ETranslation.OPERATOR_LEVEL]: "Taso",
  [ETranslation.OPERATOR_BUSINESS_ID]: "Y-tunnus",
  [ETranslation.OPERATOR_PIPEDRIVE_ORGANIZATION_ID]: "Pipedrive",
};

const usersTranslations = {
  [ETranslation.TITLE_USERS]: "Käyttäjät",
  [ETranslation.USERS_ADD_NEW]: "Lisää uusi käyttäjä",
  [ETranslation.NO_USERS]: "Ei käyttäjiä.",
  [ETranslation.USER_NAME]: "Nimi",
  [ETranslation.USER_PASSWORD]: "Salasana",
};

const userTranslations = {
  [ETranslation.TITLE_USER]: "Käyttäjä",
  [ETranslation.TITLE_USER_NEW]: "Uusi käyttäjä",
  [ETranslation.USER_FIRST_NAME]: "Etunimi",
  [ETranslation.USER_LAST_NAME]: "Sukunimi",
  [ETranslation.USER_EMAIL]: "Sähköposti",
  [ETranslation.USER_PHONE_NUMBER]: "Puhelinnumero",
  [ETranslation.USER_TITLE]: "Titteli",
  [ETranslation.USER_ALLOW_LOGIN]: "Salli kirjautuminen",
  [ETranslation.USER_PROFILE_IMAGE]: "Profiilikuva",
  [ETranslation.USER_ADDRESS]: "Kotiosoite",
  [ETranslation.USER_SEND_NEW_OFFER_REQUEST_SUMMARY]:
    "Lähetä uudet tarjouspyynnöt sähköpostiin",
};

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Tarkista sähköposti",
  [ETranslation.VALIDATION_MIN_LENGTH]:
    "Syötä vähintään {{minLength}} merkkiä.",
  [ETranslation.VALIDATION_MAX_LENGTH]: "Syötä enintään {{maxLength}} merkkiä.",
  [ETranslation.VALIDATION_MIN_AMOUNT]:
    "Luku ei voi olla pienempi kuin {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]:
    "Luku ei voi olla suurempi kuin {{maxAmount}}.",
};

const errorTranslations = {
  [ETranslation.TOKEN_ERROR]: "Kirjautumisvirhe.",
  [ETranslation.REFRESH_TOKEN_ERROR]: "Kirjautumisvirhe.",
  [ETranslation.FILE_UPLOAD_ERROR]: "Tiedoston lisääminen epäonnistui.",
  [ETranslation.IDENTIFIER_ERROR]: "Virheellinen tunniste.",
  [ETranslation.DATABASE_ERROR]: "Tietokantavirhe.",
  [ETranslation.DATA_EMPTY_ERROR]: "Virheellinen syöte.",
  [ETranslation.ORGANIZATION_ERROR]: "Kirjautumisvirhe.",
  [ETranslation.NO_ACCESS_ERROR]: "Sinulla ei ole käyttöoikeutta.",
  [ETranslation.NOT_FOUND_ERROR]: "Ei tuloksia.",
  [ETranslation.PIPEDRIVE_NOT_CONNECTED_ERROR]:
    "Tiliisi ei ole liitetty Pipedriveä.",
  [ETranslation.NO_CONTRACT_LENGTH_ERROR]:
    "Sopimuksen hyväksyminen epäonnistui, sopimuksen kesto oli tyhjä.",
  [ETranslation.SEARCH_QUERY_STRING_ERROR]: "Virheellinen syöte.",
  [ETranslation.FEEDBACK_ALREADY_EXISTS]: "Palaute on jo lisätty.",
};

const organizationTranslations = {
  [ETranslation.ORGANIZATION_ME]: "Yritykseni",
  [ETranslation.ORGANIZATION_ORDER_DATE]: "Luotu",
  [ETranslation.ORGANIZATION_IMAGE]: "Logo",
  [ETranslation.ORGANIZATION_IMAGE_DROPZONE]: "Paina tästä tai pudota tiedosto.",
  [ETranslation.ORGANIZATION_IMAGE_DROPZONE_DROP]: "Pudota tiedosto",
};

const customerOfferRequestTranslations = {
  [ETranslation.CUSTOMER_OFFER_REQUEST]: "Tarjouspyyntö",
  [ETranslation.CUSTOMER_OFFER_REQUEST_SAVE_CHANGES]: "Tallenna muokkaukset",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ARCHIVE]: "Arkistoi",
  [ETranslation.CUSTOMER_OFFER_REQUEST_UNARCHIVE]: "Palauta",
  [ETranslation.CUSTOMER_OFFER_REQUEST_HELP_TEXT_1]:
    "Tarkenna ja muokkaa tarjouspyynnön tietoja.",
  [ETranslation.CUSTOMER_OFFER_REQUEST_HELP_TEXT_2]:
    "Jos et enää halua enempää tarjouksia, arkistoi tarjouspyyntö.",
  [ETranslation.CUSTOMER_OFFER_REQUEST_THANK_YOU_MESSAGE]: "Kiitos, muokkaukset tallennettu.",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ARCHIVED_CONFIRM]: "Tarjouspyyntö on arkistoitu, eikä sitä enää näytetä palveluntarjoajille",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ERROR_TOP]: "Virhe, tarjouspyyntöä ei löydy",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ERROR_BOTTOM]: "Tarkista linkki tai ota yhteyttä.",
};

const confirmTranslations = {
  [ETranslation.CONFIRM_DELETE_SERVICEPROVIDER]:
    "Haluatko varmasti poistaa palveluntarjoajan?",
  [ETranslation.CONFIRM_DELETE_SERVICE]: "Haluatko varmasti poistaa palvelun?",
  [ETranslation.CONFIRM_DELETE_OPERATOR]:
    "Haluatko varmasti poistaa operaattorin?",
  [ETranslation.CONFIRM_DELETE_LOCATION]:
    "Haluatko varmasti poistaa sijainnin?",
  [ETranslation.CONFIRM_DELETE_SERVICEPROVIDER_FORM]:
    "Haluatko varmasti poistaa tilauksen?",
  [ETranslation.CONFIRM_DELETE_OFFER_REQUEST]:
    "Haluatko varmasti poistaa tarjouspyynnön?",

  [ETranslation.CONFIRM_BUY_TITLE]: "Osta tarjouspyyntö",
  [ETranslation.CONFIRM_BUY_DESCRIPTION]: "Osta tarjouspyynnön tiedot käyttöösi (ml. asiakkaan yhteystiedot, kohteen perustiedot). Tiedot ovat käytettävissäsi kunnes tarjouspyyntö arkistoidaan.",
  [ETranslation.CONFIRM_BUY_QUESTION]: "Haluatko ostaa valitsemasi tarjouspyynnön tiedot 19 € + alv hintaan?",
};

const locationsTranslations = {
  [ETranslation.NO_LOCATIONS]: "Ei sijainteja",
  [ETranslation.LOCATIONS]: "Sijainnit",
  [ETranslation.LOCATIONS_NEW]: "Uusi sijainti",
  [ETranslation.LOCATION]: "Sijainti",
  [ETranslation.LOCATION_NAME]: "Nimi",
  [ETranslation.LOCATION_NAME_GENITIVE]: "Nimi genetiivi (-n)",
  [ETranslation.LOCATION_NAME_INESSIVE]: "Nimi inessiivi (-ssa/ssä)",
  [ETranslation.LOCATION_CONTENT]: "Sisältö",
  [ETranslation.LOCATION_LATITUDE]: "Leveysaste, latitude, Suomessa ~ 62",
  [ETranslation.LOCATION_LONGITUDE]: "Pituusaste, longitude, Suomessa ~ 25",
  [ETranslation.LOCATION_ZOOM_LEVEL]: "Zoom level",
};

const feedbackTranslations = {
  [ETranslation.FEEDBACK_SATISFACTION]: "Tyytyväisyys palveluun",
  [ETranslation.FEEDBACK_SERVICEPROVIDER]: "Palveluntarjoaja",
  [ETranslation.FEEDBACK_SERVICE_PROVIDER_OTHER]: "Muu palveluntarjoaja",
  [ETranslation.TITLE_FEEDBACKS]: "Palaute",
  [ETranslation.TITLE_FEEDBACK_NEW]: "Uusi palaute",
  [ETranslation.FEEDBACKS_ADD_NEW]: "Lisää palaute",
  [ETranslation.NO_FEEDBACK]: "Ei palautteita",
  [ETranslation.FEEDBACK_FORM_NOT_FOUND]: "Jäin ilman tarjouspyyntöjä",
  [ETranslation.FEEDBACK_FORM_SOMETHING_ELSE]: "Joku muu, mikä?",
  [ETranslation.FEEDBACK_THANK_YOU_TITLE]: "Kiitos",
  [ETranslation.FEEDBACK_THANK_YOU_MESSAGE]: "Kiitos palautteesta!",
  [ETranslation.FEEDBACK_EXISTS_MESSAGE]: "Kiitos, olet jo antanut palautteen.",
  [ETranslation.FEEDBACK_MESSAGE]: "Palaute",
};
const settingsTranslations = {
  [ETranslation.TITLE_SETTINGS]: "Asetukset",
  [ETranslation.SEND_PASSWORD_RESET_LINK]: "Vaihda salasana",
  [ETranslation.PASSWORD_RESET_TEXT]:
    "Oletko varma, että haluat lähettää salasanan palautuslinkin sähköpostiisi?",
};

const billingTranslations = {
  [ETranslation.BILLING_EXPORT_ALL]: "Vie laskut",
  [ETranslation.BILLING_EXPORT_ORGANIZATION]: "Vie lasku",
};

const selectTranslations = {
  [ETranslation.SELECT_SERVICE_PLACEHOLDER]: 'Valitse palvelu',
  [ETranslation.SELECT_OPERATOR_PLACEHOLDER]: 'Valitse operaattori',
  [ETranslation.SELECT_LOCATIONS_PLACEHOLDER]: 'Valitse sijainnit',
  [ETranslation.SELECT_SERVICE_PROVIDER_PLACEHOLDER]: "Valitse palveluntarjoaja"
}

const contentsTranslations = {
  [ETranslation.TITLE_CONTENTS]: "Uutiset",
  [ETranslation.CONTENTS_ADD_NEW]: "Lisää uusi uutinen",
  [ETranslation.NO_CONTENTS]: "Ei uutisia",
};

const contentTranslations = {
  [ETranslation.TITLE_CONTENT]: "Uutinen",
  [ETranslation.TITLE_CONTENT_NEW]: "Uusi uutinen",
  [ETranslation.CONTENT_NAME]: "Uutisen nimi",
  [ETranslation.CONTENT_SHORTCONTENT]: "Uutinen lyhyesti",
  [ETranslation.CONTENT_CONTENTTEXT]: "Uutinen pitkästi",
  [ETranslation.CONTENT_DATE]: "Uutisen julkaisupäivä",
  [ETranslation.CONTENT_SHOWDATE]: "Näytä uutisen julkaisupäivä",
  [ETranslation.CONTENT_TAGS]: "Tagit",
  [ETranslation.CONTENT_TAG_NORMAL]: "Normal",
  [ETranslation.CONTENT_TAG_NEWS]: "Uutiset",
  [ETranslation.CONTENT_TAG_FRONTPAGE]: "Frontpage",

};

const languageTranslations = {
  [ETranslation.LANGUAGE_TITLE]: "Kieli",
  [ETranslation.LANGUAGE_IN_FINNISH]: "Suomeksi",
  [ETranslation.LANGUAGE_IN_ENGLISH]: "In english",
  [ETranslation.LANGUAGE_ENGLISH]: "English",
  [ETranslation.LANGUAGE_FINNISH]: "Suomi",


};

const termsOfServiceTranslations = {
  [ETranslation.TERMS_TITLE]: "Tervetuloa HankiX-palveluun! Aloita hyväksymällä käyttöehdot",
  [ETranslation.TERMS_BINDING]: "Käyttöehdoissa sitoudut, kiteytetysti:",
  [ETranslation.TERMS_LIST_1]: "lailliseen liiketoimintaan",
  [ETranslation.TERMS_LIST_2]: "käsittelemään asiakastietoja luottamuksellisesti",
  [ETranslation.TERMS_LIST_3]: "maksamaan palvelun käytöstä sopimuksen mukaisesti",
  [ETranslation.TERMS_READ_MORE]: "Lue linkistä kaikki",
  [ETranslation.TERMS_TERMS]: "käyttöehdot",
  [ETranslation.TERMS_ACCEPT]: "Hyväksy käyttöehdot",
}

const offerRequestInfoTranslations = {
  [ETranslation.OFFERREQUEST_INFO_OFFER_YOUR_SERVICE]: "Tarjoa palveluasi:",
  [ETranslation.OFFERREQUEST_INFO_LIST_ITEM_1]: "Valitse kiinnostava tarjouspyyntö listasta.",
  [ETranslation.OFFERREQUEST_INFO_LIST_ITEM_2]: "Varmista että voit tarjota pyydetyn palvelun kohteeseen.",
  [ETranslation.OFFERREQUEST_INFO_LIST_ITEM_3]: "Ota yhteyttä asiakkaaseen ja anna tarjouksesi.",
  [ETranslation.OFFERREQUEST_INFO_QUESTIONS]: "Kysyttävää?",
  [ETranslation.OFFERREQUEST_INFO_READ_MORE]: "Lisätietoa palvelusta ja hinnoittelusta: ",
  [ETranslation.OFFERREQUEST_INFO_CONFIRM_INFO]: "Onko yrityksesi tiedot oikein?",
  [ETranslation.OFFERREQUEST_INFO_EDIT_INFO]: "Muokkaa",
}

const translationsEn = {
  ...commonTranslations,
  ...authTranslations,
  ...headerTranslations,
  ...operatorsTranslations,
  ...operatorTranslations,
  ...offerRequestTranslations,
  ...offerRequestsTranslations,
  ...offerRequestStatusTranslations,
  ...serviceProvidersTranslations,
  ...serviceProviderTranslations,
  ...servicesTranslations,
  ...serviceTranslations,
  ...reactSelectTranslations,
  ...usersTranslations,
  ...userTranslations,
  ...validationTranslations,
  ...errorTranslations,
  ...organizationTranslations,
  ...customerOfferRequestTranslations,
  ...confirmTranslations,
  ...locationsTranslations,
  ...feedbackTranslations,
  ...settingsTranslations,
  ...billingTranslations,
  ...offerRequestTypeTranslations,
  ...buildingTypeTranslations,
  ...selectTranslations,
  ...contentTranslations,
  ...contentsTranslations,
  ...geoLocationTranslation,
  ...logItemTranslations,
  ...languageTranslations,
  ...termsOfServiceTranslations,
  ...offerRequestInfoTranslations,
  ...languageSelectTranslations
};

export default translationsEn;
