import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';
import { IServiceProviderAction, IServiceProviderState } from './../../interfaces/state';

type TAction = IServiceProviderAction;
type TState = IServiceProviderState;

const initialState: TState = {
  loading: false,
  loadingForm: false,
  serviceProviders: null,
  serviceProvider: null,
  serviceProviderForm: null,
  serviceProviderForms: null,
  errorForm: null,
  error: null,
  saveOrUpdateOk: false
};

const getServiceProviderStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    serviceProvider: null,
    loading: true,
    error: null,
  });
};

const getServiceProviderSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    serviceProvider: action.serviceprovider,
    loading: false,
    error: null,
  });
};

const getServiceProviderFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteServiceProviderStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    serviceProvider: null,
    loading: true,
    error: null,
  });
};

const deleteServiceProviderSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProvider: action.serviceprovider,
    loading: false,
    error: null,
  });
};
const deleteServiceProviderFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listServiceProvidersStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    serviceProviders: null,
    serviceProvider: null,
    loading: true,
    error: null,
  });
};

const listServiceProvidersSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProviders: action.serviceproviders,
    loading: false,
    error: null,
  });
};

const listServiceProvidersFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveServiceProviderStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveServiceProviderSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    serviceProvider: action.serviceprovider,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveServiceProviderFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateServiceProviderStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateServiceProviderSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProvider: action.serviceprovider,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const updateServiceProviderFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getServiceProviderFormStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProviderForm: null,
    loadingForm: true,
    errorForm: null,
  });
};

const getServiceProviderFormSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProviderForm: action.serviceProviderForm,
    loadingForm: false,
    errorForm: null,
  });
};

const getServiceProviderFormFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loadingForm: false,
    errorForm: action.error,
  });
};

const listServiceProviderFormsStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProviderForms: null,
    serviceProviderForm: null,
    loadingForm: true,
    errorForm: null,
  });
};

const listServiceProviderFormsSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProviderForms: action.serviceProviderForms,
    loadingForm: false,
    errorForm: null,
  });
};

const listServiceProviderFormsFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loadingForm: false,
    errorForm: action.error,
  });
};

const deleteServiceProviderFormStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProvider: null,
    loadingForm: true,
    errorForm: null,
  });
};

const deleteServiceProviderFormSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    serviceProvider: action.serviceprovider,
    loadingForm: false,
    errorForm: null,
  });
};
const deleteServiceProviderFormFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loadingForm: false,
    errorForm: action.error,
  });
};

const clearServiceProvider = (state: TState, action: TAction): TState => {
  return { ...initialState };
};

const reducer = (state: TState = initialState, action: TAction): TState => {
  switch (action.type) {
    case ActionTypes.SERVICE_PROVIDER_GET_START:
      return getServiceProviderStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_GET_SUCCESS:
      return getServiceProviderSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_GET_FAIL:
      return getServiceProviderFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_DELETE_START:
      return deleteServiceProviderStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_DELETE_SUCCESS:
      return deleteServiceProviderSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_DELETE_FAIL:
      return deleteServiceProviderFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_LIST_START:
      return listServiceProvidersStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_LIST_SUCCESS:
      return listServiceProvidersSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_LIST_FAIL:
      return listServiceProvidersFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_SAVE_START:
      return saveServiceProviderStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_SAVE_SUCCESS:
      return saveServiceProviderSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_SAVE_FAIL:
      return saveServiceProviderFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_UPDATE_START:
      return updateServiceProviderStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_UPDATE_SUCCESS:
      return updateServiceProviderSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_UPDATE_FAIL:
      return updateServiceProviderFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_GET_START:
      return getServiceProviderFormStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_GET_SUCCESS:
      return getServiceProviderFormSuccess(state, action);
    case ActionTypes.SERIVCE_PROVIDER_FORM_GET_FAIL:
      return getServiceProviderFormFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_LIST_START:
      return listServiceProviderFormsStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_LIST_SUCCESS:
      return listServiceProviderFormsSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_LIST_FAIL:
      return listServiceProviderFormsFail(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_DELETE_START:
      return deleteServiceProviderFormStart(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_DELETE_SUCCESS:
      return deleteServiceProviderFormSuccess(state, action);
    case ActionTypes.SERVICE_PROVIDER_FORM_DELETE_FAIL:
      return deleteServiceProviderFormFail(state, action);
    case ActionTypes.CLEAR_SERVICEPROVIDER:
      return clearServiceProvider(state, action);
    default:
      return state;
  }
};

export default reducer;
