import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import Spinner, { ESpinnerSize } from "../../UI/Spinner/Spinner";
import classes from "./SearchSelect.module.scss";

interface IOption {
  value: string;
  label?: string;
}

type TValue = IOption | IOption[] | "";

export type TFetchOption = (
  searchTerm: string,
  signal: AbortSignal
) => Promise<IOption[]>;

export const createSelectedValue = (
  id?: string,
  label?: string
): IOption | undefined => {
  return (typeof id !== 'undefined' && id !== '') ? { value: id, label } : undefined;
};

// export const createSelectedDeals = (
//   deals?: IDeal[]
// ): IOption[] => {
//   const options: IOption[] = [];
//   if(deals) {
//     deals.forEach(deal => {
//       if (deal && deal.id) {
//         options.push({ value: deal.id.toString(), label: deal.name });
//       }
//     });
//   }
//   return options;
// }

interface IProps {
  inputName: string;
  onChange: (value: TValue) => void;
  value: TValue;
  fetchOptions?: TFetchOption;
  searchTermMin?: number;
  placeholder?: string;
  options?: IOption[];
  multiple?: boolean;
  disabled?: boolean;
  relative?: boolean;
}

const DEFAULT_SEARCH_TERM_MIN = 3;

const SearchSelect: React.FC<IProps> = ({
  inputName,
  value,
  onChange,
  fetchOptions,
  placeholder,
  options = [],
  searchTermMin = DEFAULT_SEARCH_TERM_MIN,
  multiple,
  disabled,
  relative,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState<TValue>(value);
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState<IOption[]>(options);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>(options);
  const [hasFocus, setFocus] = useState(false);
  const searchTimeout = useRef<null | number>(null);
  const abortController = useRef<null | AbortController>();
  const searchRef = useRef<HTMLInputElement>(null);

  const canSearch =
    !disabled && (multiple || (typeof (selectValue as IOption).value === 'undefined') || hasFocus);

  const blurHandler = useCallback((event: MouseEvent | null) => {
    document.removeEventListener("click", blurHandler);
    setFocus(false);
    setLoading(false);

    if (event) {
      const classList = (event.target as any).classList;
      if (classList.contains(classes.Option)) return;
    }
    setSearchValue("");
  }, []);

  useEffect(() => {
    if (fetchOptions) return;
    setSelectOptions(options);
  }, [options, fetchOptions]);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", blurHandler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  useEffect(() => {
    filterSearch(searchValue);
    // eslint-disable-next-line
  }, [selectOptions]);

  const searchHandler = (value: string) => {
    setSearchValue(value);
    filterSearch(value);
    setFocus(true);
    if (fetchOptions) {
      if (abortController.current) abortController.current.abort();
      if (searchTimeout.current) clearTimeout(searchTimeout.current);

      searchTimeout.current = window.setTimeout(async () => {
        if (value.length >= searchTermMin) {
          setLoading(true);
          try {
            abortController.current = new AbortController();
            const newOptions = await fetchOptions(
              value,
              abortController.current.signal
            );
            setSelectOptions(newOptions);
            abortController.current = null;
          } catch (error) {
            // Nothing to do
          } finally {
            setLoading(false);
          }
        }
      }, 300);
    }
  };

  const filterSearch = (search: string) => {
    setFilteredOptions(
      [...selectOptions].filter(
        (selectOption) =>
          (selectOption.label || selectOption.value)
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
      )
    );
  };

  const selectHandler = (option: IOption) => {
    let newSelectValue: TValue;
    if (multiple) {
      newSelectValue = [...(selectValue as IOption[])];
      newSelectValue.push(option);
    } else {
      newSelectValue = option;
    }
    setSearchValue("");
    setFocus(false);
    onChange(newSelectValue);
  };

  const focusHandler = useCallback(() => {
    if (hasFocus) {
      blurHandler(null);
      return;
    }
    setFilteredOptions([...selectOptions]);
    setSearchValue("");
    setFocus(true);
    document.removeEventListener("click", blurHandler);
    document.addEventListener("click", blurHandler);
  }, [hasFocus, selectOptions, blurHandler]);

  useEffect(() => {
    if (hasFocus && searchRef.current) {
      searchRef.current.focus();
    }
  }, [hasFocus, searchRef]);

  const renderOptions = () => {
    if (!filteredOptions || filteredOptions.length === 0) {
      let text = "";
      if (searchValue.length < searchTermMin) {
        text = `Kirjoita vähintään ${searchTermMin} merkkiä.`;
      } else {
        text = "Ei hakutuloksia.";
      }

      return <div className={classes.InfoOption}>{text}</div>;
    }

    let selectedValues: string[] = [];

    if (selectValue) {
      if (multiple) {
        selectedValues = (selectValue as IOption[]).map(
          (option) => option.value.toString()
        );
      } else {
        selectedValues.push((selectValue as IOption).value);
      }
    }

    return filteredOptions.map((option) => {
      const classNames = [classes.Option];
      let canClick = true;
      if (
        selectedValues.length &&
        selectedValues.indexOf(option.value.toString()) !== -1
      ) {
        classNames.push(classes.OptionSelected);
        canClick = false;
      }
      return (
        <div
          key={option.value}
          className={classNames.join(" ")}
          onClick={() => canClick && selectHandler(option)}
        >
          {option.label}
        </div>
      );
    });
  };

  const Selected: React.FC<{ children: ReactNode; value: string }> = ({
    value,
    children,
  }) => {
    const removeSelectedHandler = (
      event: React.MouseEvent<HTMLDivElement>,
      value: string
    ) => {
      event.stopPropagation();
      if (multiple) {
        onChange(
          (selectValue as IOption[]).filter((option) => option.value !== value)
        );
      } else {
        onChange("");
      }
    };

    const classNames = [classes.Selected];

    if (!multiple) {
      classNames.push(classes.SingleSelected);
    }

    return (
      <div
        className={classNames.join(" ")}
        onClick={() => !multiple && !disabled && focusHandler()}
      >
        <div>{children}</div>
        {!disabled && (
          <div
            className={classes.Remove}
            onClick={(event) => removeSelectedHandler(event, value)}
          >
            &times;
          </div>
        )}
      </div>
    );
  };

  const renderSingle = (option: IOption) => {
    if (!option || (typeof option.value === 'undefined')) return null;
    return (
      <Selected value={option.value}>{option.label || option.value}</Selected>
    );
  };

  const renderMultiple = (options: IOption[]) => {
    if (!options || options.length === 0) return null;
    return options.map((option) => (
      <Selected key={option.value} value={option.value}>
        {option.label || option.value}
      </Selected>
    ));
  };

  const renderSearch = () => {
    return (
      <input
        className={classes.SearchInput}
        type="text"
        value={searchValue}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          searchHandler(event.target.value)
        }
        ref={searchRef}
        onClick={() => focusHandler()}
      />
    );
  };

  const classNames = [classes.InputContainer];

  if (disabled) {
    classNames.push(classes.Disabled);
  }

  return (
    <div>
      <div className={classes.Container} style={{ position: 'relative' }}>
        <div className={classNames.join(" ")}>
          {multiple
            ? renderMultiple(selectValue as IOption[])
            : renderSingle(selectValue as IOption)}
          {canSearch ? renderSearch() : null}
        </div>

        {loading ? (
          <div className={classes.OptionsContainer}>
            <div className={classes.Option}>
              <Spinner size={ESpinnerSize.SMALL} />
            </div>
          </div>
        ) : (
          hasFocus && (
            <div
              className={classes.OptionsContainer}
              style={{ position: relative ? 'relative' : 'absolute' }}
            >
              {renderOptions()}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SearchSelect;
