import { HIDDEN_VALUE } from './common-values';
import * as dateFns from "date-fns";
import { TFunction } from "i18next";
import { EBuildingType, EOfferRequestType, EOfferStatus } from "../enums";
import { IOfferRequest, IOfferRequestConstruction } from "../interfaces";
import { ETranslation } from "../translations/translation-keys";

export const getTypeString = (t: TFunction, type?: EOfferRequestType) => {
  if (!type) return null;
  switch (type) {
    case EOfferRequestType.SIMPLE:
      return t(ETranslation.OFFER_REQUEST_TYPE_SIMPLE);
    case EOfferRequestType.CONSTRUCTION:
      return t(ETranslation.OFFER_REQUEST_TYPE_CONSTRUCTION);
    case EOfferRequestType.WELLNESS:
      return t(ETranslation.OFFER_REQUEST_TYPE_WELLNESS);
  }
};

export const getBuildingTypeString = (
  t: TFunction,
  offerRequest: IOfferRequestConstruction
) => {
  const buildingType = offerRequest.buildingType;
  if (!buildingType) return null;
  if (buildingType === HIDDEN_VALUE) return buildingType;
  switch (buildingType) {
    case EBuildingType.TOWN_HOUSE:
      return t(ETranslation.BUILDING_TYPE_TOWN_HOUSE);
    case EBuildingType.SEMI_DETACHED_HOUSE:
      return t(ETranslation.BUILDING_TYPE_SEMI_DETACHED_HOUSE);
    case EBuildingType.ROW_HOUSE:
      return t(ETranslation.BUILDING_TYPE_ROW_HOUSE);
    case EBuildingType.DETACHED_HOUSE:
      return t(ETranslation.BUILDING_TYPE_DETACHED_HOUSE);
    case EBuildingType.APARTMENT_HOUSE:
      return t(ETranslation.BUILDING_TYPE_APARTMENT_HOUSE);
    case EBuildingType.OTHER:
      if (!offerRequest.buildingTypeOther) {
        return t(ETranslation.BUILDING_TYPE_OTHER);
      }
      return offerRequest.buildingTypeOther;
  }
};

export const hasOfferStatus = (
  status: EOfferStatus,
  ...statuses: Array<EOfferStatus>
) => {
  if (!status || !statuses) return false;
  return statuses.indexOf(status) !== -1;
};

export const sortOffersByDate = (offerrequests: IOfferRequest[]) => {
  // Sort item arrays by dateOfTest
  return offerrequests.sort((a, b) => {
    if (!a.created || !b.created) return 0;
    const aDate = dateFns.parseISO(a.created.toString());
    const bDate = dateFns.parseISO(b.created.toString());
    if (aDate > bDate) return 1;
    if (bDate > aDate) return -1;
    return 0;
  });
};

export const getOfferStatus = (status: EOfferStatus) => {
  switch (status) {
    case EOfferStatus.OFFER:
      return ETranslation.OFFERREQUEST_STATUS_OFFER;
    case EOfferStatus.OFFERED:
      return ETranslation.OFFERREQUEST_STATUS_OFFERED;
    case EOfferStatus.COMPLETE:
      return ETranslation.OFFERREQUEST_STATUS_COMPLETE;
  }
};
