import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IFeedbackAction } from "../../interfaces";
import { IFeedback } from "../../interfaces/domain";
import { IOfferRequest, IOrganization } from "./../../interfaces/domain";
import { ActionTypes } from "./actionTypes";

const apiPath = '/json/feedback';

const getFeedbackStart = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_GET_START,
  };
};

const getFeedbackSuccess = (feedback: IFeedback): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_GET_SUCCESS,
    feedback,
  };
};

const getFeedbackFail = (error: string): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_GET_FAIL,
    error,
  };
};

export const getFeedback = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getFeedbackStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getFeedbackSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getFeedbackFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteFeedbackStart = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_DELETE_START,
  };
};

const deleteFeedbackSuccess = (feedback: IFeedback): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_DELETE_SUCCESS,
    feedback,
  };
};

const deleteFeedbackFail = (error: string): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_DELETE_FAIL,
    error,
  };
};

export const deleteFeedback = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteFeedbackStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteFeedbackSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteFeedbackFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listFeedbacksStart = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_LIST_START,
  };
};

const listFeedbacksSuccess = (
  feedbacks: Array<IFeedback>
): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_LIST_SUCCESS,
    feedbacks,
  };
};

const listFeedbacksFail = (error: string): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_LIST_FAIL,
    error,
  };
};

export const listFeedbacks = () => {
  return async (dispatch: Dispatch) => {
    dispatch(listFeedbacksStart());
    try {
      const res = await axios.get(`${apiPath}/list`);
      dispatch(listFeedbacksSuccess(res.data));
    } catch ({ response }) {
      dispatch(listFeedbacksFail((response as AxiosResponse).data));
    }
  };
};

const saveFeedbackStart = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_SAVE_START,
  };
};

const saveFeedbackSuccess = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_SAVE_SUCCESS
  };
};

const saveFeedbackFail = (error: string): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_SAVE_FAIL,
    error,
  };
};

export const saveFeedback = (feedback: IFeedback, id: string, pin: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveFeedbackStart());
    try {
      await axios.post(`${apiPath}/add?id=${id}&pin=${pin}`, feedback);
      dispatch(saveFeedbackSuccess());
    } catch ({ response }) {
      dispatch(saveFeedbackFail((response as AxiosResponse).data));
    }
  };
};

const updateFeedbackStart = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_UPDATE_START,
  };
};

const updateFeedbackSuccess = (feedback: IFeedback): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_UPDATE_SUCCESS,
    feedback,
  };
};

const updateFeedbackFail = (error: string): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_SAVE_FAIL,
    error,
  };
};

export const updateFeedback = (feedback: IFeedback) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateFeedbackStart());
    try {
      const res = await axios.put(`${apiPath}/update`, feedback);
      dispatch(updateFeedbackSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateFeedbackFail((response as AxiosResponse).data));
    }
  };
};

const getFeedbackFormDataStart = (): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_GET_FORM_DATA_START,
  };
};

const getFeedbackFormDataSuccess = (
  offerRequest: IOfferRequest,
  serviceProviders: IOrganization[]
): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_GET_FORM_DATA_SUCCESS,
    offerRequest,
    serviceProviders,
  };
};

const getFeedbackFormDataFail = (error: string): IFeedbackAction => {
  return {
    type: ActionTypes.FEEDBACK_GET_FORM_DATA_FAIL,
    error,
  };
};

export const getFeedbackFormData = (id: string, pinCode: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getFeedbackFormDataStart());
    try {
      const res = await axios.get(`${apiPath}/form/get?id=${id}&pin=${pinCode}`);
      dispatch(
        getFeedbackFormDataSuccess(
          res.data.offerRequest,
          res.data.serviceProviders
        )
      );
      return res.data;
    } catch ({ response }) {
      dispatch(getFeedbackFormDataFail((response as AxiosResponse).data));
      return null;
    }
  };
};

export const clearFeedback = () => {
  return {
    type: ActionTypes.FEEDBACK_CLEAR,
  };
};
