import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import qs from 'query-string';

import PasswordResetDialog from '../../components/Auth/PasswordResetDialog/PasswordResetDialog';
import Alert from '../../components/UI/Alert/Alert';
import Button, { EButtonColor } from '../../components/UI/Button/Button';
import Input, { EInputType, IInputField, IInputOptions } from '../../components/UI/Input/Input';
import ModalContext, { EModalSize } from '../../components/UI/Modal/ModalContext';
import Spinner from '../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../context/InputContext';
import { IAppState, IAuthState } from '../../interfaces';
import { updateInputHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Auth.module.scss';
import { useLocation } from 'react-router';

import firebase from '../../firebase';
import i18n from '../../i18n';

export enum EAuth {
  username = "username",
  password = "password",
};

const Auth: React.FC = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const location = useLocation();

  const { loading, error, customToken } = useSelector<IAppState, IAuthState>((state) => state.auth);

  const { t } = useTranslation();

  useEffect(() => {
    const params = qs.parse(location.search);
    if (params.t) {
      dispatch(actions.getCustomToken(params.t as string));
    }

  }, [location, dispatch]);

  useEffect(() => {
    if (customToken) {
      firebase.auth().signInWithCustomToken(customToken);
    }
  }, [customToken, error]);

  const [inputs, setInputs] = useState<IInputField>({
    [EAuth.username]: {
      type: EInputType.email,
      labelTranslation: ETranslation.USERNAME,
      placeholderTranslation: ETranslation.USERNAME,
      value: "",
    },
    [EAuth.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      placeholderTranslation: ETranslation.PASSWORD,
      value: "",
    },
  });

  const createAuthInput = (inputName: EAuth, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      {...item}
      {...options}
      updateAction={EInputUpdateAction.AUTH}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const submitHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username = inputs[EAuth.username].value as string;
    const password = inputs[EAuth.password].value as string;
    const preferredLanguage = i18n.language;
    dispatch(actions.auth(username, password, preferredLanguage));
  };

  const setPasswordResetDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.RESET_PASSWORD),
    size: EModalSize.SMALL,
    content: <PasswordResetDialog onCancel={closeModal} />,
  })

  return (
    <div className={classes.Container}>
      <div className={classes.LoginBox}>
        <div className={classes.Title}>{t(ETranslation.TITLE_HANKIX)}</div>
        {(loading && <Spinner />) || (
          <form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {createAuthInput(EAuth.username)}
              {createAuthInput(EAuth.password)}
            </div>
            <div className={classes.ButtonContainer}>
              <Button onClick={submitHandler} className={classes.Button} type="submit">
                {t(ETranslation.LOGIN)}
              </Button>
            </div>
            
            <div className={classes.ButtonContainer}>
            {i18n.language === "fi" ? (
                <Button onClick={() => i18n.changeLanguage('en')} color={EButtonColor.NONE}>{t(ETranslation.LANGUAGE_IN_ENGLISH)}</Button>
              ) : (
                <Button onClick={() => i18n.changeLanguage('fi')} color={EButtonColor.NONE}>{t(ETranslation.LANGUAGE_IN_FINNISH)}</Button>
              )}
              <Button className='passwordResetButton' onClick={setPasswordResetDialog} color={EButtonColor.NONE}>
                {t(ETranslation.FORGOT_PASSWORD)}
              </Button>
            </div>
            {error && (
              <Alert className={classes.ErrorMessage}>{error}</Alert>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;

