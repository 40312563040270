import React from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../translations/translation-keys';

import classes from './SingleCheckbox.module.scss'


interface IProps {
  labelTranslation?: ETranslation;
  label?: string;
  onChange: (value: boolean) => void;
  value: boolean;

}

const SingleCheckbox: React.FC<IProps> = ({ label, labelTranslation, onChange, value }) => {
  const { t } = useTranslation();
  return (
    <label className={classes.Container}>
      <input
        type="checkbox"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
        checked={value}
        className={classes.Input}
      />
      {labelTranslation ? t(labelTranslation) : label}
    </label>
  )
}

export default SingleCheckbox;