import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AuthUser } from '../../../classes/AuthUser';
import { Routes } from '../../../classes/Routes';
import { ETranslation } from '../../../translations/translation-keys';
import Button, { EButtonSize } from '../../UI/Button/Button';
import InfoBox from '../../UI/InfoBox/InfoBox';
import classes from './OfferRequestsInfo.module.scss';

interface IProps {
  authUser: AuthUser;
}

const OfferRequestsInfo: React.FC<IProps> = ({ authUser}) => {
  const history = useHistory();
  
  const { t } = useTranslation();

  return (
    <aside>
      <InfoBox className={classes.Box}>
        <h3>{t(ETranslation.OFFERREQUEST_INFO_OFFER_YOUR_SERVICE)}</h3>
        <ol>
          <li>{t(ETranslation.OFFERREQUEST_INFO_LIST_ITEM_1)}</li>
          <li>{t(ETranslation.OFFERREQUEST_INFO_LIST_ITEM_2)}</li>
          <li>{t(ETranslation.OFFERREQUEST_INFO_LIST_ITEM_3)}</li>
        </ol>
      </InfoBox>
      <InfoBox className={classes.Box}>
        <h3>{t(ETranslation.OFFERREQUEST_INFO_QUESTIONS)}</h3>
        <ul>
          <li style={{ whiteSpace: 'nowrap'}}><FontAwesomeIcon icon={faPhone} />&nbsp; <a href="tel:+358443722362">+358443722362</a></li>
          <li style={{ whiteSpace: 'nowrap'}}><FontAwesomeIcon icon={faEnvelope} />&nbsp; <a href="mailto:pekka.nurmela@posp.fi">pekka.nurmela@posp.fi</a></li>
        </ul>
        <p>{t(ETranslation.OFFERREQUEST_INFO_READ_MORE)} <a target="_blank" rel="noreferrer" href="https://hankix.fi">hankix.fi</a></p>
      </InfoBox>
      <InfoBox className={classes.Box}>
        <h3>{t(ETranslation.OFFERREQUEST_INFO_CONFIRM_INFO)}</h3>
        <ul>
          <li>{authUser?.organization.name}</li>
          <li>{authUser?.organization.streetAddress}</li>
          <li>{authUser?.organization.zip} {authUser?.organization.city}</li>
        </ul>
        <Button size={EButtonSize.SMALL} onClick={() => history.push(Routes.ORGANIZATIONS_ME)}>{t(ETranslation.OFFERREQUEST_INFO_EDIT_INFO)}</Button>
      </InfoBox>
    </aside>
  )
}

export default OfferRequestsInfo;
