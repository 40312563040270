import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IUserState, IUserAction } from "../../interfaces";

const initialState: IUserState = {
  loading: false,
  users: null,
  user: null,
  error: null,
  saveOrUpdateOk: false,
  termsOfUseLoading: false,
  termsOfUseError: null,
  termsOfUseAccepted: false
};

const getUserStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: null,
    loading: true,
    error: null,
  });
};

const getUserSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
  });
};

const getUserFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listUsersStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listUsersSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    users: action.users,
    loading: false,
    error: null,
  });
};

const listUsersFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveUserStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveUserSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveUserFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateUserStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateUserSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const updateUserFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};


const acceptTermsOfUseStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    termsOfUseLoading: true,
    termsOfUseError: null,
  });
};

const acceptTermsOfUseSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    termsOfUseLoading: false,
    termsOfUseError: null,
    termsOfUseAccepted: true
  });
};

const acceptTermsOfUseFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    termsOfUseLoading: false,
    termsOfUseError: action.error,
  });
};

const changeUserLanguageStart = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const changeUserLanguageSuccess = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const changeUserLanguageFail = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearUser = (
  state: IUserState,
  action: IUserAction
): IUserState => {
  return { ...initialState };
};

const reducer = (
  state: IUserState = initialState,
  action: IUserAction
): IUserState => {
  switch (action.type) {
    case ActionTypes.USER_GET_START:
      return getUserStart(state, action);
    case ActionTypes.USER_GET_SUCCESS:
      return getUserSuccess(state, action);
    case ActionTypes.USER_GET_FAIL:
      return getUserFail(state, action);
    case ActionTypes.USER_LIST_START:
      return listUsersStart(state, action);
    case ActionTypes.USER_LIST_SUCCESS:
      return listUsersSuccess(state, action);
    case ActionTypes.USER_LIST_FAIL:
      return listUsersFail(state, action);
    case ActionTypes.USER_SAVE_START:
      return saveUserStart(state, action);
    case ActionTypes.USER_SAVE_SUCCESS:
      return saveUserSuccess(state, action);
    case ActionTypes.USER_SAVE_FAIL:
      return saveUserFail(state, action);
    case ActionTypes.USER_UPDATE_START:
      return updateUserStart(state, action);
    case ActionTypes.USER_UPDATE_SUCCESS:
      return updateUserSuccess(state, action);
    case ActionTypes.USER_UPDATE_FAIL:
      return updateUserFail(state, action);
    case ActionTypes.USER_ACCEPT_TERMS_OF_USE_START:
      return acceptTermsOfUseStart(state, action);
    case ActionTypes.USER_ACCEPT_TERMS_OF_USE_SUCCESS:
      return acceptTermsOfUseSuccess(state, action);
    case ActionTypes.USER_ACCEPT_TERMS_OF_USE_FAIL:
      return acceptTermsOfUseFail(state, action);
      case ActionTypes.USER_CHANGE_LANGUAGE_START:
      return changeUserLanguageStart(state, action);
    case ActionTypes.USER_CHANGE_LANGUAGE_SUCCESS:
      return changeUserLanguageSuccess(state, action);
    case ActionTypes.USER_CHANGE_LANGUAGE_FAIL:
      return changeUserLanguageFail(state, action);
    case ActionTypes.USER_CLEAR:
      return clearUser(state, action);
    default:
      return state;
  }
};

export default reducer;
