import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { TDispatch } from '../..';
import axios from '../../axios';
import { IAppState, IServiceProviderAction } from '../../interfaces';
import { IOrganization, IServiceProvider } from '../../interfaces/domain';
import { IUser } from './../../interfaces/domain';
import { ActionTypes } from './actionTypes';

const apiPath  = "/json/serviceproviders";

type TAction = IServiceProviderAction;


const getServiceProviderStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_GET_START,
  };
};

const getServiceProviderSuccess = (serviceprovider: IServiceProvider): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_GET_SUCCESS,
    serviceprovider,
  };
};

const getServiceProviderFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_GET_FAIL,
    error,
  };
};

export const getServiceProvider = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getServiceProviderStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getServiceProviderSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getServiceProviderFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteServiceProviderStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_DELETE_START,
  };
};

const deleteServiceProviderSuccess = (serviceprovider: IServiceProvider): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_DELETE_SUCCESS,
    serviceprovider,
  };
};

const deleteServiceProviderFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_DELETE_FAIL,
    error,
  };
};

export const deleteServiceProvider = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteServiceProviderStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteServiceProviderSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteServiceProviderFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listServiceProvidersStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_LIST_START,
  };
};

const listServiceProvidersSuccess = (serviceproviders: IServiceProvider[]): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_LIST_SUCCESS,
    serviceproviders,
  };
};

const listServiceProvidersFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_LIST_FAIL,
    error,
  };
};

type TListServiceProviderPromise = Promise<IServiceProvider[] | null>;
type TListServiceProviderAction = ThunkAction<
  TListServiceProviderPromise,
  IAppState,
  any,
  TAction
>;

export interface IServiceProvidersSearchOptions {
  operatorId?: string;
  locationId?: string;
  serviceId?: string;
  subscriptionLevel?: string;
  paymentMethod?: string;
}

export const listServiceProviders = (
  options?: IServiceProvidersSearchOptions
): TListServiceProviderAction => (
  dispatch: TDispatch
): TListServiceProviderPromise => {
  dispatch(listServiceProvidersStart());
  return axios
    .post(`${apiPath}/list`, options)
    .then((res) => {
      dispatch(listServiceProvidersSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch(({ response }) => {
      dispatch(listServiceProvidersFail((response as AxiosResponse).data));
      return Promise.resolve((response as AxiosResponse).data);
    });
};

const saveServiceProviderStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_SAVE_START,
  };
};

const saveServiceProviderSuccess = (serviceprovider: IServiceProvider): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_SAVE_SUCCESS,
    serviceprovider,
  };
};

const saveServiceProviderFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_SAVE_FAIL,
    error,
  };
};

export const saveServiceProvider = (organization: IServiceProvider, user?: IUser, formId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveServiceProviderStart());
    try {
      const res = await axios.post(`${apiPath}/addlocal`, { organization, user, formId });
      dispatch(saveServiceProviderSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveServiceProviderFail((response as AxiosResponse).data));
    }
  };
};

const updateServiceProviderStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_UPDATE_START,
  };
};

const updateServiceProviderSuccess = (serviceprovider: IServiceProvider): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_UPDATE_SUCCESS,
    serviceprovider,
  };
};

const updateServiceProviderFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_UPDATE_FAIL,
    error,
  };
};

export const updateServiceProvider = (serviceprovider: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateServiceProviderStart());
    try {
      const res = await axios.put(`${apiPath}/update`, serviceprovider);
      dispatch(updateServiceProviderSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateServiceProviderFail((response as AxiosResponse).data));
    }
  };
};

const getServiceProviderFormStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_GET_START,
  };
};

const getServiceProviderFormSuccess = (serviceProviderForm: IServiceProvider): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_GET_SUCCESS,
    serviceProviderForm,
  };
};

export const getServiceProviderFormFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERIVCE_PROVIDER_FORM_GET_FAIL,
    error,
  };
};

export const getServiceProviderForm = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getServiceProviderFormStart());
    try {
      const res = await axios.get(`${apiPath}/forms/get?id=${id}`);
      dispatch(getServiceProviderFormSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getServiceProviderFormFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listServiceProviderFormsStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_LIST_START,
  };
};

const listServiceProviderFormsSuccess = (serviceProviderForms: IServiceProvider[]): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_LIST_SUCCESS,
    serviceProviderForms,
  };
};

const listServiceProviderFormsFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_LIST_FAIL,
    error,
  };
};

export const listServiceProviderForms = (operatorId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(listServiceProviderFormsStart());
    const param = operatorId ? `?operatorId=${operatorId}` : '';
    try {
      const res = await axios.get(`${apiPath}/forms/list${param}`)
      dispatch(listServiceProviderFormsSuccess(res.data));
    } catch({ response }) {
      dispatch(listServiceProviderFormsFail((response as AxiosResponse).data));
    }
  }
};

const deleteServiceProviderFormStart = (): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_DELETE_START,
  };
};

const deleteServiceProviderFormSuccess = (serviceprovider: IServiceProvider): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_DELETE_SUCCESS,
    serviceprovider,
  };
};

const deleteServiceProviderFormFail = (error: string): TAction => {
  return {
    type: ActionTypes.SERVICE_PROVIDER_FORM_DELETE_FAIL,
    error,
  };
};

export const deleteServiceProviderForm = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteServiceProviderFormStart());
    try {
      const res = await axios.delete(`${apiPath}/forms/delete?id=${id}`);
      dispatch(deleteServiceProviderFormSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteServiceProviderFormFail((response as AxiosResponse).data));
      return null;
    }
  };
};


export const clearServiceProvider = () => {
  return {
    type: ActionTypes.CLEAR_SERVICEPROVIDER,
  };
};
