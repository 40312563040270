import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from '../../../hooks/useAuthUser';
import { IContent } from '../../../interfaces';
import { formatDateString } from '../../../shared/utility';
import { ETranslation } from '../../../translations/translation-keys';
import Table from '../../UI/Table/Table';
import classes from './ContentsTable.module.scss';

interface IProps {
  contents: Array<IContent>
  statusProp?: string;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>, id: string) => void;
  sortable?: boolean;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  isPrimary?: boolean;
}

enum EContentsTableSortBy {
  CREATED = "created",
  TITLE = "title",
}

const ContentTable: React.FC<IProps> = ({ contents, statusProp, onClick, sortable, containerClassName, containerStyle, isPrimary }) => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const [sortedContents, setSortedContents] = useState<IContent[]>(contents);
  const [sort, setSort] = useState<EContentsTableSortBy>(EContentsTableSortBy.CREATED);
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);


  useEffect(() => {
    if (!sortable) return;
    // console.log("sort options changed to:", sort, sortDirection);

    const newContents = [...sortedContents];

    if (sort && newContents) {
      if (sortDirection) {
        newContents.reverse();
        setSortedContents(newContents);
        return;
      }

      newContents.sort((a, b) => {
          if (a[sort] === "" || a[sort] === null || a[sort] === undefined) return 1;
          if (b[sort] === "" || b[sort] === null || b[sort] === undefined) return -1;
          if (a[sort] === b[sort]) return 0;
          if (sort === EContentsTableSortBy.CREATED) return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
          return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
      });
      setSortedContents(newContents);
    }

    // eslint-disable-next-line
  }, [sort, sortDirection, sortable]);


  const getChevron = () => {
    // console.log(sortDirection);
    return <FontAwesomeIcon className={classes.Icon} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  const changeSortOrDirection = (sortBy: EContentsTableSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }

  const mapHeaders = () => {
    if (sortable) {
      return (
        <>
           {isPrimary ? <th>{t(ETranslation.COMMON_OPERATOR_NAME)}</th> : null}
          <th onClick={() => changeSortOrDirection(EContentsTableSortBy.CREATED)}>
            {t(ETranslation.COMMON_CREATED)}
            {sort === EContentsTableSortBy.CREATED && getChevron()}
          </th>
          <th>{t(ETranslation.COMMON_SERVICE)}</th>
          <th onClick={() => changeSortOrDirection(EContentsTableSortBy.TITLE)}>
            {t(ETranslation.CONTENT_NAME)}
            {sort === EContentsTableSortBy.TITLE && getChevron()}
          </th>
          <th>{t(ETranslation.CONTENT_SHORTCONTENT)}</th>
          <th>{t(ETranslation.COMMON_WWW)}</th>
          <th>{t(ETranslation.CONTENT_TAGS)}</th>
          <th>{t(ETranslation.CONTENT_DATE)}</th>
          <th>{t(ETranslation.CONTENT_SHOWDATE)}</th>
        </>
      )
    }
    return (
      <>
        {isPrimary ? <th>{t(ETranslation.COMMON_OPERATOR_NAME)}</th> : null}
        <th>{t(ETranslation.COMMON_CREATED)}</th>
        <th>{t(ETranslation.COMMON_SERVICE)}</th>
        <th>{t(ETranslation.CONTENT_NAME)}</th>
        <th>{t(ETranslation.CONTENT_SHORTCONTENT)}</th>
        <th>{t(ETranslation.COMMON_WWW)}</th>
        <th>{t(ETranslation.CONTENT_TAGS)}</th>
        <th>{t(ETranslation.CONTENT_DATE)}</th>
        <th>{t(ETranslation.CONTENT_SHOWDATE)}</th>
        
      </>
    )
  }

  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr className={classes.TableHeader}>
            {statusProp}
            {mapHeaders()}
          </tr>
        </thead>
        <tbody>
          {sortedContents.map(contents => (
            <tr key={contents.id} onClick={(e) => onClick(e, contents.id)}>
              {authUser?.isPrimary && <td>{contents.operator?.name}</td>}
              <td>{contents.created && formatDateString(contents.created)}</td>
              <td>{contents.services?.map(s => s.name).join(", ")}</td>
              <td>{contents.title}</td>
              <td>{contents.shortContent}</td>
              <td>{contents.url}</td>
              <td>{contents.tags?.map(c => c).join(", ")}</td>
              <td>{contents.publishDate && formatDateString(contents.publishDate)}</td>
              <td>{contents.showPublishDate ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ContentTable;
