import { EOrganizationLevel, EPaymentMethod, ESubscriptionLevel } from '../enums';
import { ELanguage } from '../i18n';
import { IOrganization } from '../interfaces/domain';
import { ERoute } from './Routes';

interface IAuthUser {
  id: number;
  firebaseUserId: string;
  organization: IOrganization;
  name: string;
  email: string;
  isTermsOfUseAccepted: boolean;
  preferredLanguage: ELanguage;
}

export class AuthUser implements IAuthUser {
  public id: number;
  public firebaseUserId: string;
  public organization: IOrganization;
  public name: string;
  public email: string;
  public isTermsOfUseAccepted: boolean;
  public preferredLanguage: ELanguage;

  public constructor(authUser: IAuthUser) {
    this.id = authUser.id;
    this.firebaseUserId = authUser.firebaseUserId
    this.organization = authUser.organization;
    this.name = authUser.name;
    this.email = authUser.email;
    this.isTermsOfUseAccepted = authUser.isTermsOfUseAccepted || false;
    this.preferredLanguage = authUser.preferredLanguage;
  }

  public get routes(): ERoute[] {
    const routes: ERoute[] = [
      ERoute.ORGANIZATION_USER,
      ERoute.USER,
      ERoute.USERS,
      ERoute.ORGANIZATIONS_ME,
      ERoute.SETTINGS,
      
    ];

    const { isPrimary, isOperator, isBasic, isFree } = this;

    if(isFree) {
      routes.push.apply(routes, [
        ERoute.SUBSCRIPTION_EXPIRED
      ]);
    }

    if(isPrimary) {
      routes.push.apply(routes, [
        ERoute.ADMIN,
        ERoute.OPERATORS,
        ERoute.OPERATOR,
        ERoute.LOG_ITEMS
      ]);
    }

    if(isPrimary || isOperator) {
      routes.push.apply(routes, [
        ERoute.SERVICE_PROVIDERS,
        ERoute.SERVICE_PROVIDER,
        ERoute.SERVICE_PROVIDER_PROVIDER,
        ERoute.SERVICES,
        ERoute.SERVICE,
        ERoute.SERVICES_SERVICE,
        ERoute.SERVICE_PROVIDER_FORM,
        ERoute.FEEDBACKS,
        ERoute.LOCATIONS,
        ERoute.LOCATION,
        ERoute.CONTENT,
        ERoute.CONTENTS,
        ERoute.CONTENTS_CONTENT,
        ERoute.OFFER_REQUEST_ACCESS,
      ]);
    }

    if(isPrimary || isOperator || isBasic) {
      routes.push.apply(routes, [
        ERoute.OFFER_REQUESTS,
        ERoute.OFFER_REQUEST,
        ERoute.OFFER_REQUEST_OFFER
      ]);
    }

    return routes;
  }

  public get isPrimary(): boolean {
    return this.organization.level === EOrganizationLevel.PRIMARY;
  }

  public get isOperator(): boolean {
    return this.organization.level === EOrganizationLevel.OPERATOR;
  }

  public get isServiceProvider(): boolean {
    return this.organization.level === EOrganizationLevel.SERVICE_PROVIDER;
  }

  public get isBasic(): boolean {
    return this.isServiceProvider && this.organization.subscriptionLevel === ESubscriptionLevel.BASIC;
  }

  public get isFree(): boolean {
    return this.isServiceProvider && this.organization.subscriptionLevel === ESubscriptionLevel.FREE;
  }

  public get isMicro(): boolean {
    return this.isServiceProvider && this.organization.paymentMethod === EPaymentMethod.MICRO;
  }

  public get redirect(): ERoute {
    if(this.isPrimary || this.isOperator || this.isBasic) {
      return ERoute.OFFER_REQUESTS;
    }
    return ERoute.SUBSCRIPTION_EXPIRED;
  }

  public get allowSearch(): boolean {
    return this.isPrimary || this.isOperator || this.isBasic;
  }

  public hasOfferRequestAccess(offerRequestId: string): boolean {
    if (this.organization.offerRequestAccess){
      return this.organization.offerRequestAccess?.includes(parseInt(offerRequestId));
    }
    return false;
  }

  public addOfferRequestAccess(offerRequestId: string): void {
    let offerRequestAccess = this.organization.offerRequestAccess;
    if (!offerRequestAccess) {
      offerRequestAccess = [];
    }
    offerRequestAccess.push(parseInt(offerRequestId));
    this.organization.offerRequestAccess = offerRequestAccess;
  }

  set isBillingConfirmed(isBillingConfirmed: boolean) {
    this.organization.isBillingConfirmed = isBillingConfirmed;
  }

}
