import React from 'react';
import { useTranslation } from 'react-i18next';

import { useServiceOptions } from '../../../hooks/useServiceOptions';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';


interface IProps {
  setServiceId: React.Dispatch<React.SetStateAction<string>>;
  serviceId: string;
}

const ServiceSearch: React.FC<IProps> = ({ setServiceId, serviceId}) => {

  const { serviceOptions, servicesLoading } = useServiceOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.COMMON_SERVICE)}</label>
      <Select
        inputName={"serviceSearch"}
        value={serviceId}
        onChange={setServiceId}
        options={serviceOptions}
        isSearchable
        isClearable
        isLoading={servicesLoading}
      />
    </div>
  )

}

export default ServiceSearch;