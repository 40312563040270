import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from '../../classes/Routes';
import OfferRequestAccessSearch from '../../components/OfferRequestAccess/OfferRequestAccessSearch/OfferRequestAccessSearch';
import OfferRequestAccessTable from '../../components/OfferRequestAccess/OfferRequestAccessTable/OfferRequestAccessTable';
import Alert from '../../components/UI/Alert/Alert';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useAuthUser } from '../../hooks/useAuthUser';
import { IAppState, IOfferRequestState } from '../../interfaces';
import { linkHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {
  offerRequestAccessId?: number;
}

const OfferRequestAccess: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authUser = useAuthUser();
  const { loading, error, offerRequestAccess} = useSelector<IAppState, IOfferRequestState>(state => state.offerRequest);

  const showOffer = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
    linkHandler(event, Routes.OFFER_REQUEST(id), history);
  }

  const searchHandler = useCallback((search) => {
    dispatch(actions.listOfferRequestAccess(search));
  }, [dispatch]);

  return (
    <Container>
      {error && <Alert>{t(error)}</Alert>}
      <h2>{t(ETranslation.OFFER_REQUEST_ACCESS)}</h2>
      <OfferRequestAccessSearch onSearch={searchHandler} />
      {loading ? (
        <Spinner />
      ) : offerRequestAccess ? (
        <OfferRequestAccessTable
          offerRequestAccess={offerRequestAccess}
          onClick={showOffer}
          sortable
          isPrimary={authUser?.isPrimary}
        />
      ) : (
        <p>
          <br />
          {t(ETranslation.OFFER_REQUEST_ACCESS_NO_OFFER_REQUESTS)}
        </p>
      )}
    </Container>
  );
};

export default OfferRequestAccess;