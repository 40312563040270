import React from 'react';
import { useTranslation } from 'react-i18next';

import { subscriptionLevels } from '../../../data/select-data';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';


interface IProps {
  setSubscriptionLevel: React.Dispatch<React.SetStateAction<string>>;
  subscriptionLevel: string;
}

const SubscriptionSearch: React.FC<IProps> = ({ setSubscriptionLevel, subscriptionLevel}) => {

  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.SERVICEPROVIDER_SUBSCRIPTION_LEVEL)}</label>
      <Select
        inputName={"subscriptionSearch"}
        value={subscriptionLevel}
        onChange={setSubscriptionLevel}
        options={subscriptionLevels}
        isSearchable
        isClearable
      />
    </div>
  )

}

export default SubscriptionSearch;