import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERouteStaticParams, Routes } from '../../classes/Routes';
import Alert from '../../components/UI/Alert/Alert';
import Button from '../../components/UI/Button/Button';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import Table from '../../components/UI/Table/Table';
import { IAppState, IOrganizationState } from '../../interfaces';
import { linkHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';


interface IProps extends RouteComponentProps { }

const Operators: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { loading, error, organizations} = useSelector<IAppState, IOrganizationState>(state => state.organization);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listOrganizations());
  }, [dispatch]);

  const showOperator = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
    linkHandler(event, Routes.OPERATOR(id), history);
  }

  return (
    <Container>
      {error && <Alert children={error} />}
      <h2>{t(ETranslation.TITLE_OPERATORS)}</h2>
      <Button onClick={() => history.push(Routes.OPERATOR(ERouteStaticParams.new))} icon={faPlus} style={{ marginBottom: "1rem" }}>{t(ETranslation.OPERATORS_ADD_NEW)}</Button>
      {loading
        ? <Spinner />
        : organizations
          ? (
            <Table hover>
              <thead>
                <tr>
                  <th>{t(ETranslation.OPERATOR_NAME)}</th>
                  <th>{t(ETranslation.COMMON_TOKEN)}</th>
                  <th>{t(ETranslation.OPERATOR_BUSINESS_ID)}</th>
                  <th>{t(ETranslation.COMMON_STREET_ADDRESS)}</th>
                  <th>{t(ETranslation.COMMON_ZIP)}</th>
                  <th>{t(ETranslation.COMMON_CITY)}</th>
                  <th>{t(ETranslation.COMMON_COUNTRY)}</th>
                </tr>
              </thead>
              <tbody>
                {organizations.map((organization) => (
                  <tr key={organization.id} onClick={(e) => showOperator(e, organization.id)}>
                    <td>{organization.name}</td>
                    <td>{organization.operatorToken}</td>
                    <td>{organization.businessId}</td>
                    <td>{organization.streetAddress}</td>
                    <td>{organization.zip}</td>
                    <td>{organization.city}</td>
                    <td>{organization.countryCode}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
          : <p>{t(ETranslation.NO_OPERATORS)}</p>
      }
    </Container>
  );
}

export default Operators;
