import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOfferRequestAccess } from '../../../interfaces';
import { formatDateString } from '../../../shared/utility';
import { ETranslation } from '../../../translations/translation-keys';
import Table from '../../UI/Table/Table';
import classes from './OfferRequestAccessTable.module.scss';

interface IProps {
  offerRequestAccess: Array<IOfferRequestAccess>
  statusProp?: string;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>, id: string) => void;
  sortable?: boolean;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  isPrimary?: boolean;
}

enum EOfferRequestAccessTableSortBy {
  CREATED = "created",
  OFFERREQUESTCREATED = "offerRequestCreated",
  SERVICENAME = "serviceName",
  USERNAME = "userName",
  ORGANIZATIONNAME = "organizationName"
}

const OfferRequestAccessTable: React.FC<IProps> = ({ offerRequestAccess, statusProp, onClick, sortable, containerClassName, containerStyle, isPrimary }) => {
  const { t } = useTranslation();

  const [sortedOfferRequestAccess, setSortedOfferRequestAccess] = useState<IOfferRequestAccess[]>(offerRequestAccess);
  const [sort, setSort] = useState<EOfferRequestAccessTableSortBy>(EOfferRequestAccessTableSortBy.CREATED);
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);


  useEffect(() => {
    if (!sortable) return;

    const newAccess = [...sortedOfferRequestAccess];

    if (sort && newAccess) {
      if (sortDirection) {
        newAccess.reverse();
        setSortedOfferRequestAccess(newAccess);
        return;
      }

      newAccess.sort((a, b) => {
          if (a[sort] === "" || a[sort] === null || a[sort] === undefined) return 1;
          if (b[sort] === "" || b[sort] === null || b[sort] === undefined) return -1;
          if (a[sort] === b[sort]) return 0;
          if (sort === EOfferRequestAccessTableSortBy.CREATED) return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
          return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
      });
      setSortedOfferRequestAccess(newAccess);
    }

    // eslint-disable-next-line
  }, [sort, sortDirection, sortable]);


  const getChevron = () => {
    return <FontAwesomeIcon className={classes.Icon} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  const changeSortOrDirection = (sortBy: EOfferRequestAccessTableSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }

  const mapHeaders = () => {
    if (sortable) {
      return (
        <>
          <th onClick={() => changeSortOrDirection(EOfferRequestAccessTableSortBy.CREATED)}>
            {t(ETranslation.OFFER_REQUEST_ACCESS_CREATED)}
            {sort === EOfferRequestAccessTableSortBy.CREATED && getChevron()}
          </th>
          <th>
            {t(ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST)}
          </th>
          <th onClick={() => changeSortOrDirection(EOfferRequestAccessTableSortBy.OFFERREQUESTCREATED)}>
            {t(ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST_CREATED)}
            {sort === EOfferRequestAccessTableSortBy.OFFERREQUESTCREATED && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EOfferRequestAccessTableSortBy.SERVICENAME)} >
            {t(ETranslation.SERVICE_NAME)}
            {sort === EOfferRequestAccessTableSortBy.SERVICENAME && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EOfferRequestAccessTableSortBy.USERNAME)} >
            {t(ETranslation.OFFER_REQUEST_ACCESS_USERNAME)}
            {sort === EOfferRequestAccessTableSortBy.USERNAME && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EOfferRequestAccessTableSortBy.ORGANIZATIONNAME)} >
            {t(ETranslation.SERVICEPROVIDER_COMPANY)}
            {sort === EOfferRequestAccessTableSortBy.ORGANIZATIONNAME && getChevron()}
          </th>
        </>
      )
    }
    return (
      <>
        <th>{t(ETranslation.OFFER_REQUEST_ACCESS_CREATED)}</th>
        <th>{t(ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST)}</th>
        <th>{t(ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST_CREATED)}</th>
        <th>{t(ETranslation.SERVICE_NAME)}</th>
        <th>{t(ETranslation.OFFER_REQUEST_ACCESS_USERNAME)}</th>
        <th>{t(ETranslation.SERVICEPROVIDER_COMPANY)}</th>
      </>
    )
  }

  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr className={classes.TableHeader}>
            {statusProp}
            {mapHeaders()}
          </tr>
        </thead>
        <tbody>
          {sortedOfferRequestAccess.map(access => {
            return (
              <tr key={access.id} onClick={(e) => onClick(e, access.offerRequestId)}>
                <td>{access.created && formatDateString(access.created)}</td>
                <td>{access.offerRequestUserName}</td>
                <td>{access.offerRequestCreated && formatDateString(access.offerRequestCreated)}</td>
                <td>{access.serviceName}</td>
                <td>{access.user?.name}</td>
                <td>{access.user?.organization?.name}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default OfferRequestAccessTable;