import { IAuthAction, IAuthState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

const initialState: IAuthState = {
  authUser: null,
  loading: false,
  error: null,
  customToken: null
};

const authStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: null,
    loading: true,
    authUser: null,
  });
};

const authSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    authUser: action.user,
    error: null,
    loading: false,
  });
};

const authFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const logoutStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, { error: null, loading: true });
};

const logoutSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    authUser: null,
    error: null,
    loading: false,
  });
};

const logoutFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getCustomTokenStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, { error: null, loading: true });
};

const getCustomTokenSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: null,
    loading: false,
    customToken: action.customToken
  });
};

const getCustomTokenFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getCurrentUserStart = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getCurrentUserSuccess = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    authUser: action.user,
    loading: false,
    error: null,
  });
};

const getCurrentUserFail = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    error: action.error,
    authUser: null,
    loading: false,
  });
};

const addAuthUserOfferRequestAccess = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  const authUser = state.authUser;
  const offerRequestId = action.offerRequestId;
  if (authUser && offerRequestId) {
    authUser.addOfferRequestAccess(offerRequestId);
  }
  return updateObject(state, {
    authUser,
  });
};

const setAuthUserBillingConfirmed = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  const authUser = state.authUser;
  if (authUser) {
    authUser.isBillingConfirmed = true;
  }
  return updateObject(state, {
    authUser,
  });
};

const setAuthUserOrganization = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  const authUser = state.authUser;
  const organization = action.organization;
  if (authUser && organization) {
    authUser.organization = organization;
  }
  return updateObject(state, {
    authUser,
  });
};

const reducer = (
  state: IAuthState = initialState,
  action: IAuthAction
): IAuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_START:
      return authStart(state, action);
    case ActionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case ActionTypes.AUTH_FAIL:
      return authFail(state, action);
    case ActionTypes.AUTH_LOGOUT_START:
      return logoutStart(state, action);
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case ActionTypes.AUTH_LOGOUT_FAIL:
      return logoutFail(state, action);
    case ActionTypes.AUTH_GET_START:
      return getCurrentUserStart(state, action);
    case ActionTypes.AUTH_GET_SUCCESS:
      return getCurrentUserSuccess(state, action);
    case ActionTypes.AUTH_GET_FAIL:
      return getCurrentUserFail(state, action);
    case ActionTypes.AUTH_GET_CUSTOM_TOKEN_START:
      return getCustomTokenStart(state, action);
    case ActionTypes.AUTH_GET_CUSTOM_TOKEN_SUCCESS:
      return getCustomTokenSuccess(state, action);
    case ActionTypes.AUTH_GET_CUSTOM_TOKEN_FAIL:
      return getCustomTokenFail(state, action);
    case ActionTypes.AUTH_ADD_OFFER_REQUEST_ACCESS:
      return addAuthUserOfferRequestAccess(state, action);
    case ActionTypes.AUTH_SET_BILLING_CONFIRMED:
      return setAuthUserBillingConfirmed(state, action);
    case ActionTypes.AUTH_SET_ORGANIZATION:
      return setAuthUserOrganization(state, action);
    default:
      return state;
  }
};

export default reducer;
