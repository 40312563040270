import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Routes } from '../../classes/Routes';
import { IAppState, IOrganizationState } from '../../interfaces';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import Alert from '../UI/Alert/Alert';
import Button, { EButtonSize } from '../UI/Button/Button';
import ModalBody from '../UI/Modal/ModalBody/ModalBody';
import ModalContext from '../UI/Modal/ModalContext';
import ModalFooter from '../UI/Modal/ModalFooter/ModalFooter';
import SingleCheckbox from '../UI/SingleCheckbox/SingleCheckbox';
import Spinner from '../UI/Spinner/Spinner';
import classes from './ConfirmBillingModal.module.scss';

interface IProps {
  onSuccess: () => void;
}

const ConfirmBillingModal: React.FC<IProps> = ({ onSuccess }) => {
  const [isBillingConfirmed, setIsBillingConfirmed] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { closeModal } = useContext(ModalContext);

  const { confirmBillingSuccess, confirmBillingLoading, confirmBillingError, organization, loading } =
    useSelector<IAppState, IOrganizationState>((state) => state.organization);
  
  
    
  useEffect(() => {
    dispatch(actions.getMyOrganization());
    return () => {
      dispatch(actions.clearOrganization())
    }
  }, [dispatch]);

  useEffect(() => {
    if (confirmBillingSuccess) {
      dispatch(actions.setAuthUserBillingConfirmed());
      closeModal();
      onSuccess();
    }
  }, [confirmBillingSuccess, closeModal, onSuccess, dispatch]);


  const confirmHandler = () => {
    dispatch(actions.confirmBilling());
  };

  const changeHandler = () => {
    closeModal();
    history.push(Routes.ORGANIZATIONS_ME);
  }

  return (
    <>
      <ModalBody>
        {(confirmBillingLoading || loading) ? (
          <Spinner />
        ) : (
          <>
            {confirmBillingError && <Alert>{confirmBillingError}</Alert>}
            <p>{t(ETranslation.SERVICEPROVIDER_INVOICE_MODAL_DESCRIPTION)}</p>
            <div className={classes.DetailRow}>
              <label>{t(ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS)}</label>
              <p>{organization?.eInvoiceAddress ? organization?.eInvoiceAddress : "-"}</p>
            </div>
            <div className={classes.DetailRow}>
              <label>{t(ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS)}</label>
              <p>{organization?.eInvoiceOperatorAddress ? organization?.eInvoiceOperatorAddress : "-"}</p>
            </div>
            <div className={classes.DetailRow}>
              <label>{t(ETranslation.SERVICEPROVIDER_INVOICEINFO)}</label>
              <p>{organization?.invoicingInfo ? organization?.invoicingInfo : "-"}</p>
            </div>
            <Button onClick={changeHandler} size={EButtonSize.SMALL}>
              {t(ETranslation.SERVICEPROVIDER_INVOICE_CHANGE_BILLING_INFORMATION)}
            </Button>
            <hr style={{ margin: '1.5rem 0' }}/>
            {organization?.invoicingInfo || organization?.eInvoiceAddress ?
            <SingleCheckbox
              labelTranslation={t(ETranslation.SERVICEPROVIDER_INVOICE_CONFIRM_LABEL)}
              onChange={setIsBillingConfirmed}
              value={isBillingConfirmed}
            />
            : ""}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={confirmHandler} disabled={!isBillingConfirmed}>
        {t(ETranslation.COMMON_CONFIRM)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ConfirmBillingModal;
