import React, { useEffect, useState } from 'react';
import { ESearchStorageKey } from '../../../enums';

import { IServicesSearchOptions } from '../../../store/actions/serviceActions';
import OperatorSearch from '../../Searches/OperatorSearch/OperatorSearch';
import SearchContainer from '../../Searches/SearchContainer/SearchContainer';

interface IProps {
  onSearch: (search: IServicesSearchOptions) => void;
};

const ServicesSearch: React.FC<IProps> = ({ onSearch }) => {
  const [isInit, setInit] = useState(false);
  const [operatorId, setOperatorId] = useState<string>("");

  useEffect(() => {
    setOperatorId(localStorage.getItem(ESearchStorageKey.OFFER_REQUEST_OPERATOR_ID) || '');
    setInit(true);
  }, [operatorId, isInit]);

  useEffect(() => {
    if(isInit) {
      onSearch({ operatorId, includeArchived: true });
      localStorage.setItem(ESearchStorageKey.OFFER_REQUEST_OPERATOR_ID, operatorId);
    }
  }, [onSearch, operatorId, isInit]);

  return (
    <SearchContainer>
      <OperatorSearch setOperatorId={setOperatorId} operatorId={operatorId} />
    </SearchContainer>
  );
};

export default ServicesSearch;
