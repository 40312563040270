import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInputData, initForm, updateInputHandler } from '../../../shared/utility';
import { ILogItemSearchOptions } from '../../../store/actions/logItemActions';
import { ETranslation } from '../../../translations/translation-keys';
import Button from '../../UI/Button/Button';
import Input, { EInputType, IInputField, IInputOptions } from '../../UI/Input/Input';
import InputGroup from '../../UI/InputGroup/InputGroup';
import { ESearchStorageKey } from '../../../enums';
import { events } from '../../../data/select-data';
import * as dateFns from 'date-fns';
import { useServiceProviderOptions } from '../../../hooks/useServiceProviderOptions';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import  './LogItemsSearch.scss';


interface IProps {
  onSearch: (search: ILogItemSearchOptions) => void;
};

enum EInputs {
  operatorId = "operatorId",
  serviceProviderId = "serviceProviderId",
  event = "event",
  createdStart = "createdStart",
  createdEnd = "createdEnd"
}

const defaultCreatedStartDate = dateFns.format(dateFns.addDays(new Date(), -7), 'yyyy-MM-dd');

const LogItemsSearch: React.FC<IProps> = ({ onSearch }) => {

  const { t } = useTranslation();

  const { organizationOptions } = useOrganizationOptions({ withPlaceholder: true, withBusinessId: false });
  const { serviceProviderOptions } = useServiceProviderOptions({ withPlaceholder: true });

  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.operatorId]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_OPERATOR_NAME,
      placeholderTranslation: ETranslation.COMMON_OPERATOR_NAME,
      value: "",
      options: []
    },
    [EInputs.serviceProviderId]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_SERVICE_PROVIDER_NAME,
      placeholderTranslation: ETranslation.COMMON_SERVICE_PROVIDER_NAME,
      value: "",
      options: []
    },
    [EInputs.event]: {
      type: EInputType.select,
      labelTranslation: ETranslation.LOG_ITEM_EVENT,
      placeholderTranslation: ETranslation.LOG_ITEM_EVENT,
      options: events,
      value: "",
    },
    [EInputs.createdStart]: {
      type: EInputType.datepicker,
      labelTranslation: ETranslation.LOG_ITEM_EVENT_CREATED_START,
      value: ''
    },
    [EInputs.createdEnd]: {
      type: EInputType.datepicker,
      labelTranslation: ETranslation.LOG_ITEM_EVENT_CREATED_END,
      value: "",
    },
  });

  useEffect(() => {
    const storageData = localStorage.getItem(ESearchStorageKey.LOG_ITEMS_SEARCH);
    let inputsData: ILogItemSearchOptions = { 
      operatorId: '', 
      serviceProviderId: '',  
      event: '',
      createdStart: defaultCreatedStartDate,
      createdEnd: ''
    };
    if (storageData) {
      inputsData = JSON.parse(storageData);
    }
    initForm(setInputs, inputsData);
    onSearch(inputsData);
  }, [onSearch]);

  const createInput = (inputName: EInputs, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        {...item}
        {...options}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  };

  const searchHandler = () => {
    const data = getInputData<ILogItemSearchOptions>(inputs);
    localStorage.setItem(ESearchStorageKey.LOG_ITEMS_SEARCH, JSON.stringify(data));
    onSearch(data);
  }

  return (
    <InputGroup>
      {createInput(EInputs.operatorId, {
        options: organizationOptions
      })}
      {createInput(EInputs.serviceProviderId, {
        options: serviceProviderOptions
      })}
      {createInput(EInputs.event)}
      {createInput(EInputs.createdStart)}
      {createInput(EInputs.createdEnd)}
      <Button onClick={searchHandler}>{t(ETranslation.COMMON_FETCH)}</Button>
    </InputGroup>
  );
};

export default LogItemsSearch;