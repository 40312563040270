import { ETranslation } from './../translations/translation-keys';
import { EBuildingType, ESubscriptionLevel, EInvoicingType, ETags, EPaymentMethod, EEvents, ELanguage } from './../enums/index';
import { IOption } from '../components/UI/Input/Input';



export const subscriptionLevels: IOption[] = [
    { value: ESubscriptionLevel.FREE, labelTranslation: ETranslation.SUBSCRIPTION_LEVEL_FREE },
    { value: ESubscriptionLevel.BASIC, labelTranslation: ETranslation.SUBSCRIPTION_LEVEL_BASIC }
];


export const buildingTypes: IOption[] = [
  { labelTranslation: ETranslation.BUIDLING_TYPE_SELECT, value: "" },
  { labelTranslation: ETranslation.BUILDING_TYPE_TOWN_HOUSE, value: EBuildingType.TOWN_HOUSE },
  { labelTranslation: ETranslation.BUILDING_TYPE_SEMI_DETACHED_HOUSE, value: EBuildingType.SEMI_DETACHED_HOUSE },
  { labelTranslation: ETranslation.BUILDING_TYPE_ROW_HOUSE, value: EBuildingType.ROW_HOUSE },
  { labelTranslation: ETranslation.BUILDING_TYPE_DETACHED_HOUSE, value: EBuildingType.DETACHED_HOUSE },
  { labelTranslation: ETranslation.BUILDING_TYPE_APARTMENT_HOUSE, value: EBuildingType.APARTMENT_HOUSE },
  { labelTranslation: ETranslation.BUILDING_TYPE_OTHER, value: EBuildingType.OTHER },
];

export const invoicingTypes: IOption[] = [
  { value: EInvoicingType.EINVOICE, labelTranslation: ETranslation.INVOICING_TYPE_EINVOICE },
  { value: EInvoicingType.OTHER, labelTranslation: ETranslation.INVOICING_TYPE_OTHER },
];

export const tagTypes: IOption[] = [
  { value: ETags.NORMAL, label: "Normal" },
  { value: ETags.UUTISET, label: "News" },
  { value: ETags.FRONTPAGE, label: "FrontPage" },
];

export const paymentMethods: IOption[] = [
    { value: EPaymentMethod.MONTH, labelTranslation: ETranslation.PAYMENT_METHOD_MONTH },
    { value: EPaymentMethod.MICRO, labelTranslation: ETranslation.PAYMENT_METHOD_MICRO }
];

export const events: IOption[] = [
    { value: "", labelTranslation: ETranslation.LOG_ITEM_EVENTS_SELECT},
    { value: EEvents.AUTH_LOGIN, labelTranslation: ETranslation.LOG_ITEM_EVENTS_AUTH_LOGIN },
    { value: EEvents.AUTH_LOGOUT, labelTranslation: ETranslation.LOG_ITEM_EVENTS_AUTH_LOGOUT },
    { value: EEvents.OFFER_REQUEST_ADD_ACCESS, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_ADD_ACCESS },
    { value: EEvents.OFFER_REQUEST_ARCHIVE_PIN, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_ARCHIVE_PIN },
    { value: EEvents.OFFER_REQUEST_DELETE, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_DELETE },
    { value: EEvents.OFFER_REQUEST_LIST, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_LIST },
    { value: EEvents.OFFER_REQUEST_SAVE, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE },
    { value: EEvents.OFFER_REQUEST_SAVE_LOCAL, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE_LOCAL },
    { value: EEvents.OFFER_REQUEST_UPDATE, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE },
    { value: EEvents.OFFER_REQUEST_UPDATE_PIN, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE_PIN },
    { value: EEvents.OFFER_REQUEST_VIEW, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW },
    { value: EEvents.OFFER_REQUEST_VIEW_PIN, labelTranslation: ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW_PIN },
    { value: EEvents.ORGANIZATION_CONFIRM_BILLING, labelTranslation: ETranslation.LOG_ITEM_EVENTS_ORGANIZATION_CONFIRM_BILLING },
    { value: EEvents.USER_ACCEPT_TERMS_OF_USE, labelTranslation: ETranslation.LOG_ITEM_EVENTS_USER_ACCEPT_TERMS_OF_USE },
];

export const wellnessLength: IOption[] = [
  { value: "30", label: "30 min" },
  { value: "45", label: "45 min" },
  { value: "60", label: "60 min" },
  { value: "90", label: "90 min" },
];

export const languages: IOption[] = [
  { value: ELanguage.FI, labelTranslation: ETranslation.OFFERREQUEST_LANGUAGE_FI },
  { value: ELanguage.EN, labelTranslation: ETranslation.OFFERREQUEST_LANGUAGE_EN },
  { value: ELanguage.SE, labelTranslation: ETranslation.OFFERREQUEST_LANGUAGE_SE },
];