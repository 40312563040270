import { ILocationAction, ILocationState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: ILocationState = {
  loading: false,
  locations: null,
  location: null,
  error: null,
  saveOrUpdateOk: false
};

const getLocationStart = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    location: null,
    loading: true,
    error: null,
  });
};

const getLocationSuccess = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    location: action.location,
    loading: false,
    error: null,
  });
};

const getLocationFail = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteLocationStart = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    location: null,
    loading: true,
    error: null,
  });
};

const deleteLocationSuccess = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    location: action.location,
    loading: false,
    error: null,
  });
};

const deleteLocationFail = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listLocationsStart = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    locations: null,
    location: null,
    loading: true,
    error: null,
  });
};

const listLocationsSuccess = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    locations: action.locations,
    loading: false,
    error: null,
  });
};

const listLocationsFail = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveLocationStart = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveLocationSuccess = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    location: action.location,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const saveLocationFail = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateLocationStart = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateLocationSuccess = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    location: action.location,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const updateLocationFail = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearLocation = (
  state: ILocationState,
  action: ILocationAction
): ILocationState => {
  return { ...initialState };
};

const reducer = (
  state: ILocationState = initialState,
  action: ILocationAction
): ILocationState => {
  switch (action.type) {
    case ActionTypes.LOCATION_GET_START:
      return getLocationStart(state, action);
    case ActionTypes.LOCATION_GET_SUCCESS:
      return getLocationSuccess(state, action);
    case ActionTypes.LOCATION_GET_FAIL:
      return getLocationFail(state, action);
    case ActionTypes.LOCATION_DELETE_START:
      return deleteLocationStart(state, action);
    case ActionTypes.LOCATION_DELETE_SUCCESS:
      return deleteLocationSuccess(state, action);
    case ActionTypes.LOCATION_DELETE_FAIL:
      return deleteLocationFail(state, action);
    case ActionTypes.LOCATION_LIST_START:
      return listLocationsStart(state, action);
    case ActionTypes.LOCATION_LIST_SUCCESS:
      return listLocationsSuccess(state, action);
    case ActionTypes.LOCATION_LIST_FAIL:
      return listLocationsFail(state, action);
    case ActionTypes.LOCATION_SAVE_START:
      return saveLocationStart(state, action);
    case ActionTypes.LOCATION_SAVE_SUCCESS:
      return saveLocationSuccess(state, action);
    case ActionTypes.LOCATION_SAVE_FAIL:
      return saveLocationFail(state, action);
    case ActionTypes.LOCATION_UPDATE_START:
      return updateLocationStart(state, action);
    case ActionTypes.LOCATION_UPDATE_SUCCESS:
      return updateLocationSuccess(state, action);
    case ActionTypes.LOCATION_UPDATE_FAIL:
      return updateLocationFail(state, action);
    case ActionTypes.LOCATION_CLEAR:
      return clearLocation(state, action);
    default:
      return state;
  }
};

export default reducer;
