import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IAppState, IUserState } from '../../interfaces';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import Alert from '../UI/Alert/Alert';
import Button from '../UI/Button/Button';
import ModalBody from '../UI/Modal/ModalBody/ModalBody';
import ModalContext from '../UI/Modal/ModalContext';
import ModalFooter from '../UI/Modal/ModalFooter/ModalFooter';
import SingleCheckbox from '../UI/SingleCheckbox/SingleCheckbox';
import Spinner from '../UI/Spinner/Spinner';

interface IProps {}

const TermsOfUseModal: React.FC<IProps> = () => {
  const [isTermsOfUseAccepted, setIsTermsOfUseAccapted] = useState(false);

  const { closeModal } = useContext(ModalContext);

  const { t } = useTranslation();

  const { termsOfUseAccepted, termsOfUseError, termsOfUseLoading } =
    useSelector<IAppState, IUserState>((state) => state.users);

  useEffect(() => {
    if (termsOfUseAccepted) {
      closeModal();
    }
  }, [termsOfUseAccepted, closeModal]);

  const dispatch = useDispatch();

  const acceptHandler = () => {
    dispatch(actions.acceptTermsOfUse());
  };

  return (
    <>
      <ModalBody>
        {termsOfUseLoading ? (
          <Spinner />
        ) : (
          <>
            <h2>{t(ETranslation.TERMS_BINDING)}</h2>
            <ul><li>{t(ETranslation.TERMS_LIST_1)}</li>
            <li>{t(ETranslation.TERMS_LIST_2)}</li>
            <li>{t(ETranslation.TERMS_LIST_3)}</li></ul>
            <p>{t(ETranslation.TERMS_READ_MORE)} <a href="https://www.hankix.fi/kayttoehdot/" target="_blank" rel="noreferrer">{t(ETranslation.TERMS_TERMS)}</a></p>
            {termsOfUseError && <Alert>{termsOfUseError}</Alert>}
            <SingleCheckbox 
              labelTranslation={ETranslation.TERMS_ACCEPT}
              onChange={setIsTermsOfUseAccapted}
              value={isTermsOfUseAccepted}            
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={acceptHandler} disabled={!isTermsOfUseAccepted}>
        {t(ETranslation.COMMON_CONTINUE)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default TermsOfUseModal;
