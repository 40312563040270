import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TDispatch } from '../..';
import { IAppState, IServiceProvider, IServiceProviderState } from '../../interfaces';
import { formatDateString } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import Alert from '../UI/Alert/Alert';
import Spinner from '../UI/Spinner/Spinner';
import Table from '../UI/Table/Table';

interface IProps {
  // valmiiksi tehty operator id:llä haku jos pitää adminien saada etsittyä
  operatorId?: string;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>, id: string) => void;
}

const ServiceProviderForms: React.FC<IProps> = ({ operatorId, onClick }) => {
  const dispatch = useDispatch<TDispatch>();
  const { t } = useTranslation();

  const { loadingForm, errorForm, serviceProviderForms } = useSelector<IAppState, IServiceProviderState>(state => state.serviceProvider);


  useEffect(() => {
    dispatch(actions.listServiceProviderForms(operatorId));
  }, [dispatch, operatorId]);

  const createFormTable = (serviceProviderForms: IServiceProvider[]) => {
    serviceProviderForms.sort((a, b) => (a.created || 0) > (b.created || 0) ? -1 : 1);
    return serviceProviderForms.map(serviceProvider => (
      <tr key={serviceProvider.id} onClick={(e) => onClick(e, serviceProvider.id)}>
        <td>{serviceProvider.created && formatDateString(serviceProvider.created)}</td>
        <td>{serviceProvider.company}</td>
        <td>{serviceProvider.firstName}</td>
        <td>{serviceProvider.lastName}</td>
        <td>{serviceProvider.services?.map(s => s.name).join(", ")}</td>
      </tr>
    ));
  }
  return (
    <div>
      {errorForm && <Alert>{errorForm}</Alert>}
      <h2>{t(ETranslation.SERVICEPROVIDER_FORMS)}</h2>
      {loadingForm ? <Spinner />
        : serviceProviderForms
          ? (
            <Table hover>
              <thead>
                <tr>
                  <th>{t(ETranslation.COMMON_CREATED)}</th>
                  <th>{t(ETranslation.SERVICEPROVIDER_COMPANY)}</th>
                  <th>{t(ETranslation.SERVICEPROVIDER_FIRSTNAME)}</th>
                  <th>{t(ETranslation.SERVICEPROVIDER_LASTNAME)}</th>
                  <th>{t(ETranslation.SERVICES)}</th>
                </tr>
              </thead>
              <tbody>
                {createFormTable(serviceProviderForms)}
              </tbody>
            </Table>
          )
          : t(ETranslation.NO_SERVICEPROVIDER_FORMS)}
    </div>
  );
};

export default ServiceProviderForms;
