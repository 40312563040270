import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes } from '../../classes/Routes';
import { AuthUser } from '../../classes/AuthUser';
import HeaderContext from '../../context/HeaderContext';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Header.module.scss';
import Menu, { EMenuType, IMenuItem } from './Menu/Menu';

interface IProps extends RouteComponentProps {
  currentUser: AuthUser;
  logout: () => void;
}

const Header: React.FC<IProps> = ({ history, currentUser, logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);

  const { t } = useTranslation();

  const clickHandler = (link: string) => history.push(link);

  const menuItems: IMenuItem[] = [
    { text: t(ETranslation.ORDER_SERVICE), to: Routes.SUBSCRIPTION_EXPIRED },
    { text: t(ETranslation.OFFERREQUESTS), to: Routes.OFFER_REQUESTS },
    { text: t(ETranslation.LOCATIONS), to: Routes.LOCATIONS },
    { text: t(ETranslation.SERVICEPROVIDERS), to: Routes.SERVICE_PROVIDERS },
    { text: t(ETranslation.SERVICES), to: Routes.SERVICES },
    { text: t(ETranslation.FEEDBACKS), to: Routes.FEEDBACKS },
    { text: t(ETranslation.OPERATORS), to: Routes.OPERATORS },
    { text: t(ETranslation.CONTENTS), to: Routes.CONTENTS },
    { text: t(ETranslation.MANAGEMENT), subMenuItems: [
        { text: t(ETranslation.OFFER_REQUEST_ACCESS), to: Routes.OFFER_REQUEST_ACCESS },
        { text: t(ETranslation.LOG_ITEMS), to: Routes.LOG_ITEMS }
      ]
    },
    { text: t(ETranslation.COMMON_SEARCH), search: true },
  ];

  const dropdownMenuItems: IMenuItem[] = [
    { 
      text: currentUser?.name || "", 
      subMenuItems: [
        { text: t(ETranslation.ORGANIZATION_ME), to: Routes.ORGANIZATIONS_ME },
        { text: t(ETranslation.USERS), to: Routes.USERS },
        { text: t(ETranslation.SETTINGS), to: Routes.SETTINGS },
        { text: t(ETranslation.LOGOUT), action: logout }
      ]
    }
  ];

  return (
    <HeaderContext.Provider value={{ menuItems, dropdownMenuItems }}>
      <Menu type={EMenuType.MOBILE} isOpen={isOpen} toggleMenu={toggleMenu} currentUser={currentUser}/>
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.ToggleMenu} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} className={classes.Icon} />
          </div>
          <div className={classes.Logo} onClick={() => clickHandler(Routes.ADMIN)}>
          </div>
          <Menu type={EMenuType.DESKTOP} isOpen={false} toggleMenu={toggleMenu} currentUser={currentUser} />
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

export default withRouter(React.memo(Header));
