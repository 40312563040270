import { ILogItemAction, ILogItemState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

type TState = ILogItemState;
type TAction = ILogItemAction;

const initialState: TState = {
  loading: false,
  logItems: null,
  error: null,
};

const listLogItemsStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    logItems: null,
    loading: true,
    error: null,
  });
};

const listLogItemsSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    logItems: action.logItems,
    loading: false,
    error: null,
  });
};

const listLogItemsFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

const clearLogItem = (state: TState, action: TAction): TState => {
  return { ...initialState };
};

const reducer = (
  state: TState = initialState,
  action: TAction
): TState => {
  switch (action.type) {
    case ActionTypes.LOG_ITEM_LIST_START:
      return listLogItemsStart(state, action);
    case ActionTypes.LOG_ITEM_LIST_SUCCESS:
      return listLogItemsSuccess(state, action);
    case ActionTypes.LOG_ITEM_LIST_FAIL:
      return listLogItemsFail(state, action);
    case ActionTypes.LOG_ITEM_LIST_CLEAR:
      return clearLogItem(state, action);
    default:
      return state;
  }
};

export default reducer;