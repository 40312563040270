import React, { CSSProperties, ReactNode } from 'react';

import classes from './InfoBox.module.scss';

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const InfoBox: React.FC<IProps> = ({ children, style, className }) => {
  const classNames = [classes.Container];

  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  )
}

export default InfoBox;