import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERouteStaticParams, Routes } from '../../classes/Routes';
import ServicesSearch from '../../components/Services/ServicesSearch/ServicesSearch';
import Alert from '../../components/UI/Alert/Alert';
import Button from '../../components/UI/Button/Button';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import Table from '../../components/UI/Table/Table';
import { useAuthUser } from '../../hooks/useAuthUser';
import { IAppState, IServiceState } from '../../interfaces';
import { getTypeString } from '../../shared/offerrequest-utils';
import { linkHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { IServicesSearchOptions } from '../../store/actions/serviceActions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {
  serviceId?: number;
}

const Services: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const authUser = useAuthUser();
  const { loading, error, services } = useSelector<IAppState, IServiceState>(state => state.services);

  const { t } = useTranslation();

  const searchHandler = useCallback((search?: IServicesSearchOptions) => {
    dispatch(actions.listServices(search));
  }, [dispatch]);

  useEffect(() => {
    if(!authUser?.isPrimary) {
      searchHandler()
    }
  }, [authUser, searchHandler]);

  const showService = (event: React.MouseEvent<HTMLElement>, id: string) => {
    linkHandler(event, Routes.SERVICE(id), history);
  };

  return (
    <>
      {error && <Alert children={error} />}
      <Container>
        <h2>{t(ETranslation.TITLE_SERVICE)}</h2>
        <Button onClick={(e) => showService(e, ERouteStaticParams.new)} icon={faPlus} style={{ marginBottom: "1rem" }}>
          {t(ETranslation.TITLE_SERVICE_NEW)}
        </Button>
        {authUser?.isPrimary && <ServicesSearch onSearch={searchHandler} />}
        {loading ? (
          <Spinner />
        ) : services ? (
          <Table hover>
            <thead>
              <tr>
                <th>{t(ETranslation.SERVICE_NAME)}</th>
                <th>{t(ETranslation.COMMON_TOKEN)}</th>
                <th>{t(ETranslation.SERVICE_URL)}</th>
                <th>{t(ETranslation.LOCATIONS)}</th>
                {authUser?.isPrimary && (
                  <th>{t(ETranslation.COMMON_OPERATOR_NAME)}</th>
                )}
                <th>{t(ETranslation.OFFERREQUEST_TYPE)}</th>
                <th>{t(ETranslation.COMMON_ARCHIVED)}</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr
                  key={service.id}
                  onClick={(e) => showService(e, service.id)}
                >
                  <td>{service.name}</td>
                  <td>{service.serviceToken}</td>
                  <td>{service.url}</td>
                  <td>{service.locations?.map((l) => l.name).join(", ")}</td>
                  {authUser?.isPrimary && <td>{service.operator?.name}</td>}
                  <td>{getTypeString(t, service?.type)}</td>
                  <td>
                    {service.archived ? <FontAwesomeIcon icon={faCheck} /> : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>{t(ETranslation.NO_SERVICES)}</p>
        )}
      </Container>
    </>
  );
};

export default Services;
