import { IContentAction, IContentState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

type TState = IContentState;
type TAction = IContentAction;

const initialState: TState = {
  loading: false,
  contents: null,
  content: null,
  error: null,
  saveOrUpdateOk: false
};

const getContentStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    content: null,
    loading: true,
    error: null,
  });
};

const getContentSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null,
  });
};

const getContentFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteContentStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    content: null,
    loading: true,
    error: null,
  });
};

const deleteContentSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null,
  });
};

const deleteContentFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listContentsStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    contents: null,
    content: null,
    loading: true,
    error: null,
  });
};

const listContentsSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    contents: action.contents,
    loading: false,
    error: null,
  });
};

const listContentsFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};


const saveContentStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveContentSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveContentFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateContentStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateContentSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const updateContentFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearContent = (state: TState, action: TAction): TState => {
  return { ...initialState };
};

const reducer = (
  state: TState = initialState,
  action: TAction
): TState => {
  switch (action.type) {
    case ActionTypes.CONTENT_GET_START:
      return getContentStart(state, action);
    case ActionTypes.CONTENT_GET_SUCCESS:
      return getContentSuccess(state, action);
    case ActionTypes.CONTENT_GET_FAIL:
      return getContentFail(state, action);
    case ActionTypes.CONTENT_DELETE_START:
      return deleteContentStart(state, action);
    case ActionTypes.CONTENT_DELETE_SUCCESS:
      return deleteContentSuccess(state, action);
    case ActionTypes.CONTENT_DELETE_FAIL:
      return deleteContentFail(state, action);
    case ActionTypes.CONTENT_LIST_START:
      return listContentsStart(state, action);
    case ActionTypes.CONTENT_LIST_SUCCESS:
      return listContentsSuccess(state, action);
    case ActionTypes.CONTENT_LIST_FAIL:
      return listContentsFail(state, action);
    case ActionTypes.CONTENT_SAVE_START:
      return saveContentStart(state, action);
    case ActionTypes.CONTENT_SAVE_SUCCESS:
      return saveContentSuccess(state, action);
    case ActionTypes.CONTENT_SAVE_FAIL:
      return saveContentFail(state, action);
    case ActionTypes.CONTENT_UPDATE_START:
      return updateContentStart(state, action);
    case ActionTypes.CONTENT_UPDATE_SUCCESS:
      return updateContentSuccess(state, action);
    case ActionTypes.CONTENT_UPDATE_FAIL:
      return updateContentFail(state, action);
    case ActionTypes.CONTENT_CLEAR:
      return clearContent(state, action);
    default:
      return state;
  }
};

export default reducer;