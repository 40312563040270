import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import ConfirmDialog from "../../../components/UI/ConfirmDialog/ConfirmDialog";
import Container from "../../../components/UI/Container/Container";
import Input, {
  EInputType,
  IInputField,
  IInputOptions,
} from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import ModalContext, {
  defaultModal,
  EModalSize,
} from "../../../components/UI/Modal/ModalContext";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { IAppState, IOrganization, IOrganizationState } from "../../../interfaces";
import { countries } from "../../../shared/countries";
import {
  getInputData,
  initForm,
  updateInputHandler,
  validateInputs,
} from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import Users from "../../Users/Users";

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

export enum EOperator {
  name = "name",
  businessId = "businessId",
  streetAddress = "streetAddress",
  zip = "zip",
  city = "city",
  countryCode = "countryCode",
  operatorToken = "operatorToken",
}

const Operator: React.FC<IProps> = ({ match, history, location }) => {
  const dispatch = useDispatch();

  const { setModal } = useContext(ModalContext);

  const { loading, error, organization, saveOrUpdateOk } = useSelector<IAppState, IOrganizationState>(state => state.organization);

  const { t } = useTranslation();
  const { id } = match.params;

  const [inputs, setInputs] = useState<IInputField>({
    [EOperator.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OPERATOR_NAME,
      placeholderTranslation: ETranslation.OPERATOR_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EOperator.businessId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OPERATOR_BUSINESS_ID,
      placeholderTranslation: ETranslation.OPERATOR_BUSINESS_ID,
      value: "",
    },
    [EOperator.streetAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
    },
    [EOperator.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
    },
    [EOperator.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
    },
    [EOperator.countryCode]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_COUNTRY,
      placeholderTranslation: ETranslation.COMMON_COUNTRY,
      value: countries[0].value,
      options: countries,
    },
    [EOperator.operatorToken]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_TOKEN,
      value: "",
    },
  });

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(Routes.OPERATORS);
    }
    return () => {
      dispatch(actions.clearOrganization());
    }
  }, [saveOrUpdateOk, dispatch, history]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getOrganization(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (organization) {
      initForm(setInputs, organization);
    }
  }, [organization]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (
    inputName: EOperator,
    options?: IInputOptions
  ) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={false}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.OPERATOR}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const organization = getInputData<IOrganization>(inputs);

    if (Routes.isNew(id)) {
      dispatch(actions.saveOrganization(organization));
    } else {
      organization.id = id;
      dispatch(actions.updateOrganization(organization));
    }
  };

  const deleteOperator = (id: string) => {
    dispatch(actions.deleteServiceProvider(id));
    history.goBack();
    setModal(defaultModal);
  };

  const setConfirmDeleteDialog = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_REMOVE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          body={<p>{t(ETranslation.CONFIRM_DELETE_OPERATOR)}</p>}
          onConfirm={() => deleteOperator(id)}
          onCancel={() => setModal(defaultModal)}
        />
      ),
    });

  return (
    <Container>
      {error && <Alert children={error} />}
      <h2>
        {Routes.isNew(id)
          ? t(ETranslation.TITLE_OPERATOR_NEW)
          : t(ETranslation.TITLE_OPERATOR)}
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {createInput(EOperator.name)}
          {createInput(EOperator.businessId)}
          {createInput(EOperator.streetAddress)}
          <InputGroup>
            {createInput(EOperator.zip)}
            {createInput(EOperator.city)}
          </InputGroup>
          {createInput(EOperator.countryCode)}
          {Routes.isNotNew(id) && createInput(EOperator.operatorToken)}
        </>
      )}
      <InputGroup>
        <Button disabled={loading || !isValid} onClick={submitHandler}>
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        <Button onClick={() => history.goBack()}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {Routes.isNotNew(id) && (
          <Button disabled={loading} onClick={setConfirmDeleteDialog}>
            {t(ETranslation.COMMON_REMOVE)}
          </Button>
        )}
      </InputGroup>
      {Routes.isNotNew(id) && (
        <Users
          organizationId={id}
          history={history}
          location={location}
          match={match}
        />
      )}
    </Container>
  );
};

export default Operator;
