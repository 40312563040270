import React, { useEffect, useState } from 'react';
import { ESearchStorageKey } from '../../../enums';

import OperatorSearch from '../../Searches/OperatorSearch/OperatorSearch';
import SearchContainer from '../../Searches/SearchContainer/SearchContainer';

interface IProps {
  onSearch: (operatorId: string) => void;
};

const LocationsSearch: React.FC<IProps> = ({ onSearch }) => {
  
  const [isInit, setInit] = useState(false);
  const [operatorId, setOperatorId] = useState("");

  useEffect(() => {
    setOperatorId(localStorage.getItem(ESearchStorageKey.OFFER_REQUEST_OPERATOR_ID) || '');
    setInit(true);
  }, []);

  useEffect(() => {
    if(isInit) {
      onSearch(operatorId);
      localStorage.setItem(ESearchStorageKey.OFFER_REQUEST_OPERATOR_ID, operatorId);
    }
  }, [operatorId, onSearch, isInit]);

  return (
    <SearchContainer>
      <OperatorSearch setOperatorId={setOperatorId} operatorId={operatorId} />
    </SearchContainer>
  );
};

export default LocationsSearch;
