import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Button from '../../components/UI/Button/Button';
import Container from '../../components/UI/Container/Container';
import Input, { EInputType } from '../../components/UI/Input/Input';
import InputGroup from '../../components/UI/InputGroup/InputGroup';
import ModalContext, { defaultModal, EModalSize } from '../../components/UI/Modal/ModalContext';
import firebase from '../../firebase';
import { useAuthUser } from '../../hooks/useAuthUser';
import i18n, { ELanguage } from '../../i18n';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Settings.module.scss';

interface IProps extends RouteComponentProps { }

export enum ESettings {
  preferredLanguage = "preferredLanguage",
}

const languageOptions =  [
  {
    labelTranslation: ETranslation.LANGUAGE_ENGLISH,
    value: ELanguage.EN,
  },
  {
    labelTranslation: ETranslation.LANGUAGE_FINNISH,
    value: ELanguage.FI,
  },
];

const Settings: React.FC<IProps> = ({ history }) => {

  const { setModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const { t } = useTranslation();

  const [language, setLanguage] = useState(ELanguage.FI);


  const userLanguage = authUser?.preferredLanguage;

  useEffect(() => {
    if (userLanguage) {
      setLanguage(userLanguage);
    }
  }, [userLanguage]);

  const setPasswordResetDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.SEND_PASSWORD_RESET_LINK),
    size: EModalSize.SMALL,
    content: (
      <div>
        <p>{t(ETranslation.PASSWORD_RESET_TEXT)}</p>
        <InputGroup>
          <Button onClick={() => sendPasswordResetEmail()}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={() => setModal(defaultModal)}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </div>
    ),
  })


  const sendPasswordResetEmail = () => {
    if (authUser && authUser.email) {
      firebase.auth().sendPasswordResetEmail(authUser.email);
    }
    setModal(defaultModal);
  }

  const submitHandler = async (value: ELanguage) => {
    const preferredLanguage = value;
    i18n.changeLanguage(preferredLanguage);
    dispatch(actions.changeUserLanguage(preferredLanguage));
    setLanguage(value);
  };

  return (
    <Container>
      <h2>{t(ETranslation.TITLE_SETTINGS)}</h2>
      <div>
        <Button onClick={setPasswordResetDialog}>{t(ETranslation.SEND_PASSWORD_RESET_LINK)}</Button>
      </div>
      <h2>{t(ETranslation.LANGUAGE_TITLE)}</h2>
      <div className={classes.RadioContainer}>
      <Input
        value={language}
        type={EInputType.radio}
        onChange={(value) => submitHandler(value as ELanguage)}
        inputName="language"
        options={languageOptions}
      />
      </div>
    </Container>
  );
}

export default Settings;
