import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from '../../classes/Routes';
import Alert from '../../components/UI/Alert/Alert';
import Button from '../../components/UI/Button/Button';
import Container from '../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../components/UI/Input/Input';
import InputGroup from '../../components/UI/InputGroup/InputGroup';
import Spinner from '../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../context/InputContext';
import { invoicingTypes } from '../../data/select-data';
import { EInvoicingType, EOrganizationLevel } from '../../enums';
import { useAuthUser } from '../../hooks/useAuthUser';
import { IAppState, IOrganization, IOrganizationState } from '../../interfaces';
import { getOrganizationImage } from '../../services/organizationService';
import { countries } from '../../shared/countries';
import { getInputData, initForm, updateInputHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Organization.module.scss';

interface IProps extends RouteComponentProps {}

export enum EOrganization {
  name = "name",
  businessId = "businessId",
  streetAddress = "streetAddress",
  zip = "zip",
  city = "city",
  countryCode = "countryCode",
  operatorToken = "operatorToken",
  latitude = "latitude",
  longitude = "longitude",
  phone = "phone",
  logoBase64Text = "logoBase64Text",
  invoicingType = "invoicingType",
  eInvoiceAddress = "eInvoiceAddress",
  eInvoiceOperatorAddress = "eInvoiceOperatorAddress",
  invoicingInfo = "invoicingInfo",
  services = "services",
  locations = "locations",
  created = "created",
}

const Organization: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const [organizationImageLoading, setOrganizationImageLoading] = useState(false);

  const { loading, error, organization, imageLoading, updateImage } = useSelector<
    IAppState,
    IOrganizationState
  >((state) => state.organization);

  const authUser = useAuthUser();

  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EOrganization.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OPERATOR_NAME,
      placeholderTranslation: ETranslation.OPERATOR_NAME,
      value: "",
    },
    [EOrganization.businessId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OPERATOR_BUSINESS_ID,
      placeholderTranslation: ETranslation.OPERATOR_BUSINESS_ID,
      value: "",
    },
    [EOrganization.streetAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
    },
    [EOrganization.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
    },
    [EOrganization.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
    },
    [EOrganization.countryCode]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_COUNTRY,
      placeholderTranslation: ETranslation.COMMON_COUNTRY,
      value: "",
      options: countries,
    },
    [EOrganization.operatorToken]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_TOKEN,
      value: "",
    },
    [EOrganization.latitude]: {
      type: EInputType.number,
      labelTranslation: ETranslation.LOCATION_LATITUDE,
      placeholderTranslation: ETranslation.LOCATION_LATITUDE,
      value: "",
    },
    [EOrganization.longitude]: {
      type: EInputType.number,
      labelTranslation: ETranslation.LOCATION_LONGITUDE,
      placeholderTranslation: ETranslation.LOCATION_LONGITUDE,
      value: "",
    },
    [EOrganization.phone]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_PHONE,
      placeholderTranslation: ETranslation.COMMON_PHONE,
      value: "",
    },
    [EOrganization.logoBase64Text]: {
      type: EInputType.organizationImage,
      labelTranslation: ETranslation.ORGANIZATION_IMAGE,
      placeholderTranslation: ETranslation.ORGANIZATION_IMAGE,
      value: "",
    },
    [EOrganization.invoicingType]: {
      type: EInputType.select,
      labelTranslation: ETranslation.SERVICEPROVIDER_INVOICING_TYPE,
      placeholderTranslation: ETranslation.SERVICEPROVIDER_INVOICING_TYPE,
      value: EInvoicingType.EINVOICE,
      options: invoicingTypes,
      validation: {
        required: true,
      },
    },
    [EOrganization.eInvoiceAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS,
      placeholderTranslation: ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS,
      value: "",
    },
    [EOrganization.eInvoiceOperatorAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS,
      placeholderTranslation:
        ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS,
      value: "",
    },
    [EOrganization.invoicingInfo]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.SERVICEPROVIDER_INVOICEINFO,
      placeholderTranslation: ETranslation.SERVICEPROVIDER_INVOICEINFO,
      value: "",
    },
    [EOrganization.services]: {
      type: EInputType.static,
      labelTranslation: ETranslation.SERVICES,
      value: "",
    },
    [EOrganization.locations]: {
      type: EInputType.static,
      labelTranslation: ETranslation.LOCATIONS,
      value: "",
    },
    [EOrganization.created]: {
      type: EInputType.static,
      labelTranslation: ETranslation.ORGANIZATION_ORDER_DATE,
      value: "",
    },
  });

  useEffect(() => {
    if (authUser?.organization?.id) {
      dispatch(actions.getMyOrganization());
    }
    return () => {
      dispatch(actions.clearOrganization());
    }
  }, [dispatch, authUser]);

  useEffect(() => {
    if (organization) {
      initForm(setInputs, organization);
    }
  }, [organization]);

  const [imageData, setImageData] = useState<string | null>(null);
  useEffect(() => {
    if (organization) {
      (async () => {
        setOrganizationImageLoading(true);
        const image = await getOrganizationImage(organization.id);
        if (image) setImageData(image);
        setOrganizationImageLoading(false);
      })();
    }
  }, [organization]);

  useEffect(() => {
    if (updateImage) {
      setImageData(updateImage);
    }
  }, [updateImage]);

  const createInput = (inputName: EOrganization, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={false}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.ORGANIZATION}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const organization = getInputData<IOrganization>(inputs);

    dispatch(actions.updateMyOrganization(organization));
    history.push(Routes.OFFER_REQUESTS);
  };

  const deleteOrganizationImage = (id: string) => {
    dispatch(actions.deleteOrganizationImage(id));
    setImageData(null);
  };

  const createStaticContent = (title: string, content?: string) => (
    <div className={classes.StaticContent}>
      <label>{title}</label>
      <p>{content}</p>
    </div>
  )

  return (
    <>
      {error && <Alert children={error} />}
      <Container>
        <h2>{t(ETranslation.ORGANIZATION_ME)}</h2>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {createInput(EOrganization.name)}
            {createInput(EOrganization.businessId)}
            {createInput(EOrganization.streetAddress)}
            <InputGroup>
              {createInput(EOrganization.zip)}
              {createInput(EOrganization.city)}
            </InputGroup>
            {createInput(EOrganization.countryCode)}
            {organization?.level === EOrganizationLevel.SERVICE_PROVIDER && (
              <>
                <InputGroup>
                  {createInput(EOrganization.latitude)}
                  {createInput(EOrganization.longitude)}
                </InputGroup>
                {createInput(EOrganization.phone)}
                {organizationImageLoading || imageLoading ? (
                  <Spinner />
                ) : imageData ? (
                  <div className={classes.ImageContainer}>
                    <div>
                      <label className={classes.Label}>
                        {t(ETranslation.ORGANIZATION_IMAGE)}
                      </label>
                      <img
                        className={classes.Image}
                        src={"data:image/png;base64," + imageData}
                        alt={organization.name ? organization.name : "HankiX"}
                      />
                    </div>
                    <Button
                      disabled={loading}
                      onClick={() => deleteOrganizationImage(organization.id)}
                    >
                      {t(ETranslation.COMMON_REMOVE)}
                    </Button>
                  </div>
                ) : (
                  createInput(EOrganization.logoBase64Text)
                )}
                {createInput(EOrganization.invoicingType)}
                {inputs[EOrganization.invoicingType].value ===
                  EInvoicingType.EINVOICE && (
                  <>
                    {createInput(EOrganization.eInvoiceAddress)}
                    {createInput(EOrganization.eInvoiceOperatorAddress)}
                  </>
                )}
                {createInput(EOrganization.invoicingInfo)}      
                {createStaticContent(t(ETranslation.SERVICES), organization.services?.map(service => service.name).join(", "))}
                {createStaticContent(t(ETranslation.LOCATIONS), organization.locations?.map(location => location.name).join(", "))}
                {createInput(EOrganization.created)}
                
                
              </>
            )}
          </>
        )}
        <InputGroup>
          <Button disabled={loading} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
      </Container>
    </>
  );
};

export default Organization;
