import { useDispatch } from 'react-redux';
import { IOrganization } from './../interfaces/domain';
import { useCallback } from 'react';
import { ETranslation } from './../translations/translation-keys';
import { useTranslation } from 'react-i18next';
import { IAppState, IServiceProviderState, IAuthState } from './../interfaces/state';
import { useSelector } from 'react-redux';
import { IOption } from '../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { createOptions } from '../shared/option-utils';

import * as actions from '../store/actions';

const defaultParams = {
  withPlaceholder: false,
}

export const useServiceProviderOptions = ({ withPlaceholder } = defaultParams) => {
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);
  const { t } = useTranslation()
  const [serviceProviderOptions, setServiceProviderOptions] = useState<IOption[]>([])
  const dispatch = useDispatch();

  const [tryFetch, setTryFetch] = useState(false);

  const { serviceProviders, loading } = useSelector<IAppState, IServiceProviderState>((state) => state.serviceProvider);

  useEffect(() => {
    if (authUser) {
      setTryFetch(authUser.isPrimary || authUser.isOperator);
    }
  }, [authUser]);

  useEffect(() => {
    if (tryFetch && !serviceProviders) {
      dispatch(actions.listServiceProviders());
      setTryFetch(false);
    }
  }, [dispatch, serviceProviders, tryFetch]);

  const createLabel = useCallback((serviceProvider: IOrganization) => {
    let labelArray = [serviceProvider.name || serviceProvider.id];
    return labelArray.join(' ');
  }, []);

  useEffect(() => {
    let placeholder;
    if(withPlaceholder) {
      placeholder = t(ETranslation.SELECT_SERVICE_PROVIDER_PLACEHOLDER);
    }
    setServiceProviderOptions(serviceProviders ? createOptions(serviceProviders, createLabel, placeholder) : []);
  }, [serviceProviders, t, withPlaceholder, createLabel]);

  return { serviceProviderOptions, serviceProvidersLoading: loading };

}