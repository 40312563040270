import { ILocationAction } from './../../interfaces/state';
import { Dispatch } from "redux";
import axios from "../../axios";
import { ILocation } from "../../interfaces/domain";
import { ActionTypes } from "./actionTypes";
import { AxiosResponse } from 'axios';


export const JSON_LOCATIONS = "/json/locations";
export const JSON_LOCATIONS_ADD = JSON_LOCATIONS + "/add";
export const JSON_LOCATIONS_UPDATE = JSON_LOCATIONS + "/update";
export const JSON_LOCATIONS_LIST = JSON_LOCATIONS + "/list";

type TAction = ILocationAction;
const apiPath = '/json/locations';

const getLocationStart = (): TAction => {
  return {
    type: ActionTypes.LOCATION_GET_START,
  };
};

const getLocationSuccess = (location: ILocation): TAction => {
  return {
    type: ActionTypes.LOCATION_GET_SUCCESS,
    location,
  };
};

const getLocationFail = (error: string): TAction => {
  return {
    type: ActionTypes.LOCATION_GET_FAIL,
    error,
  };
};

export const getLocation = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getLocationStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getLocationSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getLocationFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteLocationStart = (): TAction => {
  return {
    type: ActionTypes.LOCATION_DELETE_START,
  };
};

const deleteLocationSuccess = (location: ILocation): TAction => {
  return {
    type: ActionTypes.LOCATION_DELETE_SUCCESS,
    location,
  };
};

const deleteLocationFail = (error: string): TAction => {
  return {
    type: ActionTypes.LOCATION_DELETE_FAIL,
    error,
  };
};

export const deleteLocation = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteLocationStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteLocationSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteLocationFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listLocationsStart = (): TAction => {
  return {
    type: ActionTypes.LOCATION_LIST_START,
  };
};

const listLocationsSuccess = (locations: Array<ILocation>): TAction => {
  return {
    type: ActionTypes.LOCATION_LIST_SUCCESS,
    locations,
  };
};

const listLocationsFail = (error: string): TAction => {
  return {
    type: ActionTypes.LOCATION_LIST_FAIL,
    error,
  };
};

export const listLocations = (includeServices?: boolean, operatorId?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(listLocationsStart());
    try {
      let params = [];
      if (includeServices) params.push("includeServices=true");
      if (operatorId) params.push(`operatorId=${operatorId}`);

      const res = await axios.get(`${apiPath}/list${params.length ? `?${params.join('&')}` : ''}`);
      dispatch(listLocationsSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(listLocationsFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const saveLocationStart = (): TAction => {
  return {
    type: ActionTypes.LOCATION_SAVE_START,
  };
};

const saveLocationSuccess = (location: ILocation): TAction => {
  return {
    type: ActionTypes.LOCATION_SAVE_SUCCESS,
    location,
  };
};

const saveLocationFail = (error: string): TAction => {
  return {
    type: ActionTypes.LOCATION_SAVE_FAIL,
    error,
  };
};

export const saveLocation = (service: ILocation) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveLocationStart());
    try {
      const res = await axios.post(`${apiPath}/add`, service);
      dispatch(saveLocationSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveLocationFail((response as AxiosResponse).data));
    }
  };
};

const updateLocationStart = (): TAction => {
  return {
    type: ActionTypes.LOCATION_UPDATE_START,
  };
};

const updateLocationSuccess = (location: ILocation): TAction => {
  return {
    type: ActionTypes.LOCATION_UPDATE_SUCCESS,
    location,
  };
};

const updateLocationFail = (error: string): TAction => {
  return {
    type: ActionTypes.LOCATION_UPDATE_FAIL,
    error,
  };
};

export const updateLocation = (service: ILocation) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateLocationStart());
    try {
      const res = await axios.put(`${apiPath}/update`, service);
      dispatch(updateLocationSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateLocationFail((response as AxiosResponse).data));
    }
  };
};

export const clearLocation = () => {
  return {
    type: ActionTypes.LOCATION_CLEAR,
  };
};
