export enum EOrganizationLevel {
  PRIMARY = "PRIMARY",
  OPERATOR = "OPERATOR",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  CUSTOMER = "CUSTOMER",
}

export enum EOfferStatus {
  OFFER = "OFFER",
  OFFERED = "OFFERED",
  COMPLETE = "COMPLETE"
}

// use translate
export const OfferStatus = {
  [EOfferStatus.OFFER]: "Tarjous avoin",
  [EOfferStatus.OFFERED]: "Tarjous tehty",
  [EOfferStatus.COMPLETE]: "Tarjous otettu"
};

export enum EOfferRequestType {
  SIMPLE = "SIMPLE", 
  CONSTRUCTION = "CONSTRUCTION",
  WELLNESS = "WELLNESS",
}

export enum EInvoicingType {
  EINVOICE = "eInvoice",
  OTHER = "other",
}

export enum EBuildingType {
  TOWN_HOUSE = "townHouse", 
  SEMI_DETACHED_HOUSE = "semidetachedHouse", 
  ROW_HOUSE = "rowHouse", 
  DETACHED_HOUSE = "detachedHouse", 
  APARTMENT_HOUSE = "apartmentHouse", 
  OTHER = "other",
}

export enum ESubscriptionLevel {
  FREE = "FREE",
  BASIC = "BASIC",
}

export enum ESearchStorageKey {
  SERVICE_PROVIDERS_SERVICE_ID = "SERVICE_PROVIDERS_SERVICE_ID",
  SERVICE_PROVIDERS_OPERATOR_ID = "SERVICE_PROVIDERS_OPERATOR_ID",
  SERVICE_PROVIDERS_LOCATION_ID = "SERVICE_PROVIDERS_LOCATION_ID",
  SERVICE_PROVIDERS_SUBSCRIPTION_LEVEL = "SERVICE_PROVIDERS_SUBSCRIPTION_LEVEL",
  SERVICE_PROVIDERS_PAYMENT_METHOD = "SERVICE_PROVIDERS_PAYMENT_METHOD",
  
  OFFER_REQUEST_SERVICE_ID = "OFFER_REQUEST_SERVICE_ID",
  OFFER_REQUEST_OPERATOR_ID = "OFFER_REQUEST_OPERATOR_ID",

  LOG_ITEMS_SEARCH = "LOG_ITEMS_SEARCH",

  OFFER_REQUEST_ACCESS_SEARCH = "OFFER_REQUEST_ACCESS_SEARCH",

}

export enum ETags {
  NORMAL = "normal",
  UUTISET = "uutiset",
  FRONTPAGE = "frontPage",
}

export enum EPaymentMethod {
  MONTH = "MONTH",
  MICRO = "MICRO"
}

export enum EEvents {
  OFFER_REQUEST_VIEW = "OFFER_REQUEST_VIEW",
  OFFER_REQUEST_VIEW_PIN = "OFFER_REQUEST_VIEW_PIN",
  OFFER_REQUEST_SAVE = "OFFER_REQUEST_SAVE",
  OFFER_REQUEST_SAVE_LOCAL = "OFFER_REQUEST_SAVE_LOCAL",
  OFFER_REQUEST_DELETE = "OFFER_REQUEST_DELETE",
  OFFER_REQUEST_UPDATE = "OFFER_REQUEST_UPDATE",
  OFFER_REQUEST_UPDATE_PIN = "OFFER_REQUEST_UPDATE_PIN",
  OFFER_REQUEST_ARCHIVE_PIN = "OFFER_REQUEST_ARCHIVE_PIN",
  OFFER_REQUEST_LIST = "OFFER_REQUEST_LIST",
  OFFER_REQUEST_ADD_ACCESS = "OFFER_REQUEST_ADD_ACCESS",
  USER_ACCEPT_TERMS_OF_USE = "USER_ACCEPT_TERMS_OF_USE",
  ORGANIZATION_CONFIRM_BILLING = "ORGANIZATION_CONFIRM_BILLING",
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_LOGOUT = "AUTH_LOGOUT"
}

export enum ELanguage {
  FI = "fi",
  EN = "en",
  SE = "se"
}