import { IOrganizationState, IOrganizationAction } from './../../interfaces/state';
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";


type TState = IOrganizationState;
type TAction = IOrganizationAction;

const initialState: TState = {
  loading: false,
  organization: null,
  organizations: null,
  error: null,
  saveOrUpdateOk: false,
  confirmBillingError: null,
  confirmBillingLoading: false,
  confirmBillingSuccess: false,
  imageLoading: false,
  imageError: null,
  updateImage: null,
};

const clearOrganization = (
  state: TState,
  action: TAction
): TState => {
  return { ...initialState };
};

const getMyOrganizationStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: null,
    loading: true,
    error: null,
  });
};

const getMyOrganizationSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: action.organization,
    loading: false,
    error: null,
  });
};

const getMyOrganizationFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateMyOrganizationStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateMyOrganizationSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: action.organization,
    loading: false,
    error: null,
  });
};

const updateMyOrganizationFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateOrganizationImageStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, { imageLoading: true, error: null });
};

const updateOrganizationImageSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    imageLoading: false,
    updateImage: action.image || null,
    imageError: null,
  });
};

const updateOrganizationImageFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    imageError: action.error,
    imageLoading: false,
  });
};

const deleteOrganizationImageStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, { imageLoading: true, imageError: null });
};

const deleteOrganizationImageSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    imageLoading: false,
    imageError: null,
    updateImage: null
  });
};

const deleteOrganizationImageFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    imageError: action.error,
    imageLoading: false,
  });
};

const exportOrganizationStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: null,
    loading: true,
    error: null,
  });
};

const exportOrganizationSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: action.organization,
    loading: false,
    error: null,
  });
};

const exportOrganizationFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getOrganizationStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: null,
    loading: true,
    error: null,
  });
};

const getOrganizationSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: action.organization,
    loading: false,
    error: null,
  });
};

const getOrganizationFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listOrganizationsStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const listOrganizationsSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organizations: action.organizations,
    loading: false,
    error: null,
  });
};

const listOrganizationsFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveOrganizationStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveOrganizationSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: action.organization,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveOrganizationFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateOrganizationStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateOrganizationSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    organization: action.organization,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const updateOrganizationFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const confirmBillingStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    confirmBillingLoading: true,
    confirmBillingError: null,
  });
};

const confirmBillingSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    confirmBillingLoading: false,
    confirmBillingError: null,
    confirmBillingSuccess: true
  });
};

const confirmBillingFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    confirmBillingLoading: false,
    confirmBillingError: action.error,
  });
};

const reducer = (
  state: TState = initialState,
  action: TAction
): TState => {
  switch (action.type) {
    case ActionTypes.ORGANIZATION_CLEAR:
      return clearOrganization(state, action);
    case ActionTypes.ORGANIZATION_GET_MY_START:
      return getMyOrganizationStart(state, action);
    case ActionTypes.ORGANIZATION_GET_MY_SUCCESS:
      return getMyOrganizationSuccess(state, action);
    case ActionTypes.ORGANIZATION_GET_MY_FAIL:
      return getMyOrganizationFail(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_MY_START:
      return updateMyOrganizationStart(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_MY_SUCCESS:
      return updateMyOrganizationSuccess(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_MY_FAIL:
      return updateMyOrganizationFail(state, action);
    case ActionTypes.ORGANIZATION_EXPORT_START:
      return exportOrganizationStart(state, action);
    case ActionTypes.ORGANIZATION_EXPORT_SUCCESS:
      return exportOrganizationSuccess(state, action);
    case ActionTypes.ORGANIZATION_EXPORT_FAIL:
      return exportOrganizationFail(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_IMAGE_START:
      return updateOrganizationImageStart(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_IMAGE_SUCCESS:
      return updateOrganizationImageSuccess(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_IMAGE_FAIL:
      return updateOrganizationImageFail(state, action);
    case ActionTypes.ORGANIZATION_DELETE_IMAGE_START:
      return deleteOrganizationImageStart(state, action);
    case ActionTypes.ORGANIZATION_DELETE_IMAGE_SUCCESS:
      return deleteOrganizationImageSuccess(state, action);
    case ActionTypes.ORGANIZATION_DELETE_IMAGE_FAIL:
      return deleteOrganizationImageFail(state, action);
    case ActionTypes.ORGANIZATION_GET_START:
      return getOrganizationStart(state, action);
    case ActionTypes.ORGANIZATION_GET_SUCCESS:
      return getOrganizationSuccess(state, action);
    case ActionTypes.ORGANIZATION_GET_FAIL:
      return getOrganizationFail(state, action);
    case ActionTypes.ORGANIZATION_LIST_START:
      return listOrganizationsStart(state, action);
    case ActionTypes.ORGANIZATION_LIST_SUCCESS:
      return listOrganizationsSuccess(state, action);
    case ActionTypes.ORGANIZATION_LIST_FAIL:
      return listOrganizationsFail(state, action);
    case ActionTypes.ORGANIZATION_SAVE_START:
      return saveOrganizationStart(state, action);
    case ActionTypes.ORGANIZATION_SAVE_SUCCESS:
      return saveOrganizationSuccess(state, action);
    case ActionTypes.ORGANIZATION_SAVE_FAIL:
      return saveOrganizationFail(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_START:
      return updateOrganizationStart(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_SUCCESS:
      return updateOrganizationSuccess(state, action);
    case ActionTypes.ORGANIZATION_UPDATE_FAIL:
      return updateOrganizationFail(state, action);
    case ActionTypes.ORGANIZATION_CONFIRM_BILLING_START:
      return confirmBillingStart(state, action);
    case ActionTypes.ORGANIZATION_CONFIRM_BILLING_SUCCESS:
      return confirmBillingSuccess(state, action);
    case ActionTypes.ORGANIZATION_CONFIRM_BILLING_FAIL:
      return confirmBillingFail(state, action);
    default:
      return state;
  }
};

export default reducer;
