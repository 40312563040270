import React from "react";
import { TInputValue } from "./../components/UI/Input/Input";


export enum EInputUpdateAction {
  AUTH = "AUTH",
  DEAL = "DEAL",
  DEAL_EDIT = "DEAL_EDIT",
  DEAL_VARIATION = "DEAL_VARIATION",
  OPERATOR = "OPERATOR",
  OFFERREQUEST = "OFFERREQUEST",
  OFFERREQUESTS_TABLE = "OFFERREQUESTS_TABLE",
  SERVICEPROVIDER = "SERVICEPROVIDER",
  USER = "USER",
  FINANCE_COMPANY = "FINANCE_COMPANY",
  DEALERSHIP = "DEALERSHIP",
  REPORTS = "REPORTS",
  DEAL_CONFIRM_DIALOG = "DEAL_CONFIRM_DIALOG",
  DEALS = "DEALS",
  DEAL_EXTRA_FEE = "DEAL_EXTRA_FEE",
  DEALS_TABLE = "DEALS_TABLE",
  DEAL_SEND_DIALOG = "DEAL_SEND_DIALOG",
  OPERATOR_LIMIT = "OPERATOR_LIMIT",
  SERVICE = "SERVICE",
  ORGANIZATION = "ORGANIZATION",
  LOCATION = "LOCATION",
  CONTENT = "CONTENT",
  SETTINGS = "SETTINGS"
}

const InputContext = React.createContext<{
  onAutoUpdate: (
    inputName: string,
    value: TInputValue,
    action: EInputUpdateAction,
    data?: Object
  ) => void;
  initDone: boolean;
}>({
  onAutoUpdate: (inputName, value, action, data) => {},
  initDone: false,
});

export default InputContext;
