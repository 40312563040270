import React from "react";
import classes from "./Container.module.scss";

interface IProps {
  // children: ReactNode;
  style?: React.CSSProperties;
}

const Container:React.FC<IProps> = ({ children, style }) => {
  return (
    <div className={classes.Container} style={style}>
      {children}
    </div>
  )
}

export default Container;
