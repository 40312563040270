import { ILogItem } from "../../interfaces/domain";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IAppState, ILogItemAction } from "../../interfaces";
import { ThunkAction } from "redux-thunk";
import { TDispatch } from "../..";


const apiPath = '/json/logitems'

const listLogItemsStart = (): ILogItemAction => {
  return {
    type: ActionTypes.LOG_ITEM_LIST_START,
  };
};

const listLogItemsSuccess = (logItems: Array<ILogItem>): ILogItemAction => {
  return {
    type: ActionTypes.LOG_ITEM_LIST_SUCCESS,
    logItems,
  };
};

const listLogItemsFail = (error: string): ILogItemAction => {
  return {
    type: ActionTypes.LOG_ITEM_LIST_FAIL,
    error,
  };
};

type TListLogItemPromise = Promise<ILogItem[] | null>;
type TListLogItemAction = ThunkAction<
  TListLogItemPromise,
  IAppState,
  any,
  ILogItemAction
>;

export interface ILogItemSearchOptions {
  operatorId?: string;
  serviceProviderId?: string;
  event?: string;
  createdStart?: string;
  createdEnd?: string;
}


export const listLogItems = (options?: ILogItemSearchOptions): TListLogItemAction => (
  dispatch: TDispatch
): TListLogItemPromise => {
  dispatch(listLogItemsStart());
  return axios
    .post(`${apiPath}/list`, options)
    .then((res) => {
      dispatch(listLogItemsSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch(({ response }) => {
      dispatch(listLogItemsFail(response.data));
      return Promise.resolve(response.data);
    });
};

export const clearLogItems = () => {
  return {
    type: ActionTypes.LOG_ITEM_LIST_CLEAR,
  };
};