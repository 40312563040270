import React, { useEffect, useRef, useState } from "react";

interface IProps {
  onChange?: (value: string) => void;
  className?: string;
  value: string;
  placeholder?: string;
  onBlur: (value: string) => void;
  disabled?: boolean;
}

const AutoTextArea: React.FC<IProps> = ({ onChange, className, value, placeholder, onBlur, disabled }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [parentHeight, setParentHeight] = useState("auto");

  useEffect(() => {
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
  }, [text]);

  useEffect(() => {
    setText(value)
  }, [value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight("auto");
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    setText(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div
      style={{
        minHeight: parentHeight,
      }}
    >
      <textarea
        ref={textAreaRef}
        rows={1}
        style={{
          height: textAreaHeight,
        }}
        onChange={onChangeHandler}
        className={className}
        value={value}
        placeholder={placeholder}
        onBlur={() => onBlur(value)}
        disabled={disabled}
      />
    </div>
  );
};

export default AutoTextArea;
