import React from "react";
import { AuthUser } from "../../classes/AuthUser";
import Header from "../Header/Header";

const classes = require("./Layout.module.scss");

interface IProps {
  currentUser: AuthUser | null;
  onLogout: () => void;
  children: any;
}

const Layout: React.FC<IProps> = ({ currentUser, onLogout, children }) => {
  return (
    <div className={classes.root}>
      {currentUser && ( <Header currentUser={currentUser} logout={onLogout} /> )}
      <div className={classes.Container}>
        {children}
      </div>
    </div>
  );
};

export default Layout;