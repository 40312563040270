import { IOption } from '../components/UI/Input/Input';

export function createOptions<T extends { id: string }>(
  items: T[],
  createLabel: (item: T) => string,
  placeholder?: string
): IOption[] {
  const options: IOption[] = [];

  if (placeholder) {
    options.push({ value: '', label: placeholder })
  }

  options.push.apply(
    options,
    items.map((item) => {
      return {
        value: item.id,
        label: createLabel(item),
      };
    })
  );
  return options;
}
