import React from 'react';
import { useTranslation } from 'react-i18next';

import { paymentMethods } from '../../../data/select-data';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';


interface IProps {
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
  paymentMethod: string;
}

const PaymentMethodSearch: React.FC<IProps> = ({ setPaymentMethod, paymentMethod}) => {

  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.SERVICEPROVIDER_PAYMENT_METHOD)}</label>
      <Select
        inputName={"paymentMethodSearch"}
        value={paymentMethod}
        onChange={setPaymentMethod}
        options={paymentMethods}
        isSearchable
        isClearable
      />
    </div>
  )

}

export default PaymentMethodSearch;