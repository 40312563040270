import { IOption } from "./../components/UI/Input/Input";

// TODO: labelit käännöstiedostoon
export const countries: IOption[] = [
  { label: "Suomi", value: "FI" },
  { label: "Afganistan", value: "AF" },
  { label: "Ahvenanmaa", value: "AX" },
  { label: "Alankomaat", value: "NL" },
  { label: "Alankomaiden Karibia", value: "BQ" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "Amerikan Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarktis", value: "AQ" },
  { label: "Antigua ja Barbuda", value: "AG" },
  { label: "Arabiemiirikunnat", value: "AE" },
  { label: "Argentiina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Azerbaidžan", value: "AZ" },
  { label: "Bahama", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belgia", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia ja Hertsegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet’nsaari", value: "BV" },
  { label: "Brasilia", value: "BR" },
  { label: "Brittiläinen Intian valtameren alue", value: "IO" },
  { label: "Brittiläiset Neitsytsaaret", value: "VG" },
  { label: "Brunei", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Caymansaaret", value: "KY" },
  { label: "Chile", value: "CL" },
  { label: "Cookinsaaret", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Curaçao", value: "CW" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominikaaninen tasavalta", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypti", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Eritrea", value: "ER" },
  { label: "Espanja", value: "ES" },
  { label: "Etiopia", value: "ET" },
  { label: "Etelä-Afrikka", value: "ZA" },
  { label: "Etelä-Georgia ja Eteläiset Sandwichsaaret", value: "GS" },
  { label: "Etelä-Sudan", value: "SS" },
  { label: "Falklandinsaaret", value: "FK" },
  { label: "Färsaaret", value: "FO" },
  { label: "Fidži", value: "FJ" },
  { label: "Filippiinit", value: "PH" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Grenada", value: "GD" },
  { label: "Grönlanti", value: "GL" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard ja McDonaldinsaaret", value: "HM" },
  { label: "Honduras", value: "HN" },
  { label: "Hongkong", value: "HK" },
  { label: "Indonesia", value: "ID" },
  { label: "Intia", value: "IN" },
  { label: "Irak", value: "IQ" },
  { label: "Iran", value: "IR" },
  { label: "Irlanti", value: "IE" },
  { label: "Islanti", value: "IS" },
  { label: "Israel", value: "IL" },
  { label: "Italia", value: "IT" },
  { label: "Itä-Timor", value: "TL" },
  { label: "Itävalta", value: "AT" },
  { label: "Jamaika", value: "JM" },
  { label: "Japani", value: "JP" },
  { label: "Jemen", value: "YE" },
  { label: "Jersey", value: "JE" },
  { label: "Jordania", value: "JO" },
  { label: "Joulusaari", value: "CX" },
  { label: "Kambodža", value: "KH" },
  { label: "Kamerun", value: "CM" },
  { label: "Kanada", value: "CA" },
  { label: "Kap Verde", value: "CV" },
  { label: "Kazakstan", value: "KZ" },
  { label: "Kenia", value: "KE" },
  { label: "Keski-Afrikan tasavalta", value: "CF" },
  { label: "Kiina", value: "CN" },
  { label: "Kirgisia", value: "KG" },
  { label: "Kiribati", value: "KI" },
  { label: "Kolumbia", value: "CO" },
  { label: "Komorit", value: "KM" },
  { label: "Kongon demokraattinen tasavalta", value: "CD" },
  { label: "Kongon tasavalta", value: "CG" },
  { label: "Kookossaaret", value: "CC" },
  { label: "Korean demokraattinen kansantasavalta", value: "KP" },
  { label: "Korean tasavalta", value: "KR" },
  { label: "Kreikka", value: "GR" },
  { label: "Kroatia", value: "HR" },
  { label: "Kuuba", value: "CU" },
  { label: "Kuwait", value: "KW" },
  { label: "Kypros", value: "CY" },
  { label: "Laos", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lesotho", value: "LS" },
  { label: "Libanon", value: "LB" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Liettua", value: "LT" },
  { label: "Luxemburg", value: "LU" },
  { label: "Länsi-Sahara", value: "EH" },
  { label: "Macao", value: "MO" },
  { label: "Madagaskar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malediivit", value: "MV" },
  { label: "Malesia", value: "MY" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Mansaari", value: "IM" },
  { label: "Marokko", value: "MA" },
  { label: "Marshallinsaaret", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Meksiko", value: "MX" },
  { label: "Mikronesian liittovaltio", value: "FM" },
  { label: "Moldova", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Mosambik", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolkinsaari", value: "NF" },
  { label: "Norja", value: "NO" },
  { label: "Norsunluurannikko", value: "CI" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestiina", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua-Uusi-Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Pohjois-Mariaanit", value: "MP" },
  { label: "Pitcairn", value: "PN" },
  { label: "Pohjois-Makedonia", value: "MK" },
  { label: "Portugali", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Puola", value: "PL" },
  { label: "Päiväntasaajan Guinea", value: "GQ" },
  { label: "Qatar", value: "QA" },
  { label: "Ranska", value: "FR" },
  { label: "Ranskan eteläiset alueet", value: "TF" },
  { label: "Ranskan Guayana", value: "GF" },
  { label: "Ranskan Polynesia", value: "PF" },
  { label: "Réunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Ruanda", value: "RW" },
  { label: "Ruotsi", value: "SE" },
  { label: "Saint Barthélemy", value: "BL" },
  { label: "Saint Helena, Ascension ja Tristan da Cunha", value: "SH" },
  { label: "Saint Kitts ja Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin (Ranska)", value: "MF" },
  { label: "Saint-Pierre ja Miquelon", value: "PM" },
  { label: "Saint Vincent ja Grenadiinit", value: "VC" },
  { label: "Saksa", value: "DE" },
  { label: "Salomonsaaret", value: "SB" },
  { label: "Sambia", value: "ZM" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "São Tomé ja Príncipe", value: "ST" },
  { label: "Saudi-Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychellit", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten (Alankomaat)", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Somalia", value: "SO" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  // { label: "Suomi", value: "FI" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard ja Jan Mayen", value: "SJ" },
  { label: "Swazimaa", value: "SZ" },
  { label: "Sveitsi", value: "CH" },
  { label: "Syyria", value: "SY" },
  { label: "Tadžikistan", value: "TJ" },
  { label: "Taiwan", value: "TW" },
  { label: "Tansania", value: "TZ" },
  { label: "Tanska", value: "DK" },
  { label: "Thaimaa", value: "TH" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad ja Tobago", value: "TT" },
  { label: "Tšad", value: "TD" },
  { label: "Tšekki", value: "CZ" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkki", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks- ja Caicossaaret", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraina", value: "UA" },
  { label: "Unkari", value: "HU" },
  { label: "Uruguay", value: "UY" },
  { label: "Uusi-Kaledonia", value: "NC" },
  { label: "Uusi-Seelanti", value: "NZ" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Valko-Venäjä", value: "BY" },
  { label: "Vanuatu", value: "VU" },
  { label: "Vatikaanivaltio (Pyhä istuin)", value: "VA" },
  { label: "Venezuela", value: "VE" },
  { label: "Venäjä", value: "RU" },
  { label: "Vietnam", value: "VN" },
  { label: "Viro", value: "EE" },
  { label: "Wallis ja Futunasaaret", value: "WF" },
  { label: "Yhdistynyt kuningaskunta", value: "GB" },
  { label: "Yhdysvallat", value: "US" },
  { label: "Yhdysvaltain Neitsytsaaret", value: "VI" },
  { label: "Yhdysvaltain pienet erillissaaret", value: "UM" },
  { label: "Zimbabwe", value: "ZW" },
];
