import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERouteStaticParams, Routes } from '../../classes/Routes';
import LocationsSearch from '../../components/Location/LocationSearch/LocationsSearch';
import Alert from '../../components/UI/Alert/Alert';
import Button from '../../components/UI/Button/Button';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import Table from '../../components/UI/Table/Table';
import { IAppState, IAuthState, ILocationState } from '../../interfaces';
import { linkHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {}

const Locations: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { loading, error, locations } = useSelector<IAppState, ILocationState>(state => state.locations);
  const { authUser } = useSelector<IAppState, IAuthState>(state => state.auth);

  const { t } = useTranslation();

  const showLocation = (
    event: React.MouseEvent<HTMLTableRowElement>,
    id: string
  ) => {
    linkHandler(event, Routes.LOCATION(id), history);
  };

  const searchHandler = useCallback((operatorId?: string) => {
    dispatch(actions.listLocations(true, operatorId));
  }, [dispatch])

  useEffect(() => {
    if(!authUser?.isPrimary) searchHandler();
  }, [authUser, searchHandler]);

  return (
    <>
      {error && <Alert children={error} />}
      <Container>
        <h2>{t(ETranslation.LOCATIONS)}</h2>
        <Button
          onClick={() => history.push(Routes.LOCATION(ERouteStaticParams.new))}
          icon={faPlus}
          style={{ marginBottom: "1rem" }}
        >
          {t(ETranslation.LOCATIONS_NEW)}
        </Button>
        {authUser?.isPrimary && <LocationsSearch onSearch={searchHandler} />}
        {loading ? (
          <Spinner />
        ) : locations ? (
          <Table hover>
            <thead>
              <tr>
                { authUser?.isPrimary && <th>{t(ETranslation.COMMON_OPERATOR_NAME)}</th> }
                <th>{t(ETranslation.LOCATION_NAME)}</th>
                <th>{t(ETranslation.COMMON_TOKEN)}</th>
                <th>{t(ETranslation.LOCATION_NAME_GENITIVE)}</th>
                <th>{t(ETranslation.LOCATION_NAME_INESSIVE)}</th>
                <th>{t(ETranslation.LOCATION_CONTENT)}</th>
                <th>{t(ETranslation.LOCATION_LATITUDE)}</th>
                <th>{t(ETranslation.LOCATION_LONGITUDE)}</th>
                <th>{t(ETranslation.SERVICES)}</th>
                <th>{t(ETranslation.LOCATION_ZOOM_LEVEL)}</th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location) => (
                <tr key={location.id} onClick={(e) => showLocation(e, location.id)}>
                  { authUser?.isPrimary && <td>{location.operator.name}</td>}
                  <td>{location.name}</td>
                  <td>{location.locationToken}</td>
                  <td>{location.nameGenitive}</td>
                  <td>{location.nameInessive}</td>
                  <td>{location.content}</td>
                  <td>{location.latitude}</td>
                  <td>{location.longitude}</td>
                  <td>{location.services?.map((s) => s.name).join(", ")}</td>
                  <td>{location.zoomLevel}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>{t(ETranslation.NO_LOCATIONS)}</p>
        )}
      </Container>
    </>
  );
};

export default Locations;
