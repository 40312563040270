import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { TDispatch } from "../..";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import ServiceProviderForms from "../../components/ServiceProviderForms/ServiceProviderForms";
import ServiceProvidersSearch from "../../components/ServiceProviders/ServiceProvidersSearch/ServiceProvidersSearch";
import ServiceProvidersTable from "../../components/ServiceProviders/ServiceProvidersTable/ServiceProvidersTable";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import InputGroup from "../../components/UI/InputGroup/InputGroup";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAuthUser } from "../../hooks/useAuthUser";
import { IAppState, IServiceProviderState } from "../../interfaces";
import { exportBilling, exportServiceProviders } from "../../services/organizationService";
import { createCSV, dateToString, linkHandler } from "../../shared/utility";
import * as actions from "../../store/actions";
import { IServiceProvidersSearchOptions } from "../../store/actions/serviceProviderActions";
import { ETranslation } from "../../translations/translation-keys";


interface IProps extends RouteComponentProps {
  serviceProviderId?: number;
}

const ServiceProviders: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch<TDispatch>();


  const authUser = useAuthUser();
  const { loading, error, serviceProviders } = useSelector<IAppState, IServiceProviderState>(state => state.serviceProvider);

  const { t } = useTranslation();

  const searchHandler = useCallback((search?: IServiceProvidersSearchOptions) => {
    dispatch(actions.listServiceProviders(search));
  }, [dispatch]);

  useEffect(() => {
    if(!authUser?.isPrimary) searchHandler();
  }, [authUser, searchHandler]);
  

  const showProviders = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
    linkHandler(event, Routes.SERVICE_PROVIDER(id), history);
  }

  const onServiceProviderFormClick = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
    linkHandler(event, Routes.SERVICE_PROVIDER_FORM(id), history);
  }

  const exportBillingAll = async () => {
    const csv = await exportBilling();
    createCSV(csv, `palveluntarjoajat-${dateToString(new Date())}.csv`);
  }

  const exportServiceProvidersAll = async () => {
    const csv = await exportServiceProviders();
    createCSV(csv, `palveluntarjoajayhteystiedot-${dateToString(new Date())}.csv`);
  }

  

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <ServiceProviderForms onClick={onServiceProviderFormClick} />
        <h2>{t(ETranslation.TITLE_SERVICEPROVIDERS)}</h2>
        <InputGroup style={{ marginBottom: "1rem" }}>
          <Button onClick={() => history.push(Routes.SERVICE_PROVIDER(ERouteStaticParams.new))} icon={faPlus}>
            {t(ETranslation.SERVICEPROVIDERS_ADD_NEW)}
          </Button>
          <Button onClick={() => exportBillingAll()} icon={faFileExport}>
            {t(ETranslation.BILLING_EXPORT_ALL)}
          </Button>
          <Button onClick={() => exportServiceProvidersAll()} icon={faFileExport}>
            {t(ETranslation.SERVICEPROVIDER_EXPORT_ALL)}
          </Button>
        </InputGroup>
        {authUser?.isPrimary && (<ServiceProvidersSearch onSearch={searchHandler} />) }
        {loading
          ? <Spinner />
          : serviceProviders
            ? <ServiceProvidersTable serviceproviders={serviceProviders} onClick={showProviders} sortable isPrimary={authUser?.isPrimary} />
            : <p>{t(ETranslation.NO_SERVICEPROVIDERS)}</p>
        }
      </Container>
    </>
  )
};

export default ServiceProviders;

