import axios from "../axios";

export const getOrganizationImage = async (id?: string) => {
  const queryString = id ? `?id=${id}` : '';
  try {
    const resp = await axios.get(`/json/organizations/image${queryString}`)
    return resp.data;
  } catch (error) {
    console.error(error);
  }
};


export const exportBilling = async (id?: number) => {
  const url = id ? `/json/billing/export?id=${id}`: `/json/billing/exportall`;
  try {
    const resp = await axios.get(url);
    return resp.data.csvString;
  } catch (error) {
    console.error(error);
  }
};

export const exportServiceProviders = async (id?: number) => {
  const url = id ? `/json/export/serviceprovider?id=${id}` : `/json/export/serviceproviders`;
  try {
    const resp = await axios.get(url);
    return resp.data.csvString;
  } catch (error) {
    console.error(error);
  }
};
