import React, { useEffect, useState } from 'react';

import classes from './SplashScreen.module.scss';

interface IProps {
  loading: boolean;
}

const SplashScreen:React.FC<IProps> = ({ loading }) => {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if(!loading) {
      setHide(true);
    }
  }, [loading])

  const classNames = [classes.Container];

  if (hide) {
    classNames.push(classes.Hide);
  }

  const innerClassNames = [classes.Inner];
  
  return (
    <div className={classNames.join(' ')}>
      <div className={innerClassNames.join(' ')}>H</div>
    </div>
  )
}


export default SplashScreen;