import { faExternalLinkAlt, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button, { EButtonColor, EButtonSize } from '../../../components/UI/Button/Button';
import ConfirmDialog from '../../../components/UI/ConfirmDialog/ConfirmDialog';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { defaultModal, EModalSize } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { invoicingTypes, paymentMethods, subscriptionLevels } from '../../../data/select-data';
import { EInvoicingType, EPaymentMethod, ESubscriptionLevel } from '../../../enums';
import { useAuthUser } from '../../../hooks/useAuthUser';
import { useLocationOptions } from '../../../hooks/useLocationOptions';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { useServiceOptions } from '../../../hooks/useServiceOptions';
import { IAppState, IGeoLocationState, IOrganization, IServiceProviderState } from '../../../interfaces';
import { exportBilling, exportServiceProviders } from '../../../services/organizationService';
import { countries } from '../../../shared/countries';
import { createCSV, dateToString, getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import Users from '../../Users/Users';
import classes from './ServiceProvider.module.scss';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

export enum EUser {
  firstName = "firstName",
  lastName = "lastName",
  phoneNumber = "phoneNumber",
  email = "email",
  password = "password",
}

export enum EServiceProvider {
  parent = "parent",
  name = "name",
  businessId = "businessId",
  streetAddress = "streetAddress",
  linkWWW = "linkWWW",
  linkFacebook = "linkFacebook",
  linkTwitter = "linkTwitter",
  linkInstagram = "linkInstagram",
  linkLinkedin = "linkLinkedin",
  zip = "zip",
  city = "city",
  countryCode = "countryCode",
  archived = "archived",
  services = "services",
  locations = "locations",
  invoicingType = "invoicingType",
  eInvoiceAddress = "eInvoiceAddress",
  eInvoiceOperatorAddress = "eInvoiceOperatorAddress",
  invoicingInfo = "invoicingInfo",
  subscriptionLevel = "subscriptionLevel",
  phone = "phone",
  latitude = "latitude",
  longitude = "longitude",
  paymentMethod = "paymentMethod"
}

const ServiceProvider: React.FC<IProps> = ({ match, history, location }) => {
  const dispatch = useDispatch();
  const [disableGeoLocationFetch, setDisableGeoLocationFetch] = useState(true);
  const { geoLocation, loading: geoLocationLoading, error: geoLocationError} = useSelector<IAppState, IGeoLocationState>(state => state.geoLocation);

  const { setModal } = useContext(ModalContext);

  const authUser = useAuthUser();
  const { loading, error, serviceProvider, loadingForm, errorForm, serviceProviderForm, saveOrUpdateOk } = useSelector<IAppState, IServiceProviderState>(state => state.serviceProvider);


  const { t } = useTranslation();
  const { id } = match.params;

  const [userInputs, setUserInputs] = useState<IInputField>({
    [EUser.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICEPROVIDER_FIRSTNAME,
      placeholderTranslation: ETranslation.SERVICEPROVIDER_FIRSTNAME,
      value: "",
    },
    [EUser.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICEPROVIDER_LASTNAME,
      placeholderTranslation: ETranslation.SERVICEPROVIDER_LASTNAME,
      value: "",
    },
    [EUser.phoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICEPROVIDER_USER_PHONE,
      placeholderTranslation: ETranslation.SERVICEPROVIDER_USER_PHONE,
      value: "",
    },
    [EUser.email]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_EMAIL,
      placeholderTranslation: ETranslation.COMMON_EMAIL,
      value: "",
    },
    [EUser.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.USER_PASSWORD,
      placeholderTranslation: ETranslation.USER_PASSWORD,
      value: "",
      readOnly: true,
    },
  });

  const [serviceProviderInputs, setServiceProviderInputs] =
    useState<IInputField>({
      [EServiceProvider.parent]: {
        type: EInputType.select,
        labelTranslation: ETranslation.COMMON_OPERATOR_NAME,
        placeholderTranslation: ETranslation.COMMON_OPERATOR_NAME,
        value: "",
        options: [],
        validation: {
          required: authUser?.isPrimary,
        },
      },
      [EServiceProvider.linkWWW]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_WWW,
        placeholderTranslation: ETranslation.COMMON_WWW,
        value: "",
      },
      [EServiceProvider.linkFacebook]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_FACEBOOK,
        placeholderTranslation: ETranslation.COMMON_FACEBOOK,
        value: "",
      },
      [EServiceProvider.linkTwitter]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_TWITTER,
        placeholderTranslation: ETranslation.COMMON_TWITTER,
        value: "",
      },
      [EServiceProvider.linkInstagram]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_INSTAGRAM,
        placeholderTranslation: ETranslation.COMMON_INSTAGRAM,
        value: "",
      },
      [EServiceProvider.linkLinkedin]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_LINKEDIN,
        placeholderTranslation: ETranslation.COMMON_LINKEDIN,
        value: "",
      },
      [EServiceProvider.name]: {
        type: EInputType.text,
        labelTranslation: ETranslation.SERVICEPROVIDER_COMPANY,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_COMPANY,
        value: "",
      },
      [EServiceProvider.businessId]: {
        type: EInputType.text,
        labelTranslation: ETranslation.OPERATOR_BUSINESS_ID,
        placeholderTranslation: ETranslation.OPERATOR_BUSINESS_ID,
        value: "",
      },
      [EServiceProvider.streetAddress]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
        placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
        value: "",
      },
      [EServiceProvider.zip]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_ZIP,
        placeholderTranslation: ETranslation.COMMON_ZIP,
        value: "",
      },
      [EServiceProvider.city]: {
        type: EInputType.text,
        labelTranslation: ETranslation.COMMON_CITY,
        placeholderTranslation: ETranslation.COMMON_CITY,
        value: "",
      },
      [EServiceProvider.countryCode]: {
        type: EInputType.select,
        labelTranslation: ETranslation.COMMON_COUNTRY,
        placeholderTranslation: ETranslation.COMMON_COUNTRY,
        value: countries[0].value,
        options: countries,
      },
      [EServiceProvider.archived]: {
        type: EInputType.checkbox,
        labelTranslation: ETranslation.COMMON_ARCHIVED,
        placeholderTranslation: ETranslation.COMMON_ARCHIVED,
        value: "",
      },
      [EServiceProvider.services]: {
        type: EInputType.searchSelect,
        labelTranslation: ETranslation.SERVICES,
        placeholderTranslation: ETranslation.SERVICES,
        value: [],
        multiple: true,
      },
      [EServiceProvider.locations]: {
        type: EInputType.searchSelect,
        labelTranslation: ETranslation.LOCATIONS,
        placeholderTranslation: ETranslation.LOCATIONS,
        value: [],
        multiple: true,
      },
      [EServiceProvider.invoicingType]: {
        type: EInputType.select,
        labelTranslation: ETranslation.SERVICEPROVIDER_INVOICING_TYPE,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_INVOICING_TYPE,
        value: EInvoicingType.EINVOICE,
        options: invoicingTypes,
        validation: {
          required: true,
        },
      },
      [EServiceProvider.eInvoiceAddress]: {
        type: EInputType.text,
        labelTranslation: ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS,
        value: "",
      },
      [EServiceProvider.eInvoiceOperatorAddress]: {
        type: EInputType.text,
        labelTranslation: ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS,
        placeholderTranslation:
          ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS,
        value: "",
      },
      [EServiceProvider.invoicingInfo]: {
        type: EInputType.textarea,
        labelTranslation: ETranslation.SERVICEPROVIDER_INVOICEINFO,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_INVOICEINFO,
        value: "",
      },
      [EServiceProvider.subscriptionLevel]: {
        type: EInputType.select,
        labelTranslation: ETranslation.SERVICEPROVIDER_SUBSCRIPTION_LEVEL,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_SUBSCRIPTION_LEVEL,
        options: subscriptionLevels,
        value: ESubscriptionLevel.FREE,
      },
      [EServiceProvider.phone]: {
        type: EInputType.text,
        labelTranslation: ETranslation.SERVICEPROVIDER_ORGANIZATION_PHONE,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_ORGANIZATION_PHONE,
        value: "",
      },
      [EServiceProvider.latitude]: {
        type: EInputType.number,
        labelTranslation: ETranslation.LOCATION_LATITUDE,
        placeholderTranslation: ETranslation.LOCATION_LATITUDE,
        value: "",
      },
      [EServiceProvider.longitude]: {
        type: EInputType.number,
        labelTranslation: ETranslation.LOCATION_LONGITUDE,
        placeholderTranslation: ETranslation.LOCATION_LONGITUDE,
        value: "",
      },
      [EServiceProvider.paymentMethod]: {
        type: EInputType.select,
        labelTranslation: ETranslation.SERVICEPROVIDER_PAYMENT_METHOD,
        placeholderTranslation: ETranslation.SERVICEPROVIDER_PAYMENT_METHOD,
        options: paymentMethods,
        value: EPaymentMethod.MONTH,
      },
    });

  const { organizationOptions, organizationsLoading } = useOrganizationOptions({
    withBusinessId: true,
    withPlaceholder: true,
  });

  let operatorId;
  if (authUser?.isOperator) {
    operatorId = authUser.organization.id;
  } else {
    operatorId = serviceProviderInputs[EServiceProvider.parent].value as string;
  }

  const { locationOptions, locationsLoading } = useLocationOptions({
    operatorId,
    filterByOperatorId: true
  });
  const { serviceOptions, servicesLoading } = useServiceOptions({
    operatorId,
    filterByOperatorId: true
  });

  const [serviceProviderValid, setServiceProviderValid] = useState<boolean>(false);
  const [userValid, setUserValid] = useState<boolean>(false);

  const isForm = location.pathname.startsWith(Routes.SERVICE_PROVIDER_FORM(""));

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      if (isForm) {
        dispatch(actions.getServiceProviderForm(id));
      } else {
        dispatch(actions.getServiceProvider(id));
      }
    }
  }, [id, dispatch, isForm]);

  useEffect(() => {
    if (serviceProvider) {
      initForm(setServiceProviderInputs, serviceProvider);
    }
  }, [serviceProvider]);

  useEffect(() => {
    if (serviceProviderForm) {
      initForm(setUserInputs, serviceProviderForm)
      initForm(setServiceProviderInputs, serviceProviderForm);
    }
  }, [serviceProviderForm]);

  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(Routes.SERVICE_PROVIDERS);
    }
    return () => {
      dispatch(actions.clearServiceProvider());
    }
  }, [saveOrUpdateOk, history, dispatch]);

  useEffect(() => {
    /* TODO
    if (!isForm || !serviceproviderForm || serviceproviderFormsLoading || servicesLoading || organizationsLoading) return;
    if (serviceproviderForm.organizationId) {
      history.push(Routes.SERVICE_PROVIDER(serviceproviderForm.organizationId));
      return;
    }
    if (serviceproviderForm.converted || serviceproviderForm.deleted) {
      history.push(Routes.SERVICE_PROVIDERS);
      return;
    }
    let newInputs = initForm<IServiceProvider>(inputs, serviceproviderForm);
    newInputs[EServiceProvider.NAME].value = serviceproviderForm.company;
    }
    newInputs[EServiceProvider.USER_PHONE].value = serviceproviderForm.phone;
    newInputs[EServiceProvider.ORGANIZATION_PHONE].value = "";
    setInputs(newInputs);
    */
    // eslint-disable-next-line
  }, [serviceProviderForm, isForm]);

  useEffect(() => {
    setServiceProviderValid(validateInputs(serviceProviderInputs));
  }, [serviceProviderInputs]);

  useEffect(() => {
    setUserValid(validateInputs(userInputs));
  }, [userInputs]);

  const createInput = useCallback(
    (
      inputs: IInputField,
      setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
      inputName: string,
      showValidation: boolean,
      options?: IInputOptions
    ) => {
      const item = inputs[inputName];
      return (
        <Input
          {...item}
          {...options}
          onChange={(value) => updateInputHandler(inputName, value, setInputs)}
          inputName={inputName}
          showValidation={showValidation}
        />
      );
    },
    []
  );

  const streetAddress = serviceProviderInputs[EServiceProvider.streetAddress].value as string;
  const zip = serviceProviderInputs[EServiceProvider.zip].value as string;
  const city = serviceProviderInputs[EServiceProvider.city].value as string;
  const countryCode = serviceProviderInputs[EServiceProvider.countryCode].value as string;
  const latitude = serviceProviderInputs[EServiceProvider.latitude].value as string;
  const longitude = serviceProviderInputs[EServiceProvider.longitude].value as string;

  useEffect(() => {
    setDisableGeoLocationFetch(!streetAddress.length || !zip.length || !city.length);
  }, [streetAddress, zip, city]);


  const createServiceProviderInput = (
    inputName: EServiceProvider,
    options?: IInputOptions
  ) =>
    createInput(
      serviceProviderInputs,
      setServiceProviderInputs,
      inputName,
      !serviceProviderValid,
      options
    );
  const createUserInput = (inputName: EUser, options?: IInputOptions) =>
    createInput(userInputs, setUserInputs, inputName, !userValid, options);

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const serviceProvider = getInputData<IOrganization>(serviceProviderInputs);
    const user = getInputData<IOrganization>(userInputs);

    if (Routes.isNew(id) || isForm) {
      let formId = isForm ? id : undefined;
      dispatch(actions.saveServiceProvider(serviceProvider, user, formId));
    } else {
      serviceProvider.id = id;
      dispatch(actions.updateServiceProvider(serviceProvider));
    }

  };

  const addAllLocations = () => {
    setServiceProviderInputs((inputs) => {
      const newInputs = { ...inputs };
      newInputs[EServiceProvider.locations].value = [...locationOptions];
      return newInputs;
    });
  };

  const deleteServiceProvider = (id: string) => {
    if (isForm) {
      dispatch(actions.deleteServiceProviderForm(id));
    } else {
      dispatch(actions.deleteServiceProvider(id));
    }
    history.goBack();
    setModal(defaultModal);
  };

  const setConfirmDeleteDialog = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_REMOVE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          body={
            <p>
              {isForm
                ? t(ETranslation.CONFIRM_DELETE_SERVICEPROVIDER_FORM)
                : t(ETranslation.CONFIRM_DELETE_SERVICEPROVIDER)}
            </p>
          }
          onConfirm={() => deleteServiceProvider(id)}
          onCancel={() => setModal(defaultModal)}
        />
      ),
    });

  const onExportBilling = async (id?: number) => {
    const csv = await exportBilling(id);
    createCSV(
      csv,
      `${
        serviceProvider?.name ? serviceProvider.name : "palveluntarjoaja"
      }-${dateToString(new Date())}.csv`
    );
  };

  const onExportServiceProvider = async (id?: number) => {
    const csv = await exportServiceProviders(id);
    createCSV(
      csv,
      `${
        serviceProvider?.name ? serviceProvider.name : "palveluntarjoaja"
      }-${dateToString(new Date())}.csv`
    );
  };

  const getGeoLocationHandler = async () => {
    dispatch(actions.getGeoLocation(streetAddress, zip, city, countryCode))
    if (geoLocation) {
      updateInputHandler(EServiceProvider.latitude, geoLocation.lat, setServiceProviderInputs);
      updateInputHandler(EServiceProvider.longitude, geoLocation.lng, setServiceProviderInputs);
    } else if (geoLocationError) {
      updateInputHandler(EServiceProvider.latitude, "", setServiceProviderInputs);
      updateInputHandler(EServiceProvider.longitude, "", setServiceProviderInputs);
    }
  }

  
  const errors = error || errorForm;
  const loadings = loading || loadingForm;

  return (
    <Container>
      {errors  && <Alert children={errors} />}
      <h2>
        {Routes.isNew(id)
          ? t(ETranslation.TITLE_SERVICEPROVIDER_NEW)
          : t(ETranslation.TITLE_SERVICEPROVIDER)}
      </h2>
      {loadings ? (
        <Spinner />
      ) : (
        <>
          {authUser?.isPrimary &&
            createServiceProviderInput(EServiceProvider.parent, {
              options: organizationOptions,
              loading: organizationsLoading,
            })}
          {(isForm || Routes.isNew(id)) && (
            <>
              <InputGroup>
                {createUserInput(EUser.firstName)}
                {createUserInput(EUser.lastName)}
              </InputGroup>
              <InputGroup>
                {createUserInput(EUser.phoneNumber)}
                {createUserInput(EUser.email)}
              </InputGroup>
              {createUserInput(EUser.password)}
            </>
          )}
          <InputGroup>
            {createServiceProviderInput(EServiceProvider.name)}
            {createServiceProviderInput(EServiceProvider.businessId)}
            {createServiceProviderInput(EServiceProvider.phone)}
          </InputGroup>
          <InputGroup>
            {createServiceProviderInput(EServiceProvider.streetAddress)}
            {createServiceProviderInput(EServiceProvider.zip)}
            {createServiceProviderInput(EServiceProvider.city)}
            {createServiceProviderInput(EServiceProvider.countryCode)}
          </InputGroup>
          <InputGroup>
            {createServiceProviderInput(EServiceProvider.latitude)}
            {createServiceProviderInput(EServiceProvider.longitude)}
            <Link to={{ pathname: `https://www.google.com/maps?q=${latitude},${longitude}` }} target="_blank" rel="noopener noreferrer" className={classes.linkContainer}>
              <FontAwesomeIcon className={classes.LinkIcon}icon={faExternalLinkAlt}></FontAwesomeIcon>
            </Link>
            <Button 
              onClick={getGeoLocationHandler} 
              disabled={disableGeoLocationFetch} 
              loading={geoLocationLoading} 
              size={EButtonSize.SMALL}
              style={{ marginTop: '.5rem'}}
              >
                {t(ETranslation.COMMON_FETCH)}
            </Button>
          </InputGroup>
          {geoLocationError && <Alert noHorizontalMargin>{t(ETranslation.GEO_LOCATION_ERROR)}</Alert>}
          <InputGroup>
            {createServiceProviderInput(EServiceProvider.linkWWW)}
            {createServiceProviderInput(EServiceProvider.linkFacebook)}
            {createServiceProviderInput(EServiceProvider.linkTwitter)}
            {createServiceProviderInput(EServiceProvider.linkInstagram)}
            {createServiceProviderInput(EServiceProvider.linkLinkedin)}
          </InputGroup>
          {createServiceProviderInput(EServiceProvider.services, {
            options: serviceOptions,
            loading: servicesLoading,
          })}
          <InputGroup>
            {createServiceProviderInput(EServiceProvider.locations, {
              options: locationOptions,
              loading: locationsLoading,
            })}
            {locationOptions && (
              <Button
                onClick={addAllLocations}
                color={EButtonColor.NONE}
                size={EButtonSize.SMALL}
              >
                {t(ETranslation.COMMON_ADD_ALL)}
              </Button>
            )}
          </InputGroup>

          {createServiceProviderInput(EServiceProvider.invoicingType)}
          {serviceProviderInputs[EServiceProvider.invoicingType].value ===
            EInvoicingType.EINVOICE && (
            <>
              <InputGroup>
                {createServiceProviderInput(EServiceProvider.eInvoiceAddress)}
                {createServiceProviderInput(
                  EServiceProvider.eInvoiceOperatorAddress
                )}
              </InputGroup>
            </>
          )}
          {createServiceProviderInput(EServiceProvider.invoicingInfo)}
          {createServiceProviderInput(EServiceProvider.subscriptionLevel)}
          {createServiceProviderInput(EServiceProvider.paymentMethod)}
          {createServiceProviderInput(EServiceProvider.archived)}
        </>
      )}
      <InputGroup>
        <Button
          disabled={loading || !serviceProviderValid}
          onClick={submitHandler}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        <Button onClick={() => history.goBack()}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {Routes.isNotNew(id) && (
          <Button disabled={loading} onClick={setConfirmDeleteDialog}>
            {t(ETranslation.COMMON_REMOVE)}
          </Button>
        )}
        {!isForm && Routes.isNotNew(id) && (
          <>
            <Button onClick={() => onExportBilling(+id)} icon={faFileExport}>
              {t(ETranslation.BILLING_EXPORT_ORGANIZATION)}
            </Button>
            <Button
              onClick={() => onExportServiceProvider(+id)}
              icon={faFileExport}
            >
              {t(ETranslation.SERVICEPROVIDER_EXPORT)}
            </Button>
          </>
        )}
      </InputGroup>
      {authUser &&
        (authUser.isPrimary || authUser.isOperator) &&
        serviceProvider?.activeCampaignId && (
          <div style={{ marginTop: "1rem" }}>
            <Button
              onClick={() =>
                window.open(
                  "https://positiveproductions.activehosted.com/app/contacts/" +
                    serviceProvider?.activeCampaignId,
                  "_blank"
                )
              }
            >
              ActiveCampaign contact: {serviceProvider?.activeCampaignId}
            </Button>
          </div>
        )}
      {!isForm && Routes.isNotNew(id) && (
        <Users
          organizationId={id}
          history={history}
          location={location}
          match={match}
        />
      )}
    </Container>
  );
};

export default ServiceProvider;
