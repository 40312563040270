import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOrganization } from '../../../interfaces';
import { ETranslation } from '../../../translations/translation-keys';
import Table from '../../UI/Table/Table';
import classes from './ServiceProvidersTable.module.scss';

interface IProps {
  serviceproviders: Array<IOrganization>
  statusProp?: string;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>, id: string) => void;
  sortable?: boolean;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  isPrimary?: boolean;
}

enum EServiceProvidersTableSortBy {

  CREATED = "created",
  NAME = "name",
  BUSINESSID = "businessId",
  ZIP = "zip",
  CITY = "city",
}

const ServiceProviderTable: React.FC<IProps> = ({ serviceproviders, statusProp, onClick, sortable, containerClassName, containerStyle, isPrimary }) => {
  const { t } = useTranslation();

  const [sortedServiceProviders, setSortedServiceProviders] = useState<IOrganization[]>(serviceproviders);
  const [sort, setSort] = useState<EServiceProvidersTableSortBy>(EServiceProvidersTableSortBy.CREATED);
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);

  useEffect(() => {
    if (!sortable) return;
    // console.log("sort options changed to:", sort, sortDirection);

    const newOffers = [...sortedServiceProviders];

    if (sort && newOffers) {
      if (sortDirection) {
        newOffers.reverse();
        setSortedServiceProviders(newOffers);
        return;
      }

      newOffers.sort((a, b) => {
        if (a[sort] === "" || a[sort] === null || a[sort] === undefined) return 1;
        if (b[sort] === "" || b[sort] === null || b[sort] === undefined) return -1;
        if (a[sort] === b[sort]) return 0;
        if (sort === EServiceProvidersTableSortBy.CREATED) return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
        return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
      });
      setSortedServiceProviders(newOffers);
    }

    // eslint-disable-next-line
  }, [sort, sortDirection, sortable]);

  const getChevron = () => {
    // console.log(sortDirection);
    return <FontAwesomeIcon className={classes.Icon} icon={sortDirection ? faChevronUp : faChevronDown} />
  };

  const changeSortOrDirection = (sortBy: EServiceProvidersTableSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }

  const mapHeaders = () => {
    if (sortable) {
      return (
        <>
          <th onClick={() => changeSortOrDirection(EServiceProvidersTableSortBy.NAME)}>
            {t(ETranslation.OPERATOR_NAME)}
            {sort === EServiceProvidersTableSortBy.NAME && getChevron()}
          </th>
          <th onClick={() => changeSortOrDirection(EServiceProvidersTableSortBy.BUSINESSID)}>
            {t(ETranslation.OPERATOR_BUSINESS_ID)}
            {sort === EServiceProvidersTableSortBy.BUSINESSID && getChevron()}
          </th>
          < th >
            {t(ETranslation.OFFERREQUEST_ADDRESS)}
          </th>
          < th onClick={() => changeSortOrDirection(EServiceProvidersTableSortBy.ZIP)} >
            {t(ETranslation.COMMON_ZIP)}
            {sort === EServiceProvidersTableSortBy.ZIP && getChevron()}
          </th>
          <th>{t(ETranslation.COMMON_CITY)}</th>
          <th>{t(ETranslation.COMMON_COUNTRY)}</th>
          <th>{t(ETranslation.SERVICES)}</th>
          <th>{t(ETranslation.LOCATIONS)}</th>
          {isPrimary
            ? <th>{t(ETranslation.COMMON_OPERATOR_NAME)}</th>
            : null
          }
          <th>{t(ETranslation.COMMON_ARCHIVED)}</th>
        </>
      )
    }
    return (
      <>
        <th>{t(ETranslation.OPERATOR_NAME)}</th>
        <th>{t(ETranslation.OPERATOR_BUSINESS_ID)}</th>
        <th>{t(ETranslation.COMMON_STREET_ADDRESS)}</th>
        <th>{t(ETranslation.COMMON_ZIP)}</th>
        <th>{t(ETranslation.COMMON_CITY)}</th>
        <th>{t(ETranslation.COMMON_COUNTRY)}</th>
        <th>{t(ETranslation.SERVICES)}</th>
        <th>{t(ETranslation.LOCATIONS)}</th>
        {isPrimary
          ? <th>{t(ETranslation.COMMON_OPERATOR_NAME)}</th>
          : null
        }
        <th>{t(ETranslation.COMMON_ARCHIVED)}</th>
      </>
    )
  }


  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr className={classes.TableHeader}>
            {statusProp}
            {mapHeaders()}
          </tr>
        </thead>
        <tbody>
          {sortedServiceProviders.map(serviceProvider => (
            <tr key={serviceProvider.id} onClick={(e) => onClick(e, serviceProvider.id)}>
              <td>{serviceProvider.name}</td>
              <td>{serviceProvider.businessId}</td>
              <td>{serviceProvider.streetAddress}</td>
              <td>{serviceProvider.zip}</td>
              <td>{serviceProvider.city}</td>
              <td>{serviceProvider.countryCode}</td>
              <td>{serviceProvider.services?.map(s => s.name).join(", ")}</td>
              <td>{serviceProvider.locations?.map(l => l.name).join(", ")}</td>
              {isPrimary
                ? <td>{serviceProvider.parent?.name}</td>
                : null
              }
              <td>{serviceProvider.archived ? <FontAwesomeIcon icon={faCheck} /> : null}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ServiceProviderTable;
