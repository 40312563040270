import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Routes } from '../../classes/Routes';
import Alert from '../../components/UI/Alert/Alert';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import Table from '../../components/UI/Table/Table';
import { IAppState } from '../../interfaces';
import { linkHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Feedbacks.module.scss';

const Feedbacks: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, error, feedbacks } = useSelector((state: IAppState) => ({
    loading: state.feedbacks.loading,
    error: state.feedbacks.error,
    feedbacks: state.feedbacks.feedbacks,
  }));

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listFeedbacks());
  }, [dispatch]);

  const showFeedback = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
    linkHandler(event, Routes.OFFER_REQUEST(id), history);
  }

  const mapStars = (num?: number) => {
    if (!num) return null;
    return [...Array(num)].map((e, i) => <FontAwesomeIcon key={i} className={classes.Star} icon={faStar} />);
  }

  return (
    <>
      {error && <Alert children={error} />}
      <Container>
        <h2>{t(ETranslation.TITLE_FEEDBACKS)}</h2>
        {loading
          ? <Spinner />
          : feedbacks
            ? (
              <Table hover>
                <thead>
                  <tr>
                    <th>{t(ETranslation.CUSTOMER_OFFER_REQUEST)}</th>
                    <th>{t(ETranslation.COMMON_SERVICE)}</th>
                    <th>{t(ETranslation.FEEDBACK_SERVICEPROVIDER)}</th>
                    <th>{t(ETranslation.FEEDBACK_SATISFACTION)}</th>
                    <th>{t(ETranslation.FEEDBACK_MESSAGE)}</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks.map(feedback => (
                    <tr key={feedback.id} onClick={(e) => showFeedback(e, feedback.offerRequest!.id)}>
                      <td>{feedback.offerRequest?.firstName} {feedback.offerRequest?.lastName}</td>
                      <td>{feedback.offerRequest?.service?.name}</td>
                      <td>{feedback.serviceProvider
                        ? feedback.serviceProvider.name
                        : feedback.otherFeedback
                          ? feedback.otherFeedback
                          : t(ETranslation.FEEDBACK_FORM_NOT_FOUND)
                      }</td>
                      <td>{mapStars(feedback.serviceSatisfaction)}</td>
                      <td>{feedback.message}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )
            : <p>{t(ETranslation.NO_FEEDBACK)}</p>
        }
      </Container>
    </>
  );
}

export default Feedbacks;