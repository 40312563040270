import React, { useEffect, useState } from 'react';

import { ESearchStorageKey } from '../../../enums';
import { IServiceProvidersSearchOptions } from '../../../store/actions/serviceProviderActions';
import LocationSearch from '../../Searches/LocationSearch/LocationSearch';
import OperatorSearch from '../../Searches/OperatorSearch/OperatorSearch';
import SearchContainer from '../../Searches/SearchContainer/SearchContainer';
import ServiceSearch from '../../Searches/ServiceSearch/ServiceSearch';
import SubscriptionSearch from '../../Searches/SubscriptionSearch/SubscriptionSearch';
import PaymentMethodSearch from '../../Searches/PaymentMethodSearch/PaymentMethodSearch';

interface IProps {
  onSearch: (search: IServiceProvidersSearchOptions) => void
};

const ServiceProvidersSearch: React.FC<IProps> = ({ onSearch }) => {
  const [isInit, setInit] = useState(false);
  const [operatorId, setOperatorId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [subscriptionLevel, setSubscriptionLevel] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  useEffect(() => {
    setOperatorId(localStorage.getItem(ESearchStorageKey.SERVICE_PROVIDERS_OPERATOR_ID) || '');
    setLocationId(localStorage.getItem(ESearchStorageKey.SERVICE_PROVIDERS_LOCATION_ID) || '');
    setServiceId(localStorage.getItem(ESearchStorageKey.SERVICE_PROVIDERS_SERVICE_ID) || '');
    setSubscriptionLevel(localStorage.getItem(ESearchStorageKey.SERVICE_PROVIDERS_SUBSCRIPTION_LEVEL) || '');
    setPaymentMethod(localStorage.getItem(ESearchStorageKey.SERVICE_PROVIDERS_PAYMENT_METHOD) || '');
    setInit(true);
  }, []);

  useEffect(() => {
    if(isInit) {
      onSearch({ operatorId, locationId, serviceId, subscriptionLevel, paymentMethod });
      localStorage.setItem(ESearchStorageKey.SERVICE_PROVIDERS_OPERATOR_ID, operatorId);
      localStorage.setItem(ESearchStorageKey.SERVICE_PROVIDERS_LOCATION_ID, locationId);
      localStorage.setItem(ESearchStorageKey.SERVICE_PROVIDERS_SERVICE_ID, serviceId);
      localStorage.setItem(ESearchStorageKey.SERVICE_PROVIDERS_SUBSCRIPTION_LEVEL, subscriptionLevel);
      localStorage.setItem(ESearchStorageKey.SERVICE_PROVIDERS_PAYMENT_METHOD, paymentMethod);
    }
  }, [onSearch, operatorId, locationId, serviceId, subscriptionLevel, paymentMethod, isInit]);


  return (
    <SearchContainer>
      <OperatorSearch operatorId={operatorId} setOperatorId={setOperatorId} />
      <ServiceSearch serviceId={serviceId} setServiceId={setServiceId} />
      <LocationSearch locationId={locationId} setLocationId={setLocationId} />
      <SubscriptionSearch subscriptionLevel={subscriptionLevel} setSubscriptionLevel={setSubscriptionLevel} />
      <PaymentMethodSearch paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
    </SearchContainer>
  );
};

export default ServiceProvidersSearch;

