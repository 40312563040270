import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Alert, { EAlertColor } from '../../../components/UI/Alert/Alert';
import Button from '../../../components/UI/Button/Button';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { EModalSize } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../../context/InputContext';
import { buildingTypes, languages } from '../../../data/select-data';
import { EBuildingType, EOfferRequestType } from '../../../enums';
import { IAppState, IOfferRequest, IOfferRequestState } from '../../../interfaces';
import { formatDateString, getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import classes from './CustomerOfferRequest.module.scss';

interface IProps extends RouteComponentProps {}

export enum ECustomerOfferRequest {
  created = "created",
  firstName = "firstName",
  lastName = "lastName",
  address = "address",
  phone = "phone",
  email = "email",
  service = "service",
  area = "area",
  buildingType = "buildingType",
  buildingTypeOther = "buildingTypeOther",
  constructionYear = "constructionYear",
  zip = "zip",
  city = "city",
  additionalInfo = "additionalInfo",
  province = "province",
  language = "language"
}

const CustomerOfferRequest: React.FC<IProps> = ({ location, history }) => {
  const { setModal, closeModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const q = qs.parse(location.search);
    setPin(q.pin as string);
    setEmail(q.email as string);
  }, [location])

  const { loading, error, offerRequest } = useSelector<IAppState, IOfferRequestState>(state => state.offerRequest);

  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [ECustomerOfferRequest.created]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_CREATED,
      value: "",
    },
    [ECustomerOfferRequest.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFERREQUEST_FIRSTNAME,
      placeholderTranslation: ETranslation.OFFERREQUEST_FIRSTNAME,
      value: "",
    },
    [ECustomerOfferRequest.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFERREQUEST_LASTNAME,
      placeholderTranslation: ETranslation.OFFERREQUEST_LASTNAME,
      value: "",
    },
    [ECustomerOfferRequest.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
    },
    [ECustomerOfferRequest.phone]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_PHONE,
      placeholderTranslation: ETranslation.COMMON_PHONE,
      value: "",
      validation: {
        required: true
      }
    },
    [ECustomerOfferRequest.email]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_EMAIL,
      placeholderTranslation: ETranslation.COMMON_EMAIL,
      value: "",
      disabled: true,
    },
    [ECustomerOfferRequest.service]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_SERVICE,
      placeholderTranslation: ETranslation.COMMON_SERVICE,
      value: "",
    },
    [ECustomerOfferRequest.area]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA,
      placeholderTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA,
      value: "",
    },
    [ECustomerOfferRequest.buildingType]: {
      type: EInputType.select,
      labelTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE,
      placeholderTranslation: ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE,
      options: buildingTypes,
      value: EBuildingType.TOWN_HOUSE,
    },
    [ECustomerOfferRequest.buildingTypeOther]: {
      type: EInputType.text,
      labelTranslation:
        ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER,
      placeholderTranslation:
        ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER,
      value: "",
    },
    [ECustomerOfferRequest.constructionYear]: {
      type: EInputType.text,
      labelTranslation:
        ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR,
      placeholderTranslation:
        ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR,
      value: "",
    },
    [ECustomerOfferRequest.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
    },
    [ECustomerOfferRequest.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
    },
    [ECustomerOfferRequest.additionalInfo]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.OFFERREQUEST_INFO,
      placeholderTranslation: ETranslation.OFFERREQUEST_INFO,
      value: "",
    },
    [ECustomerOfferRequest.province]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OFFER_REQUEST_PROVINCE,
      placeholderTranslation: ETranslation.OFFER_REQUEST_PROVINCE,
      value: "",
    },
    [ECustomerOfferRequest.language]: {
      type: EInputType.select,
      labelTranslation: ETranslation.OFFER_REQUEST_LANGUAGE,
      placeholderTranslation: ETranslation.OFFER_REQUEST_LANGUAGE,
      value: "",
      options: []
    },
  });

  useEffect(() => {
    if (pin && email) {
      dispatch(actions.getCustomerOfferRequest(email, pin));
    } 
  }, [dispatch, pin, email]);

  useEffect(() => {
    if (offerRequest) {
      const formattedOfferRequest = { ...offerRequest };
      formattedOfferRequest.created = formatDateString(formattedOfferRequest.created);
      initForm(setInputs, formattedOfferRequest);
    }
  }, [offerRequest]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (
    inputName: ECustomerOfferRequest,
    options?: IInputOptions
  ) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={offerRequest?.archived}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.OFFERREQUEST}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const offerRequest = getInputData<IOfferRequest>(inputs);

    dispatch(
      actions.updateCustomerOfferRequest(
        email,
        pin,
        offerRequest
      )
    );

    setModal({
      isOpen: true,
      title: t(ETranslation.FEEDBACK_THANK_YOU_TITLE),
      size: EModalSize.SMALL,
      content: (
        <div>
          <p>{t(ETranslation.CUSTOMER_OFFER_REQUEST_THANK_YOU_MESSAGE)}</p>
          <Button onClick={closeModal}>{t(ETranslation.COMMON_CLOSE)}</Button>
        </div>
      ),
    });
  };

  const archiveHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch(
      actions.archiveCustomerOfferRequest(email, pin)
    );
  };

  return (
    <>
      {offerRequest?.archived && (
        <Alert color={EAlertColor.WARNING}>
          {t(ETranslation.CUSTOMER_OFFER_REQUEST_ARCHIVED_CONFIRM)}
        </Alert>
      )}
      {error ?
        <div className={classes.Error}>
          <span className={classes.ErrorIcon}><FontAwesomeIcon icon={faExclamationCircle}/></span>
          <h2>{t(ETranslation.CUSTOMER_OFFER_REQUEST_ERROR_BOTTOM)}</h2>
          <p>{t(ETranslation.CUSTOMER_OFFER_REQUEST_ERROR_BOTTOM)}</p>
          <a href="mailto:info@hankix.fi">info@hankix.fi</a>
        </div>
        :
        <Container>
          <h2>{t(ETranslation.CUSTOMER_OFFER_REQUEST)}</h2>
          {loading ? (
            <Spinner />
          ) : offerRequest?.deleted ? (
            <p>{t(ETranslation.OFFER_REQUEST_DELETED)}</p>
          ) : (
            <>
              <InputGroup>
                {createInput(ECustomerOfferRequest.created)}
                {createInput(ECustomerOfferRequest.service)}
              </InputGroup>
              <InputGroup>
                {createInput(ECustomerOfferRequest.firstName)}
                {createInput(ECustomerOfferRequest.lastName)}
              </InputGroup>
              {offerRequest?.service?.type !== EOfferRequestType.WELLNESS ? (
                <InputGroup>
                  {createInput(ECustomerOfferRequest.address)}
                  {createInput(ECustomerOfferRequest.zip)}
                  {createInput(ECustomerOfferRequest.city)}
                </InputGroup>
              ) : (
                <InputGroup>
                  {createInput(ECustomerOfferRequest.city)}
                  {createInput(ECustomerOfferRequest.province)}
                </InputGroup>
              )}
              <InputGroup>
                {createInput(ECustomerOfferRequest.phone)}
                {createInput(ECustomerOfferRequest.email)}
                {createInput(ECustomerOfferRequest.language, {
                  options: languages
                })}
              </InputGroup>
              {offerRequest?.service?.type === EOfferRequestType.CONSTRUCTION ? (
                <>
                  <InputGroup>
                    {createInput(ECustomerOfferRequest.area)}
                    {createInput(ECustomerOfferRequest.constructionYear)}
                  </InputGroup>
                  <InputGroup>
                    {createInput(ECustomerOfferRequest.buildingType)}
                    {inputs[ECustomerOfferRequest.buildingType].value ===
                    EBuildingType.OTHER
                      ? createInput(ECustomerOfferRequest.buildingTypeOther)
                      : null}
                  </InputGroup>
                </>
              ) : null}
              {createInput(ECustomerOfferRequest.additionalInfo)}
            </>
          )}
          {!offerRequest?.deleted && (
            <>
              <div className={classes.HelpText}>
                <p>{t(ETranslation.CUSTOMER_OFFER_REQUEST_HELP_TEXT_1)}</p>
                <p>{t(ETranslation.CUSTOMER_OFFER_REQUEST_HELP_TEXT_2)}</p>
              </div>
              <InputGroup>
                <Button
                  disabled={loading || offerRequest?.archived || !isValid}
                  onClick={submitHandler}
                >
                  {t(ETranslation.CUSTOMER_OFFER_REQUEST_SAVE_CHANGES)}
                </Button>
                <Button disabled={loading} onClick={archiveHandler}>
                  {offerRequest?.archived
                    ? t(ETranslation.CUSTOMER_OFFER_REQUEST_UNARCHIVE)
                    : t(ETranslation.CUSTOMER_OFFER_REQUEST_ARCHIVE)}
                </Button>
              </InputGroup>
            </>
          )}
        </Container>
      }
    </>
  );
};

export default CustomerOfferRequest;
