import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthUser } from '../../../classes/AuthUser';
import { IOfferRequest, IOfferRequestConstruction } from '../../../interfaces';
import { formatDateString } from '../../../shared/utility';
import { ETranslation } from '../../../translations/translation-keys';
import Button, { EButtonSize } from '../../UI/Button/Button';
import Table from '../../UI/Table/Table';
import classes from './OfferRequestsTable.module.scss';
import OfferRequestsTableHeader from './OfferRequestsTableHeader/OfferRequestsTableHeader';

interface IProps {
  offerrequests: Array<IOfferRequest>
  onClick: (event: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement>, id: string) => void;
  sortable?: boolean;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  authUser: AuthUser;
}

export enum EOfferRequestsTableSortBy {
  created = "created",
  firstName = "firstName",
  lastName = "lastName",
  address = "address",
  email = "email",
  service = "service"
}

const OfferRequestTable: React.FC<IProps> = ({ offerrequests, onClick, sortable, containerClassName, containerStyle, authUser }) => {

  const [sortedOfferRequests, setSortedOfferRequests] = useState<IOfferRequest[]>(offerrequests);
  const [sort, setSort] = useState<EOfferRequestsTableSortBy>(EOfferRequestsTableSortBy.created);
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);

  const { t } = useTranslation();

  const { isPrimary, isMicro } = authUser;

  useEffect(() => {
    if (!sortable) return;
    // console.log("sort options changed to:", sort, sortDirection);

    const newOffers = [...sortedOfferRequests];

    if (sort && newOffers) {
      if (sortDirection) {
        newOffers.reverse();
        setSortedOfferRequests(newOffers);
        return;
      }

      newOffers.sort((a, b) => {
          if (a[sort] === "" || a[sort] === null || a[sort] === undefined) return 1;
          if (b[sort] === "" || b[sort] === null || b[sort] === undefined) return -1;
          if (a[sort] === b[sort]) return 0;
          if (sort === EOfferRequestsTableSortBy.created) return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
          return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
      });
      setSortedOfferRequests(newOffers);
    }

    // eslint-disable-next-line
  }, [sort, sortDirection, sortable]);

  const changeSortOrDirection = (sortBy: EOfferRequestsTableSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  }

  return (
    <div className={containerClassName} style={containerStyle}>
      <Table hover>
        <thead>
          <tr className={classes.TableHeader}>
            {isMicro && (
              <th></th>
            )}
            <OfferRequestsTableHeader onSort={changeSortOrDirection} sort={sort} sortBy={EOfferRequestsTableSortBy.created} sortable={sortable} sortDirection={sortDirection}>{ETranslation.COMMON_CREATED}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader onSort={changeSortOrDirection} sort={sort} sortBy={EOfferRequestsTableSortBy.service} sortable={sortable} sortDirection={sortDirection}>{ETranslation.COMMON_SERVICE}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader onSort={changeSortOrDirection} sort={sort} sortBy={EOfferRequestsTableSortBy.firstName} sortable={sortable} sortDirection={sortDirection}>{ETranslation.OFFERREQUEST_FIRSTNAME}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader onSort={changeSortOrDirection} sort={sort} sortBy={EOfferRequestsTableSortBy.lastName} sortable={sortable} sortDirection={sortDirection}>{ETranslation.OFFERREQUEST_LASTNAME}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader onSort={changeSortOrDirection} sort={sort} sortBy={EOfferRequestsTableSortBy.address} sortable={sortable} sortDirection={sortDirection}>{ETranslation.OFFERREQUEST_ADDRESS}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader>{ETranslation.COMMON_ZIP}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader>{ETranslation.COMMON_CITY}</OfferRequestsTableHeader>
            <OfferRequestsTableHeader>{ETranslation.OFFERREQUEST_EMAIL}</OfferRequestsTableHeader>
            {isPrimary && <OfferRequestsTableHeader>{ETranslation.COMMON_OPERATOR_NAME}</OfferRequestsTableHeader>}
            {/*
              <OfferRequestsTableHeader>{ETranslation.OFFERREQUEST_TYPE}</OfferRequestsTableHeader>
              <OfferRequestsTableHeader>{ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA}</OfferRequestsTableHeader>
              <OfferRequestsTableHeader>{ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE}</OfferRequestsTableHeader>
            */}
            <OfferRequestsTableHeader>{ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR}</OfferRequestsTableHeader>
            {isPrimary && <OfferRequestsTableHeader>{ETranslation.COMMON_ARCHIVED}</OfferRequestsTableHeader>}
          </tr>
        </thead>
        <tbody>
          {sortedOfferRequests.map(offerRequest => {
            let constructionOfferRequest = offerRequest as IOfferRequestConstruction;
            return (
              <tr key={offerRequest.id} onClick={(e) => onClick(e, offerRequest.id)}>
                {isMicro && (
                  <td>{authUser.hasOfferRequestAccess(offerRequest.id) ? (
                    <div className={classes.HasAccessCheck}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                  ) : (
                    <Button  onClick={(e) => onClick(e, offerRequest.id)} size={EButtonSize.SMALL}>{t(ETranslation.COMMON_BUY)}</Button>
                  )}</td>
                )}
                <td>{offerRequest.created && formatDateString(offerRequest.created)}</td>
                <td>{offerRequest.service?.name}</td>
                <td>{offerRequest.firstName}</td>
                <td>{offerRequest.lastName}</td>
                <td>{offerRequest.address}</td>
                <td>{offerRequest.zip}</td>
                <td>{offerRequest.city}</td>
                <td>{offerRequest.email}</td>
                {isPrimary && <td>{offerRequest?.operator?.name}</td>}
                {/*
                  <td>{getTypeString(t, offerrequest?.service?.type)}</td>
                  <td>{constructionOfferRequest.area}</td>
                  <td>{getBuildingTypeString(t, offerrequest)}</td>
                */}
                <td>{constructionOfferRequest.constructionYear}</td>
                {isPrimary && <td>{offerRequest.archived ? <FontAwesomeIcon icon={faCheck} /> : ""}</td>}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default OfferRequestTable;
