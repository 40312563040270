import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ESearchStorageKey } from '../../../enums';
import { IAppState, IAuthState } from '../../../interfaces';
import { IOfferRequestsSearchOptions } from '../../../store/actions/offerRequestActions';
import { ETranslation } from '../../../translations/translation-keys';
import OperatorSearch from '../../Searches/OperatorSearch/OperatorSearch';
import SearchContainer from '../../Searches/SearchContainer/SearchContainer';
import ServiceSearch from '../../Searches/ServiceSearch/ServiceSearch';

interface IProps {
  onSearch: (search: IOfferRequestsSearchOptions) => void;
};

const OfferRequestsSearch: React.FC<IProps> = ({ onSearch }) => {
  const [isInit, setInit] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [limit, setLimit] = useState(true);

  const { authUser } = useSelector<IAppState, IAuthState>((state) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    setOperatorId(localStorage.getItem(ESearchStorageKey.OFFER_REQUEST_OPERATOR_ID) || '');
    setServiceId(localStorage.getItem(ESearchStorageKey.OFFER_REQUEST_SERVICE_ID) || '');
    setInit(true);
  }, []);

  useEffect(() => {
    if(isInit) {
      onSearch({ operatorId, serviceId, limit });
      localStorage.setItem(ESearchStorageKey.OFFER_REQUEST_OPERATOR_ID, operatorId);
      localStorage.setItem(ESearchStorageKey.OFFER_REQUEST_SERVICE_ID, serviceId);
    }
  }, [operatorId, serviceId, limit, onSearch, isInit]);

  return (
    <>
    <SearchContainer>
      {authUser?.isPrimary && (
        <OperatorSearch operatorId={operatorId} setOperatorId={setOperatorId} />
      )}
      <ServiceSearch serviceId={serviceId} setServiceId={setServiceId} />
    </SearchContainer>
    <div style={{ margin: '1rem',cursor: 'pointer', textDecoration:'underline'}} onClick={()=> setLimit(false)}>{t(ETranslation.COMMON_LOAD_ALL)}</div>
    </>
  );
};

export default OfferRequestsSearch;
