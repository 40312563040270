import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button from '../../../components/UI/Button/Button';
import ConfirmDialog from '../../../components/UI/ConfirmDialog/ConfirmDialog';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import ModalContext, { defaultModal, EModalSize } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../../context/InputContext';
import { EOfferRequestType } from '../../../enums';
import { useAuthUser } from '../../../hooks/useAuthUser';
import { useLocationOptions } from '../../../hooks/useLocationOptions';
import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { IAppState, IService, IServiceState } from '../../../interfaces';
import { getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

export enum EService {
  operator = "operator",
  name = "name",
  url = "url",
  archived = "archived",
  serviceToken = "serviceToken",
  locations = "locations",
  type = "type",
}

const Service: React.FC<IProps> = ({ match, history, location }) => {
  const dispatch = useDispatch();

  const { setModal } = useContext(ModalContext);

  const { organizationOptions, organizationsLoading } = useOrganizationOptions({
    withPlaceholder: true,
    withBusinessId: true,
  });

  const { loading, error, service, saveOrUpdateOk } = useSelector<IAppState, IServiceState>(state => state.services);
  const authUser = useAuthUser();

  const { t } = useTranslation();
  const { id } = match.params;

  const [inputs, setInputs] = useState<IInputField>({
    [EService.operator]: {
      type: EInputType.select,
      labelTranslation: ETranslation.COMMON_OPERATOR_NAME,
      placeholderTranslation: ETranslation.COMMON_OPERATOR_NAME,
      value: "",
      options: [],
      validation: {
        required: authUser?.isPrimary,
      },
    },
    [EService.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICE_NAME,
      placeholderTranslation: ETranslation.SERVICE_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EService.url]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SERVICE_URL,
      placeholderTranslation: ETranslation.SERVICE_URL,
      value: "",
    },
    [EService.archived]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.COMMON_ARCHIVED,
      placeholderTranslation: ETranslation.COMMON_ARCHIVED,
      value: "",
    },
    [EService.serviceToken]: {
      type: EInputType.static,
      labelTranslation: ETranslation.COMMON_TOKEN,
      value: "",
    },
    [EService.locations]: {
      type: EInputType.searchSelect,
      labelTranslation: ETranslation.LOCATIONS,
      placeholderTranslation: ETranslation.SELECT_LOCATIONS_PLACEHOLDER,
      value: "",
      multiple: true,
    },
    [EService.type]: {
      type: EInputType.radio,
      options: [
        {
          labelTranslation: ETranslation.OFFER_REQUEST_TYPE_SIMPLE,
          value: EOfferRequestType.SIMPLE,
        },
        {
          labelTranslation: ETranslation.OFFER_REQUEST_TYPE_CONSTRUCTION,
          value: EOfferRequestType.CONSTRUCTION,
        },
        {
          labelTranslation: ETranslation.OFFER_REQUEST_TYPE_WELLNESS,
          value: EOfferRequestType.WELLNESS,
        },
      ],
      value: EOfferRequestType.SIMPLE,
    },
  });


  let operatorId;
  if (authUser?.isOperator) {
    operatorId = authUser.organization.id;
  } else {
    operatorId = inputs[EService.operator].value as string;
  }

  const { locationOptions, locationsLoading } = useLocationOptions({
    operatorId,
    filterByOperatorId: true
  });

  const [isValid, setIsValid] = useState<boolean>(false);


  useEffect(() => {

    if(saveOrUpdateOk) {
      history.push(Routes.SERVICES)
    }
    return () => {
      dispatch(actions.clearService());
    }
  }, [saveOrUpdateOk, history, dispatch]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getService(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (service) {
      initForm(setInputs, service);
    }
  }, [service]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (inputName: EService, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={false}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.SERVICE}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const service = getInputData<IService>(inputs);

    if (Routes.isNew(id)) {
      dispatch(actions.saveService(service));
    } else {
      service.id = id;
      dispatch(actions.updateService(service));
    }
  };

  const addAllLocationsHandler = () => {
    setInputs((inputs) => {
      const newInputs = { ...inputs };
      newInputs[EService.locations].value = [...locationOptions];
      return newInputs;
    });
  };

  const setConfirmDeleteDialog = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_REMOVE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          body={<p>{t(ETranslation.CONFIRM_DELETE_SERVICE)}</p>}
          onConfirm={() => {
            // TODO should wait for result and then goBack
            dispatch(actions.deleteService(id));
            history.goBack();
            setModal(defaultModal);
          }}
          onCancel={() => setModal(defaultModal)}
        />
      ),
    });

  return (
    <Container>
      {error && <Alert children={error} />}
      <h2>
        {Routes.isNew(id)
          ? t(ETranslation.TITLE_SERVICE_NEW)
          : t(ETranslation.TITLE_SERVICE)}
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {createInput(EService.type)}
          {authUser?.isPrimary &&
            createInput(EService.operator, {
              options: organizationOptions,
              loading: organizationsLoading,
            })}
          {createInput(EService.name)}
          {createInput(EService.url)}
          <InputGroup>
            {createInput(EService.locations, {
              options: locationOptions,
              loading: locationsLoading,
            })}
            {locationOptions && (
              <Button onClick={addAllLocationsHandler}>
                {t(ETranslation.COMMON_ADD_ALL)}
              </Button>
            )}
          </InputGroup>

          {createInput(EService.archived)}
          {Routes.isNotNew(id) && createInput(EService.serviceToken)}
        </>
      )}
      <InputGroup>
        <Button disabled={loading || !isValid} onClick={submitHandler}>
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        <Button onClick={() => history.goBack()}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {Routes.isNotNew(id) && (
          <Button disabled={loading} onClick={setConfirmDeleteDialog}>
            {t(ETranslation.COMMON_REMOVE)}
          </Button>
        )}
      </InputGroup>
    </Container>
  );
};

export default Service;
