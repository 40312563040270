import { IServiceAction, IServiceState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IServiceState = {
  loading: false,
  services: null,
  service: null,
  error: null,
  saveOrUpdateOk: false
};

const getServiceStart = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    service: null,
    loading: true,
    error: null,
  });
};

const getServiceSuccess = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    service: action.service,
    loading: false,
    error: null,
  });
};

const getServiceFail = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteServiceStart = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    service: null,
    loading: true,
    error: null,
  });
};

const deleteServiceSuccess = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    service: action.service,
    loading: false,
    error: null,
  });
};

const deleteServiceFail = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listServicesStart = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    services: null,
    service: null,
    loading: true,
    error: null,
  });
};

const listServicesSuccess = (
  state: IServiceState,
  action: IServiceAction
): IServiceState => {
  return updateObject(state, {
    services: action.services,
    loading: false,
    error: null,
  });
};

const listServicesFail = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveServiceStart = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveServiceSuccess = (
  state: IServiceState,
  action: IServiceAction
): IServiceState => {
  return updateObject(state, {
    service: action.service,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveServiceFail = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateServiceStart = (
  state: IServiceState,
  action: IServiceAction
): IServiceState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateServiceSuccess = (
  state: IServiceState,
  action: IServiceAction
): IServiceState => {
  return updateObject(state, {
    service: action.service,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const updateServiceFail = (state: IServiceState, action: IServiceAction): IServiceState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearService = (state: IServiceState, action: IServiceAction): IServiceState => {
  return { ...initialState };
};

const reducer = (
  state: IServiceState = initialState,
  action: IServiceAction
): IServiceState => {
  switch (action.type) {
    case ActionTypes.SERVICE_GET_START:
      return getServiceStart(state, action);
    case ActionTypes.SERVICE_GET_SUCCESS:
      return getServiceSuccess(state, action);
    case ActionTypes.SERVICE_GET_FAIL:
      return getServiceFail(state, action);
    case ActionTypes.SERVICE_DELETE_START:
      return deleteServiceStart(state, action);
    case ActionTypes.SERVICE_DELETE_SUCCESS:
      return deleteServiceSuccess(state, action);
    case ActionTypes.SERVICE_DELETE_FAIL:
      return deleteServiceFail(state, action);
    case ActionTypes.SERVICE_LIST_START:
      return listServicesStart(state, action);
    case ActionTypes.SERVICE_LIST_SUCCESS:
      return listServicesSuccess(state, action);
    case ActionTypes.SERVICE_LIST_FAIL:
      return listServicesFail(state, action);
    case ActionTypes.SERVICE_SAVE_START:
      return saveServiceStart(state, action);
    case ActionTypes.SERVICE_SAVE_SUCCESS:
      return saveServiceSuccess(state, action);
    case ActionTypes.SERVICE_SAVE_FAIL:
      return saveServiceFail(state, action);
    case ActionTypes.SERVICE_UPDATE_START:
      return updateServiceStart(state, action);
    case ActionTypes.SERVICE_UPDATE_SUCCESS:
      return updateServiceSuccess(state, action);
    case ActionTypes.SERVICE_UPDATE_FAIL:
      return updateServiceFail(state, action);
    case ActionTypes.SERVICE_CLEAR:
      return clearService(state, action);
    default:
      return state;
  }
};

export default reducer;
