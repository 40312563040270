import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../classes/Routes";

import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import { ETranslation } from "../../translations/translation-keys";

interface IProps extends RouteComponentProps {}

const SubscriptionExpired: React.FC<IProps> = ({ history }) => {
  const { t } = useTranslation();


  const expiredInfoLinkHandler = () => {
    history.push(Routes.ORGANIZATIONS_ME);
  }

  return (
    <Container>
      <h2>{t(ETranslation.SUBSCRIPTION_EXPIRED)}</h2>
      <p>{t(ETranslation.SERVICEPROVIDER_EXPIRED_SUBSCRIBE)}</p>
      <p>{t(ETranslation.SUBSCRIPTION_EXPIRED_INFO)}<Button link onClick={expiredInfoLinkHandler}>{t(ETranslation.SUBSCRIPTION_EXPIRED_INFO_LINK)}</Button> </p>
      <div>
        <Button onClick={() => window.open("https://www.hankix.fi", "_blank")}>
          Hankix.fi
        </Button>
      </div>
    </Container>
  );
};

export default SubscriptionExpired;
