import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { ERoute } from './classes/Routes';
import Layout from './components/Layout/Layout';
import SplashScreen from './components/SplashScreen/SplashScreen';
import Modal from './components/UI/Modal/Modal';
import ModalContext, { defaultModal, EModalSize, IModal } from './components/UI/Modal/ModalContext';
import Admin from './containers/Admin/Admin';
import SubscriptionExpired from './containers/SubscriptionExpired/SubscriptionExpired';
import Auth from './containers/Auth/Auth';
import Feedback from './containers/Feedbacks/Feedback/Feedback';
import Feedbacks from './containers/Feedbacks/Feedbacks';
import Location from './containers/Locations/Location/Location';
import Locations from './containers/Locations/Locations';
import CustomerOfferRequest from './containers/OfferRequests/OfferRequest/CustomerOfferRequest';
import OfferRequest from './containers/OfferRequests/OfferRequest/OfferRequest';
import OfferRequests from './containers/OfferRequests/OfferRequests';
import Operator from './containers/Operators/Operator/Operator';
import Operators from './containers/Operators/Operators';
import Organization from './containers/Organization/Organization';
import ServiceProvider from './containers/ServiceProviders/ServiceProvider/ServiceProvider';
import ServiceProviders from './containers/ServiceProviders/ServiceProviders';
import Service from './containers/Services/Service/Service';
import Services from './containers/Services/Services';
import Settings from './containers/Settings/Settings';
import User from './containers/Users/User/User';
import Users from './containers/Users/Users';
import firebase from './firebase';
import GuardedRoute from './GuardedRoute';
import { IAppState, IAuthState } from './interfaces';
import * as actions from './store/actions';
import Content from './containers/Contents/Content/Content';
import Contents from './containers/Contents/Contents';
import OfferRequestAccess from './containers/OfferRequestAccess/OfferRequestAccess';
import LogItems from './containers/LogItem/LogItems';
import TermsOfUseModal from './components/TermsOfUseModal/TermsOfUseModal';
import i18n from './i18n';
import { ETranslation } from './translations/translation-keys';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState(true);
  const [showSplash, setShowSplash] = useState(true);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { authUser, error } = useSelector<IAppState, IAuthState>((state) => state.auth);

  useEffect(() => {
    if (authUser && loading) { // When loading is true it's first time this is run, this should be done only once.
      setLoading(false);
      if(authUser.isPrimary) dispatch(actions.listOrganizations());
      if(authUser.isPrimary || authUser.isOperator) dispatch(actions.listLocations());
      if(authUser.isPrimary || authUser.isOperator || authUser.isBasic) dispatch(actions.listServices());
      if(authUser?.preferredLanguage != null) {
        i18n.changeLanguage(authUser?.preferredLanguage)
      }
    }
  }, [dispatch, authUser, loading]); 

  useEffect(() => {
    if (authUser && authUser.isServiceProvider && !authUser.isTermsOfUseAccepted) {
      setModal({
        isOpen: true,
        content: <TermsOfUseModal />,
        size: EModalSize.MEDIUM,
        disabled: true,
        title: t(ETranslation.TERMS_TITLE)
      });
    }
  }, [t, authUser]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error])

  useEffect(() => {
    if(!loading) {
      setTimeout(() => {
        setShowSplash(false);
      }, 200);
    }
  }, [loading])

  useEffect(() => {
    firebase.auth().onIdTokenChanged(fbUser => {
      if (fbUser) {
        dispatch(actions.getCurrentUser());
      } else {
        setLoading(false);
      }
    });
  }, [dispatch]);

  return (
    <>
      {showSplash && <SplashScreen loading={loading} /> }
      <Layout currentUser={authUser} onLogout={() => dispatch(actions.logout())}>
        <ModalContext.Provider
          value={{
            setModal,
            closeModal: () => setModal(defaultModal),
          }}
        >
          <Modal modal={modal} />

          {loading ? null : authUser ? (
              <Switch>
                <GuardedRoute user={authUser} path={ERoute.ADMIN} component={Admin} />
                <GuardedRoute user={authUser} path={ERoute.ORGANIZATION_USER} exact component={User} />
                <GuardedRoute user={authUser} path={ERoute.OPERATOR} component={Operator} />
                <GuardedRoute user={authUser} path={ERoute.OPERATORS} component={Operators} />
                <GuardedRoute user={authUser} path={ERoute.USER} component={User} />
                <GuardedRoute user={authUser} path={ERoute.USERS} component={Users} />
                <GuardedRoute user={authUser} path={ERoute.OFFER_REQUEST_OFFER} component={OfferRequest} />
                <GuardedRoute user={authUser} path={ERoute.OFFER_REQUEST} component={OfferRequest} />
                <GuardedRoute user={authUser} path={ERoute.OFFER_REQUESTS} component={OfferRequests} />
                <GuardedRoute user={authUser} path={ERoute.SERVICE_PROVIDER_FORM} component={ServiceProvider} />
                <GuardedRoute user={authUser} path={ERoute.SERVICE_PROVIDER_PROVIDER} component={ServiceProvider} />
                <GuardedRoute user={authUser} path={ERoute.SERVICE_PROVIDER} component={ServiceProvider} />
                <GuardedRoute user={authUser} path={ERoute.SERVICES_SERVICE} component={Service} />
                <GuardedRoute user={authUser} path={ERoute.SERVICE_PROVIDERS} component={ServiceProviders} />
                
                <GuardedRoute user={authUser} path={ERoute.SERVICE} component={Service} />
                <GuardedRoute user={authUser} path={ERoute.SERVICES} component={Services} />
                <GuardedRoute user={authUser} path={ERoute.CONTENT} component={Content} />
                <GuardedRoute user={authUser} path={ERoute.CONTENTS} component={Contents} />
                <GuardedRoute user={authUser} path={ERoute.CONTENTS_CONTENT} component={Content} />
                <GuardedRoute user={authUser} path={ERoute.FEEDBACKS} component={Feedbacks} />
                <GuardedRoute user={authUser} path={ERoute.ORGANIZATIONS_ME} component={Organization} />
                <GuardedRoute user={authUser} path={ERoute.LOCATION} component={Location} />
                <GuardedRoute user={authUser} path={ERoute.LOCATIONS} component={Locations} />
                <GuardedRoute user={authUser} path={ERoute.SETTINGS} component={Settings} />
                <GuardedRoute user={authUser} path={ERoute.SUBSCRIPTION_EXPIRED} component={SubscriptionExpired} />
                <GuardedRoute user={authUser} path={ERoute.OFFER_REQUEST_ACCESS} component={OfferRequestAccess} />
                <GuardedRoute user={authUser} path={ERoute.LOG_ITEMS} component={LogItems} />
                <Redirect to={authUser.redirect} />
              </Switch>
            ) : (
              <Switch>
                <Route path={ERoute.LOGIN} component={Auth} />
                <Route path={ERoute.CUSTOMER_OFFER_REQUEST} component={CustomerOfferRequest} />
                <Route path={ERoute.FEEDBACK} component={Feedback} />
                <Redirect to={ERoute.LOGIN} />
              </Switch>
            )
          }
        </ModalContext.Provider>
      </Layout>
    </>
  )    
};

export default App;
