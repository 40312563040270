import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationOptions } from '../../../hooks/useOrganizationOptions';
import { ETranslation } from '../../../translations/translation-keys';
import Select from '../../UI/Select/Select';


interface IProps {
  setOperatorId: React.Dispatch<React.SetStateAction<string>>;
  operatorId: string;
}

const OperatorSearch: React.FC<IProps> = ({ setOperatorId, operatorId}) => {

  const { organizationOptions, organizationsLoading } = useOrganizationOptions();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(ETranslation.COMMON_OPERATOR_NAME)}</label>
      <Select
        inputName={"operatorSearch"}
        value={operatorId}
        onChange={setOperatorId}
        options={organizationOptions}
        isSearchable
        isClearable
        isLoading={organizationsLoading}
      />
    </div>
  )

}

export default OperatorSearch;