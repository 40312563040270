import axios from "../axios";

export const search = async (searchTerm: string, signal: AbortSignal) => {
  return axios
    .get(`/json/search?q=${searchTerm}`)
    .then((res) => {
      if (res.data) {
        return res.data;
      }
    })
    .catch((error) => {
      if (error instanceof Error) {
        throw error;
      } else {
        throw new Error(error);
      }
    });
};
