import { IGeoLocationAction, IGeoLocationState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

const initialState: IGeoLocationState = {
  loading: false,
  geoLocation: null,
  error: null,
};

const getGeoLocationStart = (state: IGeoLocationState, action: IGeoLocationAction): IGeoLocationState => {
  return updateObject(state, {
    geoLocation: null,
    loading: true,
    error: null,
  });
};

const getGeoLocationSuccess = (state: IGeoLocationState, action: IGeoLocationAction): IGeoLocationState => {
  return updateObject(state, {
    geoLocation: action.geoLocation,
    loading: false,
    error: null,
  });
};

const getGeoLocationFail = (state: IGeoLocationState, action: IGeoLocationAction): IGeoLocationState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (
  state: IGeoLocationState = initialState,
  action: IGeoLocationAction
): IGeoLocationState => {
  switch (action.type) {
    case ActionTypes.GEOLOCATION_GET_START:
      return getGeoLocationStart(state, action);
    case ActionTypes.GEOLOCATION_GET_SUCCESS:
      return getGeoLocationSuccess(state, action);
    case ActionTypes.GEOLOCATION_GET_FAIL:
      return getGeoLocationFail(state, action);
    default:
      return state;
  }
};

export default reducer;