import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { IAppState, IContentState } from "../../interfaces";
import { ETranslation } from "../../translations/translation-keys";
import { linkHandler } from "../../shared/utility";
import { useAuthUser } from "../../hooks/useAuthUser";
import { Routes, ERouteStaticParams } from "../../classes/Routes";
import * as actions from '../../store/actions';
import ContentTable from "../../components/Content/ContentTable/ContentsTable";

interface IProps extends RouteComponentProps {
  contentId?: number;
}

const Contents: React.FC<IProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const { loading, error, contents } = useSelector<IAppState, IContentState>(state => state.content);

  useEffect(() => {
    dispatch(actions.listContents());
  }, [dispatch]);

  const showContent = (event: React.MouseEvent<HTMLElement>, id: string) => {
    linkHandler(event, Routes.CONTENT(id), history);
  };

  return (
    <Container>
      {error && <Alert>{t(error)}</Alert>}
      <h2>{t(ETranslation.TITLE_CONTENT)}</h2>
      {(authUser?.isPrimary || authUser?.isOperator) && (
        <Button
          onClick={() =>
            history.push(Routes.CONTENT(ERouteStaticParams.new))
          }
          icon={faPlus}
          style={{ marginBottom: "1rem" }}
        >
          {t(ETranslation.CONTENTS_ADD_NEW)}
        </Button>
      )}
      {loading ? (
        <Spinner />
      ) : contents ? (
        <ContentTable
        contents={contents}
          onClick={showContent}
          sortable
          isPrimary={authUser?.isPrimary}
        />
      ) : (
        <p>
          <br />
          {t(ETranslation.NO_CONTENTS)}
        </p>
      )}
    </Container>
  );
};

export default Contents;