import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../../../translations/translation-keys';
import { EOfferRequestsTableSortBy } from '../OfferRequestsTable';


interface IProps {
  children: ETranslation;
  sortable?: boolean;
  sortBy?: EOfferRequestsTableSortBy;
  onSort?: (sortBy: EOfferRequestsTableSortBy) => void;
  sort?: EOfferRequestsTableSortBy;
  sortDirection?: boolean;

}

const OfferRequestsTableHeader: React.FC<IProps> = ({ children, sortBy, sortable, onSort, sortDirection, sort }) => {
  const { t } = useTranslation(); 

  const sortHandler = (sortBy?: EOfferRequestsTableSortBy) => {
    if (!sortable || !sortBy || !onSort) return;
    onSort(sortBy);
  }

  return (
    <th onClick={() => sortHandler(sortBy)}>
      {t(children)}
      {sortable && sort === sortBy && <FontAwesomeIcon style={{ marginLeft: '.5rem', fontSize: '.75rem'}} icon={sortDirection ? faChevronUp : faChevronDown} />}
    </th>
  )
}

export default OfferRequestsTableHeader;