import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import axios from '../../axios';
import { IServiceAction } from '../../interfaces';
import { IService } from '../../interfaces/domain';
import { ActionTypes } from './actionTypes';


const apiPath = '/json/services';

const getServiceStart = (): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_GET_START,
  };
};

const getServiceSuccess = (service: IService): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_GET_SUCCESS,
    service,
  };
};

const getServiceFail = (error: string): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_GET_FAIL,
    error,
  };
};

export const getService = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getServiceStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getServiceSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getServiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteServiceStart = (): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_DELETE_START,
  };
};

const deleteServiceSuccess = (service: IService): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_DELETE_SUCCESS,
    service,
  };
};

const deleteServiceFail = (error: string): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_DELETE_FAIL,
    error,
  };
};

export const deleteService = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteServiceStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteServiceSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteServiceFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listServicesStart = (): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_LIST_START,
  };
};

const listServicesSuccess = (
  services: Array<IService>
): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_LIST_SUCCESS,
    services,
  };
};

const listServicesFail = (error: string): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_LIST_FAIL,
    error,
  };
};

export interface IServicesSearchOptions {
  includeArchived?: boolean;
  operatorId?: string;
}

export const listServices = (
  options?: IServicesSearchOptions
) => {
  return async (dispatch: Dispatch) => {
    dispatch(listServicesStart());
    try {
      const resp = await axios.post<IService[]>(`${apiPath}/list`, options);
      dispatch(listServicesSuccess(resp.data));
    } catch({ response }) {
      dispatch(listServicesFail((response as AxiosResponse).data));
    }
  }
};

const saveServiceStart = (): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_SAVE_START,
  };
};

const saveServiceSuccess = (service: IService): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_SAVE_SUCCESS,
    service,
  };
};

const saveServiceFail = (error: string): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_SAVE_FAIL,
    error,
  };
};

export const saveService = (service: IService) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveServiceStart());
    try {
      const res = await axios.post(`${apiPath}/add`, service);
      dispatch(saveServiceSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveServiceFail((response as AxiosResponse).data));
    }
  };
};

const updateServiceStart = (): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_UPDATE_START,
  };
};

const updateServiceSuccess = (service: IService): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_UPDATE_SUCCESS,
    service,
  };
};

const updateServiceFail = (error: string): IServiceAction => {
  return {
    type: ActionTypes.SERVICE_UPDATE_FAIL,
    error,
  };
};

export const updateService = (service: IService) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateServiceStart());
    try {
      const res = await axios.put(`${apiPath}/update`, service);
      dispatch(updateServiceSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateServiceFail((response as AxiosResponse).data));
    }
  };
};

export const clearService = () => {
  return {
    type: ActionTypes.SERVICE_CLEAR,
  };
};
