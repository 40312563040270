import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ERouteStaticParams, Routes } from '../../classes/Routes';
import Alert from '../../components/UI/Alert/Alert';
import Button from '../../components/UI/Button/Button';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import Table from '../../components/UI/Table/Table';
import { IAppState, IUserState } from '../../interfaces';
import { linkHandler } from '../../shared/utility';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {
  organizationId?: string;
}

const Users: React.FC<IProps> = ({ history, organizationId }) => {
  const dispatch = useDispatch();

  const { loading, error, users } = useSelector<IAppState, IUserState>(state => state.users);

  const { t } = useTranslation();

  useEffect(() => {
    organizationId
      ? dispatch(actions.listUsers(organizationId))
      : dispatch(actions.listUsers());
  }, [dispatch, organizationId]);

  const showUser = (
    event: React.MouseEvent<HTMLTableRowElement>,
    id: string
  ) => {
    if (organizationId) {
      linkHandler(event, Routes.ORGANIZATION_USER(organizationId, id), history);
    } else {
      linkHandler(event, Routes.USER(id), history);
    }
  };

  const addUser = () => {
    if (organizationId) {
      history.push(
        Routes.ORGANIZATION_USER(organizationId, ERouteStaticParams.new)
      );
    } else {
      history.push(Routes.USER(ERouteStaticParams.new));
    }
  };

  return (
    <Container
      style={organizationId ? { padding: 0, margin: 0, marginTop: "1rem" } : {}}
    >
      {error && <Alert>{t(error)}</Alert>}
      <h2>{t(ETranslation.TITLE_USERS)}</h2>
      <Button onClick={addUser} icon={faPlus} style={{ marginBottom: "1rem" }}>
        {t(ETranslation.USERS_ADD_NEW)}
      </Button>
      {loading ? (
        <Spinner />
      ) : users ? (
        <Table hover>
          <thead>
            <tr>
              <th>{t(ETranslation.USER_NAME)}</th>
              <th>{t(ETranslation.USER_EMAIL)}</th>
              <th>{t(ETranslation.USER_TITLE)}</th>
              <th>{t(ETranslation.USER_PHONE_NUMBER)}</th>
              <th>{t(ETranslation.USER_ADDRESS)}</th>
              <th>{t(ETranslation.USER_ALLOW_LOGIN)}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} onClick={(e) => showUser(e, user.id)}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.title}</td>
                <td>{user.phoneNumber}</td>
                <td>
                  {user.streetAddress} {user.zip} {user.city}
                </td>
                <td>
                  {user.allowLogin ? <FontAwesomeIcon icon={faCheck} /> : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>{t(ETranslation.NO_USERS)}</p>
      )}
    </Container>
  );
};

export default Users;
