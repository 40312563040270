import React from "react";
import { IOption } from "../Input/Input";
import classes from "./Star.module.scss";
import StarOption from "./StarOption/StarOption";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  options: IOption[] | undefined;
  disabled?: boolean;
  invalid?: boolean;
}

const Star: React.FC<IProps> = ({ value, onChange, options, disabled, invalid }) => {
  const activeIndex = options?.findIndex(o => o.value === value);
  return (
    <div className={classes.OptionContainer}>
      {options &&
        options.map((option, i) => (
          <React.Fragment key={option.value}>
            <StarOption
              value={option.value}
              isActive={i <= (activeIndex || 0)}
              onClick={onChange}
              disabled={disabled}
              invalid={invalid}
            />
            {i < (options.length - 1) && (
              <span style={{ width: '1px' }}></span>
            )}
          </React.Fragment>

        ))}
    </div>
  );
};

export default Star;
