
export enum ERoute {
  ADMIN =  "/admin",
  SUBSCRIPTION_EXPIRED = "/subscriptionexpired",
  ORGANIZATIONS = "/organizations",
  ORGANIZATION_USER = `/operators/:organizationId/users/:id`,
  OFFER_REQUESTS = "/offerrequests",
  OFFER_REQUEST_OFFER = `/offerrequests/:offerRequestId/offerrequests/:id`,
  OFFER_REQUEST = `/offerrequests/:id`,
  SERVICE_PROVIDER = `/serviceproviders/:id`,
  SERVICE_PROVIDERS =  "/serviceproviders",
  SERVICE_PROVIDER_PROVIDER = `/serviceproviders/:serviceProviderId/serviceproviders/:id`,
  SERVICE_PROVIDER_FORM = `/serviceproviders/forms/:id`,
  SERVICES = "/services",
  SERVICES_SERVICE = `/services/:serviceId/services/:id`,
  SERVICE = `/services/:id`,
  CONTENTS = "/contents",
  CONTENTS_CONTENT = `/contents/:contentId/contents/:id`,
  CONTENT = `/contents/:id`,
  FEEDBACKS =  "/feedbacks",
  FEEDBACK =  "/feedback",
  LOGIN =  "/login",
  OPERATORS =  "/operators",
  OPERATOR = `/operators/:id`,
  SETTINGS =  "/settings",
  USERS =  "/users",
  USER = `/users/:id`,
  ORGANIZATIONS_ME =  "/organizations/me",
  CUSTOMER_OFFER_REQUEST =  "/offerrequest",
  LOCATIONS =  "/locations",
  LOCATION = `/locations/:id`,
  OFFER_REQUEST_ACCESS = "/offerrequestaccess",
  LOG_ITEMS = "/logitems"
}

export enum ERouteStaticParams {
  new = 'new'
}

type TParam = string | ERouteStaticParams;

interface IParams {
  [key: string]: TParam;
}

export class Routes {
  public static ADMIN = ERoute.ADMIN;
  public static SUBSCRIPTION_EXPIRED = ERoute.SUBSCRIPTION_EXPIRED;
  public static ORGANIZATIONS = ERoute.ORGANIZATIONS;
  public static ORGANIZATION_USER = (organizationId: TParam, id: TParam) => Routes.withParams(ERoute.ORGANIZATION_USER, { organizationId, id });
  public static OFFER_REQUESTS = ERoute.OFFER_REQUESTS;
  public static OFFER_REQUEST_OFFER = (offerRequestId: TParam, id: TParam) => Routes.withParams(ERoute.OFFER_REQUEST_OFFER, { offerRequestId, id });
  public static OFFER_REQUEST = (id: TParam) => Routes.withParams(ERoute.OFFER_REQUEST, { id });
  public static SERVICE_PROVIDER = (id: TParam) => Routes.withParams(ERoute.SERVICE_PROVIDER, { id });
  public static SERVICE_PROVIDERS = ERoute.SERVICE_PROVIDERS;
  public static SERVICE_PROVIDER_PROVIDER = (serviceProviderId: TParam, id: TParam) => Routes.withParams(ERoute.SERVICE_PROVIDER_PROVIDER, { serviceProviderId, id });
  public static SERVICE_PROVIDER_FORM = (id: TParam) => Routes.withParams(ERoute.SERVICE_PROVIDER_FORM, { id });
  public static SERVICES = ERoute.SERVICES;
  public static SERVICES_SERVICE = (serviceId: TParam, id: TParam ) => Routes.withParams(ERoute.SERVICES_SERVICE, { serviceId, id });
  public static SERVICE = (id: TParam) => Routes.withParams(ERoute.SERVICE, { id });
  public static CONTENTS = ERoute.CONTENTS;
  public static CONTENTS_CONTENT = (contentId: TParam, id: TParam ) => Routes.withParams(ERoute.CONTENTS_CONTENT, { contentId, id });
  public static CONTENT = (id: TParam) => Routes.withParams(ERoute.CONTENT, { id });
  public static FEEDBACKS =  ERoute.FEEDBACKS;
  public static FEEDBACK =  ERoute.FEEDBACK;
  public static LOGIN =  ERoute.LOGIN;
  public static OPERATORS =  ERoute.OPERATORS;
  public static OPERATOR = (id: TParam) => Routes.withParams(ERoute.OPERATOR, { id });
  public static SETTINGS = ERoute.SETTINGS;
  public static USERS = ERoute.USERS;
  public static USER = (id: TParam) => Routes.withParams(ERoute.USER, { id });
  public static ORGANIZATIONS_ME = ERoute.ORGANIZATIONS_ME;
  public static CUSTOMER_OFFER_REQUEST = ERoute.CUSTOMER_OFFER_REQUEST;
  public static LOCATIONS =  ERoute.LOCATIONS;
  public static LOCATION = (id: TParam) => Routes.withParams(ERoute.LOCATION, { id });
  public static OFFER_REQUEST_ACCESS = ERoute.OFFER_REQUEST_ACCESS;
  public static LOG_ITEMS = ERoute.LOG_ITEMS;

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key] as string);
    }
    return routeWithParams;
  }

  public static isNew(id: TParam): boolean {
    return id === ERouteStaticParams.new;
  }

  public static isNotNew(id: TParam): boolean {
    return id !== ERouteStaticParams.new;
  }

}