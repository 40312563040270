import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { TDispatch } from '../..';
import axios from '../../axios';
import { IAppState, IContentAction } from '../../interfaces';
import { IContent, IOrganization } from '../../interfaces/domain';
import { ActionTypes } from './actionTypes';

const apiPath  = "/json/contents";

type TAction = IContentAction;


const getContentStart = (): TAction => {
  return {
    type: ActionTypes.CONTENT_GET_START,
  };
};

const getContentSuccess = (content: IContent): TAction => {
  return {
    type: ActionTypes.CONTENT_GET_SUCCESS,
    content,
  };
};

const getContentFail = (error: string): TAction => {
  return {
    type: ActionTypes.CONTENT_GET_FAIL,
    error,
  };
};

export const getContent = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getContentStart());
    try {
      const res = await axios.get(`${apiPath}/get?id=${id}`);
      dispatch(getContentSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(getContentFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const deleteContentStart = (): TAction => {
  return {
    type: ActionTypes.CONTENT_DELETE_START,
  };
};

const deleteContentSuccess = (content: IContent): TAction => {
  return {
    type: ActionTypes.CONTENT_DELETE_SUCCESS,
    content,
  };
};

const deleteContentFail = (error: string): TAction => {
  return {
    type: ActionTypes.CONTENT_DELETE_FAIL,
    error,
  };
};

export const deleteContent = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteContentStart());
    try {
      const res = await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteContentSuccess(res.data));
      return res.data;
    } catch ({ response }) {
      dispatch(deleteContentFail((response as AxiosResponse).data));
      return null;
    }
  };
};

const listContentsStart = (): TAction => {
  return {
    type: ActionTypes.CONTENT_LIST_START,
  };
};

const listContentsSuccess = (contents: IContent[]): TAction => {
  return {
    type: ActionTypes.CONTENT_LIST_SUCCESS,
    contents,
  };
};

const listContentsFail = (error: string): TAction => {
  return {
    type: ActionTypes.CONTENT_LIST_FAIL,
    error,
  };
};

type TListContentPromise = Promise<IContent[] | null>;
type TListContentAction = ThunkAction<
  TListContentPromise,
  IAppState,
  any,
  TAction
>;

export interface IContentsSearchOptions {
  operatorId?: string;
}

export const listContents = (
  options?: IContentsSearchOptions
): TListContentAction => (
  dispatch: TDispatch
): TListContentPromise => {
  dispatch(listContentsStart());
  return axios
    .post(`${apiPath}/list`, options)
    .then((res) => {
      dispatch(listContentsSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch(({ response }) => {
      dispatch(listContentsFail((response as AxiosResponse).data));
      return Promise.resolve((response as AxiosResponse).data);
    });
};

const saveContentStart = (): TAction => {
  return {
    type: ActionTypes.CONTENT_SAVE_START,
  };
};

const saveContentSuccess = (content: IContent): TAction => {
  return {
    type: ActionTypes.CONTENT_SAVE_SUCCESS,
    content,
  };
};

const saveContentFail = (error: string): TAction => {
  return {
    type: ActionTypes.CONTENT_SAVE_FAIL,
    error,
  };
};

export const saveContent = (content: IContent) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveContentStart());
    try {
      const res = await axios.post(`${apiPath}/add`, content);
      dispatch(saveContentSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveContentFail((response as AxiosResponse).data));
    }
  };
};

const updateContentStart = (): TAction => {
  return {
    type: ActionTypes.CONTENT_UPDATE_START,
  };
};

const updateContentSuccess = (content: IContent): TAction => {
  return {
    type: ActionTypes.CONTENT_UPDATE_SUCCESS,
    content,
  };
};

const updateContentFail = (error: string): TAction => {
  return {
    type: ActionTypes.CONTENT_UPDATE_FAIL,
    error,
  };
};

export const updateContent = (content: IOrganization) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateContentStart());
    try {
      const res = await axios.put(`${apiPath}/update`, content);
      dispatch(updateContentSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateContentFail((response as AxiosResponse).data));
    }
  };
};




export const clearContent = () => {
  return {
    type: ActionTypes.CONTENT_CLEAR,
  };
};
