import React from "react";
import ReactDropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TDispatch } from "../../..";
import { IAppState } from '../../../interfaces';
import * as actions from "../../../store/actions";
import Alert from "../Alert/Alert";
import Spinner from "../Spinner/Spinner";
import classes from "./OrganizationImageDropzone.module.scss";

export interface IDropzoneConfig {
  text?: string;
  dragText?: string;
}

interface IProps {
  className?: string;
  config?: IDropzoneConfig;
}

const OrganizationImageDropzone: React.FC<IProps> = ({
  className,
  config = { dragText: "", text: "" },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<TDispatch>();
  const { error, loading } = useSelector((state: IAppState) => ({
    error: state.organization.imageError,
    loading: state.organization.imageLoading,
  }));

  const onDropHandler = async (files: File[]) => {
    const formData = new FormData();
    for (let file of files) {
      formData.append("files[]", file);
    }

    if (!formData.has("files[]")) return;
    await dispatch(actions.updateOrganizationImage(formData));
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <ReactDropzone onDrop={onDropHandler} accept={"image/png,image/jpg,image/jpeg"}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div className={className || classes.Dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={classes.Container}>
                {loading ? (
                  <Spinner />
                ) : null}
                <p className={classes.Text}>{isDragActive ? config.dragText : config.text}</p>
              </div>
            </div>
          );
        }}
      </ReactDropzone>
    </>
  );
};

export default OrganizationImageDropzone;
