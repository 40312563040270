import { IFeedbackState, IFeedbackAction } from "./../../interfaces/state";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

type TState = IFeedbackState;
type TAction = IFeedbackAction;

const initialState: TState = {
  loading: false,
  feedbacks: null,
  feedback: null,
  offerRequest: null,
  serviceProviders: null,
  error: null,
  saveOk: false
};

const getFeedbackStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: null,
    loading: true,
    error: null,
  });
};

const getFeedbackSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: action.feedback,
    loading: false,
    error: null,
  });
};

const getFeedbackFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteFeedbackStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: null,
    loading: true,
    error: null,
  });
};

const deleteFeedbackSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: action.feedback,
    loading: false,
    error: null,
  });
};

const deleteFeedbackFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listFeedbacksStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedbacks: null,
    feedback: null,
    loading: true,
    error: null,
  });
};

const listFeedbacksSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedbacks: action.feedbacks,
    loading: false,
    error: null,
  });
};

const listFeedbacksFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveFeedbackStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveFeedbackSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: action.feedback,
    loading: false,
    saveOk: true,
    error: null,
  });
};

const saveFeedbackFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateFeedbackStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateFeedbackSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: action.feedback,
    loading: false,
    error: null,
  });
};

const updateFeedbackFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getFeedbackFormDataStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequest: null,
    serviceProviders: null,
    loading: true,
    error: null,
  });
};

const getFeedbackFormDataSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    serviceProviders: action.serviceProviders,
    loading: false,
    error: null,
  });
};

const getFeedbackFormDataFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearFeedback = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    feedback: null,
  });
};

const reducer = (state: TState = initialState, action: TAction): TState => {
  switch (action.type) {
    case ActionTypes.FEEDBACK_GET_START:
      return getFeedbackStart(state, action);
    case ActionTypes.FEEDBACK_GET_SUCCESS:
      return getFeedbackSuccess(state, action);
    case ActionTypes.FEEDBACK_GET_FAIL:
      return getFeedbackFail(state, action);
    case ActionTypes.FEEDBACK_DELETE_START:
      return deleteFeedbackStart(state, action);
    case ActionTypes.FEEDBACK_DELETE_SUCCESS:
      return deleteFeedbackSuccess(state, action);
    case ActionTypes.FEEDBACK_DELETE_FAIL:
      return deleteFeedbackFail(state, action);
    case ActionTypes.FEEDBACK_LIST_START:
      return listFeedbacksStart(state, action);
    case ActionTypes.FEEDBACK_LIST_SUCCESS:
      return listFeedbacksSuccess(state, action);
    case ActionTypes.FEEDBACK_LIST_FAIL:
      return listFeedbacksFail(state, action);
    case ActionTypes.FEEDBACK_SAVE_START:
      return saveFeedbackStart(state, action);
    case ActionTypes.FEEDBACK_SAVE_SUCCESS:
      return saveFeedbackSuccess(state, action);
    case ActionTypes.FEEDBACK_SAVE_FAIL:
      return saveFeedbackFail(state, action);
    case ActionTypes.FEEDBACK_UPDATE_START:
      return updateFeedbackStart(state, action);
    case ActionTypes.FEEDBACK_UPDATE_SUCCESS:
      return updateFeedbackSuccess(state, action);
    case ActionTypes.FEEDBACK_UPDATE_FAIL:
      return updateFeedbackFail(state, action);
    case ActionTypes.FEEDBACK_GET_FORM_DATA_START:
      return getFeedbackFormDataStart(state, action);
    case ActionTypes.FEEDBACK_GET_FORM_DATA_SUCCESS:
      return getFeedbackFormDataSuccess(state, action);
    case ActionTypes.FEEDBACK_GET_FORM_DATA_FAIL:
      return getFeedbackFormDataFail(state, action);
    case ActionTypes.FEEDBACK_CLEAR:
      return clearFeedback(state, action);
    default:
      return state;
  }
};

export default reducer;
