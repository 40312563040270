import { IOfferRequestAction, IOfferRequestState } from "../../interfaces";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

type TState = IOfferRequestState;
type TAction = IOfferRequestAction;

const initialState: TState = {
  loading: false,
  offerRequests: null,
  offerRequest: null,
  error: null,
  saveOrUpdateOk: false,
  addAccessLoading: false,
  addAccessSuccessId: null,
  addAccessError: null,
  offerRequestAccess: null
};

const getOfferRequestStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequest: null,
    loading: true,
    error: null,
  });
};

const getOfferRequestSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
  });
};

const getOfferRequestFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteOfferRequestStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequest: null,
    loading: true,
    error: null,
  });
};

const deleteOfferRequestSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
  });
};

const deleteOfferRequestFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const listOfferRequestsStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequests: null,
    offerRequest: null,
    loading: true,
    error: null,
  });
};

const listOfferRequestsSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequests: action.offerrequests,
    loading: false,
    error: null,
  });
};

const listOfferRequestsFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const saveOfferRequestStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveOfferRequestSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
    saveOrUpdateOk: true,
  });
};

const saveOfferRequestFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateOfferRequestStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateOfferRequestSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const updateOfferRequestFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getCustomerOfferRequestStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequest: null,
    loading: true,
    error: null,
  });
};

const getCustomerOfferRequestSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
  });
};

const getCustomerOfferRequestFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateCustomerOfferRequestStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const updateCustomerOfferRequestSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
  });
};

const updateCustomerOfferRequestFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const archiveCustomerOfferRequestStart = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const archiveCustomerOfferRequestSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequest: action.offerRequest,
    loading: false,
    error: null,
  });
};

const archiveCustomerOfferRequestFail = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const addAccessOfferRequestStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    addAccessLoading: true,
    addAccessSuccessId: null,
    addAccessError: null,
  });
};

const addAccessOfferRequestSuccess = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    addAccessLoading: false,
    addAccessSuccessId: action.offerRequest?.id,
  });
};

const addAccessOfferRequestFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    addAccessLoading: false,
    addAccessSuccessId: null,
    addAccessError: action.error,
  });
};

const addAccessOfferRequestClear = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    addAccessLoading: false,
    addAccessSuccessId: null,
    addAccessError: null,
  });
};

const listOfferRequestAccessStart = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    offerRequestAccess: null,
    loading: true,
    error: null,
  });
};

const listOfferRequestAccessSuccess = (
  state: TState,
  action: TAction
): TState => {
  return updateObject(state, {
    offerRequestAccess: action.offerRequestAccess,
    loading: false,
    error: null,
  });
};

const listOfferRequestAccessFail = (state: TState, action: TAction): TState => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

const clearOfferRequest = (state: TState, action: TAction): TState => {
  return { ...initialState };
};

const reducer = (
  state: TState = initialState,
  action: TAction
): TState => {
  switch (action.type) {
    case ActionTypes.OFFER_REQUEST_GET_START:
      return getOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_GET_SUCCESS:
      return getOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_GET_FAIL:
      return getOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_DELETE_START:
      return deleteOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_DELETE_SUCCESS:
      return deleteOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_DELETE_FAIL:
      return deleteOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_LIST_START:
      return listOfferRequestsStart(state, action);
    case ActionTypes.OFFER_REQUEST_LIST_SUCCESS:
      return listOfferRequestsSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_LIST_FAIL:
      return listOfferRequestsFail(state, action);
    case ActionTypes.OFFER_REQUEST_SAVE_START:
      return saveOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_SAVE_SUCCESS:
      return saveOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_SAVE_FAIL:
      return saveOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_UPDATE_START:
      return updateOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_UPDATE_SUCCESS:
      return updateOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_UPDATE_FAIL:
      return updateOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_GET_START:
      return getCustomerOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_GET_SUCCESS:
      return getCustomerOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_GET_FAIL:
      return getCustomerOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_UPDATE_START:
      return updateCustomerOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_UPDATE_SUCCESS:
      return updateCustomerOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_UPDATE_FAIL:
      return updateCustomerOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_ARCHIVE_START:
      return archiveCustomerOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_ARCHIVE_SUCCESS:
      return archiveCustomerOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_CUSTOMER_ARCHIVE_FAIL:
      return archiveCustomerOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_ADD_ACCESS_START:
      return addAccessOfferRequestStart(state, action);
    case ActionTypes.OFFER_REQUEST_ADD_ACCESS_SUCCESS:
      return addAccessOfferRequestSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_ADD_ACCESS_FAIL:
      return addAccessOfferRequestFail(state, action);
    case ActionTypes.OFFER_REQUEST_ADD_ACCESS_CLEAR:
      return addAccessOfferRequestClear(state, action);
    case ActionTypes.OFFER_REQUEST_ACCESS_LIST_START:
      return listOfferRequestAccessStart(state, action);
    case ActionTypes.OFFER_REQUEST_ACCESS_LIST_SUCCESS:
      return listOfferRequestAccessSuccess(state, action);
    case ActionTypes.OFFER_REQUEST_ACCESS_LIST_FAIL:
      return listOfferRequestAccessFail(state, action);
    case ActionTypes.OFFER_REQUEST_CLEAR:
      return clearOfferRequest(state, action);
    default:
      return state;
  }
};

export default reducer;
