import * as dateFns from 'date-fns';
import React, { useEffect, useRef } from 'react';
import * as uuid from 'uuid';

import History from 'history';

import {
  EInputType,
  IInputField,
  IInputFieldItem,
  IOption,
  IValidationResult,
  TInputValue,
} from '../components/UI/Input/Input';
import { IService } from '../interfaces';
import { ETranslation } from '../translations/translation-keys';

export function updateObject<T>(
  oldObject: T,
  updatedProperties: Partial<T>
): T {
  return {
    ...oldObject,
    ...updatedProperties,
  };
}

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const isOptionArray = (value: TInputValue): value is IOption[] =>
  Array.isArray(value) &&
  value.length > 0 &&
  value.every((item: any) => item.value !== undefined);
const isOption = (value: TInputValue): value is IOption =>
  (value as IOption).value !== undefined;
export const isStringArray = (value: TInputValue): value is string[] =>
  Array.isArray(value) &&
  value.length > 0 &&
  value.every((item: any) => typeof item === "string");

export function getInputData<T>(inputs: IInputField): T {
  let data: any = {};
  for (let key in inputs) {
    const input = inputs[key];
    let value = input.value;

    if (value === undefined) {

    } else if (value === null){
      
    } else if (isOptionArray(value)) {
      value = value.map((item) => item.value);
    } else if (isOption(value)) {
      value = value.value;
    }
    data[key] = value;
  }
  return data;
}

export const initForm = (
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  data: any,
  disableFields?: boolean
): void => {
  setInputs((inputs) => {
    let newInputs = {
      ...inputs,
    };
    // Set values from data and validate
    for (let key in inputs) {
      let value = data[key];
      if (typeof value !== "undefined") {
        let input = inputs[key];

        // Kauhee ratkasu
        const inputType = input.type;
        switch(inputType) {
          case EInputType.static:
            if(typeof value === 'object') {
              value = value.name;
            }
            break;
          case EInputType.select:
            if(typeof value === 'object') {
              value = value.id;
            } 
            break;
          case EInputType.searchSelect: 
            if(typeof value === 'object') {
              if(Array.isArray(value)) {
                if (value.length > 0 && typeof value[0] === 'object') {
                  value = value.map(v => ({value: v.id, label: v.name }));
                }
              } else {
                value = {value: value.id, label: value.name};
              }
            }
            break;
        }

        newInputs[key] = {
          ...input,
          value,
        };
      }
      updateInputValid(newInputs, key);
    }
    return newInputs;
  })
};

const updateInputValid = (
  newState: IInputField,
  inputName: string
): IInputField => {
  newState[inputName].validationResult = validateInput(
    newState,
    newState[inputName]
  );

  const validation = newState[inputName].validation;
  if (validation && validation.dependencies) {
    for (let dependencyName of validation.dependencies) {
      newState[dependencyName].validationResult = validateInput(
        newState,
        newState[dependencyName]
      );
    }
  }
  return newState;
};

export const updateInputHandler = (
  inputName: string,
  value: TInputValue,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>
) => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    newState[inputName].value = value;
    newState = updateInputValid(newState, inputName);
    return newState;
  });
};

export const disableInputs = (
  disabled: boolean,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  exclude?: string[]
): void => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    for (let key in newState) {
      if (exclude && exclude.includes(key)) {
        continue;
      }
      newState[key].disabled = disabled;
    }
    return newState;
  });
};

const INVALID_DEFAULT: IValidationResult = {
  isValid: false,
  message: null,
};
const validateRequiredIf = (
  value: string,
  requiredIfValue?: string | string[]
) => {
  if (!requiredIfValue) return false;
  if (Array.isArray(requiredIfValue)) {
    return !requiredIfValue.includes(value);
  } else {
    return requiredIfValue !== value;
  }
};

const isValidValue = (
  item: IInputFieldItem,
  requiredIfValue?: string | string[],
  requiredCompareValue?: string
): IValidationResult => {
  const { type, validation = {} } = item;
  let value;
  switch (type) {
    case EInputType.text:
    case EInputType.number:
    case EInputType.date:
    case EInputType.radio:
    case EInputType.tel:
    case EInputType.textarea:
    case EInputType.time:
    case EInputType.select:
    case EInputType.email:
    case EInputType.password:
      value = item.value as string;
      if (requiredCompareValue) {
        if (requiredCompareValue === value) return INVALID_DEFAULT;
      } else if (value === "") {
        return INVALID_DEFAULT;
      }
      // if (requireIfValue && requireIfValue !== value) return INVALID_DEFAULT;
      if (validateRequiredIf(value, requiredIfValue)) return INVALID_DEFAULT;
      if (type === EInputType.email && !isValidEmail(value as string))
        return {
          isValid: false,
          message: ETranslation.VALIDATION_EMAIL, // ADD Translation
        };
      if (validation.minLength && value.length < validation.minLength) {
        return {
          isValid: false,
          message:
            validation.minLengthMessage || ETranslation.VALIDATION_MIN_LENGTH,
          messageParams: {
            minLength: validation.minLength.toString(),
          },
        };
      }
      if (validation.maxLength && value.length > validation.maxLength) {
        return {
          isValid: false,
          message:
            validation.maxLengthMessage || ETranslation.VALIDATION_MAX_LENGTH,
          messageParams: {
            maxLength: validation.maxLength.toString(),
          },
        };
      }
      if (validation.minAmount && parseInt(value) < validation.minAmount) {
        return {
          isValid: false,
          message:
            validation.minAmountMessage || ETranslation.VALIDATION_MIN_AMOUNT,
          messageParams: {
            minAmount: validation.minAmount.toString(),
          },
        };
      }
      if (validation.maxAmount && parseInt(value) > validation.maxAmount) {
        return {
          isValid: false,
          message:
            validation.maxAmountMessage || ETranslation.VALIDATION_MAX_AMOUNT,
          messageParams: {
            maxAmount: validation.maxAmount.toString(),
          },
        };
      }
      break;
    case EInputType.checkbox:
      value = item.value as string[];
      if (value.length === 0) return INVALID_DEFAULT;
      break;
    case EInputType.datepicker:
      value = item.value as Date;
      if (!value) return INVALID_DEFAULT;
      break;
    default:
      return INVALID_DEFAULT;
  }
  return {
    isValid: true,
    message: null,
  };
};

export const validateInput = (
  inputs: IInputField,
  item: IInputFieldItem
): IValidationResult => {
  const { validation } = item;
  if (validation) {
    if (validation.required) {
      return isValidValue(item, undefined, validation.requiredCompareValue);
    } else if (validation.requiredIf) {
      if (
        isValidValue(inputs[validation.requiredIf], validation.requiredIfValue)
          .isValid
      ) {
        return isValidValue(item);
      }
    } else if (validation.requiredIfNot) {
      if (
        !isValidValue(
          inputs[validation.requiredIfNot],
          validation.requiredIfValue
        ).isValid
      ) {
        return isValidValue(item);
      }
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

export const validateInputs = (inputs: IInputField) => {
  const keys = Object.keys(inputs);
  for (let key of keys) {
    const item = inputs[key];
    const validationResult = validateInput(inputs, item);
    if (!validationResult.isValid) {
      return false;
    }
  }
  return true;
};

export const dateToDateTimeString = (date: Date | null): string => {
  if (!date || !dateFns.isValid(date)) return "";
  return dateFns.format(date, "dd.MM.yyyy HH:mm");
};

// copy without reference
export const dateToString = (date?: Date | null): string => {
  if (!date) return "";
  const newDate = new Date(date.valueOf());
  return dateFns.format(newDate, "dd.MM.yyyy");
};

// format date string to dd.MM.yyyy
export const formatDateString = (date?: string | null): string => {
  if (!date) return "";
  const newDate = new Date(date);
  return dateFns.format(newDate, "dd.MM.yyyy");
};

/**
 * Excel payment
 *
 * https://support.google.com/docs/answer/3093185?hl=fi
 * https://stackoverflow.com/a/6088618
 * https://gist.github.com/maarten00/23400873d51bf2ec4eeb
 *
 * MAKSU(korko; jaksojen_määrä; nykyinen_arvo; [tuleva_arvo], [loppu_tai_alku])
 * korko – Korkokanta (desimaalina, 0.25, ei 25%).
 * jaksojen_määrä – Suoritettavien maksutapahtumien määrä.
 * nykyinen_arvo – Annuiteetin nykyinen arvo.
 * tuleva_arvo – [VALINNAINEN] – Viimeisen maksun suorittamisen jälkeen jäljellä oleva tuleva arvo.
 * loppu_tai_alku – [VALINNAINEN – oletuksena 0] – Maksut erääntyvät kunkin jakson lopussa (0) tai alussa (1).
 * esim. pmt(3.25/100/12, 60, -28950.00,  3000.00, 1)
 */
export const pmt = (
  rate: number,
  number_of_periods: number,
  present_value: number,
  future_value?: number,
  end_or_beginning?: number
) => {
  rate = rate || 0;
  number_of_periods = number_of_periods || 0;
  present_value = present_value || 0;
  future_value = future_value || 0;
  end_or_beginning = end_or_beginning || 0;

  if (rate !== 0.0) {
    // Interest rate exists
    const q = Math.pow(1 + rate, number_of_periods);
    return (
      -(rate * (future_value + q * present_value)) /
      ((-1 + q) * (1 + rate * end_or_beginning))
    );
  } else if (number_of_periods !== 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_periods;
  }

  return 0.0;
};

/**
 * Excel future value
 *
 * https://support.google.com/docs/answer/3093224?hl=fi
 * https://gist.github.com/lancevo/6010111
 *
 * TULEVA.ARVO(korko; jaksojen_määrä; maksun_määrä; [nykyinen_arvo]; [loppu_tai_alku])
 * korko – Korkokanta.
 * jaksojen_määrä – Suoritettavien maksutapahtumien määrä.
 * maksun_määrä – Jaksoa kohden maksettava määrä.
 * nykyinen_arvo – [VALINNAINEN – 0 oletuksena] – Annuiteetin nykyinen arvo.
 * loppu_tai_alku – [VALINNAINEN – oletuksena 0] – Maksut erääntyvät kunkin jakson lopussa (0) tai alussa (1).
 */
export const fv = (
  rate: number,
  number_of_periods: number,
  payment_amount: number,
  present_value?: number,
  end_or_beginning?: number
) => {
  rate = rate || 0;
  number_of_periods = number_of_periods || 0;
  payment_amount = payment_amount || 0;

  let pow = Math.pow(1 + rate, number_of_periods);
  present_value = present_value || 0;
  end_or_beginning = end_or_beginning || 0;

  if (rate !== 0.0) {
    return (
      (payment_amount * (1 + rate * end_or_beginning) * (1 - pow)) / rate -
      present_value * pow
    );
  } else {
    return -1 * (present_value + payment_amount * number_of_periods);
  }
};

export const calculateMaintenanceBudgetTotal = (
  extraTireSets: number,
  extraTireSetPrice: number,
  maintenanceBudget: number,
  tireStoragePrice: number,
  replacementCarPrice: number
) => {
  // huoltobudjetti (total): lisärengassarjat * sarjahinta (pitää lisätä kenttä) + huoltobudjetti + rengashotelli + sijaisauto
  extraTireSets = extraTireSets || 0;
  extraTireSetPrice = extraTireSetPrice || 0;
  maintenanceBudget = maintenanceBudget || 0;
  tireStoragePrice = tireStoragePrice || 0;
  replacementCarPrice = replacementCarPrice || 0;

  const tirePrice = +extraTireSets * +extraTireSetPrice;
  if (tirePrice !== 0.0) {
    return (
      +tirePrice + +maintenanceBudget + +tireStoragePrice + +replacementCarPrice
    );
  } else {
    return +maintenanceBudget + +tireStoragePrice + +replacementCarPrice;
  }
};

export const calculateTotalPriceIncludingPollution = (
  carTax: number,
  totalPrice: number,
  replacementCarPrice: number,
  grossProfitVat: number,
  winterTires: number
) => {
  // kokonaishinta saastutuksella = kokonaishinta + sijaisauto + kate + talvirenkaat
  carTax = carTax || 0;
  totalPrice = totalPrice || 0;
  replacementCarPrice = replacementCarPrice || 0;
  grossProfitVat = grossProfitVat || 0;
  winterTires = winterTires || 0;
  return (
    +carTax +
    +totalPrice +
    +replacementCarPrice +
    +grossProfitVat +
    +winterTires
  );
};

export const toTwoDecimals = (num: number): number => {
  return Number(num.toFixed(2));
};

export const removeWhitespace = (str: string): string => {
  return str.replace(/\s/g, "");
};

export const generateUUID = (prefix?: string) => {
  return `${prefix}-${uuid.v1()}`;
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === "development";
};

export const copyToClipboard = (input: HTMLInputElement) => {
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand("copy");
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      window.getSelection()?.removeAllRanges();
    }
  }
};

export const servicesToOptions = (services?: IService[]) => {
  if (!services) return undefined;
  const options: IOption[] = [];
  services.forEach((s) => {
    if (s.id) {
      options.push({
        value: s.id.toString(),
        label: s.name,
      });
    }
  });
  return options;
};

export const serviceToOption = (service?: IService) => {
  if (!service || !service.id) return undefined;
  const option: IOption = {
    value: service.id.toString(),
    label: service.name,
  };

  return option;
};

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const createCSV = (csvString: string, fileName: string) => {
  if (!csvString) return;
  const a = document.createElement("a");
  window.URL = window.URL || window.webkitURL;
  const base64toBlobContent = base64toBlob(
    csvString,
    "attachment/csv;charset=ISO-8859-1"
  );
  // console.log("base64toBlobContent ", base64toBlobContent);

  const downloadUrl = window.URL.createObjectURL(base64toBlobContent);

  a.href = downloadUrl;
  a.target = "_blank";
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// ainoa toimiva byte[] utf8 -> iso-8859-1 käännös
const base64toBlob = (base64Data: string, contentType: string) => {
  contentType = contentType || "";
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};


export const linkHandler = (event: React.MouseEvent<HTMLElement>, route: string, history: History.History<unknown>): void => {
  event.ctrlKey ? window.open(route, "_blank") : history.push(route);
}