import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import LogItemsSearch from '../../components/LogItems/LogItemsSearch/LogItemsSearch';
import LogItemsTable from '../../components/LogItems/LogItemsTable/LogItemsTable';
import Alert from '../../components/UI/Alert/Alert';
import Container from '../../components/UI/Container/Container';
import Spinner from '../../components/UI/Spinner/Spinner';
import { IAppState, ILogItemState } from '../../interfaces';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';

interface IProps extends RouteComponentProps {
}

const LogItems: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading, error, logItems } = useSelector<IAppState, ILogItemState>(state => state.logItem);

  const searchHandler = useCallback((search) => {
    dispatch(actions.listLogItems(search));
  }, [dispatch]);

  return (
    <Container>
      {error && <Alert>{t(error)}</Alert>}
      <h2>{t(ETranslation.LOG_ITEMS)}</h2>
      <LogItemsSearch onSearch={searchHandler} />
      {loading ? (
        <Spinner />
      ) : logItems ? (
        <LogItemsTable
          logItems={logItems}
          sortable
        />
      ) : (
        <p>
          <br />
          {t(ETranslation.LOG_ITEM_NO_LOG_ITEMS)}
        </p>
      )}
    </Container>
  );
};

export default LogItems;