import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import * as searchService from "../../../services/searchService";
import { updateInputHandler } from "../../../shared/utility";
import { ETranslation } from "../../../translations/translation-keys";
import Input, { EInputType, IInputField, IInputOptions } from "../../UI/Input/Input";
import classes from "./Search.module.scss";

enum ESearch {
  search = "search",
}

interface IProps extends RouteComponentProps {
  text: string;
}

enum EKind {
  OfferRequest = "OfferRequest",
  Organization = "Organization",
}

interface ISearchItem {
  value: string;
  label: string;
  kind: EKind;
}

const Search: React.FC<IProps> = ({ text, history, location }) => {
  const { t } = useTranslation();

  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const [inputs, setInputs] = useState<IInputField>({
    [ESearch.search]: {
      type: EInputType.searchSelect,
      placeholder: t(ETranslation.COMMON_SEARCH),
      value: "",
    }
  });

  const createInput = (inputName: ESearch, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      {...item}
      {...options}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  useEffect(() => {
    if (searchOpen) setSearchOpen(false);
    // eslint-disable-next-line
  }, [location]);


  const searchOption = inputs[ESearch.search].value as ISearchItem;

  useEffect(() => {
    const value = searchOption.value;
    if (value) {
      // setInputs({ ...initialInputs });
      switch (searchOption.kind) {
        case EKind.OfferRequest:
          history.push(Routes.OFFER_REQUEST(value));
          break;
        case EKind.Organization:
          history.push(Routes.SERVICE_PROVIDER(value));
          break;
        default:
          break;
      }
    }
  }, [searchOption, history]);

  const classNames = [classes.Container];

  if (searchOpen) {
    classNames.push(classes.Open);
  }

  return (
    <div className={classNames.join(" ")}>
      {!searchOpen && (
        <p onClick={() => setSearchOpen(true)}>
          <FontAwesomeIcon icon={faSearch} />
          {" "}
          {text}
        </p>
      )}
      {searchOpen && createInput(ESearch.search, { 
        fetchOptions: searchService.search, 
        containerStyles: { width: "100%", margin: 0 }
      })}
    </div>
  );
}

export default withRouter(Search);
