import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_SAVE]: "Save",
  [ETranslation.COMMON_RETURN]: "Return",
  [ETranslation.COMMON_ADD]: "Add",
  [ETranslation.COMMON_REMOVE]: "Delete",
  [ETranslation.COMMON_COPY]: "Copy",
  [ETranslation.COMMON_STREET_ADDRESS]: "Street address",
  [ETranslation.COMMON_ZIP]: "ZIP code",
  [ETranslation.COMMON_CITY]: "City",
  [ETranslation.COMMON_NO_CHOICE]: "No choise",
  [ETranslation.COMMON_CREATED]: "Created",
  [ETranslation.COMMON_YES]: "Yes",
  [ETranslation.COMMON_NO]: "No",
  [ETranslation.COMMON_COUNTRY]: "Country",
  [ETranslation.COMMON_PHONE]: "Phone number",
  [ETranslation.COMMON_EMAIL]: "Email",
  [ETranslation.COMMON_SERVICE]: "Service",
  [ETranslation.COMMON_ARCHIVED]: "Archived",
  [ETranslation.COMMON_WWW]: "WWW",
  [ETranslation.COMMON_FACEBOOK]: "Facebook",
  [ETranslation.COMMON_TWITTER]: "Twitter",
  [ETranslation.COMMON_INSTAGRAM]: "Instagram",
  [ETranslation.COMMON_LINKEDIN]: "Linkedin",
  [ETranslation.COMMON_LATITUDE]: "Latitude",
  [ETranslation.COMMON_LONGITUDE]: "Longitude",
  [ETranslation.COMMON_TOKEN]: "Token",
  [ETranslation.COMMON_SEARCH]: "Search",
  [ETranslation.COMMON_SEND]: "Send",
  [ETranslation.COMMON_CANCEL]: "Cancel",
  [ETranslation.COMMON_ADD_ALL]: "Add all",
  [ETranslation.COMMON_CLOSE]: "Close",
  [ETranslation.COMMON_OPERATOR_NAME]: "Operator",
  [ETranslation.COMMON_SERVICE_PROVIDER_NAME]: "Serviceprovider",
  [ETranslation.COMMON_FETCH]: "Fetch",
  [ETranslation.COMMON_BUY]: "Purchase",
  [ETranslation.COMMON_COPY_CONTACT_INFORMATION]: "Copy to clipboard",
  [ETranslation.COMMON_COPIED]: "Copied to clipboard",
  [ETranslation.COMMON_CONTINUE]: "Continue",
  [ETranslation.COMMON_CONFIRM]: "Confirm",
  [ETranslation.COMMON_LOAD_ALL]: "Load all",
  
};

const geoLocationTranslation = {
  [ETranslation.GEO_LOCATION_ERROR]: 'Geo location search failed'
}

const authTranslations = {
  [ETranslation.TITLE_HANKIX]: "HankiX offer request service",
  [ETranslation.LOGIN]: "Login",
  [ETranslation.USERNAME]: "Username",
  [ETranslation.PASSWORD]: "Password",
  [ETranslation.PASSWORD_MIN_LENGTH_MESSAGE]:
  "Password must contain at least 6 characters",
  [ETranslation.FORGOT_PASSWORD]: "Forgot your password?",
  [ETranslation.RESET_PASSWORD]: "Reset password",
};

const headerTranslations = {
  [ETranslation.ADMIN]: "Admin",
  [ETranslation.OPERATORS]: "Operators",
  [ETranslation.ORGANIZATIONS]: "Organizations",
  [ETranslation.OFFERREQUESTS]: "Offer requests",
  [ETranslation.SERVICEPROVIDERS]: "Serviceproviders",
  [ETranslation.SERVICES]: "Services",
  [ETranslation.FEEDBACKS]: "Feedbacks",
  [ETranslation.USERS]: "Users",
  [ETranslation.LOGOUT]: "Log out",
  [ETranslation.SETTINGS]: "Settings",
  [ETranslation.CONTENTS]: "News management",
  [ETranslation.MANAGEMENT]: "Management",
  [ETranslation.OFFER_REQUEST_ACCESS]: "Purchased offer requests",
  [ETranslation.LOG_ITEMS]: "User log",
  [ETranslation.ORDER_SERVICE]: 'Order service',
};

const offerRequestsTranslations = {
  [ETranslation.TITLE_OFFERREQUESTS]: "Offer requests",
  [ETranslation.OFFERREQUESTS_ADD_NEW]: "Add new offer request",
  [ETranslation.NO_OFFERREQUESTS]: "No offer requests yet! Patience! Marketing is starting.",
};

const offerRequestTranslations = {
  [ETranslation.TITLE_OFFERREQUEST]: "Offer request",
  [ETranslation.TITLE_SORT]: "Sort",
  [ETranslation.TITLE_OFFERREQUEST_NEW]: "New offer request",
  [ETranslation.OFFERREQUEST_FIRSTNAME]: "Firstname",
  [ETranslation.OFFERREQUEST_LASTNAME]: "Lastname",
  [ETranslation.OFFERREQUEST_EMAIL]: "Email",
  [ETranslation.OFFERREQUEST_CREATED]: "Arrived",
  [ETranslation.OFFERREQUEST_ADDRESS]: "Address",
  [ETranslation.OFFERREQUEST_PHONE]: "Phone number",
  [ETranslation.OFFERREQUEST_CITY]: "City",
  [ETranslation.OFFERREQUEST_STATUS]: "Status",
  [ETranslation.OFFERREQUEST_TYPE]: "Type",
  [ETranslation.OFFERREQUEST_OPERATOR]: "Customr",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_AREA]: "Area m\u00b2",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE]: "Building type",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_BUILDING_TYPE_OTHER]:
    "Other buildring type",
  [ETranslation.OFFER_REQUEST_CONSTRUCTION_CONSTRUCTION_YEAR]: "Construction year",
  [ETranslation.OFFER_REQUEST_WELLNESS_LENGTH]: "Length",
  [ETranslation.OFFER_REQUEST_WELLNESS_REGULAR]: "Regular",
  [ETranslation.OFFERREQUEST_INFO]: "Additional information and clarifications",
  [ETranslation.OFFER_REQUEST_DELETED]: "Offer request deleted",
  [ETranslation.OFFER_REQUEST_ARCHIVED]: "Offer request archived",
  [ETranslation.OFFER_REQUEST_PROVINCE]: "Province",
  [ETranslation.OFFER_REQUEST_LANGUAGE]: "Kieli",

  [ETranslation.OFFER_REQUEST_ADD_ACCESS_ERROR]: "Something went wrong, Could not add rights to offer request",
  [ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST_CREATED]: "Offer request created date",
  [ETranslation.OFFER_REQUEST_ACCESS_USERNAME]: "Users name",
  [ETranslation.OFFER_REQUEST_ACCESS_OFFER_REQUEST]: "Offer request",
  [ETranslation.OFFER_REQUEST_ACCESS_CREATED]: "Purchase date",
  [ETranslation.OFFER_REQUEST_ACCESS_CREATED_START]: "Purchase date start",
  [ETranslation.OFFER_REQUEST_ACCESS_CREATED_END]: "Purchase date end",
  [ETranslation.OFFER_REQUEST_ACCESS_NO_OFFER_REQUESTS]:"No purchased offer requests were found",

};

const logItemTranslations = {
  [ETranslation.LOG_ITEM_EVENT]: "Event",
  [ETranslation.LOG_ITEM_USER_AGENT]: "User Agent",
  [ETranslation.LOG_ITEM_EVENT_CREATED_START]: "Event date start",
  [ETranslation.LOG_ITEM_EVENT_CREATED_END]: "Event date end",
  [ETranslation.LOG_ITEM_EVENTS_SELECT]: "Choose event",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW]: "OFFER_REQUEST_VIEW",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_VIEW_PIN]: "OFFER_REQUEST_VIEW_PIN",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE]: "OFFER_REQUEST_SAVE",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_SAVE_LOCAL]: "OFFER_REQUEST_SAVE_LOCAL",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_DELETE]: "OFFER_REQUEST_DELETE",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE]: "OFFER_REQUEST_UPDATE",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_UPDATE_PIN]: "OFFER_REQUEST_UPDATE_PIN",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_ARCHIVE_PIN]: "OFFER_REQUEST_ARCHIVE_PIN",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_LIST]: "OFFER_REQUEST_LIST",
  [ETranslation.LOG_ITEM_EVENTS_OFFER_REQUEST_ADD_ACCESS]: "OFFER_REQUEST_ADD_ACCESS",
  [ETranslation.LOG_ITEM_EVENTS_USER_ACCEPT_TERMS_OF_USE]: "USER_ACCEPT_TERMS_OF_USE",
  [ETranslation.LOG_ITEM_EVENTS_ORGANIZATION_CONFIRM_BILLING]: "ORGANIZATION_CONFIRM_BILLING",
  [ETranslation.LOG_ITEM_EVENTS_AUTH_LOGIN]: "AUTH_LOGIN",
  [ETranslation.LOG_ITEM_EVENTS_AUTH_LOGOUT]: "AUTH_LOGOUT",
  [ETranslation.LOG_ITEM_NO_LOG_ITEMS]: "No user log events were found",

}

const offerRequestTypeTranslations = {
  [ETranslation.OFFER_REQUEST_TYPE_SIMPLE]: "Simple",
  [ETranslation.OFFER_REQUEST_TYPE_CONSTRUCTION]: "Construction",
  [ETranslation.OFFER_REQUEST_TYPE_WELLNESS]: "Wellness",
};

const buildingTypeTranslations = {
  [ETranslation.BUIDLING_TYPE_SELECT]: "Select building type",
  [ETranslation.BUILDING_TYPE_TOWN_HOUSE]: "Town house",
  [ETranslation.BUILDING_TYPE_SEMI_DETACHED_HOUSE]: "Semi detached house",
  [ETranslation.BUILDING_TYPE_ROW_HOUSE]: "Row house",
  [ETranslation.BUILDING_TYPE_DETACHED_HOUSE]: "Detached house",
  [ETranslation.BUILDING_TYPE_APARTMENT_HOUSE]: "Apartment house",
  [ETranslation.BUILDING_TYPE_OTHER]: "Other",
};

const languageSelectTranslations = {
  [ETranslation.OFFERREQUEST_LANGUAGE_FI]: "Finnish",
  [ETranslation.OFFERREQUEST_LANGUAGE_EN]: "English",
  [ETranslation.OFFERREQUEST_LANGUAGE_SE]: "Swedish",
};

const reactSelectTranslations = {
  [ETranslation.REACT_SELECT_PLACEHOLDER]: "Select",
  [ETranslation.REACT_SELECT_ADD_NEW]: "Add",
};

const operatorsTranslations = {
  [ETranslation.TITLE_OPERATORS]: "Operators",
  [ETranslation.OPERATORS_ADD_NEW]: "Add new operator",
  [ETranslation.NO_OPERATORS]: "No operators.",
};

const offerRequestStatusTranslations = {
  [ETranslation.OFFERREQUEST_STATUS_OFFER]: "Offer open",
  [ETranslation.OFFERREQUEST_STATUS_OFFERED]: "Offer made",
  [ETranslation.OFFERREQUEST_STATUS_COMPLETE]: "Offer taken",
  [ETranslation.OFFERREQUEST_SEND_DATE]: "Sent date",
};

const serviceProvidersTranslations = {
  [ETranslation.TITLE_SERVICEPROVIDERS]: "Serviceproviders",
  [ETranslation.SERVICEPROVIDERS_ADD_NEW]: "Add new serviceprovider",
  [ETranslation.NO_SERVICEPROVIDERS]: "No serviceproviders",
  [ETranslation.SERVICEPROVIDER_FORMS]: "New orders",
  [ETranslation.NO_SERVICEPROVIDER_FORMS]: "No new orders",
};

const serviceProviderTranslations = {
  [ETranslation.TITLE_SERVICEPROVIDER]: "Serviceproviders",
  [ETranslation.TITLE_SORT]: "Sort",
  [ETranslation.TITLE_SERVICEPROVIDER_NEW]: "New serviceprovider",
  [ETranslation.SERVICEPROVIDER_FIRSTNAME]: "Firstname",
  [ETranslation.SERVICEPROVIDER_LASTNAME]: "Lastname",
  [ETranslation.SERVICEPROVIDER_EMAIL]: "Email",
  [ETranslation.SERVICEPROVIDER_CREATED]: "Joined",
  [ETranslation.SERVICEPROVIDER_ADDRESS]: "Address",
  [ETranslation.SERVICEPROVIDER_PHONE]: "Phone number",
  [ETranslation.SERVICEPROVIDER_CITY]: "City",
  [ETranslation.SERVICEPROVIDER_STATUS]: "Status",
  [ETranslation.SERVICEPROVIDER_TYPE]: "Type",
  [ETranslation.SERVICEPROVIDER_OPERATOR]: "Customer",
  [ETranslation.SERVICEPROVIDER_COMPANY]: "Company",

  [ETranslation.SERVICEPROVIDER_INVOICING_TYPE]: "Invoicing type",
  [ETranslation.SERVICEPROVIDER_EINVOICE_ADDRESS]: "EInvoice address",
  [ETranslation.SERVICEPROVIDER_EINVOICEOPERATOR_ADDRESS]: "Operator address",
  [ETranslation.SERVICEPROVIDER_INVOICEINFO]: "Invoice info",
  [ETranslation.SERVICEPROVIDER_INVOICE_CONFIRM]: "Opening an offer request is a paid action. Confirm your billing information.",
  [ETranslation.SERVICEPROVIDER_INVOICE_MODAL_DESCRIPTION]: "Confirmation of billing information allows you to purchase offer request information at unit price. We invoice purchases on a monthly basis as a consolidated invoice. You will receive the information in the request for quotation immediately and you will be able to make an offer to the customer. Purchasing a offer request does not guarantee the acceptance of the offer and does not oblige you to make an offer.",
  [ETranslation.SERVICEPROVIDER_INVOICE_CONFIRM_LABEL]: "I confirm that my billing information is correct.",
  [ETranslation.SERVICEPROVIDER_INVOICE_CHANGE_BILLING_INFORMATION]: "Change billing information",
  [ETranslation.SERVICEPROVIDER_SUBSCRIPTION_LEVEL]: "Subscription",
  [ETranslation.INVOICING_TYPE_EINVOICE]: "EInvoice",
  [ETranslation.INVOICING_TYPE_OTHER]: "Other billing type",
  [ETranslation.SERVICEPROVIDER_USER_PHONE]: "Users phone number",
  [ETranslation.SERVICEPROVIDER_ORGANIZATION_PHONE]: "Organizations phone number",
  [ETranslation.SUBSCRIPTION_LEVEL_FREE]: "Free",
  [ETranslation.SUBSCRIPTION_LEVEL_BASIC]: "Basic",
  [ETranslation.SERVICEPROVIDER_EXPORT_ALL]: "Export serviceproviders",
  [ETranslation.SERVICEPROVIDER_EXPORT]: "Export serviceprovider",
  [ETranslation.SUBSCRIPTION_EXPIRED]: "Your service subscription period has expired!",
  [ETranslation.SUBSCRIPTION_EXPIRED_INFO]: 'You can edit ',
  [ETranslation.SUBSCRIPTION_EXPIRED_INFO_LINK]: 'your business information',
  [ETranslation.SERVICEPROVIDER_EXPIRED_SUBSCRIBE]: "Order the service from www.hankix.fi or contact info@hankix.fi",
  [ETranslation.SERVICEPROVIDER_PAYMENT_METHOD]: "Payment method",
  [ETranslation.PAYMENT_METHOD_MONTH]: "Month",
  [ETranslation.PAYMENT_METHOD_MICRO]: "Micro",
};

const servicesTranslations = {
  [ETranslation.TITLE_SERVICES]: "Services",
  [ETranslation.SERVICES_ADD_NEW]: "Add new service",
  [ETranslation.NO_SERVICES]: "No services",
};

const serviceTranslations = {
  [ETranslation.TITLE_SERVICE]: "Service",
  [ETranslation.TITLE_SERVICE_NEW]: "New service",
  [ETranslation.SERVICE_NAME]: "Service name",
  [ETranslation.SERVICE_URL]: "URL",
};

const operatorTranslations = {
  [ETranslation.TITLE_OPERATOR]: "Operator",
  [ETranslation.TITLE_OPERATOR_NEW]: "New operator",
  [ETranslation.OPERATOR_NAME]: "Name",
  [ETranslation.OPERATOR_LEVEL]: "Level",
  [ETranslation.OPERATOR_BUSINESS_ID]: "Business ID",
  [ETranslation.OPERATOR_PIPEDRIVE_ORGANIZATION_ID]: "Pipedrive",
};

const usersTranslations = {
  [ETranslation.TITLE_USERS]: "Users",
  [ETranslation.USERS_ADD_NEW]: "Add new uder",
  [ETranslation.NO_USERS]: "No users.",
  [ETranslation.USER_NAME]: "Name",
  [ETranslation.USER_PASSWORD]: "Password",
};

const userTranslations = {
  [ETranslation.TITLE_USER]: "User",
  [ETranslation.TITLE_USER_NEW]: "New user",
  [ETranslation.USER_FIRST_NAME]: "Firstname",
  [ETranslation.USER_LAST_NAME]: "Lastname",
  [ETranslation.USER_EMAIL]: "Email",
  [ETranslation.USER_PHONE_NUMBER]: "Phone number",
  [ETranslation.USER_TITLE]: "Title",
  [ETranslation.USER_ALLOW_LOGIN]: "Allow login",
  [ETranslation.USER_PROFILE_IMAGE]: "Profile picture",
  [ETranslation.USER_ADDRESS]: "Home address",
  [ETranslation.USER_SEND_NEW_OFFER_REQUEST_SUMMARY]:
    "Send new offer requests to email",
};

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Check email",
  [ETranslation.VALIDATION_MIN_LENGTH]:
    "Input minimum of {{minLength}} characters.",
  [ETranslation.VALIDATION_MAX_LENGTH]: "Input maximum {{maxLength}} characters.",
  [ETranslation.VALIDATION_MIN_AMOUNT]:
    "Amount cannot be lesser than {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]:
    "Amount cannot be greater than {{maxAmount}}.",
};

const errorTranslations = {
  [ETranslation.TOKEN_ERROR]: "Login error.",
  [ETranslation.REFRESH_TOKEN_ERROR]: "Login error.",
  [ETranslation.FILE_UPLOAD_ERROR]: "File upload failed.",
  [ETranslation.IDENTIFIER_ERROR]: "Invalid identifier.",
  [ETranslation.DATABASE_ERROR]: "Tietokantavirhe.",
  [ETranslation.DATA_EMPTY_ERROR]: "Invalid input.",
  [ETranslation.ORGANIZATION_ERROR]: "Login error.",
  [ETranslation.NO_ACCESS_ERROR]: "You have no access.",
  [ETranslation.NOT_FOUND_ERROR]: "No results.",
  [ETranslation.PIPEDRIVE_NOT_CONNECTED_ERROR]:
    "You account has not been connected to Pipedrive.",
  [ETranslation.NO_CONTRACT_LENGTH_ERROR]:
    "Contract approval failed, contract duration was empty.",
  [ETranslation.SEARCH_QUERY_STRING_ERROR]: "Invalid input.",
  [ETranslation.FEEDBACK_ALREADY_EXISTS]: "Feedback has already been added",
};

const organizationTranslations = {
  [ETranslation.ORGANIZATION_ME]: "My organization",
  [ETranslation.ORGANIZATION_ORDER_DATE]: "Created",
  [ETranslation.ORGANIZATION_IMAGE]: "Logo",
  [ETranslation.ORGANIZATION_IMAGE_DROPZONE]: "Click here or drop an image",
  [ETranslation.ORGANIZATION_IMAGE_DROPZONE_DROP]: "Drop image",
};

const customerOfferRequestTranslations = {
  [ETranslation.CUSTOMER_OFFER_REQUEST]: "Offer request",
  [ETranslation.CUSTOMER_OFFER_REQUEST_SAVE_CHANGES]: "Save changes",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ARCHIVE]: "Archive",
  [ETranslation.CUSTOMER_OFFER_REQUEST_UNARCHIVE]: "Unarchive",
  [ETranslation.CUSTOMER_OFFER_REQUEST_HELP_TEXT_1]:
    "Specify and edit the information in the offer request",
  [ETranslation.CUSTOMER_OFFER_REQUEST_HELP_TEXT_2]:
    "If you no longer wish to receive further offers, please archive the offer request.",
  [ETranslation.CUSTOMER_OFFER_REQUEST_THANK_YOU_MESSAGE]: "Thank you, changes have been saved.",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ARCHIVED_CONFIRM]: "Offer request has been archived and it will no longer be shown to serviceproviders.",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ERROR_TOP]: "Error, offer request not found",
  [ETranslation.CUSTOMER_OFFER_REQUEST_ERROR_BOTTOM]: "Check the link or contact us.",
};

const confirmTranslations = {
  [ETranslation.CONFIRM_DELETE_SERVICEPROVIDER]:
    "Are you sure you want to delete this serviceprovider?",
  [ETranslation.CONFIRM_DELETE_SERVICE]: "Are you sure you want to delete this service?",
  [ETranslation.CONFIRM_DELETE_OPERATOR]:
    "Are you sure you want to delete this operator?",
  [ETranslation.CONFIRM_DELETE_LOCATION]:
    "Are you sure you want to delete this location?",
  [ETranslation.CONFIRM_DELETE_SERVICEPROVIDER_FORM]:
    "Are you sure you want to delete this order?",
  [ETranslation.CONFIRM_DELETE_OFFER_REQUEST]:
    "Are you sure you want to delete this offer request?",


    [ETranslation.CONFIRM_BUY_TITLE]: "Open offer request",
    [ETranslation.CONFIRM_BUY_DESCRIPTION]: "By purchasing the offer request you get to view the information until the customer does not want anymore offers and the offer request is archived.",
    [ETranslation.CONFIRM_BUY_QUESTION]: "Are you sure you want to purchace this offer request?"    
};

const locationsTranslations = {
  [ETranslation.NO_LOCATIONS]: "No locations ",
  [ETranslation.LOCATIONS]: "Locations",
  [ETranslation.LOCATIONS_NEW]: "New location",
  [ETranslation.LOCATION]: "Location",
  [ETranslation.LOCATION_NAME]: "Name",
  [ETranslation.LOCATION_NAME_GENITIVE]: "Name genetive ('s)",
  [ETranslation.LOCATION_NAME_INESSIVE]: "Name inessive (in/at)",
  [ETranslation.LOCATION_CONTENT]: "Content",
  [ETranslation.LOCATION_LATITUDE]: "Latitude, In Finland ~ 62",
  [ETranslation.LOCATION_LONGITUDE]: "Longitude, In Finland ~ 25",
  [ETranslation.LOCATION_ZOOM_LEVEL]: "Zoom level",
};

const feedbackTranslations = {
  [ETranslation.FEEDBACK_SATISFACTION]: "Satisfaction with service",
  [ETranslation.FEEDBACK_SERVICEPROVIDER]: "Serviceprovider",
  [ETranslation.FEEDBACK_SERVICE_PROVIDER_OTHER]: "Other serviceprovider",
  [ETranslation.TITLE_FEEDBACKS]: "Feedback",
  [ETranslation.TITLE_FEEDBACK_NEW]: "New feedback",
  [ETranslation.FEEDBACKS_ADD_NEW]: "Add new feedback",
  [ETranslation.NO_FEEDBACK]: "No feedback",
  [ETranslation.FEEDBACK_FORM_NOT_FOUND]: "I was left without offer requests",
  [ETranslation.FEEDBACK_FORM_SOMETHING_ELSE]: "Something else, what?",
  [ETranslation.FEEDBACK_THANK_YOU_TITLE]: "Thank you",
  [ETranslation.FEEDBACK_THANK_YOU_MESSAGE]: "Thank you for feedback!",
  [ETranslation.FEEDBACK_EXISTS_MESSAGE]: "Than you, you have already given feedback.",
  [ETranslation.FEEDBACK_MESSAGE]: "Feedback",
};
const settingsTranslations = {
  [ETranslation.TITLE_SETTINGS]: "Settings",
  [ETranslation.SEND_PASSWORD_RESET_LINK]: "Change password",
  [ETranslation.PASSWORD_RESET_TEXT]:
    "Are you sure you want to send a change password link to your email?",
};

const billingTranslations = {
  [ETranslation.BILLING_EXPORT_ALL]: "Export invoices",
  [ETranslation.BILLING_EXPORT_ORGANIZATION]: "Export invoice",
};

const selectTranslations = {
  [ETranslation.SELECT_SERVICE_PLACEHOLDER]: 'Choose service',
  [ETranslation.SELECT_OPERATOR_PLACEHOLDER]: 'Choose operator',
  [ETranslation.SELECT_LOCATIONS_PLACEHOLDER]: 'Choose locations',
  [ETranslation.SELECT_SERVICE_PROVIDER_PLACEHOLDER]: "Choose serviceprovider"
}

const contentsTranslations = {
  [ETranslation.TITLE_CONTENTS]: "News",
  [ETranslation.CONTENTS_ADD_NEW]: "Add new news",
  [ETranslation.NO_CONTENTS]: "No news",
};

const contentTranslations = {
  [ETranslation.TITLE_CONTENT]: "News",
  [ETranslation.TITLE_CONTENT_NEW]: "New news",
  [ETranslation.CONTENT_NAME]: "News name",
  [ETranslation.CONTENT_SHORTCONTENT]: "News in brief",
  [ETranslation.CONTENT_CONTENTTEXT]: "News contents",
  [ETranslation.CONTENT_DATE]: "News release date",
  [ETranslation.CONTENT_SHOWDATE]: "Show news release date",
  [ETranslation.CONTENT_TAGS]: "Tags",
  [ETranslation.CONTENT_TAG_NORMAL]: "Normal",
  [ETranslation.CONTENT_TAG_NEWS]: "News",
  [ETranslation.CONTENT_TAG_FRONTPAGE]: "Frontpage",

};

const languageTranslations = {
  [ETranslation.LANGUAGE_TITLE]: "Language",
  [ETranslation.LANGUAGE_IN_FINNISH]: "Suomeksi",
  [ETranslation.LANGUAGE_IN_ENGLISH]: "In english",
  [ETranslation.LANGUAGE_ENGLISH]: "English",
  [ETranslation.LANGUAGE_FINNISH]: "Suomi",


};

const termsOfServiceTranslations = {
  [ETranslation.TERMS_TITLE]: "Welcome to the HankiX-service! Start by accepting terms of use",
  [ETranslation.TERMS_BINDING]: "You agree to the terms of use:",
  [ETranslation.TERMS_LIST_1]: "legitimate business",
  [ETranslation.TERMS_LIST_2]: "treat customer information confidentially",
  [ETranslation.TERMS_LIST_3]: "to pay for the use of the service in accordance with the contract",
  [ETranslation.TERMS_READ_MORE]: "Read everything from the link",
  [ETranslation.TERMS_TERMS]: "terms of use",
  [ETranslation.TERMS_ACCEPT]: "Accept the terms of use",
}

const offerRequestInfoTranslations = {
  [ETranslation.OFFERREQUEST_INFO_OFFER_YOUR_SERVICE]: "Offer your service:",
  [ETranslation.OFFERREQUEST_INFO_LIST_ITEM_1]: "Select an interesting offer request from the list.",
  [ETranslation.OFFERREQUEST_INFO_LIST_ITEM_2]: "Make sure you can provide the requested service to the destination.",
  [ETranslation.OFFERREQUEST_INFO_LIST_ITEM_3]: "Contact the customer and submit your offer.",
  [ETranslation.OFFERREQUEST_INFO_QUESTIONS]: "Questions?",
  [ETranslation.OFFERREQUEST_INFO_READ_MORE]: "Read more about the service and pricing: ",
  [ETranslation.OFFERREQUEST_INFO_CONFIRM_INFO]: "Is your business information correct?",
  [ETranslation.OFFERREQUEST_INFO_EDIT_INFO]: "Edit",
}

const translationsEn = {
  ...commonTranslations,
  ...authTranslations,
  ...headerTranslations,
  ...operatorsTranslations,
  ...operatorTranslations,
  ...offerRequestTranslations,
  ...offerRequestsTranslations,
  ...offerRequestStatusTranslations,
  ...serviceProvidersTranslations,
  ...serviceProviderTranslations,
  ...servicesTranslations,
  ...serviceTranslations,
  ...reactSelectTranslations,
  ...usersTranslations,
  ...userTranslations,
  ...validationTranslations,
  ...errorTranslations,
  ...organizationTranslations,
  ...customerOfferRequestTranslations,
  ...confirmTranslations,
  ...locationsTranslations,
  ...feedbackTranslations,
  ...settingsTranslations,
  ...billingTranslations,
  ...offerRequestTypeTranslations,
  ...buildingTypeTranslations,
  ...selectTranslations,
  ...contentTranslations,
  ...contentsTranslations,
  ...geoLocationTranslation,
  ...logItemTranslations,
  ...languageTranslations,
  ...termsOfServiceTranslations,
  ...offerRequestInfoTranslations,
  ...languageSelectTranslations
};

export default translationsEn;
