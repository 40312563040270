import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { TDispatch } from '../../..';
import { Routes } from '../../../classes/Routes';
import Alert from '../../../components/UI/Alert/Alert';
import Button from '../../../components/UI/Button/Button';
import Container from '../../../components/UI/Container/Container';
import Input, { EInputType, IInputField, IInputOptions } from '../../../components/UI/Input/Input';
import InputGroup from '../../../components/UI/InputGroup/InputGroup';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { EInputUpdateAction } from '../../../context/InputContext';
import { EOrganizationLevel } from '../../../enums';
import { IAppState, IOrganizationState, IUser, IUserState } from '../../../interfaces';
import { getInputData, initForm, updateInputHandler, validateInputs } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';

interface IMatch {
  id: string;
  organizationId?: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

export enum EUser {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  phoneNumber = "phoneNumber",
  title = "title",
  allowLogin = "allowLogin",
  streetAddress = "streetAddress",
  zip = "zip",
  city = "city",
  password = "password",
  sendNewOfferRequestSummary = "sendNewOfferRequestSummary",
}

const User: React.FC<IProps> = ({ match, history }) => {
  const dispatch = useDispatch<TDispatch>();
  const [isValid, setValid] = useState(false);
  const [isServiceProviderUser, setIsServiceProviderUser] = useState(false);

  const { loading, error, user, saveOrUpdateOk } = useSelector<IAppState, IUserState>(state => state.users);
  const { organization } = useSelector<IAppState, IOrganizationState>(state => state.organization);

  const { t } = useTranslation();
  const { id, organizationId } = match.params;

  const initialInputs: IInputField = {
    [EUser.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_FIRST_NAME,
      placeholderTranslation: ETranslation.USER_FIRST_NAME,
      value: "",
      validation: {
        required: true,
      }
    },
    [EUser.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_LAST_NAME,
      placeholderTranslation: ETranslation.USER_LAST_NAME,
      value: "",
      validation: {
        required: true,
      }
    },
    [EUser.email]: {
      type: EInputType.email,
      labelTranslation: ETranslation.USER_EMAIL,
      placeholderTranslation: ETranslation.USER_EMAIL,
      value: "",
      validation: {
        required: true
      }
    },
    [EUser.phoneNumber]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_PHONE_NUMBER,
      placeholderTranslation: ETranslation.USER_PHONE_NUMBER,
      value: "",
    },
    [EUser.title]: {
      type: EInputType.text,
      labelTranslation: ETranslation.USER_TITLE,
      placeholderTranslation: ETranslation.USER_TITLE,
      value: "",
    },
    [EUser.streetAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_STREET_ADDRESS,
      placeholderTranslation: ETranslation.COMMON_STREET_ADDRESS,
      value: "",
    },
    [EUser.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_ZIP,
      placeholderTranslation: ETranslation.COMMON_ZIP,
      value: "",
    },
    [EUser.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.COMMON_CITY,
      placeholderTranslation: ETranslation.COMMON_CITY,
      value: "",
    },
    [EUser.allowLogin]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.USER_ALLOW_LOGIN,
      value: true,
    },
    [EUser.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.USER_PASSWORD,
      placeholderTranslation: ETranslation.USER_PASSWORD,
      value: "",
      validation: {
        required: Routes.isNew(id),
        minLength: 6,
        minLengthMessage: ETranslation.PASSWORD_MIN_LENGTH_MESSAGE,
      },
      readOnly: true,
    },
    [EUser.sendNewOfferRequestSummary]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.USER_SEND_NEW_OFFER_REQUEST_SUMMARY,
      value: "",
    },
  };

  const [inputs, setInputs] = useState<IInputField>({ ...initialInputs });

  useEffect(() => {
    if(saveOrUpdateOk) {
      history.goBack();
    }

    return () => {
      dispatch(actions.clearUser());
    }

  }, [dispatch, history, saveOrUpdateOk]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      if (organizationId) {
        dispatch(actions.getUser(id, organizationId));
      } else {
        dispatch(actions.getUser(id));
      }
    }
  }, [id, dispatch, organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch(actions.getOrganization(organizationId));
    }
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (organization) {
      setIsServiceProviderUser(organization.level === EOrganizationLevel.SERVICE_PROVIDER);
    }
  }, [organization]);

  useEffect(() => {
    if (user) {
      initForm(setInputs, user);
    } 
  }, [user]);

  useEffect(() => {
    setValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = (inputName: EUser, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.USER}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
      showValidation={!isValid}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const user = getInputData<IUser>(inputs);

    if (Routes.isNew(id)) {
      await dispatch(actions.saveUser(user, organizationId));
    } else {
      user.id = id;
      await dispatch(actions.updateUser(user, organizationId));
    }
  };

  return (
    <>
      {error && <Alert>{t(error)}</Alert>}
      <Container>
        <h2>{Routes.isNew(id) ? t(ETranslation.TITLE_USER_NEW) : t(ETranslation.TITLE_USER)}</h2>
        {loading
          ? <Spinner />
          : (
            <>
              <InputGroup>
                {createInput(EUser.firstName)}
                {createInput(EUser.lastName)}
              </InputGroup>
              {createInput(EUser.title)}
              {createInput(EUser.email, {
                disabled: Routes.isNotNew(id)
              })}
              {Routes.isNew(id) && createInput(EUser.password)}
              {createInput(EUser.phoneNumber)}
              {createInput(EUser.streetAddress)}
              <InputGroup>
                {createInput(EUser.zip)}
                {createInput(EUser.city)}
              </InputGroup>

              {createInput(EUser.allowLogin)}
              {isServiceProviderUser && createInput(EUser.sendNewOfferRequestSummary)}
            </>
          )
        }
        <InputGroup>
          <Button disabled={loading || !isValid} onClick={submitHandler}>
            {t(ETranslation.COMMON_SAVE)}
          </Button>
          <Button onClick={() => history.goBack()}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
        </InputGroup>
      </Container>
    </>
  );
};

export default User;
