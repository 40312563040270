// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";

const firebaseConfig = {
   apiKey: "AIzaSyCDDl5SALD0nQ3luvyLG7y3wsJikzEzh8o",
   authDomain: "hankix.firebaseapp.com",
   databaseURL: "https://hankix.firebaseio.com",
   projectId: "hankix",
   storageBucket: "hankix.appspot.com",
   messagingSenderId: "871159815572",
   appId: "1:871159815572:android:1ac816e787d7e575ff4381"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
